import {
  SCHEDULE_ADD,
  SCHEDULE_ADD_ERROR,
  SCHEDULE_ADD_RESET,
  SCHEDULE_ADD_SUCCESS,
  SCHEDULE_UPDATE,
  SCHEDULE_UPDATE_SUCCESS,
  SCHEDULE_UPDATE_RESET,
  SCHEDULE_UPDATE_ERROR,
} from "./types";

export const createSchedule = (schedule) => ({
  type: SCHEDULE_ADD,
  payload: schedule,
});

export const createScheduleSuccess = () => ({
  type: SCHEDULE_ADD_SUCCESS,
});

export const createScheduleError = (error) => ({
  type: SCHEDULE_ADD_ERROR,
  payload: error,
});

export const createScheduleReset = () => ({
  type: SCHEDULE_ADD_RESET,
});

export const updateSchedule = (id, schedule) => ({
  type: SCHEDULE_UPDATE,
  id,
  schedule,
});

export const updateScheduleSuccess = (newSchedule) => ({
  type: SCHEDULE_UPDATE_SUCCESS,
  payload: newSchedule,
});

export const updateScheduleError = (errors) => ({
  type: SCHEDULE_UPDATE_ERROR,
  payload: errors,
});

export const updateShecduleReset = () => ({
  type: SCHEDULE_UPDATE_RESET,
});
