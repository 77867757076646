import { message, Row, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyData } from '../../../components/EmptyData';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { PermissionsHandler } from '../../../components/PermissionHandler';
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { ProfileMenu } from '../../../components/ProfileMenu';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { ConfigContext } from '../../../context/config';
import { deleteUser, getUserById, resetUserDeleted, updateUser, userResetUpdated } from '../../../store/users/actions';
import { CAN_PUT } from '../../../utils/permissions-utils';
import { UserCard } from './components/UserCard';
import { AuthContext, checkToken } from '../../../context/Auth';
import { logoutAction } from '../../../store/logout';

export const ViewUser = ({ route, history, match: { params: { id, subpage } } }) => {

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const dispatch = useDispatch();

  const {
    user: { data: user, isLoading },
    me: { data: meData },
    update,
    delete: { isLoading: deleteLoading }
  } = useSelector(state => state.users);

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  useEffect(() => {
    !!id && dispatch(getUserById(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => dispatch(resetUserDeleted());
  }, [dispatch]);

  useEffect(() => {
    if (update.isUpdated) {
      message.success(messageTranslator("users.profile.card.update.success.message", "users"));
      dispatch(userResetUpdated());
    }
  }, [dispatch, update.isUpdated, messageTranslator]);

  const handleUserDelete = (id) => dispatch(deleteUser(id));

  const handleFormUpdate = (values) => {
    values.id = user.id;
    values.role = user.role['@id'];
    dispatch(updateUser(values));
  };

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={`${user ? `${user.firstName} ${user?.lastName}` : messageTranslator("users.empty", "users")}`}>
      <Spin spinning={isLoading}>
        {
          user && !isLoading && (
            <Row gutter={[30, 20]} justify="center" wrap="false" className='tabs-wrapper'>
              <PermissionsHandler
                resourceName="User"
                blocPermission={CAN_PUT}
                userRole={meData?.role}
                connectedUserId={meData?.id}
                ownerId={id}
              >
                <ProfileMenu
                  history={history}
                  id={id}
                />
              </PermissionsHandler>
              <UserCard
                user={user}
                me={meData}
                isLoading={isLoading}
                deleteLoading={deleteLoading}
                update={update}
                onDeleteUser={handleUserDelete}
                onFormSubmit={handleFormUpdate}
              />
            </Row>
          )
        }
      </Spin>
    </PageSkeleton>
  );
};
