// * Get notifications
export const NOTIFICATIONS_GET         = 'NOTIFICATIONS_GET';
export const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS';
export const NOTIFICATIONS_GET_ERROR   = 'NOTIFICATIONS_GET_ERROR';

// * Mark as seen
export const NOTIFICATIONS_SET_SEEN          = 'NOTIFICATIONS_SET_SEEN';
export const NOTIFICATIONS_SET_SEEN_SUCCESS  = 'NOTIFICATIONS_SET_SEEN_SUCCESS';
export const NOTIFICATIONS_SET_SEEN_ERROR    = 'NOTIFICATIONS_SET_SEEN_ERROR';

