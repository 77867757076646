import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchBar } from '../../../../../components/SearchBar';
import { ConfigContext } from '../../../../../context/config';
import { REPORTS } from '../../../../../routes/paths';
import { REPORTS_CATEGORIES } from '../../../../../utils/constants';
import { extractPageCategory } from '../../../../../utils/utils';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { CAN_CREATE } from '../../../../../utils/permissions-utils';
import "./reportsMenu.less";

export const ReportsMenu = ({
  history,
  page,
  searchTerms,
  onCreateNewReportClick,
  onSearch,
  user
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const reportsMenuItems = [
    {
      key: REPORTS_CATEGORIES.ALL,
      label: "reports.menu.all.label"
    },
    {
      key: REPORTS_CATEGORIES.SCHEDULED,
      label: "reports.menu.scheduled.label"
    },
    {
      key: REPORTS_CATEGORIES.UNSCHEDULED,
      label: "reports.menu.unscheduled.label"
    }
  ];

  const [selectedKey, setSelectedKey] = useState([]);

  useEffect(() => {
    setSelectedKey([extractPageCategory(history.location.pathname)]);
  }, [history]);

  const handleMenuClick = ({ key }) => setSelectedKey([key]);

  return (
    <div className="reports-header-wrapper">
      <Row>
        <Col flex="auto">
          <Menu
            onClick={handleMenuClick}
            selectedKeys={selectedKey}
            mode="horizontal"
            className="reports-menu"
          >
            {
              reportsMenuItems.map(item => (
                <Menu.Item key={item.key}>
                  <Link to={`${REPORTS}/${item.key}`}>
                    {messageTranslator(item.label, "reports")}
                  </Link>
                </Menu.Item>
              ))
            }
          </Menu>
        </Col>

        <Col flex="200px">
          <SearchBar
            history={history}
            page={page}
            searchTerms={searchTerms}
            inMenu
            onSearchTrigger={onSearch}
          />
        </Col>

        <PermissionsHandler
          resourceName="FolderReport"
          blocPermission={CAN_CREATE}
          userRole={user?.role}
        >
          <Col flex="164px">
            <Button
              icon={<PlusCircleOutlined />}
              onClick={onCreateNewReportClick}
              className="create-report-btn"
            >
              {messageTranslator("reports.menu.button.addNewReport", "reports")}
            </Button>
          </Col>
        </PermissionsHandler>
      </Row>
    </div>
  );
};
