import { put, takeLatest } from "redux-saga/effects";
import {
  changePasswordApi,
  createUserApi,
  deleteUserApi,
  getMeApi,
  getUserByIdApi,
  ListUsersApi,
  updateUserApi,
} from "../../api/users";
import { transformPermissions } from "../../utils/utils";
import {
  changePasswordError,
  changePasswordSuccess,
  createUserError,
  createUserSuccess,
  deleteUserError,
  deleteUserSuccess,
  getMeError,
  getMeSuccess,
  getUserByIdError,
  getUserByIdSuccess,
  getUsers,
  getUsersError,
  getUsersSuccess,
  updateUserError,
  updateUserSuccess,
} from "./actions";
import {
  USERS_LIST,
  USER_CHANGE_PASSWORD,
  USER_CREATE,
  USER_DELETE,
  USER_GET_BY_ID,
  USER_GET_ME,
  USER_UPDATE,
} from "./types";

// * Create user
function* createUserSaga({ payload: user }) {
  try {
    const res = yield createUserApi(user);
    yield put(createUserSuccess(res.data));
  } catch (error) {
    yield put(createUserError(error));
  }
}

export function* watchCreateUser() {
  yield takeLatest(USER_CREATE, createUserSaga);
}

// * Get user
function* getUserByIdSaga({ payload: id }) {
  try {
    const res = yield getUserByIdApi(id);
    yield put(getUserByIdSuccess(res.data));
  } catch (error) {
    yield put(getUserByIdError(error));
  }
}

export function* watchGetUserById() {
  yield takeLatest(USER_GET_BY_ID, getUserByIdSaga);
}

// * Get Me
function* getMeSaga() {
  try {
    const { data: me } = yield getMeApi();
    me.role.permissions = transformPermissions(me.role.permissions);
    yield put(getMeSuccess(me));
  } catch (error) {
    yield put(getMeError(error));
  }
}

export function* watchGetMe() {
  yield takeLatest(USER_GET_ME, getMeSaga);
}

// * Get users
function* getUsersSaga({ payload: params }) {
  try {
    const res = yield ListUsersApi(params);
    yield put(
      getUsersSuccess({
        users: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(getUsersError(error));
  }
}

export function* watchGetUsers() {
  yield takeLatest(USERS_LIST, getUsersSaga);
}

// * Update user
function* updateUserSaga({ payload: user }) {
  try {
    const res = yield updateUserApi(user);
    yield put(updateUserSuccess(res.data));
  } catch (error) {
    yield put(updateUserError(error));
  }
}

export function* watchUserUpdate() {
  yield takeLatest(USER_UPDATE, updateUserSaga);
}

// * Delete user
function* deleteUserSaga({ payload: id }) {
  try {
    yield deleteUserApi(id);
    yield put(deleteUserSuccess());
    yield put(getUsers(1));
  } catch (error) {
    yield put(deleteUserError(error));
  }
}

export function* watchDeleteUser() {
  yield takeLatest(USER_DELETE, deleteUserSaga);
}

// * Change password
function* changePasswordSaga({ payload: passwords }) {
  try {
    yield changePasswordApi(passwords);
    yield put(changePasswordSuccess());
  } catch (error) {
    yield put(changePasswordError(error));
  }
}

export function* watchChangePassword() {
  yield takeLatest(USER_CHANGE_PASSWORD, changePasswordSaga);
}
