import { Col, message, Row, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InboxMenu } from '../../../components/InboxMenu';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { createConfig, createConfigReset, getConfig, updateConfig, updateConfigReset } from '../../../store/config/actions';
import { CONFIG_NAMES } from '../../../utils/constants';
import { ScrapingSettingsForm } from './components/ScrappingSettingsForm';

export const ScrapingSettings = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const {
    config:
    {
      create,
      config,
      update: { isUpdated, isLoading }
    } } = useSelector(state => state);

  useEffect(() => {
    dispatch(getConfig(CONFIG_NAMES.scrap));
  }, [dispatch]);

  useEffect(() => {
    if (create.isCreated) {
      message.success(messageTranslator("config.settings.form.success.message", "config"));
      dispatch(getConfig(CONFIG_NAMES.scrap));
      dispatch(createConfigReset());
    }
  }, [create.isCreated, messageTranslator, dispatch]);

  useEffect(() => {
    if (isUpdated) {
      message.success(messageTranslator("config.settings.form.update.success.message", "config"));
      dispatch(updateConfigReset());
    }
  }, [isUpdated, messageTranslator, dispatch]);

  const handleSubmitScrapConfig = (_config) => {
    dispatch(config?.data?.name === CONFIG_NAMES.scrap ? updateConfig(_config) : createConfig(_config));
  };

  
  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={isLoading || create.isLoading || config.isLoading}>
        <Row gutter={[30, 20]} justify="center" wrap="false" className='tabs-wrapper'>
          <Col className='inbox-menu-wrapper'>
            <InboxMenu history={history} />
          </Col>
          <Col className='inbox-settings-wrapper'>
            <ScrapingSettingsForm
              config={config}
              onSubmitConfig={handleSubmitScrapConfig}
            />
          </Col>
        </Row>
      </Spin>
    </PageSkeleton>
  );
};
