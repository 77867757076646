export const ALERT_CREATE = "ALERT_CREATE";
export const ALERT_CREATE_SUCCESS = "ALERT_CREATE_SUCCESS";
export const ALERT_CREATE_ERROR = "ALERT_CREATE_ERROR";
export const ALERT_RESET_CREATED = "ALERT_RESET_CREATED";

export const ALERT_GET_BY_ID = "ALERT_GET_BY_ID";
export const ALERT_GET_BY_ID_SUCCESS = "ALERT_GET_BY_ID_SUCCESS";
export const ALERT_GET_BY_ID_ERROR = "ALERT_GET_BY_ID_ERROR";

export const ALERT_GET_LIST = "ALERT_GET_LIST";
export const ALERT_GET_LIST_SUCCESS = "ALERT_GET_LIST_SUCCESS";
export const ALERT_GET_LIST_ERROR = "ALERT_GET_LIST_ERROR";

export const ALERT_UPDATE = "ALERT_UPDATE";
export const ALERT_UPDATE_SUCCESS = "ALERT_UPDATE_SUCCESS";
export const ALERT_UPDATE_ERROR = "ALERT_UPDATE_ERROR";
export const ALERT_RESET_UPDATED = "ALERT_RESET_UPDATED";

export const ALERT_FIND_DUPLICATION = "ALERT_FIND_DUPLICATION";
export const ALERT_FIND_DUPLICATION_SUCCESS = "ALERT_FIND_DUPLICATION_SUCCESS";
export const ALERT_FIND_DUPLICATION_ERROR = "ALERT_FIND_DUPLICATION_ERROR";
export const ALERT_RESET_DUPLICATION = "ALERT_RESET_DUPLICATION";

export const ALERT_DELETE = "ALERT_DELETE";
export const ALERT_DELETE_SUCCESS = "ALERT_DELETE_SUCCESS";
export const ALERT_DELETE_ERROR = "ALERT_DELETE_ERROR";
export const ALERT_RESET_DELETED = "ALERT_RESET_DELETED";

export const ALERT_HANDLE_SELECT = "ALERT_HANDLE_SELECT";
