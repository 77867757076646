import { Badge, Card, Col, Row, Space, Typography, Spin } from 'antd';
import ReactECharts from 'echarts-for-react';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../context/config';
import { generateDateNames, generateDaysFromRange, getCount } from '../../../../utils/utils';

const POSITIVE_COLOR = "#21D59B";
const NEGATIVE_COLOR = "#FF0000";

export const ReactionsStats = ({ unit, dates, feedbacks, isLoading }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const options = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: 'category',
      data: dates?.length ? generateDaysFromRange({dates, messageTranslator, unit: feedbacks?.unit, number: feedbacks?.unit_number}) : generateDateNames(feedbacks?.results.negative ? feedbacks.results.negative : {}, unit, messageTranslator),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: getCount(feedbacks?.results.negative ? feedbacks.results.negative : {}, unit),
        type: 'line',
        smooth: true,
        symbol: 'none',
        itemStyle: {
          color: NEGATIVE_COLOR
        },
        lineStyle: {
          color: NEGATIVE_COLOR
        }
      },
      {
        data: getCount(feedbacks?.results.positive ? feedbacks.results.positive : {}, unit),
        type: 'line',
        smooth: true,
        symbol: 'none',
        itemStyle: {
          color: POSITIVE_COLOR
        },
        lineStyle: {
          color: POSITIVE_COLOR
        }
      }
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

  return (
    <Spin spinning={isLoading}>
    <Card>
      <Typography.Title level={3}>{messageTranslator("stats.reactions.card.title", "stats")}</Typography.Title>
      <Row justify="center" align="middle">
        <Col>
          <Space size={30}>
            <Badge color={POSITIVE_COLOR} text={messageTranslator("stats.reactions.card.positive.text", "stats")} />
            <Badge color={NEGATIVE_COLOR} text={messageTranslator("stats.reactions.card.negative.text", "stats")} />
          </Space>
        </Col>
      </Row>
      <ReactECharts option={options} />
    </Card>
    </Spin>
  )
}
