import { Avatar, Card, Col, List, Progress, Row, Typography, Spin } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../context/config';

export const MostInfluencers = ({ influencers, isLoading }) => {
  
  const { messageTranslator } = useContext(ConfigContext);

  const mapObjectToArray = (data) => {
    if (!data?.results) return [];
    return Object.keys(data.results).map(key => (data.results[key]))
  }

  return (
    <Spin spinning={isLoading}>
      <Card
        title={messageTranslator("stats.influencers.card.title", "stats")}
        className="static-card most-influence-card"
      >
        <List
          dataSource={mapObjectToArray(influencers)}
          renderItem={influencer => (
            <a href={`https://twitter.com/${influencer.name}`} target="blank">
              <List.Item>
                <List.Item.Meta
                  avatar={(
                    <Avatar
                      size={44}
                      src={influencer.image}
                    />
                  )}
                  title={(
                    <Row gutter={16}>
                      <Col flex='auto'>
                        <Typography.Text className='influencer-name'>{influencer.fullName}</Typography.Text><br />
                        <Typography.Text type="secondary">@{influencer.name}</Typography.Text>
                      </Col>
                      <Col flex='80px' className='influencer-percent'>
                        <Typography.Text><span>{influencer.score}</span> / 100</Typography.Text>
                        <Progress percent={influencer.score} showInfo={false} />
                      </Col>
                    </Row>
                  )}
                />
              </List.Item>
            </a>
          )}
        />
      </Card>
    </Spin>
  );
};
