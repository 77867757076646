import { CheckCircleFilled } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { FOLDER_STATES } from '../../../../../utils/constants';

export const FolderPublishedContent = ({ linkTo, linkToReport, linkToArchive, status, onModalClose }) => {

  let sendEmailButtonStyle = {
    padding: "0px"
  }

  return (
    <Space direction='vertical' size={22} className="confirm-send full-width">
      <CheckCircleFilled />
      <Typography.Title level={2}>{
        status === FOLDER_STATES.DRAFT
        ?
        "تم إرجاع الملف الصحفي للمحرر"
        :
        status === FOLDER_STATES.PUBLISHER_CHECK
        ?
        "تم إرسال الملف الصحفي للإعتماد و النشر"
        :
        status === FOLDER_STATES.PUBLISHED
        ?
        "تم نشر الملف الصحفي بنجاح"
        :
        null
      }</Typography.Title>
      <Typography.Text type="secondary">لمشاهدة صفحة الملف الصحفي <Link to={status === FOLDER_STATES.PUBLISHED ? linkToArchive : linkTo} onClick={onModalClose}>إضغط هنا</Link></Typography.Text>
      {status === FOLDER_STATES.PUBLISHED && <Typography.Text type="secondary">لرؤية تقرير الملف الصحفي <Link style={sendEmailButtonStyle} onClick={onModalClose} to={linkToReport}>إضغط هنا</Link></Typography.Text>}
    </Space>
  );
};
