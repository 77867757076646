import { Col, Empty, message, Row, Spin } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { getEmailGroups } from '../../../store/emailGroups/actions';
import { cancelSchedule, deleteReport, deleteReportReset, exportReport, exportReportReset, getReport, getSchedule } from '../../../store/reports/actions';
import { createSchedule, createScheduleReset, updateSchedule, updateShecduleReset } from '../../../store/schedule/actions';
import { REPORT_FORMAT } from '../../../utils/constants';
import { categorizeData } from '../../../utils/utils';
import { CategoriesCounter } from './components/CategoriesCounter';
import { ReceiversModal } from './components/ReceiversModal';
import { ReportHeader } from './components/ReportHeader';
import { ReportNewsList } from './components/ReportNewsList';
import { ScheduleModal } from './components/ScheduleModal';
import { logoutAction } from '../../../store/logout';
import { AuthContext, checkToken } from '../../../context/Auth';

export const ViewReport = ({ route, match: { params: { id } }, history }) => {

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  const {
    config: { globalConfig: { data: globalConfig } },
    reports: {
      report: { data: report, isLoading: reportIsLoading },
      delete: deleteReportState,
      export: { isLoading: loadingExport, isExported, file, error: exportError },
      cancelSchedule: { isLoading: loadingCancelSchedule, isCancelled },
      schedule
    },
    emailGroups: {
      list
    },
    schedules: {
      create: { isCreated },
      update: { isUpdated }
    },
    users: {
      me: { data: meData },
    },
  } = useSelector(state => state);
  const modalRef = useRef();
  const emailsModalRef = useRef();

  const [cetegorizedData, setCategorizedData] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [exprtParams, setExportParams] = useState(false);

  useEffect(() => {
    dispatch(getReport(id));
    dispatch(getEmailGroups({}));
  }, [id, dispatch]);

  useEffect(() => {
    if (report) {
      setCategorizedData(categorizeData(report.folders));
    }
  }, [report]);

  useEffect(() => {
    if(report?.scheduledReport) {
      dispatch(getSchedule(report?.scheduledReport?.['id']));
    }
  }, [report, dispatch]);

  useEffect(() => {
    if (isCreated) {
      modalRef?.current.closeModal();
      modalRef?.current.resetFields();
      dispatch(createScheduleReset());
    }
  }, [isCreated, dispatch]);

  useEffect(() => {
    if (isUpdated) {
      message.success(messageTranslator("report.schedule.form.update.success.message", "reports"));
      modalRef?.current.closeModal();
      dispatch(updateShecduleReset());
    }
  }, [isUpdated, messageTranslator, dispatch]);

  useEffect(() => {
    if (deleteReportState.isDeleted) {
      dispatch(deleteReportReset());
      history.push('/reports/all');
    }
  }, [deleteReportState.isDeleted, dispatch, history]);

  useEffect(() => {
    if (isExported) {
      setModalVisibility(false);
      emailsModalRef.current.resetModal();
      dispatch(exportReportReset());
      if ([REPORT_FORMAT.html, REPORT_FORMAT.mail].includes(exprtParams.format))
        message.success(messageTranslator("reports.view.send.modal.email.success.message", "reports"));
    }
  }, [isExported, dispatch, messageTranslator, exprtParams]);

  useEffect(() => {
    if (file && file.file_path && exprtParams.format === REPORT_FORMAT.pdf) {
      window.open(`${process.env.REACT_APP_BASE_URL}${file.file_path}`);
    }
  }, [file, exprtParams]);

  const handleOpenModal = () => modalRef?.current.openModal();

  const handleSubmitReport = (schedule) => {
    report && dispatch(report?.scheduled ? updateSchedule(report?.scheduledReport?.id, {...schedule, alert: report.alert['@id']}) : createSchedule({ ...schedule, alert: report.alert['@id'] }));
  };

  const handleDeleteReport = (id) => dispatch(deleteReport(id));

  const handleCloseModal = () => setModalVisibility(false);

  const handleExportReport = (params) => {
    setExportParams(params);
    if (params.format === REPORT_FORMAT.pdf) {
      dispatch(exportReport({ id }));
    }
    else if ([REPORT_FORMAT.html, REPORT_FORMAT.mail].includes(params.format)) {
      setModalVisibility(true);
    }
  };

  const handleSubmitEmails = (emails) => {
    const params = { id, ...emails };
    if (exprtParams.format === REPORT_FORMAT.html) params.format = REPORT_FORMAT.html;
    dispatch(exportReport(params));
  }

  const handleCancelSchedule = (id) => dispatch(cancelSchedule(id));

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={reportIsLoading || loadingCancelSchedule || loadingExport}>
        {
          report && !reportIsLoading && (
            <>
              <ReportHeader
                report={report}
                delete={deleteReportState}
                isCancelledSchedule={isCancelled}
                user={meData}
                onExportReport={handleExportReport}
                onScheduleClick={handleOpenModal}
                onDeleteReport={handleDeleteReport}
                onCancelSchedule={handleCancelSchedule}
              />
              <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>

                  <CategoriesCounter
                    categorizedNews={cetegorizedData}
                  />

                  <ReportNewsList
                    globalConfig={globalConfig}
                    categorizedNews={cetegorizedData}
                  />

                  <ScheduleModal
                    schedule={!!report.scheduledReport ? schedule : null}
                    report={report}
                    ref={modalRef}
                    emailGroupList={list.data}
                    onOk={handleSubmitReport}
                  />

                </Col>
              </Row>
            </>
          )
        }
        <ReceiversModal
          ref={emailsModalRef}
          modalProps={{
            visible: isModalVisible,
            title: messageTranslator("reports.view.send.modal.title", "reports")
          }}
          emailGroupList={list.data}
          isLoading={loadingExport}
          onCancel={handleCloseModal}
          onOk={handleSubmitEmails}
          exportError={exportError}
        />
      </Spin>
    </PageSkeleton>
  );
};
