import { put, takeLatest } from 'redux-saga/effects';
import { getScrapResultsApi } from '../../api/scrap-results';
import { getScrapResultsError, getScrapResultsSuccess } from './actions';
import { SCRAP_RESULTS_GET } from './types';

function* getScrapResultsSaga({ payload: params }) {
  try {
    const res = yield getScrapResultsApi(params);
    yield put(getScrapResultsSuccess({
      data: res.data['hydra:member'],
      totalItems: res.data['hydra:totalItems']
    }));
  } catch (error) {
    yield put(getScrapResultsError(error));
  }
};

export function* watchGetScrapResults() {
  yield takeLatest(SCRAP_RESULTS_GET, getScrapResultsSaga);
};