import { Avatar, Badge, Col, List, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { FALLBACK_IMAGE, SCRAP_FALLBACK_IMAGE } from '../../../../../utils/constants';

export const ReportNewsList = ({ globalConfig, categorizedNews = [], isLoading }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [categoryIcon, setCatgoryIcon] = useState(FALLBACK_IMAGE);

  useEffect(() => {
    if (globalConfig) setCatgoryIcon(() => {
      if (globalConfig?.values.default_category_image?.contentUrl) return `${process.env.REACT_APP_BASE_URL}${globalConfig.values.default_category_image.contentUrl}`;
      return FALLBACK_IMAGE;
    })
  }, [globalConfig]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {
          categorizedNews.map(category => (
            <div
              className="daily-folder-category-list"
              key={category.id}
            >
              <List
                size="large"
                dataSource={[...category.data]}
                loading={isLoading}
                locale={{
                  emptyText: messageTranslator("alerts.daily.result.empty.text", "alerts")
                }}
                header={(
                  <Row align='middle'>
                    <Col flex="75px">
                      <Avatar
                        src={category.image ? `${process.env.REACT_APP_BASE_URL}${category.image}` : categoryIcon}
                      />
                    </Col>
                    <Col flex="auto">
                      <Typography.Text strong>{category.name}</Typography.Text>
                    </Col>
                  </Row>
                )}
                renderItem={result => (
                  <List.Item
                    className="ant-form-small"
                    actions={result.childrenDuplicated.map(child => (
                      <Tooltip title={child.title || `-- ${messageTranslator("alerts.daily.untitled.text", "alerts")} --`}>
                        <a
                          href={child.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Avatar
                            size={40}
                            src={child.sourceImage || SCRAP_FALLBACK_IMAGE}
                          />
                        </a>
                      </Tooltip>
                    ))}
                  >
                    <List.Item.Meta
                      avatar={(
                        <a
                          href={result.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Badge offset={[-5, 50]} count={
                            result.sourceType !== 'website' ? (
                              <Avatar
                                size={20}
                                src={`/images/social-media/${result.sourceType}.png`}
                                className="avatar-source"
                              />
                            ) : null
                          }>
                            <Avatar
                              size={64}
                              src={result.sourceImage || `${process.env.REACT_APP_BASE_URL}${globalConfig?.values?.default_source_image?.contentUrl}`}
                            />
                          </Badge>
                        </a>
                      )}
                      title={(
                        <>
                          <span className='date'>{moment(result.createdAt).format('DD MMM')}</span> <span className='user'>
                            <a
                              href={result.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {`${result.sourceType === 'twitter' ? `@${result.sourceName}` : result.sourceName}`}
                            </a>
                          </span>
                        </>
                      )}
                      description={(
                        <a
                          href={result.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {result.title || result.content.substring(0, 200)}
                        </a>
                      )}
                    />
                  </List.Item>
                )}
              />
            </div>
          ))
        }
      </Col>
    </Row>
  );
};
