import { message } from "antd";
import { put, takeLatest } from "redux-saga/effects";
import {
  createAlertApi,
  deleteAlertApi,
  getAlertByIdApi,
  getAlertsApi,
  updateAlertApi,
} from "../../api/alert";
import {
  createAlertError,
  createAlertSuccess,
  deleteAlertError,
  deleteAlertSuccess,
  findDuplicatedAlertError,
  findDuplicatedAlertSuccess,
  getAlertListError,
  getAlertListSuccess,
  getAlertSuccess,
  updateAlertError,
  updateAlertSuccess,
  handleSelectedAlert,
} from "./actions";
import {
  ALERT_CREATE,
  ALERT_DELETE,
  ALERT_FIND_DUPLICATION,
  ALERT_GET_BY_ID,
  ALERT_GET_LIST,
  ALERT_UPDATE,
  ALERT_HANDLE_SELECT,
} from "./types";

// Create
function* createAlertSaga({ payload: alert }) {
  try {
    const res = yield createAlertApi(alert);
    yield put(createAlertSuccess(res.data.id));
  } catch (error) {
    message.error(error?.response?.data?.message);
    yield put(createAlertError(error));
  }
}

export function* watchCreateAlert() {
  yield takeLatest(ALERT_CREATE, createAlertSaga);
}

// List
function* getAlertListSaga({ payload: params }) {
  try {
    const res = yield getAlertsApi(params);
    yield put(
      getAlertListSuccess({
        list: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(getAlertListError(error));
  }
}

export function* watchGetAlertList() {
  yield takeLatest(ALERT_GET_LIST, getAlertListSaga);
}

//handle selected alert

function* handleSelectedAlertSaga({ payload: data }) {
  yield put(handleSelectedAlert(data));
}

export function* watchHandleSelectedAlert() {
  yield takeLatest(ALERT_HANDLE_SELECT, handleSelectedAlertSaga);
}

// Get alert
function* getAlertSaga({ payload: id }) {
  try {
    const res = yield getAlertByIdApi(id);
    yield put(getAlertSuccess(res.data));
  } catch (error) {
    yield put(getAlertListError(error));
  }
}

export function* watchGetALert() {
  yield takeLatest(ALERT_GET_BY_ID, getAlertSaga);
}

// Find duplication
function* findDuplicationSaga({ payload: name }) {
  try {
    const res = yield getAlertsApi({ page: 1, name });
    yield put(
      findDuplicatedAlertSuccess({
        data: !!res.data["hydra:member"].length,
        identifiers: res.data["hydra:member"].map((alert) => alert.id),
      })
    );
  } catch (error) {
    yield put(findDuplicatedAlertError(error));
  }
}

export function* watchFindDuplication() {
  yield takeLatest(ALERT_FIND_DUPLICATION, findDuplicationSaga);
}

// Update alert
function* updateAlertSaga({ payload: alert }) {
  try {
    const res = yield updateAlertApi(alert);
    yield put(updateAlertSuccess(res.data));
  } catch (error) {
    yield put(updateAlertError(error));
  }
}

export function* watchUpdateAlert() {
  yield takeLatest(ALERT_UPDATE, updateAlertSaga);
}

// Delete alert
function* deleteAlertSaga({ payload: id }) {
  try {
    yield deleteAlertApi(id);
    yield put(deleteAlertSuccess(id));
  } catch (error) {
    yield put(deleteAlertError(error));
  }
}

export function* watchDeleteAlert() {
  yield takeLatest(ALERT_DELETE, deleteAlertSaga);
}
