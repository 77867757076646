import { put, takeLatest } from "redux-saga/effects";
import {
  createReportApi,
  deleteReportApi,
  exportReportApi,
  getReportApi,
  getReportsApi,
  updateReportApi,
} from "../../api/reports";
import { cancelScheduleApi, getSheduleApi } from "../../api/schedule";
import {
  cancelScheduleError,
  cancelScheduleSuccess,
  createReportError,
  createReportSuccess,
  deleteReportError,
  deleteReportSuccess,
  exportReportError,
  exportReportSuccess,
  getReportError,
  getReportsSuccess,
  getReportSuccess,
  updateReportError,
  updateReportSuccess,
  getReportScheduleSuccess,
  getReportScheduleError,
} from "./actions";
import {
  REPORT_CANCEL_SCHEDULE,
  REPORT_CREATE,
  REPORT_DELETE,
  REPORT_EXPORT,
  REPORT_GET_LIST,
  REPORT_GET_ONE,
  REPORT_UPDATE,
  REPORT_GET_SCHEDULE,
} from "./types";

// * Create report
function* createReportSaga({ payload: report }) {
  try {
    const res = yield createReportApi(report);
    yield put(createReportSuccess(res.data));
  } catch (error) {
    yield put(createReportError(error));
  }
}

export function* watchCreateReport() {
  yield takeLatest(REPORT_CREATE, createReportSaga);
}

// * Get report
function* getReportSaga({ payload: id }) {
  try {
    const res = yield getReportApi(id);
    yield put(getReportSuccess(res.data));
  } catch (error) {
    yield put(getReportError(error));
  }
}

export function* watchGetReport() {
  yield takeLatest(REPORT_GET_ONE, getReportSaga);
}

// * Get reports
function* getReportsSaga({ payload: params }) {
  try {
    const res = yield getReportsApi(params);
    yield put(
      getReportsSuccess({
        data: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(createReportError(error));
  }
}

export function* watchGetReports() {
  yield takeLatest(REPORT_GET_LIST, getReportsSaga);
}

// * Update report
function* updateReportSaga({ payload: report }) {
  try {
    const res = yield updateReportApi(report);
    yield put(updateReportSuccess(res.data));
  } catch (error) {
    yield put(updateReportError(error));
  }
}

export function* watchUpdateReport() {
  yield takeLatest(REPORT_UPDATE, updateReportSaga);
}

// * Delete report
function* deleteReportSaga({ payload: id }) {
  try {
    yield deleteReportApi(id);
    yield put(deleteReportSuccess(id));
  } catch (error) {
    yield put(deleteReportError(error));
  }
}

export function* watchDeleteReport() {
  yield takeLatest(REPORT_DELETE, deleteReportSaga);
}

// * Export report
function* exportReportSaga({ payload: params }) {
  try {
    const res = yield exportReportApi(params);
    yield put(exportReportSuccess(res.data));
  } catch (error) {
    yield put(exportReportError(error));
  }
}

export function* watchExportReport() {
  yield takeLatest(REPORT_EXPORT, exportReportSaga);
}

// * Cancel schedule
function* cancelScheduleSaga({ payload: id }) {
  try {
    yield cancelScheduleApi(id);
    yield put(cancelScheduleSuccess());
  } catch (error) {
    yield put(cancelScheduleError(error));
  }
}

export function* watchCancelSchedule() {
  yield takeLatest(REPORT_CANCEL_SCHEDULE, cancelScheduleSaga);
}

//* Get schedule
function* getScheduleSaga({ payload: id }) {
  try {
    const res = yield getSheduleApi(id);
    yield put(getReportScheduleSuccess(res.data));
  } catch (error) {
    yield put(getReportScheduleError(error));
  }
}

export function* watchGetSchedule() {
  yield takeLatest(REPORT_GET_SCHEDULE, getScheduleSaga);
}
