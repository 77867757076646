import { Col, Layout, Row } from 'antd';
import React from 'react';
import { Logo } from '../../../../components/Logo';
import { EntityLogo } from '../../../../components/EntityLogo';
import './styles.less';

const { Header } = Layout;

export const NewsHeader = () => {
  return (
    <Header className='anonym-header'>
      <Row align='middle'>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
          <Row justify='space-between' align='middle' wrap={false}>
            <Col flex="200px">
              <Logo />
            </Col>
            <Col flex="200px" className='text-right'>
              <EntityLogo />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>

  );
};
