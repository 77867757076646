import {
  alertResultUpdateCategory,
  removeItemFromArray,
  replaceItemInArray,
} from "../../utils/utils";
import {
  ALERT_RESULTS_ADVANCED_SEARCH,
  ALERT_RESULTS_ADVANCED_SEARCH_ERROR,
  ALERT_RESULTS_ADVANCED_SEARCH_SUCCESS,
  ALERT_RESULTS_FILTER_BY_SOURCE_TYPE,
  ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_ERROR,
  ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_SUCCESS,
  ALERT_RESULTS_GET,
  ALERT_RESULTS_GET_ERROR,
  ALERT_RESULTS_GET_SUCCESS,
  ALERT_NEW_RESULTS_GET,
  ALERT_NEW_RESULTS_GET_ERROR,
  ALERT_NEW_RESULTS_GET_SUCCESS,
  ALERT_NEW_RESULTS_GET_RESET,
  ALERT_RESULTS_PATCH_ACTIONS,
  ALERT_RESULTS_PATCH_ACTIONS_ERROR,
  ALERT_RESULTS_PATCH_ACTIONS_SUCCESS,
  ALERT_RESULTS_PATCH_CATEGORY,
  ALERT_RESULTS_PATCH_CATEGORY_ERROR,
  ALERT_RESULTS_PATCH_CATEGORY_RESET,
  ALERT_RESULTS_PATCH_CATEGORY_SUCCESS,
  ALERT_RESULTS_SEARCH,
  ALERT_RESULTS_SEARCH_ERROR,
  ALERT_RESULTS_SEARCH_SUCCESS,
  FOLDER_ADD,
  FOLDER_ADD_ERROR,
  FOLDER_ADD_RESET,
  FOLDER_ADD_SUCCESS,
  FOLDER_CHANGE_STATE_AUDITOR,
  FOLDER_CHANGE_STATE_AUDITOR_ERROR,
  FOLDER_CHANGE_STATE_AUDITOR_RESET,
  FOLDER_CHANGE_STATE_AUDITOR_SUCCESS,
  FOLDER_COMBINE_DUPLICATIONS,
  FOLDER_COMBINE_DUPLICATIONS_ERROR,
  FOLDER_COMBINE_DUPLICATIONS_RESET,
  FOLDER_COMBINE_DUPLICATIONS_SUCCESS,
  FOLDER_GET_DAILY,
  FOLDER_GET_DAILY_ERROR,
  FOLDER_GET_DAILY_SUCCESS,
  FOLDER_PATCH,
  FOLDER_PATCH_ERROR,
  FOLDER_PATCH_RESET,
  FOLDER_PATCH_SUCCESS,
  FOLDER_PUBLISH,
  FOLDER_PUBLISH_ERROR,
  FOLDER_PUBLISH_RESET,
  FOLDER_PUBLISH_SUCCESS,
  FOLDER_REMOVE_ITEM,
  FOLDER_REMOVE_ITEM_ERROR,
  FOLDER_REMOVE_ITEM_RESET,
  FOLDER_REMOVE_ITEM_SUCCESS,
  FOLDER_SET_REVIEW,
  FOLDER_SET_REVIEW_ERROR,
  FOLDER_SET_REVIEW_RESET,
  FOLDER_SET_REVIEW_SUCCESS,
  SEARCH_SOURCE_NAMES,
  SEARCH_SOURCE_NAMES_ERROR,
  SEARCH_SOURCE_NAMES_SUCCESS,
} from "./types";

export const alertResultsInitialState = {
  results: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null,
  },
  newResults: {
    data: [],
    newAlerts: 0,
    isLoading: false,
    errors: null,
  },
  patchActions: {
    isLoading: false,
    errors: null,
  },
  sourceNames: {
    isLoading: false,
    data: [],
    errors: null,
  },
  patchCategory: {
    isLoading: false,
    isPatched: null,
    errors: null,
  },
  simpleSearch: {
    isLoading: false,
    errors: null,
  },
  filterBySourceName: {
    isLoading: false,
    errors: null,
  },
  folders: {
    create: {
      isLoading: false,
      isCreated: false,
      errors: null,
    },
    duplication: {
      isLoading: false,
      isCreated: false,
      errors: null,
    },
    folder: {
      data: null,
      isLoading: false,
      isLoadedComplete: false,
      errors: null,
    },
    update: {
      isLoading: false,
      isUpdated: false,
      errors: null,
    },
    publish: {
      isLoading: false,
      isPublished: false,
      errors: null,
    },
    review: {
      isLoading: false,
      isSetToReview: false,
      errors: null,
    },
    delete: {
      isLoading: false,
      isDeleted: false,
      errors: null,
    },
    auditorState: {
      isLoading: false,
      isUpdated: false,
      errors: null,
    },
  },
};

export const alertResultsReducer = (
  state = alertResultsInitialState,
  action
) => {
  switch (action.type) {
    case ALERT_RESULTS_GET:
      return {
        ...state,
        results: {
          ...state.results,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_RESULTS_GET_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          isLoading: false,
          data: action.payload.results,
          totalItems: action.payload.totalItems,
        },
      };

    case ALERT_RESULTS_GET_ERROR:
      return {
        ...state,
        results: {
          ...state.results,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_NEW_RESULTS_GET:
      return {
        ...state,
        newResults: {
          ...state.newResults,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_NEW_RESULTS_GET_SUCCESS:
      return {
        ...state,
        newResults: {
          ...state.newResults,
          isLoading: false,
          data: action.payload.newResults,
          newAlerts: action.payload.newAlerts,
        },
      };

    case ALERT_NEW_RESULTS_GET_ERROR:
      return {
        ...state,
        results: {
          ...state.newResults,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_NEW_RESULTS_GET_RESET:
      return {
        ...state,
        results: {
          ...state.newResults,
          newAlerts: 0,
        },
      };

    case ALERT_RESULTS_PATCH_ACTIONS:
      return {
        ...state,
        patchActions: {
          ...state.patchActions,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_RESULTS_PATCH_ACTIONS_SUCCESS:
      return {
        ...state,
        patchActions: {
          ...state.patchActions,
          isLoading: false,
        },
        results: {
          ...state.results,
          data: alertResultUpdateCategory(action.payload)
            ? removeItemFromArray(action.payload.data.id, state.results.data)
            : replaceItemInArray(action.payload.data, state.results.data, "id"),
        },
      };

    case ALERT_RESULTS_PATCH_ACTIONS_ERROR:
      return {
        ...state,
        patchActions: {
          ...state.patchActions,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Search source names
    case SEARCH_SOURCE_NAMES:
      return {
        ...state,
        sourceNames: {
          ...state.sourceNames,
          isLoading: true,
          errors: null,
        },
      };

    case SEARCH_SOURCE_NAMES_SUCCESS:
      return {
        ...state,
        sourceNames: {
          ...state.sourceNames,
          isLoading: false,
          data: action.payload,
        },
      };

    case SEARCH_SOURCE_NAMES_ERROR:
      return {
        ...state,
        sourceNames: {
          ...state.sourceNames,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Advanced search
    case ALERT_RESULTS_ADVANCED_SEARCH:
      return {
        ...state,
        results: {
          ...state.results,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_RESULTS_ADVANCED_SEARCH_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          data: action.payload.data,
          totalItems: action.payload.totalItems,
          isLoading: false,
        },
      };

    case ALERT_RESULTS_ADVANCED_SEARCH_ERROR:
      return {
        ...state,
        results: {
          ...state.results,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Patch result category
    case ALERT_RESULTS_PATCH_CATEGORY:
      return {
        ...state,
        patchCategory: {
          ...state.patchCategory,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_RESULTS_PATCH_CATEGORY_SUCCESS:
      return {
        ...state,
        patchCategory: {
          ...state.patchCategory,
          isLoading: false,
          isPatched: action.payload,
        },
        results: {
          ...state.results,
          data: replaceItemInArray(action.payload, state.results.data, "id"),
        },
      };

    case ALERT_RESULTS_PATCH_CATEGORY_ERROR:
      return {
        ...state,
        patchCategory: {
          ...state.patchCategory,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_RESULTS_PATCH_CATEGORY_RESET:
      return {
        ...state,
        patchCategory: {
          ...state.patchCategory,
          isPatched: null,
        },
      };

    // * Search for results
    case ALERT_RESULTS_SEARCH:
      return {
        ...state,
        simpleSearch: {
          ...state.simpleSearch,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_RESULTS_SEARCH_SUCCESS:
      return {
        ...state,
        simpleSearch: {
          ...state.simpleSearch,
          isLoading: false,
        },
        results: {
          ...state.results,
          data: action.payload.data,
          totalItems: action.payload.totalItems,
        },
      };

    case ALERT_RESULTS_SEARCH_ERROR:
      return {
        ...state,
        simpleSearch: {
          ...state.simpleSearch,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Filter by sourceType
    case ALERT_RESULTS_FILTER_BY_SOURCE_TYPE:
      return {
        ...state,
        filterBySourceName: {
          ...state.filterBySourceName,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_SUCCESS:
      return {
        ...state,
        filterBySourceName: {
          ...state.filterBySourceName,
          isLoading: false,
        },
        results: {
          ...state.results,
          data: action.payload.data,
          totalItems: action.payload.totalItems,
        },
      };

    case ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_ERROR:
      return {
        ...state,
        filterBySourceName: {
          ...state.filterBySourceName,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Add folder
    case FOLDER_ADD:
      return {
        ...state,
        folders: {
          ...state.folders,
          create: {
            ...state.folders.create,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_ADD_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          create: {
            ...state.folders.create,
            isLoading: false,
            isCreated: true,
          },
          folder: {
            ...state.folders.folder,
            data: action.payload,
          },
        },
      };

    case FOLDER_ADD_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          create: {
            ...state.folders.create,
            isLoading: false,
            errors: action.payload,
          },
        },
      };

    case FOLDER_ADD_RESET:
      return {
        ...state,
        folders: {
          ...state.folders,
          create: {
            ...state.folders.create,
            isCreated: false,
            errors: null,
          },
        },
      };

    // * Get daily folder
    case FOLDER_GET_DAILY:
      return {
        ...state,
        folders: {
          ...state.folders,
          folder: {
            ...state.folders.folder,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_GET_DAILY_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          folder: {
            ...state.folders.folder,
            data: action.payload,
            isLoadedComplete: true,
            isLoading: false,
          },
        },
      };

    case FOLDER_GET_DAILY_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          folder: {
            ...state.folders.folder,
            errors: action.payload,
            isLoading: false,
          },
        },
      };

    // * Patch folder
    case FOLDER_PATCH:
      return {
        ...state,
        folders: {
          ...state.folders,
          update: {
            ...state.folders.update,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_PATCH_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          update: {
            ...state.folders.update,
            isLoading: false,
            isUpdated: true,
          },
        },
      };

    case FOLDER_PATCH_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          update: {
            ...state.folders.update,
            isLoading: false,
            errors: action.payload,
          },
        },
      };

    case FOLDER_PATCH_RESET:
      return {
        ...state,
        folders: {
          ...state.folders,
          update: {
            ...state.folders.update,
            isUpdated: false,
            errors: null,
          },
        },
      };

    case FOLDER_COMBINE_DUPLICATIONS:
      return {
        ...state,
        folders: {
          ...state.folders,
          duplication: {
            ...state.folders.duplication,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_COMBINE_DUPLICATIONS_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          duplication: {
            ...state.folders.duplication,
            isLoading: false,
            isCreated: true,
          },
        },
      };

    case FOLDER_COMBINE_DUPLICATIONS_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          duplication: {
            ...state.folders.duplication,
            isLoading: false,
            errors: action.payload,
          },
        },
      };

    case FOLDER_COMBINE_DUPLICATIONS_RESET:
      return {
        ...state,
        folders: {
          ...state.folders,
          duplication: {
            ...state.folders.duplication,
            isCreated: false,
            errors: null,
          },
        },
      };

    // * Publish folder
    case FOLDER_PUBLISH:
      return {
        ...state,
        folders: {
          ...state.folders,
          publish: {
            ...state.folders.publish,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_PUBLISH_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          publish: {
            ...state.folders.publish,
            isLoading: false,
            isPublished: true,
          },
        },
      };

    case FOLDER_PUBLISH_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          publish: {
            ...state.folders.publish,
            isLoading: false,
            errors: action.payload,
          },
        },
      };

    case FOLDER_PUBLISH_RESET:
      return {
        ...state,
        folders: {
          ...state.folders,
          publish: {
            ...state.folders.publish,
            isPublished: false,
            errors: null,
          },
        },
      };

    // * Set to review
    case FOLDER_SET_REVIEW:
      return {
        ...state,
        folders: {
          ...state.folders,
          review: {
            ...state.folders.review,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_SET_REVIEW_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          review: {
            ...state.folders.review,
            isLoading: false,
            isSetToReview: true,
          },
        },
      };

    case FOLDER_SET_REVIEW_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          review: {
            ...state.folders.review,
            isLoading: false,
            errors: action.payload,
          },
        },
      };

    case FOLDER_SET_REVIEW_RESET:
      return {
        ...state,
        folders: {
          ...state.folders,
          review: {
            ...state.folders.review,
            isSetToReview: false,
            errors: null,
          },
        },
      };

    // * Remove item from folder
    case FOLDER_REMOVE_ITEM:
      return {
        ...state,
        folders: {
          ...state.folders,
          delete: {
            ...state.folders.delete,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_REMOVE_ITEM_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          delete: {
            ...state.folders.delete,
            isLoading: false,
            isDeleted: true,
          },
        },
      };

    case FOLDER_REMOVE_ITEM_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          delete: {
            ...state.folders.delete,
            isLoading: false,
            errors: action.payload,
          },
        },
      };

    case FOLDER_REMOVE_ITEM_RESET:
      return {
        ...state,
        folders: {
          ...state.folders,
          delete: {
            ...state.folders.delete,
            isDeleted: false,
            errors: null,
          },
        },
      };

    // * Auditor chnage folder state
    case FOLDER_CHANGE_STATE_AUDITOR:
      return {
        ...state,
        folders: {
          ...state.folders,
          auditorState: {
            ...state.folders.auditorState,
            isLoading: true,
            errors: null,
          },
        },
      };

    case FOLDER_CHANGE_STATE_AUDITOR_SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          auditorState: {
            ...state.folders.auditorState,
            isLoading: false,
            isUpdated: true,
          },
        },
      };

    case FOLDER_CHANGE_STATE_AUDITOR_ERROR:
      return {
        ...state,
        folders: {
          ...state.folders,
          auditorState: {
            ...state.folders.auditorState,
            isLoading: false,
            errors: action.payload,
          },
        },
      };

    case FOLDER_CHANGE_STATE_AUDITOR_RESET:
      return {
        ...state,
        folders: {
          ...state.folders,
          auditorState: {
            ...state.folders.auditorState,
            isUpdated: false,
            errors: null,
          },
        },
      };

    default:
      return state;
  }
};
