export const routesTranslations = {
  ar: {
    "auth.login.pageTabTitle": "تسجيل الدخول",
    "auth.forget.pageTabTitle": "نسيت كلمة المرور",
    "auth.reset.pageTabTitle": "إعادة تعيين كلمة المرور",
    "routes.accounts.pageTabTitle": "الحسابات",
    "routes.home.pageTabTitle": "الرئيسية",
    "routes.inbox.pageTabTitle": "الوارد",
    "alerts.create": "إنشاء تنبيه",
    "routes.sources.pageTabTitle": "المصادر",
    "routes.sources.scrap.pageTabTitle": "جميع المرصود ف ",
    "routes.roles.pageTabTitle": "الصلاحيات",
    "routes.roles.create.pageTabTitle": "إنشاء صلاحيات",
    "routes.roles.update.pageTabTitle": "تعديل ملف الصلاحيات",
    "routes.categories.pageTabTitle": "التصنيفات",
    "routes.daily.pageTabTitle": "الملف اليومي",
    "routes.reports.pageTabTitle": "التقارير",
    "routes.influencers.pageTabTitle": "المؤثرين",
    "routes.news.pageTabTitle": "الأخبار",
    "routes.alertReuslts.pageTabTitle": "قائمة الأخبار",
    "routes.allNotifications.pageTabTitle": "قائمة الإشعارات",
    "routes.dashboard.pageTabTitle": "لوحة الإحصائيات",
    "routes.config.pageTabTitle": "الإعدادات",
    "routes.mailGroup.pageTabTitle": "المجموعات البريدية",
    "routes.newMailGroup.pageTabTitle": "إنشاء مجموعة بريدية",
    "routes.updateMailGroup.pageTabTitle": "تعديل مجموعة بريدية",
    "routes.globalConfig.pageTabTitle": "الإعدادات العامة",
    "routes.scrapingConfig.pageTabTitle": "إعدادات جلب الأخبار",
    "routes.twitterAPiSettings.pageTabTitle": "إعدادات API تويتر",
    "routes.twitterCriteriaSettings.pageTabTitle":
      "إعدادات معايير البحث في تويتر",

    "routes.sidebar.items.all": "جميع الأخبار",
    "routes.sidebar.items.daily": "الملف الصحفي اليومي",
    "routes.sidebar.items.unseen": "الغير مقروء",
    "routes.sidebar.items.bookmarked": "المفضلة",
    "routes.sidebar.items.archived": "المحفوظة",
    "routes.sidebar.items.deleted": "المحذوفات",
    "routes.sidebar.items.undesirable": "الغير مهمة",
    "routes.sidebar.items.foldersArchive": "أرشيف الملفات",
    "routes.sidebar.label.alerts.count": "تنبيه",

    "routes.footer.copyrights": "جميع الحقوق محفوظة لــ منصة راصد",
  },
  en: {
    "auth.login.pageTabTitle": "Login",
    "auth.forget.pageTabTitle": "Forgot password",
    "auth.reset.pageTabTitle": "Reset password",
    "routes.accounts.pageTabTitle": "Accounts",
    "routes.home.pageTabTitle": "Home",
    "routes.inbox.pageTabTitle": "Inbox",
    "alerts.create": "Create alert",
    "routes.sources.pageTabTitle": "Sources",
    "routes.sources.scrap.pageTabTitle": "All observed in ",
    "routes.roles.create.pageTabTitle": "Create role",
    "routes.roles.pageTabTitle": "Roles",
    "routes.roles.update.pageTabTitle": "Edit role",
    "routes.categories.pageTabTitle": "Categories",
    "routes.daily.pageTabTitle": "Daily folder",
    "routes.reports.pageTabTitle": "Reports",
    "routes.influencers.pageTabTitle": "Influencers",
    "routes.news.pageTabTitle": "News",
    "routes.alertReuslts.pageTabTitle": "News list",
    "routes.allNotifications.pageTabTitle": "Notifications list",
    "routes.dashboard.pageTabTitle": "Stats dashboard",
    "routes.config.pageTabTitle": "Configs",
    "routes.mailGroup.pageTabTitle": "Email groups",
    "routes.newMailGroup.pageTabTitle": "New email group",
    "routes.updateMailGroup.pageTabTitle": "Update email group",
    "routes.globalConfig.pageTabTitle": "Global configurations",
    "routes.scrapingConfig.pageTabTitle": "Scraping configurations",
    "routes.twitterAPiSettings.pageTabTitle": "Twitter API configurations",
    "routes.twitterCriteriaSettings.pageTabTitle":
      "Twitter search criteria configurations",

    "routes.sidebar.items.all": "All news",
    "routes.sidebar.items.daily": "Daily press folder",
    "routes.sidebar.items.unseen": "Unread news",
    "routes.sidebar.items.bookmarked": "Favorite",
    "routes.sidebar.items.archived": "Saved news",
    "routes.sidebar.items.deleted": "Recycle",
    "routes.sidebar.items.undesirable": "Undesirable",
    "routes.sidebar.items.foldersArchive": "Folders Archive",
    "routes.sidebar.label.alerts.count": "alerts",

    "routes.footer.copyrights": "All rights reserved to Rased platform",
  },
};
