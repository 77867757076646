import { Button, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { PermissionsHandler } from '../../../components/PermissionHandler';
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { SearchBar } from '../../../components/SearchBar';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { activateUpdateSource, createSource, deleteSource, deleteSourceReset, findDuplicatedSourceName, findDuplicatedSourceUrl, listSources, resetCreateSource, resetDuplicatedSourceName, resetDuplicatedSourceUrl, updateSource, updateSourceReset } from '../../../store/sources/actions';
import { CAN_CREATE } from '../../../utils/permissions-utils';
import { getPageSearchQueries, makeValidUrl, pageChangeHandler, searchHandler } from '../../../utils/utils';
import { AddSourceModal } from './components/AddSourceModal';
import { SourcesList } from './components/SourcesList';
import { AuthContext } from '../../../context/Auth';
import { checkToken } from '../../../context/Auth';
import { logoutAction } from "../../../store/logout";
import './listSources.less';

export const ListSources = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const [isModalVisible, setModalVisibility] = useState(() => false);
  const [selectedSource, setSelectedSource] = useState(() => null);
  const [page, setPage] = useState(() => 1);
  const [searchTerms, setSearchTerms] = useState({});

  const dispatch = useDispatch();

  const searchParams = useQuery(history);

  const {
    users: { me: { data: meData } },
    sources: {
      list,
      delete: { isLoading, isDeleted },
      create: { isCreated, ...restCreate },
      update: { isUpdated, ...restUpdate },
      duplications,
      toggleEnabled: { isLoading: loadingActivation }
    }
  } = useSelector(state => state);

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    dispatch(listSources(terms));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (isCreated || isUpdated) {
      handleCloseModal();
      dispatch(updateSourceReset());
      dispatch(resetCreateSource());
    }
  }, [dispatch, isCreated, isUpdated]);

  useEffect(() => {
    if (isDeleted) {
      dispatch(deleteSourceReset());
    }
  }, [isDeleted, dispatch]);

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handleSearch = (terms) => searchHandler(history, terms);

  const handleDeleteSource = (id) => dispatch(deleteSource(id));

  // * Add Source
  const handleSourceSubmit = (source) => {
    if(source.url) source.url = makeValidUrl(source.url);
    if (source.facebookUrl) source.facebookUrl = makeValidUrl(source.facebookUrl);
    if (source.instagramUrl) source.instagramUrl = makeValidUrl(source.instagramUrl);
    if (source.twitterUrl) source.twitterUrl = makeValidUrl(source.twitterUrl);
    if (source.youtubeUrl) source.youtubeUrl = makeValidUrl(source.youtubeUrl);
    dispatch(selectedSource ? updateSource({
      ...source,
      id: selectedSource.id
    }) : createSource(source));
  };

  const handleOpenModal = () => setModalVisibility(true);

  const handleCloseModal = () => {
    setSelectedSource(null);
    setModalVisibility(false);
  };

  const handleCheckSourceName = ({ target: { value: name } }) =>  !!name ? dispatch(findDuplicatedSourceName({ page: 1, name })) : dispatch(resetDuplicatedSourceName());

  const handleCheckSourceUrl = ({ target: { value: url } }) => !!url ? dispatch(findDuplicatedSourceUrl({ page: 1, url: makeValidUrl(url) })) : dispatch(resetDuplicatedSourceUrl());

  const handleNameChange = () => dispatch(resetDuplicatedSourceName());

  const handleUrlChange = () => dispatch(resetDuplicatedSourceUrl());
  // * Add Source

  // * Update Source
  const handleSelectedSource = (source) => {
    handleOpenModal();
    setSelectedSource(source);
  };

  // * Activate update
  const handleToggleEnabled = (data) => dispatch(activateUpdateSource(data));

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={list.isLoading || isLoading || restCreate.isLoading || restUpdate.isLoading || duplications.isLoading || loadingActivation}>
        <PermissionsHandler
          resourceName="Source"
          blocPermission={CAN_CREATE}
          userRole={meData?.role}
        >
          <div className='btn-wrapper'>
            <Button
              type="primary"
              onClick={handleOpenModal}
            >
              {messageTranslator("sources.button.newSource", "sources")}
            </Button>
          </div>
        </PermissionsHandler>

        <SearchBar
          label={route.title}
          searchTerms={searchTerms}
          history={history}
          onSearchTrigger={handleSearch}
        />

        <SourcesList
          sources={list}
          role={meData?.role}
          loadingActivation={loadingActivation}
          loadingDelete={isLoading}
          currentPage={page}
          onPageChange={handlePageChange}
          onUpdate={handleSelectedSource}
          onDeleteSource={handleDeleteSource}
          onToggleEnabled={handleToggleEnabled}
        />

        <AddSourceModal
          visible={isModalVisible}
          selectedSource={selectedSource}
          create={restCreate}
          update={restUpdate}
          translator={messageTranslator}
          duplications={duplications}
          onOk={handleSourceSubmit}
          onCancel={handleCloseModal}
          onCheckSourceName={handleCheckSourceName}
          onCheckSourceUrl={handleCheckSourceUrl}
          onNameChange={handleNameChange}
          onUrlChange={handleUrlChange}
        />
      </Spin>
    </PageSkeleton>
  );
};
