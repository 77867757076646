export const usersTranslations = {
  ar: {
    "users.create.button.text": "إضافة مستخدم جديد",
    "users.list.emptyText": "لا يوجد حسابات مضافة حالياً",
    "users.list.labels.username": "إسم المستخدم",
    "users.list.labels.role": "الصلاحيات",
    "users.list.labels.creationDate": "تاريخ الإنشاء",
    "users.list.labels.jobtitle": "المسمى الوظيفي",
    "users.list.labels.firstName": "الإسم الأول",
    "users.list.labels.lastName": "إسم العائلة",
    "users.list.labels.email": "البريد الإلكتروني",
    "users.list.labels.userRole": "صلاحيات المستخدم",
    "users.list.labels.password": "كلمة المرور",
    "users.list.labels.confirmPassword": "تأكيد كلمة المرور",
    "users.list.labels.generatePassword": "أنشئ كلمة مرور",
    "users.list.labels.invalidEmail": "الرجاء إدخال بريد إلكتروني صحيح !",
    "users.list.labels.invalidPassword": "كلمة العبور يجب أن لا تقل عن 8 أحرف !",
    "users.list.labels.passwordsNotMatch": "كلمتي العبور غير متطابقتين !",

    "users.list.labels.updateUserData": "تعديل بيانات المستخدم",
    "users.list.labels.deleteUser": "حذف المستخدم",
    "users.list.labels.deleteConfirmText": "تأكيد حذف المستخدم",
    "users.list.label.add": "إضافة مستخدم جديد",
    "users.list.label.update": "تعديل بيانات المستخدم",
    "users.empty": "لا توجد بيانات",

    "users.add.image.title": "صورة المستخدم",
    "users.add.image.error": "الرجاء تحميل صورة المستخدم",

    "users.profile.update.coords": "تعديل معلومات الحساب",
    "users.profile.update.password": "تعديل كلمة المرور",
    
    "users.profile.card.update.title": "معلومات الحســاب",
    "users.profile.card.changePassword.title": "كلمة المرور",
    
    "users.profile.card.update.success.message": "تم تعديل معلومات الحســاب بنجاح",
    
    "users.profile.card.password.current.label": "كلمة المرور الحالية",
    "users.profile.card.password.new.label": "كلمة المرور الجديدة",
    "users.profile.card.password.confirm.label": "إعادة كلمة المرور الجديدة",
    "users.profile.card.avatar.error": "الرجاء تحميل صورة للملف الشخصي !",

    "users.password.change.error.reusedPassword": "لا يمكن إعادة استخدام كلمة المرور القديمة!",

    "users.password.change.success.message": "تم تغيير كلمة العبور"
  },
  en: {
    "users.create.button.text": "Add a new user",
    "users.list.emptyText": "There are no accounts added at the moment",
    "users.list.labels.username": "Username",
    "users.list.labels.role": "Role",
    "users.list.labels.creationDate": "Creation date",
    "users.list.labels.jobtitle": "Job title",
    "users.list.labels.firstName": "First name",
    "users.list.labels.lastName": "Family name",
    "users.list.labels.email": "Email",
    "users.list.labels.userRole": "User's role",
    "users.list.labels.password": "Password",
    "users.list.labels.confirmPassword": "Confirm password",
    "users.list.labels.generatePassword": "Generate password",
    "users.list.labels.invalidEmail": "Please enter a valid Email!",
    "users.list.labels.invalidPassword": "Minimum length 8 characters!",
    "users.list.labels.passwordsNotMatch": "Passwords doesn't match!",

    "users.list.labels.updateUserData": "Update user's data",
    "users.list.labels.deleteUser": "Delete user",
    "users.list.labels.deleteConfirmText": "Are you sure you want to delete",
    "users.list.label.add": "Add a new user",
    "users.list.label.update": "Update user",
    "users.empty": "There is no data",

    "users.add.image.title": "User avatar",
    "users.add.image.error": "Please upload the user's avatar",

    "users.profile.update.coords": "Edit account information",
    "users.profile.update.password": "Edit password",
    
    "users.profile.card.update.title": "Account information",
    "users.profile.card.changePassword.title": "Password",
    
    "users.profile.card.update.success.message": "Account information has been successfully updated",
    
    "users.profile.card.password.current.label": "Current Password",
    "users.profile.card.password.new.label": "New password",
    "users.profile.card.password.confirm.label": "Confirm new password",
    "users.profile.card.avatar.error": "Please upload a profile picture !",

    "users.password.change.error.reusedPassword": "Cannot re use the old password !",

    "users.password.change.success.message": "Password has been successfully changed"
  }
};