import { put, takeLatest } from 'redux-saga/effects';
import { createSourceApi, deleteSourceApi, getSourceByIdApi, getSourcesApi, patchSourceApi, updateSourceApi } from '../../api/sources';
import { activateUpdateSourceError, activateUpdateSourceSuccess, createSourceError, createSourceSuccess, deleteSourceError, deleteSourceSuccess, findDuplicatedSourceNameError, findDuplicatedSourceNameSuccess, findDuplicatedSourceUrlError, findDuplicatedSourceUrlSuccess, getSourceError, getSourceSuccess, listSourcesError, listSourcesSuccess, updateSourceError, updateSourceSuccess } from './actions';
import { SOURCE_CREATE, SOURCE_DELETE, SOURCE_FIND_NAME_DUPLICATION, SOURCE_FIND_URL_DUPLICATION, SOURCE_GET, SOURCE_LIST, SOURCE_TOGGLE_ENABLED, SOURCE_UPDATE } from './types';

// * Create source
function* createSourceSaga({ payload: source }) {
  try {
    const res = yield createSourceApi(source);
    yield put(createSourceSuccess(res.data));
  } catch (error) {
    yield put(createSourceError(error));
  }
};

export function* watchCreateSource() {
  yield takeLatest(SOURCE_CREATE, createSourceSaga);
}

// * List sources
function* getListSourcesSaga({payload: params}) {
  try {
    const res = yield getSourcesApi(params);
    yield put(listSourcesSuccess({
      sources: res.data['hydra:member'],
      totalItems: res.data['hydra:totalItems']
    }));
  } catch (error) {
    yield put(listSourcesError(error));
  }
};

export function* watchGetListSources() {
  yield takeLatest(SOURCE_LIST, getListSourcesSaga);
};

// * Find duplication
function* findDuplicatedSaga({ payload: params }) {
  try {
    const res = yield getSourcesApi(params);
    yield put(
      params.name ?
      findDuplicatedSourceNameSuccess({
        data: res.data['hydra:member'].map(source => (source.id))
      })
      :
      findDuplicatedSourceUrlSuccess({
        data: res.data['hydra:member'].map(source => (source.id))
      })
    );
  } catch (error) {
    yield put(
      params.name ?
      findDuplicatedSourceNameError(error)
      :
      findDuplicatedSourceUrlError(error)
      );
  }
};

export function* watchFindDuplicatedSource() {
  yield takeLatest([SOURCE_FIND_NAME_DUPLICATION, SOURCE_FIND_URL_DUPLICATION], findDuplicatedSaga);
};

// * Get source
function* getSourceSaga({ payload: id }) {
  try {
    const res = yield getSourceByIdApi(id);
    yield put(getSourceSuccess(res.data));
  } catch (error) {
    yield put(getSourceError(error));
  }
};

export function* watchGetSource() {
  yield takeLatest(SOURCE_GET, getSourceSaga);
};

// * Update source
function* updateSourceSaga({ payload: source }) {
  try {
    const res = yield updateSourceApi(source);
    yield put(updateSourceSuccess(res.data));
  } catch (error) {
    yield put(updateSourceError(error));
  }
};

export function* watchUpdateSource() {
  yield takeLatest(SOURCE_UPDATE, updateSourceSaga);
};

// * Delete source
function* deleteSourceSaga({ payload: id }) {
  try {
    yield deleteSourceApi(id);
    yield put(deleteSourceSuccess(id));
  } catch (error) {
    yield put(deleteSourceError(error));
  }
};

export function* watchDeleteSource() {
  yield takeLatest(SOURCE_DELETE, deleteSourceSaga);
};

// * Activate update source
function* activateUpdateSourceSaga({ payload: params }) {
  try {
    const res = yield patchSourceApi(params);
    yield put(activateUpdateSourceSuccess(res.data));
  } catch (error) {
    yield put(activateUpdateSourceError(error));
  }
};

export function* watchActivateUpdateSource() {
  yield takeLatest(SOURCE_TOGGLE_ENABLED, activateUpdateSourceSaga);
};
