import { CloseOutlined, DeleteFilled, DeleteOutlined, EditOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, FolderFilled, FolderOutlined, StarFilled, StarOutlined, UnorderedListOutlined, WarningFilled } from '@ant-design/icons';
import { Alert, Avatar, Badge, Button, Checkbox, Col, Form, List, Popconfirm, Row, Space, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateSelector } from '../../../../../components/DateSelector';
import { ConfigContext } from '../../../../../context/config';
import { FALLBACK_IMAGE, FOLDER_STATES, ROLES, SCRAP_FALLBACK_IMAGE } from '../../../../../utils/constants';
import { ACTIVE, ARCHIVED, DAILY, DELETED, FOLDERS_ARCHIVE, UNDESIRABLE } from '../../../../../utils/data/category';
import { patchMomentArabicLocal, patchMomentEnglishLocal } from '../../../../../utils/moment-utils';
import { AddNewsToGroup, findItemByKey, toggleAddToArray, toggleAddToFolderArray } from '../../../../../utils/results-utils';
import { categorizeNews } from '../../../../../utils/utils';
import { AddNoteForm } from '../AddNoteForm';
import { UpdateResultCategoryFrom } from '../UpdateResultCategoryFrom';
import './styles.less';

export const FolderNews = forwardRef(({
  globalConfig,
  news,
  meData,
  alertId,
  pageCategory,
  categories,
  onResultSeen,
  onSelectDuplicationParent,
  onSetBookmarked,
  onFolderItemCheck,
  onSubmitResultCategory,
  onSubmitNote,
  onRemoveNewsFromFolder,
  onDateChange
}, ref) => {

  const { configState, messageTranslator } = useContext(ConfigContext);

  const [checked, setChecked] = useState([]);
  const [editableRows, setEditableRows] = useState([]);
  const [isArabic, setArabic] = useState(false);

  const [categorizedData, setCategorizedData] = useState([]);

  const [hasNoteForm, setNoteForm] = useState([]);

  const [categoryIcon, setCatgoryIcon] = useState(FALLBACK_IMAGE);

  useEffect(() => {
    if (globalConfig) setCatgoryIcon(() => {
      if (globalConfig?.values.default_category_image?.contentUrl) return `${process.env.REACT_APP_BASE_URL}${globalConfig.values.default_category_image.contentUrl}`;
      return FALLBACK_IMAGE;
    })
  }, [globalConfig]);

  useImperativeHandle(ref, () => ({
    clearCheckedItems() {
      setChecked([]);
      setEditableRows([]);
    }
  }));

  useEffect(() => {
    if (configState.language === "ar") {
      setArabic(true);
      patchMomentArabicLocal();
    } else {
      setArabic(false);
      patchMomentEnglishLocal();
    } 
  }, [configState.language]);

  useEffect(() => {
    if (news) setCategorizedData(categorizeNews(news.folderItems, messageTranslator));

  }, [news, categories, messageTranslator]);

  const handleAddResultToFolderArray = (result) => AddNewsToGroup(result, onFolderItemCheck);

  const handleRemoveNewsFromFolder = (result) => {
    let excecluded = [result['@id']];
    result.childrenDuplicated.forEach(child => excecluded.push(child['@id']));
    const newFolderItems = news?.folderItems.filter(item => !excecluded.includes(item['@id'])).map(item => item['@id']);
    onRemoveNewsFromFolder(newFolderItems);
  };

  const renderDeleteNewsFromFolder = (meData, folder) => {
    return (
      meData
      &&
      folder
      &&
      (
        (ROLES.PUBLISHER === meData?.role.title && FOLDER_STATES.PUBLISHER_CHECK === folder.moderationState && folder.publisher?.id === meData.id)
        ||
        (ROLES.AUDITOR === meData?.role.title && folder.moderationState === FOLDER_STATES.NEEDS_REVIEW && folder.auditor?.id === meData.id)
        ||
        (ROLES.EDITOR === meData?.role.title && folder.moderationState === FOLDER_STATES.DRAFT && folder.editor?.id === meData?.id)
        ||
        (ROLES.EDITOR === meData?.role.title && folder.moderationState === FOLDER_STATES.DRAFT && folder?.editor == null)
      )
    )
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {
            pageCategory === DAILY
              ? (
                <>
                  {
                    (!!news && (news.moderationState !== FOLDER_STATES.PUBLISHED && pageCategory === DAILY))
                    && (
                      <div className='header-list-wrapper'>
                        <Typography.Title level={5}>
                          {`${messageTranslator("alerts.daily.header.date", "alerts")} ${moment().format('DD MMMM YYYY')}`}{isArabic && 'م'}
                        </Typography.Title>
                      </div>
                    )
                  }
                </>
              ) : (
                <DateSelector
                  theme={pageCategory === FOLDERS_ARCHIVE && 'light'}
                  onDateApply={onDateChange}
                  isArchive
                />
              )
          }
        </Col>
      </Row>

      {
        (!news || (news?.moderationState === FOLDER_STATES.PUBLISHED && pageCategory === DAILY))
        && (
          <Row justify='center'>
            <Col>
              <Space style={{ padding: "12px 35px", backgroundColor: "#EFF4FA", borderRadius: "4px" }} size={4}>
                <Typography.Text className='blue-Text'>
                  {
                    !news
                      ?
                      `${messageTranslator("alerts.daily.empty.text", "alerts")} `
                      :
                      news && news.moderationState === FOLDER_STATES.PUBLISHED && pageCategory === DAILY
                        ?
                        `${messageTranslator("alerts.daily.published.text", "alerts")} `
                        :
                        null
                  }
                </Typography.Text>
                <Typography.Text className='blue-Text' strong>
                  <Link to={`/alert/${alertId}/folders`}>{messageTranslator("alerts.view.banner.clickHere", "alerts")}</Link>
                </Typography.Text>
              </Space>
            </Col>
          </Row>
        )
      }

      {
        !!news && ((news.moderationState !== FOLDER_STATES.PUBLISHED && pageCategory === DAILY) || pageCategory === FOLDERS_ARCHIVE)
        && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {
                categorizedData.map(category => {
                  return (
                    <div
                      className="daily-folder-category-list"
                      key={category.id}
                    >
                      <List
                        size="large"
                        dataSource={[...category.data]}
                        locale={{
                          emptyText: messageTranslator("alerts.daily.result.empty.text", "alerts")
                        }}
                        header={(
                          <Row align='middle'>
                            <Col flex="75px">
                              <Avatar
                                src={category.image ? `${process.env.REACT_APP_BASE_URL}${category.image}` : categoryIcon}
                              />
                            </Col>
                            <Col flex="auto">
                              <Typography.Text strong>{category.name}</Typography.Text>
                            </Col>
                          </Row>
                        )}
                        renderItem={result => {
                          const isChecked = checked.find(item => item['@id'] === result['@id']);
                          return (
                            <>
                              {
                                !!result.auditorNotes
                                &&
                                [
                                  ROLES.EDITOR.toUpperCase(),
                                  ROLES.AUDITOR.toUpperCase()
                                ].includes(meData?.role?.slug)
                                && (
                                  <Row>
                                    <Col span={24}>
                                      <Alert
                                        className='alert-news'
                                        message={(
                                          <Row justify='space-between'>
                                            <Col>
                                              <Typography.Text>{result.auditorNotes}</Typography.Text> &nbsp; | &nbsp; <Typography.Text strong>{news.auditor.userName}</Typography.Text>

                                            </Col>
                                            <Col><WarningFilled /></Col>
                                          </Row>
                                        )}
                                        type="warning"
                                      />
                                    </Col>
                                  </Row>
                                )
                              }

                              <List.Item
                                className={isChecked ? "active ant-form-small" : "ant-form-small"}
                                actions={result.childrenDuplicated.map(child => (
                                  <Tooltip title={child.title || `-- ${messageTranslator("alerts.daily.untitled.text", "alerts")} --`}>
                                    <a
                                      href={child.url}
                                      onClick={() => onResultSeen(child['@id'])}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Avatar
                                        src={child.sourceImage || SCRAP_FALLBACK_IMAGE}
                                        size={40}
                                      />
                                    </a>
                                  </Tooltip>
                                ))}
                              >
                                <List.Item.Meta
                                  avatar={(
                                    <a
                                      href={result.url}
                                      onClick={() => onResultSeen(result.id)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Badge offset={[-5, 50]} count={
                                        result.sourceType !== 'website' ? (
                                          <Avatar
                                            size={20}
                                            src={`/images/social-media/${result.sourceType}.png`}
                                            className="avatar-source"
                                          />
                                        ) : null
                                      }>
                                        <Avatar
                                          size={64}
                                          src={result.sourceImage || `${process.env.REACT_APP_BASE_URL}${globalConfig?.values?.default_source_image?.contentUrl}`}
                                        />
                                      </Badge>
                                    </a>
                                  )}
                                  title={(
                                    <>
                                      <span className='date'>{moment(result.createdAt).format('DD MMM')}</span> <span className='user'>
                                        <a
                                          href={result.url}
                                          onClick={() => onResultSeen(result.id)}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {`${result.sourceType === 'twitter' ? `@${result.sourceName}` : result.sourceName}`}
                                        </a>
                                      </span>
                                    </>
                                  )}
                                  description={(
                                    <a
                                      href={result.url}
                                      onClick={() => onResultSeen(result.id)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {result.title || result.content.substring(0, 200)}
                                    </a>
                                  )}
                                />
                                <div className='actions-wrapper'>
                                  {
                                    (isChecked && news && news.moderationState !== FOLDER_STATES.PUBLISHED) && (
                                      <Button
                                        onClick={() => toggleAddToFolderArray(result, setEditableRows)}
                                        className='edit-btn'
                                      >
                                        + {messageTranslator(result.category ? "alerts.daily.result.editCategory.btn" : "alerts.daily.result.addCategory.btn", "alerts")}
                                      </Button>
                                    )
                                  }
                                  <div className={"status " + result.sentiment} />
                                  {
                                    renderDeleteNewsFromFolder(meData, news) && (
                                      <div className='delete-wrapper'>
                                        <Popconfirm
                                          title={messageTranslator("alerts.daily.result.delete.confirmText", "alerts")}
                                          onConfirm={(e) => handleRemoveNewsFromFolder(result)}
                                          okText={messageTranslator("commons.delete.confirm", "commons")}
                                          cancelText={messageTranslator("commons.cancel", "commons")}
                                        >
                                          <Tooltip
                                            title={messageTranslator("alerts.daily.result.delete.tooltip", "alerts")}
                                          >
                                            <CloseOutlined />
                                          </Tooltip>
                                        </Popconfirm>
                                      </div>
                                    )
                                  }
                                  <Form.Item className="small-check">
                                    {
                                      renderDeleteNewsFromFolder(meData, news)
                                      && (
                                        <Row>
                                          <Col span={24}>
                                            <Checkbox
                                              onChange={() => {
                                                toggleAddToFolderArray(result, setChecked);
                                                handleAddResultToFolderArray(result);
                                              }}
                                              checked={isChecked}
                                            />
                                          </Col>
                                        </Row>
                                      )
                                    }
                                  </Form.Item>
                                  <div className='action-list'>
                                    {
                                      !!result.childrenDuplicated.length
                                      &&
                                      renderDeleteNewsFromFolder(meData, news)
                                      && (
                                        <Tooltip
                                          title={messageTranslator("alerts.daily.result.duplicated.tooltip", "alerts")}
                                        >
                                          <UnorderedListOutlined onClick={() => onSelectDuplicationParent(result)} />
                                        </Tooltip>
                                      )
                                    }

                                    {
                                      news && news[ROLES.AUDITOR]?.id === meData?.id && news.moderationState === FOLDER_STATES.NEEDS_REVIEW && (
                                        <Tooltip title={messageTranslator("alerts.daily.result.notice.add.tooltip", "alerts")}>
                                          <EditOutlined onClick={() => toggleAddToArray(result, setNoteForm, '@id')} />
                                        </Tooltip>
                                      )
                                    }

                                    {
                                      pageCategory !== FOLDERS_ARCHIVE
                                      && (
                                        <>
                                          <Tooltip title={messageTranslator(result.bookmarked ? "alerts.daily.result.unbookmark.tooltip" : "alerts.daily.result.bookmark.tooltip", "alerts")}>
                                            {
                                              result.bookmarked
                                                ?
                                                <StarFilled onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  bookmarked: !result.bookmarked
                                                })} />
                                                :
                                                <StarOutlined onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  bookmarked: !result.bookmarked
                                                })} />
                                            }
                                          </Tooltip>
                                          <Tooltip
                                            title={messageTranslator(result.status === ARCHIVED ? "alerts.daily.result.unarchive.tooltip" : "alerts.daily.result.archive.tooltip", "alerts")}
                                          >
                                            {
                                              result.status === ARCHIVED
                                                ?
                                                <FolderFilled onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  status: ACTIVE
                                                })} />
                                                :
                                                <FolderOutlined onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  status: ARCHIVED
                                                })} />
                                            }
                                          </Tooltip>
                                          <Tooltip
                                            title={messageTranslator(result.status === UNDESIRABLE ? "alerts.daily.result.unUndesirable.tooltip" : "alerts.daily.result.undesirable.tooltip", "alerts")}
                                          >
                                            {
                                              result.status === UNDESIRABLE
                                                ?
                                                <ExclamationCircleFilled onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  status: ACTIVE
                                                })} />
                                                :
                                                <ExclamationCircleOutlined onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  status: UNDESIRABLE
                                                })} />
                                            }
                                          </Tooltip>
                                          <Tooltip title={messageTranslator(result.status === DELETED ? "alerts.daily.result.restore.tooltip" : "alerts.daily.result.remove.tooltip", "alerts")}>
                                            {
                                              result.status === DELETED
                                                ?
                                                <DeleteFilled onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  status: ACTIVE
                                                })} />
                                                :
                                                <DeleteOutlined onClick={() => onSetBookmarked({
                                                  id: result.id,
                                                  status: DELETED
                                                })} />
                                            }
                                          </Tooltip>
                                        </>
                                      )
                                    }
                                  </div>
                                </div>
                                {
                                  findItemByKey(editableRows, result, '@id') && (
                                    <UpdateResultCategoryFrom
                                      result={result}
                                      categories={categories}
                                      stateDispatcher={setEditableRows}
                                      onToggleCheckedItems={toggleAddToFolderArray}
                                      onSubmitUpdates={onSubmitResultCategory}
                                    />
                                  )
                                }
                              </List.Item>
                              {
                                !!findItemByKey(hasNoteForm, result, '@id') && (
                                  <AddNoteForm
                                    result={result}
                                    stateDispatcher={setNoteForm}
                                    onToggleCheckedItems={toggleAddToArray}
                                    onSubmitNote={onSubmitNote}
                                  />
                                )
                              }
                            </>
                          )
                        }}
                      />
                    </div>
                  )
                })
              }
            </Col>
          </Row>
        )
      }
    </>
  );
});
