import { put, takeLatest } from "redux-saga/effects";
import { createSheduleApi, updateShceduleApi } from "../../api/schedule";
import {
  createScheduleError,
  createScheduleSuccess,
  updateScheduleSuccess,
  updateScheduleError,
} from "./actions";
import { SCHEDULE_ADD, SCHEDULE_UPDATE } from "./types";

function* createScheduleSaga({ payload: schedule }) {
  try {
    yield createSheduleApi(schedule);
    yield put(createScheduleSuccess());
  } catch (error) {
    yield put(createScheduleError(error));
  }
}

export function* watchCreateSchedule() {
  yield takeLatest(SCHEDULE_ADD, createScheduleSaga);
}

// * Update shcedule
function* updateShecudleSaga(action) {
  try {
    const res = yield updateShceduleApi(action.id, action.schedule);
    yield put(updateScheduleSuccess(res.data));
  } catch (error) {
    yield put(updateScheduleError(error));
  }
}

export function* watchUpdateShedudle() {
  yield takeLatest(SCHEDULE_UPDATE, updateShecudleSaga);
}
