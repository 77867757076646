import { PERMISSIONS_GET, PERMISSIONS_GET_ERROR, PERMISSIONS_GET_SUCCESS } from "./types";

export const getPermissions = () => ({
  type: PERMISSIONS_GET
});

export const getPermissionsSuccess = (permissions) => ({
  type: PERMISSIONS_GET_SUCCESS,
  payload: permissions
});

export const getPermissionsError = (errors) => ({
  type: PERMISSIONS_GET_ERROR,
  payload: errors
});
