import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { SearchBar } from '../../../components/SearchBar';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { getScrapResults } from '../../../store/scrap-results/actions';
import { getSource } from '../../../store/sources/actions';
import { getPageSearchQueries, pageChangeHandler, searchHandler } from '../../../utils/utils';
import { SourceList } from './components/SourceList';
import { AuthContext } from '../../../context/Auth';
import { checkToken } from '../../../context/Auth';
import { logoutAction } from "../../../store/logout";
import './viewSource.less';

export const ViewSource = ({ route, history, match: { params: { id } } }) => {

  const searchParams = useQuery(history);

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const {
    config: { globalConfig: { data: globalConfig } },
    users: { me: { data: meData } },
    sources: {
      source: { data: source, isLoading }
    },
    scrapResults: {
      list
    }
  } = useSelector(state => state);

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [searchTerms, setSearchTerms] = useState({});

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });
  
  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    if (id) {
      dispatch(getScrapResults({
        id,
        ...terms
      }));
    }
  }, [dispatch, id, searchParams]);

  useEffect(() => {
    if (id) dispatch(getSource(id));
  }, [id, dispatch]);

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handleSourcesSearch = (terms = {}) => searchHandler(history, terms);

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={`${messageTranslator(route.title, "routes")} ${source?.name}`}>
      <Spin spinning={isLoading || list.isLoading}>
        <SearchBar
          history={history}
          label={route.title}
          page={page}
          icon
          searchTerms={searchTerms}
          extraLabel={source?.name}
          onSearchTrigger={handleSourcesSearch}
        />

        <SourceList
          globalConfig={globalConfig}
          scrapResults={list}
          currentPage={page}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
      </Spin>
    </PageSkeleton>
  );
};
