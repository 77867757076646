import { ArrowLeftOutlined, ArrowRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import { AlertContext } from '../../../../../context/Alert';
import { ConfigContext } from '../../../../../context/config';
import { languages } from '../../../../../utils/data/languages';

export const renderItem = (title, value) => ({
  value: title,
  label: (
    <div
      data-value={value}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {title}
    </div>
  ),
});

export const LanguageStep = ({
  isLoading,
  onFinish,
  onPrev
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const { alertState } = useContext(AlertContext);
  const [selectedLang, setSelectedLang] = useState([]);
  const [hasError, setError] = useState(false);

  const handleNext = () => {
    if (selectedLang.length) {
      setError(false);
      onFinish({
        ...alertState.data,
        languages: selectedLang
      });
    }
    else setError(true);
  };

  const handleSelectOption = (langs) => setSelectedLang(langs);

  return (
    <>
      <Row align="top">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <div className="img-step-wrapper">
            <img src="/images/step4.png" alt="Step Four" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} className="bg-white">
          <Row className="step-content" align="middle">
            <Col className="full-width">
              <Scrollbar mobileNative={false}>
                <div className="content-wrapper">
                  <Row align='middle'>
                    <Col>
                      <div className="entete">
                        <div className="step-title">
                          {messageTranslator("alerts.add.stepper.stepFive.title", "alerts")}
                        </div>
                        <div className="step-desc">
                          {messageTranslator("alerts.add.stepper.stepFive.desc", "alerts")}
                        </div>
                      </div>
                      <h1>
                        {messageTranslator("alerts.add.langs.placeholder", "alerts")}
                      </h1>
                      <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} 
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder={messageTranslator("alerts.add.langs.placeholder", "alerts")}
                        onChange={handleSelectOption}
                      >
                        {languages.map(lang => (
                          <Select.Option key={lang.value} value={lang.value}>
                            {messageTranslator(lang.label, "constants")}
                          </Select.Option>
                        ))}
                      </Select>
                      {
                        hasError && (
                          <Alert
                            message={messageTranslator("alerts.add.stepper.stepFive.error", "alerts")}
                            type="error"
                            showIcon
                          />
                        )
                      }
                    </Col>
                  </Row>
                </div>
              </Scrollbar>
            </Col>
          </Row>

          <div className="nav-step-wrapper">
            <Row justify="space-between" className="full-width">
              <Col>
                <Button
                  type="primary"
                  onClick={onPrev}
                >
                  <ArrowLeftOutlined className='transformMiroir' /> {messageTranslator("commons.pagination.prev", "commons")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={handleNext}
                  loading={isLoading}
                >
                  {messageTranslator("commons.pagination.next", "commons")} <ArrowRightOutlined className='transformMiroir' />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
