import { api } from "..";

const URL = '/api/categories';

export const createCategoryApi = (category) => api.post(URL, category);

export const listCategoriesApi = ({ search = null, page = 1 }) => {
  let query = `page=${page}`;
  if (search) query += `&search=${decodeURI(search)}`;
  return api.get(`${URL}?${query}`);
};

export const updateCategoryApi = ({ id, ...category }) => api.put(`${URL}/${id}`, category);

export const deleteCategoryApi = (id) => api.delete(`${URL}/${id}`);