import { ROLES_CREATE, ROLES_CREATE_ERROR, ROLES_CREATE_SUCCESS, ROLES_DELETE, ROLES_DELETE_SUCCESS, ROLES_GET_BY_ID, ROLES_GET_BY_ID_ERROR, ROLES_GET_BY_ID_SUCCESS, ROLES_LIST, ROLES_LIST_ERROR, ROLES_LIST_SUCCESS, ROLES_RESET_CREATED, ROLES_RESET_DELETED, ROLES_RESET_UPDATED, ROLES_UPDATE, ROLES_UPDATE_ERROR, ROLES_UPDATE_SUCCESS } from "./types";

// Create role
export const createRole = (role) => ({
  type: ROLES_CREATE,
  payload: role
});

export const createRoleSuccess = (role) => ({
  type: ROLES_CREATE_SUCCESS,
  payload: role
});

export const createRoleError = (errors) => ({
  type: ROLES_CREATE_ERROR,
  payload: errors
});

export const resetRoleCreated = () => ({
  type: ROLES_RESET_CREATED
});

// Get role
export const getRole = (id) => ({
  type: ROLES_GET_BY_ID,
  payload: id
});

export const getRoleSuccess = (role) => ({
  type: ROLES_GET_BY_ID_SUCCESS,
  payload: role
});

export const getRoleError = (errors) => ({
  type: ROLES_GET_BY_ID_ERROR,
  payload: errors
});

// Get roles
export const getRoles = (params) => ({
  type: ROLES_LIST,
  payload: params
});

export const getRolesSuccess = (roles) => ({
  type: ROLES_LIST_SUCCESS,
  payload: roles
});

export const getRolesError = (errors) => ({
  type: ROLES_LIST_ERROR,
  payload: errors
});

// Delete role
export const deleteRole = (id) => ({
  type: ROLES_DELETE,
  payload: id
});

export const deleteRoleSuccess = (id) => ({
  type: ROLES_DELETE_SUCCESS,
  payload: id
});

export const deleteRoleError = (errors) => ({
  type: ROLES_CREATE_ERROR,
  payload: errors
});

export const resetRoledeleted = () => ({
  type: ROLES_RESET_DELETED
});

// Update role
export const updateRole = (role) => ({
  type: ROLES_UPDATE,
  payload: role
});

export const updateRoleSuccess = (role) => ({
  type: ROLES_UPDATE_SUCCESS,
  payload: role
});

export const updateRoleError = (errors) => ({
  type: ROLES_UPDATE_ERROR,
  payload: errors
});

export const resetRoleUpdated = () => ({
  type: ROLES_RESET_UPDATED
});
