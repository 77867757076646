import { GET_COUNTRIES_STATS, GET_COUNTRIES_STATS_ERROR, GET_COUNTRIES_STATS_SUCCESS, GET_FEEDBACK_STATS, GET_FEEDBACK_STATS_ERROR, GET_FEEDBACK_STATS_SUCCESS, GET_HASHTAGS_STATS, GET_HASHTAGS_STATS_ERROR, GET_HASHTAGS_STATS_SUCCESS, GET_IMPORTANT_NEWS_STATS, GET_IMPORTANT_NEWS_STATS_ERROR, GET_IMPORTANT_NEWS_STATS_SUCCESS, GET_INFLUENCERS_STATS, GET_INFLUENCERS_STATS_ERROR, GET_INFLUENCERS_STATS_SUCCESS, GET_KEYWORD_STATS, GET_KEYWORD_STATS_ERROR, GET_KEYWORD_STATS_SUCCESS, GET_MONITORING_STATS, GET_MONITORING_STATS_ERROR, GET_MONITORING_STATS_SUCCESS } from "./types";

export const statsInitialState = {
  countries: {
    data: null,
    isLoading: false,
    errors: null
  },
  sentiments: {
    data: null,
    isLoading: false,
    errors: null
  },
  importantNews: {
    data: [],
    isLoading: false,
    errors: null
  },
  monitoring: {
    data: null,
    isLoading: false,
    errors: null
  },
  hashtags: {
    data: null,
    isLoading: false,
    errors: null
  },
  keywords: {
    data: null,
    isLoading: false,
    errors: null
  },
  influencers: {
    data: null,
    isLoading: false,
    errors: null
  }
};

export const statsReducer = (state = statsInitialState, action) => {
  switch (action.type) {

    // * Get countries
    case GET_COUNTRIES_STATS:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: true,
          errors: null
        }
      };

    case GET_COUNTRIES_STATS_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_COUNTRIES_STATS_ERROR:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Feedback
    case GET_FEEDBACK_STATS:
      return {
        ...state,
        sentiments: {
          ...state.sentiments,
          isLoading: true,
          errors: null
        }
      };

    case GET_FEEDBACK_STATS_SUCCESS:
      return {
        ...state,
        sentiments: {
          ...state.sentiments,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_FEEDBACK_STATS_ERROR:
      return {
        ...state,
        sentiments: {
          ...state.sentiments,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Important news
    case GET_IMPORTANT_NEWS_STATS:
      return {
        ...state,
        importantNews: {
          ...state.importantNews,
          isLoading: true,
          errors: null
        }
      };

    case GET_IMPORTANT_NEWS_STATS_SUCCESS:
      return {
        ...state,
        importantNews: {
          ...state.importantNews,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_IMPORTANT_NEWS_STATS_ERROR:
      return {
        ...state,
        importantNews: {
          ...state.importantNews,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Monitoring
    case GET_MONITORING_STATS:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          isLoading: true,
          errors: null
        }
      };

    case GET_MONITORING_STATS_SUCCESS:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_MONITORING_STATS_ERROR:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Hashtags
    case GET_HASHTAGS_STATS:
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          isLoading: true,
          errors: null
        }
      };

    case GET_HASHTAGS_STATS_SUCCESS:
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_HASHTAGS_STATS_ERROR:
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Keywords
    case GET_KEYWORD_STATS:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          isLoading: true,
          errors: null
        }
      };

    case GET_KEYWORD_STATS_SUCCESS:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_KEYWORD_STATS_ERROR:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          isLoading: false,
          error: action.payload
        }
      };

    // * influencers
    case GET_INFLUENCERS_STATS:
      return {
        ...state,
        influencers: {
          ...state.influencers,
          isLoading: true,
          errors: null
        }
      };

    case GET_INFLUENCERS_STATS_SUCCESS:
      return {
        ...state,
        influencers: {
          ...state.influencers,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_INFLUENCERS_STATS_ERROR:
      return {
        ...state,
        influencers: {
          ...state.influencers,
          isLoading: false,
          data: action.payload
        }
      };

    default:
      return state;
  }
}
