import { ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
// import './folder-banner.less';

export const PublisherFolderArchiveBanner = ({
  counter,
  checkedItems,
  backTo,
  openDuplicationModal,
  openReviewModal,
  onPublishFolder
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <Card
      className='folder-banner'
      size='small'
    >
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
          <Row justify="space-between" align='middle'>
            <Col flex="auto" xs={24}>
              <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.counter", "alerts")} <span className='nbre-alerts'>{counter}</span></Typography.Title>
            </Col>

            <Col flex="none" xs={24}>
              <span className="btn-wrapper">
                <Button
                  onClick={() => openReviewModal(true)}
                  key="add-folder"
                  type="primary"
                >
                  <ReloadOutlined />
                  {messageTranslator("alerts.daily.banner.declineAndReturn", "alerts")}
                </Button>
              </span>

            </Col>

          </Row>
        </Col>
      </Row>
    </Card>
  );
};
