import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';

export const RequestSent = ({ onResend }) => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <Row justify='center'>
      <Col flex="0 0 auto">
        <Typography.Text className='schedule-msg'>
          <CheckCircleFilled />
          {messageTranslator("auth.forgot.form.success.message", "auth")}
        </Typography.Text>
      </Col>
      <Col flex="0 0 auto">
        <Button onClick={onResend}>
          {messageTranslator("auth.forgot.form.resent.text", "auth")}
        </Button>
      </Col>
    </Row>
  );
};
