import { api } from '..';
import { REPORTS_CATEGORIES } from '../../utils/constants';

const URL = '/api/folders-reports';

export const createReportApi = (report) => api.post(URL, report);

export const getReportApi = (id) => api.get(`${URL}/${id}`);

export const getReportsApi = ({ page = 1, ...rest }) => {
  let query = `?page=${page}`;
  if (REPORTS_CATEGORIES.SCHEDULED in rest) query += `&scheduled=${rest.scheduled}`;
  if (rest.search) query += `&search=${rest.search}`;
  return api.get(`${URL}${query}`);
};

export const updateReportApi = ({ id, ...report }) => api.put(`${URL}/${id}`, report);

export const deleteReportApi = (id) => api.delete(`${URL}/${id}`);

export const exportReportApi = ({ id, ...data }) => api.post(`${URL}/${id}/export`, data);
