import { ProfileFilled, RightOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../../context/config';
import "./createReport.less";

export const CreateReport = ({ onNext }) => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <Space direction="vertical" className="created-report-wrapper">
      <Typography.Title level={2}>
      {messageTranslator("reports.modal.title", "reports")}
      </Typography.Title>
      <Typography.Text type="secondary">
        {messageTranslator("reports.modal.subTitle", "reports")}
      </Typography.Text>
      <Button onClick={onNext} icon={<ProfileFilled />} className="created-report-btn">
        {messageTranslator("reports.modal.stepOne.button", "reports")}
        <RightOutlined className='transformMiroir' />
      </Button>
    </Space>
  );
};
