export const LOGO_PATH = "/images/logo.png";

export const SUPER_ADMIN = "SUPER_ADMIN";

export const LANG = "language";

export const PAGE_SIZE = 30;

export const FOLDER_LIST_STORAGE = "scrapping:create-folder";

export const ROLES = {
  EDITOR: "editor",
  AUDITOR: "auditor",
  PUBLISHER: "publisher",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const FOLDER_STATES = {
  DRAFT: "draft",
  NEEDS_REVIEW: "needs_review",
  PUBLISHER_CHECK: "publisher_check",
  PUBLISHED: "published",
};

export const sentimentOptions = [
  { label: "constants.sentiments.negative", value: "negative" },
  { label: "constants.sentiments.positive", value: "positive" },
  { label: "constants.sentiments.neutral", value: "neutral" },
];

export const REPORTS_CATEGORIES = {
  ALL: "all",
  SCHEDULED: "scheduled",
  UNSCHEDULED: "unscheduled",
};

export const CONFIG_NAMES = {
  email: "config.mail",
  global: "config.global",
  scrap: "config.scrap",
  twitter: "config.twitter",
  twitterCriteria: "config.criteria",
};

export const REPORT_FORMAT = {
  pdf: "pdf",
  html: "html",
  mail: "mail",
};

export const SCRAP_FALLBACK_IMAGE = "/images/fallback/scrap-result-icon.png";
export const FALLBACK_IMAGE = "/images/fallback/fallback-icon.png";

export const deviceBreakpoints = {
  xxl: 1600,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
};

export const statsSegments = {
  day: "day",
  week: "week",
  month: "month",
};

export const statsTypes = {
  unit: "unit",
  period: "period",
};
