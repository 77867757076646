import {Alert, Button, Card, Checkbox, Col, Divider, Form, Input, message, Radio, Row, Space, Typography} from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { CONFIG_NAMES } from '../../../../../utils/constants';
import { generateConfigList } from '../../../../../utils/utils';

export const InboxSettings = ({
  create: { isCreated, errors },
  config: { data: config },
  loadingTestEmail,
  testEmailError,
  onSubmitForm,
  onSendTestEmail
}) => {

  const emailFormatOptions = useMemo(() => [
    {
      value: "html",
      label: "HTML",
    },
    {
      value: "text",
      label: "Plain Text",
    }
  ], []);

  const authOptions = useMemo(() => [
    {
      value: "smtp",
      label: "Enable SMTP authentication",
    }
  ], []);

  const encryptionOptions = useMemo(() => [
    {
      value: "none",
      label: "None",
    },
    {
      value: "ssl",
      label: "SSL",
    },
    {
      value: "tls",
      label: "TLS",
    }
  ], []);

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();

  const { setFieldsValue, setFields, resetFields, getFieldsValue } = form;

  useEffect(() => {
    if (config) setFieldsValue({ ...config.values })
  }, [config, setFieldsValue]);

  useEffect(() => {
    setFieldsValue({
      authentication: authOptions[0].value,
      format: emailFormatOptions[0].value,
      encryption: encryptionOptions[1].value
    })
  }, [setFieldsValue, authOptions, emailFormatOptions, encryptionOptions]);

  useEffect(() => {
    if (isCreated) {
      message.success(messageTranslator("config.settings.form.success.message", "config"))
      resetFields([]);
    }
  }, [isCreated, resetFields, messageTranslator]);

  const handleFormSubmit = ({ mailBody, email, ...rest }) => {
    if (!(/^\d+$/.test(rest.port))) setFields([
      {
        name: "port",
        value: rest.port,
        errors: [messageTranslator("commons.isNumber.message", "commons")]
      }
    ]);
    else {
      onSubmitForm({
        name: CONFIG_NAMES.email,
        values: generateConfigList(rest)
      });
    }
  };

  const handleTestEmail = () => {
    const { mailBody, email } = getFieldsValue(["mailBody", "email"]);
    let mailBodyError = [];
    let emailError = [];
    if (!mailBody || !email) {
      mailBodyError = [messageTranslator("commons.required.message", "commons")];
      emailError = [messageTranslator("commons.required.message", "commons")]
    }
    else {
      onSendTestEmail({ mailBody, email });
    }
    setFields([
      {
        name: "mailBody",
        errors: mailBodyError
      },
      {
        name: "email",
        errors: emailError
      }
    ]);
  };

  return (
    <Card
      title={messageTranslator("config.menu.item.label", "config")}
      bordered={false}
    >
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
        className='form-settings'
      >
        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              name="sender"
              label="Sender"
              rules={[
                { type: 'email', message: messageTranslator("commons.validEmail.message", "commons") },
                { required: true, message: messageTranslator("commons.required.message", "commons") }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="password"
              label="Password"
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              name="host"
              label="SMTP Host"
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="port"
              label="SMTP Port"
              rules={[
                // { type: "number", pattern: new RegExp(/\d+/g), message: messageTranslator("commons.isNumber.message", "commons") },
                { required: true, message: messageTranslator("commons.required.message", "commons") }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col md={8} xs={24}>
            <Form.Item
              name="format"
              label="Email Format"
            >
              <Radio.Group options={emailFormatOptions} />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              name="authentication"
              label="Authentication"
            >
              <Checkbox.Group options={authOptions} />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              name="encryption"
              label="SMTP encryption"
            >
              <Radio.Group options={encryptionOptions} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row gutter={[30, 15]}>
          {!!testEmailError?.general && testEmailError.general.map(error => (
              <Col md={24} xs={24}>
                <Alert message={messageTranslator(error, 'errors') } type="error" />
              </Col>
          ))}
          <Col md={8} xs={24}>
            <Typography>Send test Email</Typography>
          </Col>
          <Col md={16} xs={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="To"
                  className='row-direction'
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mailBody"
                  label="Message"
                  className='row-direction'
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span
                  className='btn-wrapper blue'
                  style={{ marginLeft: "70px" }}
                >
                  <Button
                    type="primary"
                    onClick={handleTestEmail}
                    loading={loadingTestEmail}
                    disabled={loadingTestEmail}
                  >Send Test Email</Button>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Space>
          <span className='btn-wrapper'>
            <Button type="primary" htmlType="submit">
              {messageTranslator("commons.save", "commons")}
            </Button>
          </span>
          <Button>
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </Space>
      </Form>
    </Card>
  );
};
