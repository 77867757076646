import { ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../../context/config';
import { LOGIN } from '../../../../../routes/paths';

export const ForgotPasswordForm = forwardRef(({
  errors,
  onSubmit
}, ref) => {

  const [formRef] = Form.useForm();
  const { resetFields } = formRef;

  const { messageTranslator } = useContext(ConfigContext);

  const handleSubmitForm = (values) => onSubmit(values);

  useImperativeHandle(ref, () => ({
    resetForm: resetFields
  }), [resetFields]);

  return (
    <Form
      form={formRef}
      onFinish={handleSubmitForm}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: messageTranslator("commons.required.message", "commons") },
              { type: "email", message: messageTranslator("commons.validEmail.message", "commons") }
            ]}
          >
            <Input placeholder={messageTranslator("auth.forgot.form.email.placeholder", "auth")} />
          </Form.Item>
        </Col>
      </Row>
      {
        errors?.general?.length && (
          <Row>
            <Col span={24}>
              {
                errors.general.map(error => (
                  <Alert message={messageTranslator(error, "errors")} type="error" showIcon />
                ))
              }
            </Col>
          </Row>
        )
      }
      <Row>
        <Col span={24}>
          <Button type="primary" block htmlType="submit">
            {messageTranslator("auth.forgot.form.send.text", "auth")}
          </Button>
        </Col>
      </Row>
      <Row justify='center'>
        <Col flex="0 0 auto">
          <Link to={LOGIN}>
            {messageTranslator("auth.forgot.form.back.text", "auth")}
            <ArrowRightOutlined className='transformMiroir' />
          </Link>
        </Col>
      </Row>
    </Form>
  );
});
