export const alertsTranslations = {
  ar: {
    "alerts.new.text": "إضافة تنبيه جديد",
    "alerts.list.emptyText": "لا يوجد تنبيهات مضافة حالياً",

    "alerts.list.siteName": "إسم البحث",
    "alerts.list.username": "إسم المستخدم",
    "alerts.list.by": "بواسطة",
    "alerts.list.langs": "اللغات",
    "alerts.list.createAt": "تاريخ الإنشاء",
    "alerts.list.goToFolder": "الذهاب لصفحة الملف",
    "alerts.list.update": "تعديل التنبيه",
    "alerts.list.delete": "حذف التنبيه",
    "alerts.list.delete.confirm": "تأكيد حذف التنبيه",

    "alerts.list.update.success.message": "تم تعديل التنبيه بنجاح",

    "alerts.add.duplicated.name": "إسم الجهة متواجد حاياً !",
    "alerts.update.modal.title": "تعديل تنبيه",
    "alerts.update.name.label": "إسم الجهة المراد رصد أخبارها",
    "alerts.update.name.placeholder": "إسم الجهة المراد رصد أخبارها",
    "alerts.update.url.label": "الموقع الإلكتروني للجهة المراد رصد أخبارها",
    "alerts.update.url.placeholder":
      "الموقع الإلكتروني للجهة المراد رصد أخبارها",
    "alerts.update.checkbox.label": "مراقبة المشاركات الخاصة بك",
    "alerts.update.checkbox.description":
      "سنقوم بإحضار كل المحتوى بواسطة هذه الصفحة بغض النظر عن إعدادات الكلمات الرئيسية الخاصة بك",
    "alerts.add.socialMedia.twitter": "رابط التويتر",
    "alerts.add.socialMedia.facebook": "رابط الفيسبوك",
    "alerts.add.socialMedia.instagram": "رابط الإنستغرام",
    "alerts.add.socialMedia.youtube": "رابط اليوتيوب",
    "alerts.add.langs.label": "اللغات",
    "alerts.add.langs.placeholder": "اللغات المطلوبة",

    "alerts.add.stepper.stepOne.title": "ما هو إسم الجهة المرد إضافتها؟",
    "alerts.add.stepper.stepOne.label":
      "أدخل إسم الجهة المراد مراقبة و إدارة الأخبار و التقارير لها",

    "alerts.add.stepper.stepTwo.title": "الموقع الإلكتروني للجهة",
    "alerts.add.stepper.stepTwo.label":
      "أدخل الموقع الإلكتروني للجهة المراد إضافتها",
    "alerts.add.stepper.stepTwo.invalidUrl":
      "الرجاء إدخال رابط صالح للعمل ! مثلاً: https://www.*****.com",

    "alerts.add.stepper.stepThree.tagsSettings": "إعدادات الكلمات المفتاحية",
    "alerts.add.stepper.stepThree.tagsDescription":
      "التنبيهات الخاصة بك ستعمل بالجمع و الرصد بناءا على القواعد التالية",
    "alerts.add.stepper.stepThree.optionalTags.desc":
      "سيتم العمل على تجميع ورصد الأخبار التي تحتوي على أي من هذه الكلمات بالإضافة للكلمات الموجودة في الكلمات المطلوبة",
    "alerts.add.stepper.stepThree.requiredTags.desc":
      "سيتم العمل على تجميع ورصد الأخبار التي تحتوي على جميع هذه الكلمات",
    "alerts.add.stepper.stepThree.excludedTags.desc":
      "سيتم العمل على تجاهل جميع هذه الكلمات بالرصد والتجميع",
    "alerts.add.stepper.stepThree.hashtags.desc":
        "سيتم العمل على تجميع ورصد الأخبار التي تحتوي على جميع هذه الهاشتاقات",
    "alerts.add.stepper.stepThree.mentions.desc":
        "سيتم العمل على تجميع ورصد الأخبار التي تحتوي على جميع هذه الإشارات",
    "alerts.add.stepper.stepThree.tag.placeholder": "إضغط <<Enter>> للإضافة",

    "alerts.add.stepper.stepFour.social.title":
      "حسابات مواقع التواصل الإجتماعي",
    "alerts.add.stepper.stepFour.social.desc":
      "التنبيهات الخاصة بك ستعمل بالجمع والرصد بناءاً على الحسابات التالية أو أي روابط لها علاقة بها",
    "alerts.add.stepper.stepFour.social.twitter": "حساب تويتر",
    "alerts.add.stepper.stepFour.social.facebook": "حساب فيسبوك",
    "alerts.add.stepper.stepFour.social.instagram": "حساب إنستجرام",
    "alerts.add.stepper.stepFour.social.youtube": "حساب يوتيوب",

    "alerts.add.stepper.stepFive.title": "إعدادات اللغة",
    "alerts.add.stepper.stepFive.desc":
      "التنبيهات الخاصة ستعمل بالجمع والرصد بناءاً على اللغات التي ستقوم باختيارها",
    "alerts.add.stepper.stepFive.error":
      "الرجاء إختيار اللغات المطلوبة في الرصد !",

    "alerts.view.banner.returnFolder": "رفض و إعادة الملف الصحفي للمدقق",
    "alerts.view.banner.sendToReview": "إرسال الملف الصحفي للمراجعة",
    "alerts.view.banner.writeRecommendation": "كتابة التحليل و التوصيات",
    "alerts.view.banner.writeDeclineCause": "كتابة سبب الرفض",

    "alerts.view.banner.sentToReview": "تم إرسال الملف الصحفي للمراجعة",
    "alerts.view.banner.openFolder": "لمشاهدة صفحة الملف الصحفي",
    "alerts.view.banner.clickHere": "إضغط هنا",
    "alerts.view.banner.choosePublisher": "إختيار الناشر",
    "alerts.view.banner.chooseAuditor": "إختيار المدقق",
    "alerts.view.banner.chooseEditor": "إختيار المحرر",
    "alerts.view.banner.placeholder.choosePublisher":
      "الرجاء إختيار الشخص الناشر",
    "alerts.view.banner.placeholder.chooseAuditor":
      "الرجاء إختيار الشخص المدقق",
    "alerts.view.banner.placeholder.chooseEditor":
      "الرجاء إختيار الشخص المراجع",

    "alerts.view.filters.calendar.today": "اليوم",
    "alerts.view.filters.calendar.yesterday": "بالأمس",
    "alerts.view.filters.calendar.lastWeek": "آخر أسبوع",
    "alerts.view.filters.calendar.last7Days": "آخر 7 أيام",
    "alerts.view.filters.calendar.last30Days": "آخر 30 يوم",
    "alerts.view.filters.calendar.lastMonth": "آخر شهر",

    "alerts.view.filters.sources.news": "الأخبار",
    "alerts.view.filters.sources.instagram": "انستغرام",
    "alerts.view.filters.sources.facebook": "فيسبوك",
    "alerts.view.filters.sources.twitter": "تويتر",
    "alerts.view.filters.sources.linkedin": "لينكدان",
    "alerts.view.filters.sources.youtube": "يوتيوب",

    "alerts.view.filters.list.sources.all": "جميع المصادر",
    "alerts.view.filters.list.sources.websites": "المواقع الإخبارية",
    "alerts.view.filters.list.sources.twitter": "تويتر",
    "alerts.view.filters.list.sources.facebook": "فيسبوك",
    "alerts.view.filters.list.sources.linkedin": "لينكدان",
    "alerts.view.filters.list.sources.instagram": "انستغرام",
    "alerts.view.filters.list.sources.videos": "الفيديو",

    "alerts.view.filters.search.placeholder": "البحث بالأخبار المرصودة",
    "alerts.view.filters.dates.label": "تاريخ محدد",
    "alerts.view.filters.newsSources.label": "المصادر الإخبارية",
    "alerts.view.filters.sentiment.label": "الانطباع",
    "alerts.view.filters.sources.label": "المصادر",

    "alerts.daily.header.date": "الملف الصحفي ليوم",
    "alerts.daily.empty.text":
      "لا يوجد ملف صحفي لهذا اليوم و لمشاهدة جميع الملفات الصحفية المضافة",
    "alerts.daily.published.text":
      "لقد تم نشر الملف لهذا اليوم و لمشاهدة جميع الملفات الصحفية المضافة",
    "alerts.daily.result.empty.text": "لا يوجد نتائج حالياً",
    "alerts.daily.untitled.text": "لا يوجد عنوان للخبر",
    "alerts.daily.result.addCategory.btn": "إضافة تصنيف",
    "alerts.daily.result.editCategory.btn": "تعديل التصنيف",
    "alerts.daily.result.delete.confirmText": "تأكيد حذف الخبر من الملف",
    "alerts.daily.result.delete.tooltip": "إزالة من الملف",
    "alerts.daily.result.duplicated.tooltip": "تعديل الأخبار المكررة",
    "alerts.daily.result.notice.add.tooltip": "أضف ملاحظة",
    "alerts.daily.result.unbookmark.tooltip": "حذف من قائمة المفضلة",
    "alerts.daily.result.bookmark.tooltip": "إضافة لقائمة المفضلة",
    "alerts.daily.result.unarchive.tooltip": "إزالة من الأرشيف",
    "alerts.daily.result.archive.tooltip": "إضافة للأرشيف",
    "alerts.daily.result.unUndesirable.tooltip":
      "إزالة من قائمة غير المرغوب فيهم",
    "alerts.daily.result.undesirable.tooltip":
      "إضافة في قائمة غير المرغوب فيهم",
    "alerts.daily.result.restore.tooltip": "إلغاء الحذف",
    "alerts.daily.result.remove.tooltip": "حذف",

    "alerts.daily.result.updateCategory.cat.label": "الرجاء اختيار التصنيف",
    "alerts.daily.result.updateCategory.sentiment.label": "تعديل الإنطباع",

    "alerts.daily.addNote.placeholder": "الملاحظة",

    "alerts.daily.banner.counter": "عدد الأخبار المضافة للتقرير الصحفي",
    "alerts.daily.banner.addNews": "خبر جديد",
    "alerts.daily.banner.duplicated": "الأخبار المكررة",
    "alerts.daily.banner.sendToReview": "إرسال للمراجعة",
    "alerts.daily.banner.sendToPublish": "إرسال للنشر",
    "alerts.daily.banner.declineAndReturn": "رفض و إعادة",
    "alerts.daily.banner.publishAndSend": "نشر و إرسال",
    "alerts.daily.banner.editor.notAuthorized": "الملف قيد التحرير عند المحرر",
    "alerts.daily.banner.auditor.notAuthorized":
      "الملف قيد التدقيق عند المراجع",
    "alerts.daily.banner.publisher.notAuthorized":
      "الملف قيد الاعتماد والنشر عند الناشر",
    "alerts.daily.banner.superAdmin.notAuthorized":
      "لا تمتلك الصلاحيات للتعديل على الملف الصحفي",
    "alerts.daily.banner.folderNotCreated":
      "لم يتم إنشاء الملف الصحفي حتى الآن",

    "alerts.daily.banner.inform.draft":
      "الملف الصحفي قيد التحرير ولا يمكن التعديل عليه في الفترة الحالية",
    "alerts.daily.banner.inform.review":
      "تم إرسال الملف الصحفي للمراجعة ولا يمكن التعديل عليه في الفترة الحالية",
    "alerts.daily.banner.inform.publisher_check":
      "تم إرسال الملف الصحفي للإعتماد و النشر ولا يمكن التعديل عليه في الفترة الحالية",
    "alerts.daily.banner.inform.published":
        "لقد تم نشر الملف الصحفي لهذا اليوم ولا يمكن التعديل عليه في الفترة الحالية",
    "alerts.daily.banner.cancelDuplications": "إلغاء التكرار",

    "alerts.daily.banner.duplicationModal.label": "الرجاء إختيار الخبر الرئيسي",

    "alerts.daily.banner.approveAndPublish": "إرسال للإعتماد والنشر",
    "alerts.daily.banner.returnToEditor": "إرجاع للمحرر",
    "alerts.daily.banner.publish": "نشر الملف الصحفي",
    "alerts.daily.banner.sendFolder": "إرسال الملف الصحفي",

    "alerts.results.list.sync.updateNews": "تحديث قائمة الأخبار",

    "alerts.results.uncategorizedModal.title": "يوجد أخبار غير مصنفة",
    "alerts.results.uncategorizedModal.desc":
      "الرجاء تصنيف الأخبار قبل إضافتها للملف الصحفي",

    "alerts.results.banner.addToFolder": "إضافة للملف الصحفي",

    "alerts.daily.public.empty": "لا يوجد ملف صحفي لهذا اليوم",

    "alerts.list.update.category.success": "تم تعديل التصنيف بنجاح",

    "alerts.daily.uncategorized.text": "أخبار غير مصنفة",
    "alerts.new.prefix": "يوجد",
    "alerts.newAlert.text": "خبر جديد",
    "alerts.newAlerts.text": "أخبار جديدة",
    "alerts.results.already.added": "تم إضافة الخبر مسبقا الى الملف بتاريخ",
  },
  en: {
    "alerts.new.text": "Add a new alert",
    "alerts.list.emptyText": "No added alerts currently",

    "alerts.list.siteName": "Search name",
    "alerts.list.username": "Username",
    "alerts.list.by": "By",
    "alerts.list.langs": "Languages",
    "alerts.list.createAt": "Created at",
    "alerts.list.goToFolder": "Go to folder page",
    "alerts.list.update": "Update alert",
    "alerts.list.delete": "Delete alert",
    "alerts.list.delete.confirm": "Are you sure you want to delete the alert",

    "alerts.list.update.success.message": "Alert has been successfully updated",

    "alerts.add.duplicated.name": "Alert name already exists !",
    "alerts.update.modal.title": "Update alert",
    "alerts.update.name.label":
      "Name of the entity whose news is to be monitored",
    "alerts.update.name.placeholder":
      "Name of the entity whose news is to be monitored",
    "alerts.update.url.label":
      "Website of the entity whose news is to be monitored",
    "alerts.update.url.placeholder":
      "Website of the entity whose news is to be monitored",
    "alerts.update.checkbox.label": "Monitor your posts",
    "alerts.update.checkbox.description":
      "We'll fetch all content by this page regardless of your keyword settings",
    "alerts.add.socialMedia.twitter": "Twitter url",
    "alerts.add.socialMedia.facebook": "Facebook url",
    "alerts.add.socialMedia.instagram": "Instagram url",
    "alerts.add.socialMedia.youtube": "Youtube url",
    "alerts.add.langs.label": "Languages",
    "alerts.add.langs.placeholder": "Required languages",

    "alerts.add.stepper.stepOne.title":
      "What's the name of the entity you want to add ?",
    "alerts.add.stepper.stepOne.label":
      "Enter the name of the entity you want to monitor and manage news and reports for",

    "alerts.add.stepper.stepTwo.title": "The entity's website",
    "alerts.add.stepper.stepTwo.label":
      "Enter the website of the entity you want to add",
    "alerts.add.stepper.stepTwo.invalidUrl":
      "Please enter a valid URL such as: https://www.*****.com",

    "alerts.add.stepper.stepThree.tagsSettings": "Keyword Settings",
    "alerts.add.stepper.stepThree.tagsDescription":
      "Your alerts will be collected and monitored based on the following rules",
    "alerts.add.stepper.stepThree.optionalTags.desc":
      "News that contains any of these words in addition to the words in the required words will be collected and monitored",
    "alerts.add.stepper.stepThree.requiredTags.desc":
      "Work will be to collect and monitor news that contains all these words",
    "alerts.add.stepper.stepThree.excludedTags.desc":
      "All these words will be ignored by monitoring and collecting",
    "alerts.add.stepper.stepThree.hashtags.desc":
        "News that contains any of these Hashtags will be collected and monitored",
    "alerts.add.stepper.stepThree.mentions.desc":
        "News that contains any of these Hashtags will be collected and monitored",
    "alerts.add.stepper.stepThree.tag.placeholder": "Press <<Enter>> to add",

    "alerts.add.stepper.stepFour.social.title": "Social media accounts",
    "alerts.add.stepper.stepFour.social.desc":
      "Your alerts will be collected and monitored based on the following accounts or any links related to them",
    "alerts.add.stepper.stepFour.social.twitter": "Twitter account",
    "alerts.add.stepper.stepFour.social.facebook": "Facebook account",
    "alerts.add.stepper.stepFour.social.instagram": "Instagram account",
    "alerts.add.stepper.stepFour.social.youtube": "Youtube account",

    "alerts.add.stepper.stepFive.title": "Language settings",
    "alerts.add.stepper.stepFive.desc":
      "Special alerts will collect and monitor based on the languages you select",
    "alerts.add.stepper.stepFive.error":
      "Please choose the languages required in the monitoring !",

    "alerts.view.banner.returnFolder":
      "Refusal and return the press folder to the auditor",
    "alerts.view.banner.sendToReview": "Send the press folder for review",
    "alerts.view.banner.writeRecommendation":
      "Writing analysis and recommendations",
    "alerts.view.banner.writeDeclineCause": "Write the reason for rejection",

    "alerts.view.banner.sentToReview":
      "The press folder has been sent for review",
    "alerts.view.banner.openFolder": "To view the press folder page",
    "alerts.view.banner.clickHere": "click here",
    "alerts.view.banner.choosePublisher": "Select the publisher",
    "alerts.view.banner.chooseAuditor": "Select the auditor",
    "alerts.view.banner.chooseEditor": "Select the editor",
    "alerts.view.banner.placeholder.choosePublisher":
      "Please select the publisher",
    "alerts.view.banner.placeholder.chooseAuditor": "Please select the auditor",
    "alerts.view.banner.placeholder.chooseEditor": "Please select the editor",

    "alerts.view.filters.calendar.today": "Today",
    "alerts.view.filters.calendar.yesterday": "Yesterday",
    "alerts.view.filters.calendar.lastWeek": "Last week",
    "alerts.view.filters.calendar.last7Days": "Last 7 days",
    "alerts.view.filters.calendar.last30Days": "Last 30 days",
    "alerts.view.filters.calendar.lastMonth": "Last month",

    "alerts.view.filters.sources.news": "News",
    "alerts.view.filters.sources.instagram": "Instagram",
    "alerts.view.filters.sources.facebook": "Facebook",
    "alerts.view.filters.sources.twitter": "Twitter",
    "alerts.view.filters.sources.linkedin": "Linkedin",
    "alerts.view.filters.sources.youtube": "Youtube",

    "alerts.view.filters.list.sources.all": "All sources",
    "alerts.view.filters.list.sources.websites": "News sites",
    "alerts.view.filters.list.sources.twitter": "Twitter",
    "alerts.view.filters.list.sources.facebook": "Facebook",
    "alerts.view.filters.list.sources.linkedin": "Linkedin",
    "alerts.view.filters.list.sources.instagram": "Instagram",
    "alerts.view.filters.list.sources.videos": "Videos",

    "alerts.view.filters.search.placeholder": "Search by monitored news",
    "alerts.view.filters.dates.label": "Specific date",
    "alerts.view.filters.newsSources.label": "News sources",
    "alerts.view.filters.sentiment.label": "Impression",
    "alerts.view.filters.sources.label": "Sources",

    "alerts.daily.header.date": "Daily press folder",
    "alerts.daily.empty.text":
      "There is no press folder for today and to see all the added press folders",
    "alerts.daily.published.text":
      "The folder has been published for today and to see all the added press folders",
    "alerts.daily.result.empty.text": "There are no results at the moment",
    "alerts.daily.untitled.text": "There is no title for the article",
    "alerts.daily.result.addCategory.btn": "Add a category",
    "alerts.daily.result.editCategory.btn": "Edit a category",
    "alerts.daily.result.delete.confirmText":
      "Confirm deleting the news from the folder",
    "alerts.daily.result.delete.tooltip": "Remove from folder",
    "alerts.daily.result.duplicated.tooltip": "Edit duplicated news",
    "alerts.daily.result.notice.add.tooltip": "Add a note",
    "alerts.daily.result.unbookmark.tooltip": "Remove from favorites list",
    "alerts.daily.result.bookmark.tooltip": "Add to favorites List",
    "alerts.daily.result.unarchive.tooltip": "Remove from archive",
    "alerts.daily.result.archive.tooltip": "Add to archive",
    "alerts.daily.result.unUndesirable.tooltip": "Remove from unwanted list",
    "alerts.daily.result.undesirable.tooltip": "Add in the unwanted list",
    "alerts.daily.result.restore.tooltip": "Undelete",
    "alerts.daily.result.remove.tooltip": "Delete",

    "alerts.daily.result.updateCategory.cat.label": "Please select a category",
    "alerts.daily.result.updateCategory.sentiment.label": "Update impression",

    "alerts.daily.addNote.placeholder": "Note",

    "alerts.daily.banner.counter":
      "The number of news added to the press report",
    "alerts.daily.banner.addNews": "Add news",
    "alerts.daily.banner.duplicated": "Duplicate news",
    "alerts.daily.banner.sendToReview": "Send for review",
    "alerts.daily.banner.sendToPublish": "Send to publish",
    "alerts.daily.banner.declineAndReturn": "Reject and return",
    "alerts.daily.banner.publishAndSend": "Publish and send",
    "alerts.daily.banner.editor.notAuthorized":
      "The file is being edited by the editor",
    "alerts.daily.banner.auditor.notAuthorized":
      "The file is under review by the reviewer",
    "alerts.daily.banner.publisher.notAuthorized":
      "The file is currently being approved and published by the publisher",
    "alerts.daily.banner.superAdmin.notAuthorized":
      "Admin not allowed to modify the press folder",
    "alerts.daily.banner.folderNotCreated":
      "The press file has not been created yet",

    "alerts.daily.banner.inform.draft":
      "The press folder is under editing and cannot be modified at this time",
    "alerts.daily.banner.inform.review":
      "The press folder has been sent for review and cannot be modified at this time",
    "alerts.daily.banner.inform.publisher_check":
      "The press folder has been sent for approval and publication and cannot be modified at the present time",
    "alerts.daily.banner.inform.published":
      "The press folder has been publisher and cannot be modified at the present time",
    "alerts.daily.banner.cancelDuplications": "Cancel Duplications",

    "alerts.daily.banner.duplicationModal.label":
      "Please select the main story",

    "alerts.daily.banner.approveAndPublish":
      "Submit for approval and publication",
    "alerts.daily.banner.returnToEditor": "Return to Editor",
    "alerts.daily.banner.publish": "Publish the press folder",
    "alerts.daily.banner.sendFolder": "Send the press folder",

    "alerts.results.list.sync.updateNews": "Update news list",

    "alerts.results.uncategorizedModal.title": "There is uncategorized news",
    "alerts.results.uncategorizedModal.desc":
      "Please categorize the news before adding it to the press folder",

    "alerts.results.banner.addToFolder": "Add to press folder",

    "alerts.daily.public.empty": "There is no press folder for today",

    "alerts.list.update.category.success":
      "Category has been successfully updated",

    "alerts.daily.uncategorized.text": "Uncategorized news",
    "alerts.newAlert.text": "one new new",
    "alerts.newAlerts.text": "new news",
    "alerts.new.prefix": "There's",
    "alerts.results.already.added": "news already added to folder in",
  },
};
