import { SCRAP_RESULTS_GET, SCRAP_RESULTS_GET_ERROR, SCRAP_RESULTS_GET_SUCCESS } from "./types";

export const getScrapResults = (params) => ({
  type: SCRAP_RESULTS_GET,
  payload: params
});

export const getScrapResultsSuccess = (results) => ({
  type: SCRAP_RESULTS_GET_SUCCESS,
  payload: results
});

export const getScrapResultsError = (error) => ({
  type: SCRAP_RESULTS_GET_ERROR,
  payload: error
});