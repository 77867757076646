// * Countries
export const GET_COUNTRIES_STATS         = 'GET_COUNTRIES_STATS';
export const GET_COUNTRIES_STATS_SUCCESS = 'GET_COUNTRIES_STATS_SUCCESS';
export const GET_COUNTRIES_STATS_ERROR   = 'GET_COUNTRIES_STATS_ERROR';

// * Feedback
export const GET_FEEDBACK_STATS         = 'GET_FEEDBACK_STATS';
export const GET_FEEDBACK_STATS_SUCCESS = 'GET_FEEDBACK_STATS_SUCCESS';
export const GET_FEEDBACK_STATS_ERROR   = 'GET_FEEDBACK_STATS_ERROR';

// * Important news
export const GET_IMPORTANT_NEWS_STATS         = 'GET_IMPORTANT_NEWS_STATS';
export const GET_IMPORTANT_NEWS_STATS_SUCCESS = 'GET_IMPORTANT_NEWS_STATS_SUCCESS';
export const GET_IMPORTANT_NEWS_STATS_ERROR   = 'GET_IMPORTANT_NEWS_STATS_ERROR';

// * Monitoring
export const GET_MONITORING_STATS         = 'GET_MONITORING_STATS';
export const GET_MONITORING_STATS_SUCCESS = 'GET_MONITORING_STATS_SUCCESS';
export const GET_MONITORING_STATS_ERROR   = 'GET_MONITORING_STATS_ERROR';

// * Hashtags
export const GET_HASHTAGS_STATS         = 'GET_HASHTAGS_STATS';
export const GET_HASHTAGS_STATS_SUCCESS = 'GET_HASHTAGS_STATS_SUCCESS';
export const GET_HASHTAGS_STATS_ERROR   = 'GET_HASHTAGS_STATS_ERROR';

// * Tags
export const GET_KEYWORD_STATS         = 'GET_KEYWORD_STATS';
export const GET_KEYWORD_STATS_SUCCESS = 'GET_KEYWORD_STATS_SUCCESS';
export const GET_KEYWORD_STATS_ERROR   = 'GET_KEYWORD_STATS_ERROR';

// * Influencers
export const GET_INFLUENCERS_STATS         = 'GET_INFLUENCERS_STATS';
export const GET_INFLUENCERS_STATS_SUCCESS = 'GET_INFLUENCERS_STATS_SUCCESS';
export const GET_INFLUENCERS_STATS_ERROR   = 'GET_INFLUENCERS_STATS_ERROR';
