import React, { createContext, useState } from 'react';
import { loginApi } from '../../api/auth';
import { ALERTS } from '../../routes/paths';
import { isValidToken, TOKEN_NAME } from '../../utils/token-utils';
import Cookies from 'universal-cookie';

export const AuthContext = createContext({});
const cookies = new Cookies();

export const checkToken = () => {
  const token = localStorage.getItem(TOKEN_NAME) || cookies.get(TOKEN_NAME);
  if (isValidToken(token)) return [token, true];
  else {
    localStorage.removeItem(TOKEN_NAME);
    sessionStorage.removeItem(TOKEN_NAME);
    return [null, false];
  }
};

const initialState = {
  token: checkToken()[0],
  isLoggedIn: checkToken()[1],
  isLoading: false,
  errors: null
};

export const AuthContextProvider = ({children}) => {

const history = window.location;

  const [state, setState] = useState(initialState);

  setInterval(() => {
    if(!checkToken()[0]) logout();
  }, 1000 * 60);

  const loginAction = ({remember, ...credentials}) => {

    setState(prevState => ({
      ...prevState,
      isLoading: true
    }));
    
    loginApi(credentials).then(response => {
      remember ?
      localStorage.setItem(TOKEN_NAME, response.data.token)
      :
      cookies.set(TOKEN_NAME, response.data.token, { path: '/' })
      //sessionStorage.setItem(TOKEN_NAME, response.data.token)
      
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        isLoggedIn: true,
        token: response.data.token
      }));

      history.pathname = ALERTS;

    }).catch(err => {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        errors: err
      }))
    });
  };

  const logout = () => {
    localStorage.removeItem(TOKEN_NAME)
    //sessionStorage.removeItem(TOKEN_NAME);
    cookies.remove(TOKEN_NAME, { path: "/" });
    setState(prevState => ({
      ...prevState,
      token: null,
      isLoggedIn: false
    }));

    //history.replace("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        loginAction,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
