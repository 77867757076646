export const languages = [
  {
    value: '/api/languages/ar',
    label: 'constants.lang.ar'
  },
  {
    value: '/api/languages/en',
    label: 'constants.lang.en'
  }
];

export const advancedSearchLanguages = [
  {
    value: 'ar',
    label: 'constants.lang.ar'
  },
  {
    value: 'en',
    label: 'constants.lang.en'
  }
];
