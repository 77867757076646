import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Avatar, Col, message, Popconfirm, Row, Table, Tooltip, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ColorPicker } from '../../../../../components/ColorPicker';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { ConfigContext } from '../../../../../context/config';
import { PAGE_SIZE } from '../../../../../utils/constants';
import { CAN_DELETE, CAN_PUT } from '../../../../../utils/permissions-utils';
import { listIndexer, tablePaginationOptions } from '../../../../../utils/utils';

const Column = Table;

export const CategoriesList = ({
  listData,
  role,
  currentPage,
  loadingDelete,
  onPageChange,
  onSelectCategory,
  onDeleteCategory
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const { errors, data, totalItems } = listData;

  useEffect(() => {
    !!errors && message.error(errors, 5);
  }, [errors]);

  return (
    <Table
      dataSource={[...data]}
      pagination={tablePaginationOptions({ totalItems, pageSize: 30, currentPage, onPageChange, messageTranslator })}
      locale={{
        emptyText: messageTranslator("categories.list.empty", "categories")
      }}
      rowKey={rec => rec['@id']}
      scroll={{ x: true }}
    >
      <Column
        key="number"
        title="#"
        render={(category, rec, index) => <span>{listIndexer(index, PAGE_SIZE, currentPage)}</span>}
      />
      <Column
        key="name"
        title={messageTranslator("categories.list.label.name", "categories")}
        render={category => <Link to={`#`}>{category.name}</Link>}
      />
      <Column
        key="image"
        title={messageTranslator("categories.list.label.icon", "categories")}
        render={category => (
          <Avatar
            src={`${category?.image?.contentUrl ? `${process.env.REACT_APP_BASE_URL}${category.image.contentUrl}` : undefined}`}
            size={37}
          />
        )}
      />
      <Column
        key="createdAt"
        title={messageTranslator("categories.list.label.newsNumber", "categories")}
        render={category => <Typography.Text className='blue-Text' strong>{category.resultsCount}</Typography.Text>}
      />
      <Column
        key="color"
        title={messageTranslator("categories.list.label.color", "categories")}
        render={category => <ColorPicker inList color={category.color} />}
      />
      <Column
        key="actions"
        width={70}
        render={category => (
          <Row gutter={[8, 8]}>
            <PermissionsHandler
              resourceName="Category"
              blocPermission={CAN_PUT}
              userRole={role}
            >
              <Col span={12}>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("categories.list.tooltip.update", "categories")}
                >
                  <EditFilled onClick={() => onSelectCategory(category)} />
                </Tooltip>
              </Col>
            </PermissionsHandler>
            {!category.resultsCount && <PermissionsHandler
                resourceName="Alert"
                blocPermission={CAN_DELETE}
                userRole={role}
            >
              <Col span={12}>
                <Tooltip
                    placement="bottom"
                    title={messageTranslator("categories.list.tooltip.delete", "categories")}
                >
                  <Popconfirm
                      title={`${messageTranslator("categories.list.delete.confirm", "categories")} ${category.name}`}
                      onConfirm={(e) => onDeleteCategory(category.id)}
                      okText={messageTranslator("commons.delete.confirm", "commons")}
                      cancelText={messageTranslator("commons.cancel", "commons")}
                      okButtonProps={{
                        loading: loadingDelete
                      }}
                  >
                    <DeleteFilled />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </PermissionsHandler>}
          </Row>
        )}
      />
    </Table>
  );
};
