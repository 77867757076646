// * Send reset request
export const FORGET_PASSWORD         = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR   = 'FORGET_PASSWORD_ERROR';
export const FORGET_PASSWORD_RESET   = 'FORGET_PASSWORD_RESET';

// * Reset password
export const RESET_PASSWORD         = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR   = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_RESET   = 'RESET_PASSWORD_RESET';