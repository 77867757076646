import { Avatar, Badge, Col, List, Row, Space, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { FALLBACK_IMAGE, SCRAP_FALLBACK_IMAGE } from '../../../../../utils/constants';
import { categorizeNews } from '../../../../../utils/utils';


export const NewsList = ({
  globalConfig,
  folderData
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [categorizedData, setCategorizedData] = useState([]);

  const [categoryIcon, setCatgoryIcon] = useState(FALLBACK_IMAGE);

  useEffect(() => {
    if (globalConfig) setCatgoryIcon(() => {
      if (globalConfig?.values.default_category_image?.contentUrl) return `${process.env.REACT_APP_BASE_URL}${globalConfig.values.default_category_image.contentUrl}`;
      return FALLBACK_IMAGE;
    })
  }, [globalConfig]);

  useEffect(() => {
    !!folderData && setCategorizedData(categorizeNews(folderData.folderItems, messageTranslator));
  }, [folderData, messageTranslator]);

  if (!folderData) return (
    <Row justify='center'>
      <Col>
        <Space style={{ padding: "12px 35px", backgroundColor: "#EFF4FA", borderRadius: "4px" }} size={4}>
          <Typography.Text className='blue-Text'>
            {messageTranslator("alerts.daily.public.empty", "alerts")}
          </Typography.Text>
        </Space>
      </Col>
    </Row>
  );

  return (
    <Row gutter={[24, 24]}>
      <Col span={24} id="myUL">
        {
          categorizedData.map(category => {
            return (
              <div
                className="daily-folder-category-list"
                id={category.id}
                key={category.id}
              >
                <List
                  size="large"
                  dataSource={[...category.data]}
                  locale={{
                    emptyText: messageTranslator("alerts.daily.result.empty.text", "alerts")
                  }}
                  header={(
                    <Row align='middle'>
                      <Col flex="75px">
                        <Avatar
                          src={category.image ? `${process.env.REACT_APP_BASE_URL}${category.image}` : categoryIcon}
                          size={40}
                        />
                      </Col>
                      <Col flex="auto">
                        <Typography.Text strong>{category?.name}</Typography.Text>
                      </Col>
                    </Row>
                  )}
                  renderItem={result => {
                    return (
                      <>
                        <List.Item
                          actions={result.childrenDuplicated.map(child => (
                            <Tooltip title={child.title || `-- ${messageTranslator("alerts.daily.untitled.text", "alerts")} --`}>
                              <a
                                href={child.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Avatar
                                  src={child.sourceImage || SCRAP_FALLBACK_IMAGE}
                                  size={40}
                                />
                              </a>
                            </Tooltip>
                          ))}
                        >
                          <List.Item.Meta
                            avatar={(
                              <a
                                href={result.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Badge offset={[-5, 50]} count={
                                  result.sourceType !== 'website' ? (
                                    <Avatar
                                      size={20}
                                      src={`/images/social-media/${result.sourceType}.png`}
                                      className="avatar-source"
                                    />
                                  ) : null
                                }>
                                  <Avatar
                                    size={64}
                                    src={result.sourceImage || `${process.env.REACT_APP_BASE_URL}${globalConfig?.values?.default_source_image?.contentUrl}`}
                                  />
                                </Badge>
                              </a>
                            )}
                            title={(
                              <>
                                <span className='date'>{moment(result.createdAt).format('DD MMM')}</span> <span className='user'>
                                  <a
                                    href={result.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {`${result.sourceType === 'twitter' ? `@${result.sourceName}` : result.sourceName}`}
                                  </a>
                                </span>
                              </>
                            )}
                            description={(
                              <a
                                href={result.url}
                                target="_blank"
                                rel="noreferrer"
                                className="list_item_desc"
                              >
                                {result.title || result.content.substring(0, 200)}
                              </a>
                            )}
                          />
                        </List.Item>
                      </>
                    )
                  }}
                />
              </div>
            )
          })
        }
      </Col>
    </Row>

  );
};
