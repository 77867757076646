import { CONFIG_CREATE, CONFIG_CREATE_ERROR, CONFIG_CREATE_RESET, CONFIG_CREATE_SUCCESS, CONFIG_GET, CONFIG_GET_ERROR, CONFIG_GET_SUCCESS, CONFIG_UPDATE, CONFIG_UPDATE_RESET, CONFIG_UPDATE_SUCCESS, EMAIL_CONFIG_SEND_TEST, EMAIL_CONFIG_SEND_TEST_ERROR, EMAIL_CONFIG_SEND_TEST_RESET, EMAIL_CONFIG_SEND_TEST_SUCCESS, GLOABL_CONFIG_LOAD, GLOABL_CONFIG_LOAD_SUCCESS } from "./types";

// * Create email config
export const createConfig = (config) => ({
  type: CONFIG_CREATE,
  payload: config
});

export const createConfigSuccess = () => ({
  type: CONFIG_CREATE_SUCCESS
});

export const createConfigError = (errors) => ({
  type: CONFIG_CREATE_ERROR,
  payload: errors
});

export const createConfigReset = () => ({
  type: CONFIG_CREATE_RESET
});

// * Get email config
export const getConfig = (name) => ({
  type: CONFIG_GET,
  payload: name
});

export const getConfigSuccess = (config) => ({
  type: CONFIG_GET_SUCCESS,
  payload: config
});

export const getConfigError = (errors) => ({
  type: CONFIG_GET_ERROR,
  payload: errors
});

// * Update email config
export const updateConfig = (config) => ({
  type: CONFIG_UPDATE,
  payload: config
});

export const updateConfigSuccess = (newConfig) => ({
  type: CONFIG_UPDATE_SUCCESS,
  payload: newConfig
});

export const updateConfigError = (errors) => ({
  type: CONFIG_UPDATE,
  payload: errors
});

export const updateConfigReset = () => ({
  type: CONFIG_UPDATE_RESET
});

// * Send test email
export const sendTestEmail = (testEmail) => ({
  type: EMAIL_CONFIG_SEND_TEST,
  payload: testEmail
});

export const sendTestEmailSuccess = () => ({
  type: EMAIL_CONFIG_SEND_TEST_SUCCESS
});

export const sendTestEmailError = (error) => ({
  type: EMAIL_CONFIG_SEND_TEST_ERROR,
  payload: error
});

export const sendTestEmailReset = () => ({
  type: EMAIL_CONFIG_SEND_TEST_RESET
});

// * Load gloabl config
export const loadGlobalConfig = () => ({
  type: GLOABL_CONFIG_LOAD
});

export const loadGlobalConfigSuccess = (config) => ({
  type: GLOABL_CONFIG_LOAD_SUCCESS,
  payload: config
});
