import { Button, Card, Col, Divider, Form, Input, message, Row, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { checkReuseSamePassword, matchPasswords } from '../../../../../utils/utils';

export const PasswordCard = ({
  changePassword: { isLoading, isChanged, errors },
  onSubmit
}) => {

  const [formRef] = Form.useForm();
  const { resetFields, setFields } = formRef;

  const { messageTranslator } = useContext(ConfigContext);

  const REQUIRED_FIELD = messageTranslator("commons.required.message", "commons");
  const MINIMUM_LENGTH = messageTranslator("users.list.labels.invalidPassword", "users");

  useEffect(() => {
    if (errors?.general) setFields([
      {
        name: "oldPassword",
        errors: errors.general.map(error => messageTranslator(error, "errors"))
      }
    ])
  }, [errors, setFields, messageTranslator]);

  useEffect(() => {
    if (isChanged) {
      message.success(messageTranslator("users.password.change.success.message", "users"));
      resetFields();
    }
  }, [isChanged, messageTranslator, resetFields]);

  const handleCancelUpdate = () => resetFields();

  const handleFormSubmit = ({ confirm, ...passwords }) => onSubmit(passwords);

  return (
    <Col className='inbox-settings-wrapper'>
      <Card
        title={<Typography.Title level={4}>{messageTranslator("users.profile.card.changePassword.title", "users")}</Typography.Title>}
      >
        <Form
          layout="vertical"
          form={formRef}
          onFinish={handleFormSubmit}
        >
          <Row gutter={[36, 16]}>
            <Col md={12} xs={24}>
              <Form.Item
                label={messageTranslator("users.profile.card.password.current.label", "users")}
                name="oldPassword"
                rules={[
                  { required: true, message: REQUIRED_FIELD }
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[36, 16]}>
            <Col md={12} xs={24}>
              <Form.Item
                label={messageTranslator("users.profile.card.password.new.label", "users")}
                name="password"
                rules={[
                  { required: true, message: REQUIRED_FIELD },
                  { min: 8, message: MINIMUM_LENGTH },
                  ({ getFieldValue }) => checkReuseSamePassword(getFieldValue, messageTranslator("users.password.change.error.reusedPassword", "users"))
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={messageTranslator("users.profile.card.password.confirm.label", "users")}
                name="confirm"
                rules={[
                  { required: true, message: REQUIRED_FIELD },
                  ({ getFieldValue }) => matchPasswords(getFieldValue, messageTranslator("users.list.labels.passwordsNotMatch", "users"))
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row justify="end">
            <Col>
              <span key="cancel" className='btn-wrapper white'>
                <Button onClick={handleCancelUpdate}>
                  {messageTranslator("commons.cancel", "commons")}
                </Button>
              </span>
            </Col>
            <Col>
              <span key="cancel" className='btn-wrapper'>
                <Button type="primary" loading={isLoading} htmlType="submit">
                  {messageTranslator("commons.save", "commons")}
                </Button>
              </span>
            </Col>
          </Row>
        </Form>
      </Card>
    </Col>
  );
};
