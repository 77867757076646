import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Avatar, Col, Popconfirm, Row, Switch, Table, Tooltip, Typography } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { ConfigContext } from '../../../../../context/config';
import { SOURCES } from '../../../../../routes/paths';
import { CAN_DELETE, CAN_PUT } from '../../../../../utils/permissions-utils';
import { listIndexer, tablePaginationOptions } from '../../../../../utils/utils';

const Column = Table;

const PER_PAGE = 20;

export const SourcesList = ({
  sources: { data, totalItems },
  role,
  currentPage,
  loadingDelete,
  onUpdate,
  onPageChange,
  onDeleteSource,
  onToggleEnabled
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const renderSocialMedia = (source) => (
    <ul className='social-list'>
      {
        !!source.twitterUrl && <li><Avatar size={24} src="/images/social-media/twitter.png" /></li>
      }
      {
        !!source.facebookUrl && <li><Avatar size={24} src="/images/social-media/facebook.png" /></li>
      }
      {
        !!source.instagramUrl && <li><Avatar size={24} src="/images/social-media/instagram.png" /></li>
      }
      {
        !!source.youtubeUrl && <li><Avatar size={24} src="/images/social-media/youtube.png" /></li>
      }
    </ul>
  );

  return (
    <Table
      dataSource={[...data]}
      pagination={tablePaginationOptions({ totalItems, pageSize: PER_PAGE, currentPage, onPageChange, messageTranslator })}
      locale={{
        emptyText: messageTranslator("sources.emtyText", "sources")
      }}
      className='sources-list'
      rowKey={rec => rec['@id']}
      scroll={{ x: true }}
    >
      <Column
        key="index"
        title="#"
        render={(source, rec, index) => <Typography.Text>{listIndexer(index, PER_PAGE, currentPage)}</Typography.Text>}
      />
      <Column
        key="name"
        title={messageTranslator("sources.list.siteName", "sources")}
        render={source => <Typography.Text>{source.name}</Typography.Text>}
      />
      <Column
        key="url"
        title={messageTranslator("sources.list.sourceUrl", "sources")}
        render={source => <Typography.Text>{source.url}</Typography.Text>}
      />
      <Column
        key="media"
        title={messageTranslator("sources.list.socialMedia", "sources")}
        render={source => renderSocialMedia(source)}
      />
      <Column
        key="icon"
        title={messageTranslator("sources.list.sourceImage", "sources")}
        render={source => (
          <Avatar
            src={`${source?.image?.contentUrl ? `${process.env.REACT_APP_BASE_URL}${source.image.contentUrl}` : undefined}`}
            size={37}
          />
        )}
      />
      <Column
        key="detect"
        title={messageTranslator("sources.list.monitoring", "sources")}
        render={source => <Link to={`${SOURCES}/${source.id}`} className='blue-Text'>{source.resultsCount}</Link>}
      />
      <Column
        key="editable"
        title={messageTranslator("sources.list.activation", "sources")}
        render={source => <Switch checked={source.enabled} onChange={(checked) => onToggleEnabled({ enabled: checked, id: source.id })} />}
      />
      <Column
        key="actions"
        title=""
        render={source =>
          <Row gutter={[8, 8]}>
            <PermissionsHandler
              resourceName="Source"
              blocPermission={CAN_PUT}
              userRole={role}
            >
              <Col>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("sources.list.confirmEdit", "sources")}
                >
                  <EditFilled onClick={() => onUpdate(source)} />
                </Tooltip>
              </Col>
            </PermissionsHandler>
            <PermissionsHandler
              resourceName="Source"
              blocPermission={CAN_DELETE}
              userRole={role}
            >
              <Col>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("sources.list.confirmDelete", "sources")}
                >
                  <Popconfirm
                    title={messageTranslator("sources.list.confirmDelete.text", "sources")}
                    okText={messageTranslator("commons.delete.confirm", "commons")}
                    cancelText={messageTranslator("commons.cancel", "commons")}
                    onConfirm={() => onDeleteSource(source.id)}
                    okButtonProps={{
                      loading: loadingDelete
                    }}
                  >
                    <DeleteFilled />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </PermissionsHandler>
          </Row>
        }
      />
    </Table>
  );
};
