import {
  ALERT_RESULTS_ADVANCED_SEARCH,
  ALERT_RESULTS_ADVANCED_SEARCH_ERROR,
  ALERT_RESULTS_ADVANCED_SEARCH_SUCCESS,
  ALERT_RESULTS_FILTER_BY_SOURCE_TYPE,
  ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_ERROR,
  ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_SUCCESS,
  ALERT_RESULTS_GET,
  ALERT_RESULTS_GET_ERROR,
  ALERT_RESULTS_GET_SUCCESS,
  ALERT_NEW_RESULTS_GET,
  ALERT_NEW_RESULTS_GET_SUCCESS,
  ALERT_NEW_RESULTS_GET_ERROR,
  ALERT_RESULTS_PATCH_ACTIONS,
  ALERT_RESULTS_PATCH_ACTIONS_ERROR,
  ALERT_RESULTS_PATCH_ACTIONS_SUCCESS,
  ALERT_RESULTS_PATCH_CATEGORY,
  ALERT_RESULTS_PATCH_CATEGORY_ERROR,
  ALERT_RESULTS_PATCH_CATEGORY_RESET,
  ALERT_RESULTS_PATCH_CATEGORY_SUCCESS,
  ALERT_RESULTS_SEARCH,
  ALERT_RESULTS_SEARCH_ERROR,
  ALERT_RESULTS_SEARCH_SUCCESS,
  FOLDER_ADD,
  FOLDER_ADD_ERROR,
  FOLDER_ADD_RESET,
  FOLDER_ADD_SUCCESS,
  FOLDER_CHANGE_STATE_AUDITOR,
  FOLDER_CHANGE_STATE_AUDITOR_ERROR,
  FOLDER_CHANGE_STATE_AUDITOR_RESET,
  FOLDER_CHANGE_STATE_AUDITOR_SUCCESS,
  FOLDER_COMBINE_DUPLICATIONS,
  FOLDER_COMBINE_DUPLICATIONS_ERROR,
  FOLDER_COMBINE_DUPLICATIONS_RESET,
  FOLDER_COMBINE_DUPLICATIONS_SUCCESS,
  FOLDER_GET_DAILY,
  FOLDER_GET_DAILY_ERROR,
  FOLDER_GET_DAILY_SUCCESS,
  FOLDER_PATCH,
  FOLDER_PATCH_ERROR,
  FOLDER_PATCH_RESET,
  FOLDER_PATCH_SUCCESS,
  FOLDER_PUBLISH,
  FOLDER_PUBLISH_ERROR,
  FOLDER_PUBLISH_RESET,
  FOLDER_PUBLISH_SUCCESS,
  FOLDER_REMOVE_ITEM,
  FOLDER_REMOVE_ITEM_ERROR,
  FOLDER_REMOVE_ITEM_RESET,
  FOLDER_REMOVE_ITEM_SUCCESS,
  FOLDER_SET_REVIEW,
  FOLDER_SET_REVIEW_ERROR,
  FOLDER_SET_REVIEW_RESET,
  FOLDER_SET_REVIEW_SUCCESS,
  SEARCH_SOURCE_NAMES,
  SEARCH_SOURCE_NAMES_ERROR,
  SEARCH_SOURCE_NAMES_SUCCESS,
  ALERT_NEW_RESULTS_GET_RESET,
} from "./types";

// * Get alert results
export const getAlertResults = (params) => ({
  type: ALERT_RESULTS_GET,
  payload: params,
});

export const getAlertResultsSuccess = (results) => ({
  type: ALERT_RESULTS_GET_SUCCESS,
  payload: results,
});

export const getAlertResultsError = (errors) => ({
  type: ALERT_RESULTS_GET_ERROR,
  payload: errors,
});

// * Get new alert results
export const getNewAlertResults = (params) => ({
  type: ALERT_NEW_RESULTS_GET,
  payload: params,
});

export const getNewAlertResultsSuccess = (results) => ({
  type: ALERT_NEW_RESULTS_GET_SUCCESS,
  payload: results,
});

export const getNewAlertResultsError = (errors) => ({
  type: ALERT_NEW_RESULTS_GET_ERROR,
  payload: errors,
});

export const getNewAlertResultsReset = () => ({
  type: ALERT_NEW_RESULTS_GET_RESET,
});

// * Set bookmarks
export const patchAlertResultActions = (params) => ({
  type: ALERT_RESULTS_PATCH_ACTIONS,
  payload: params,
});

export const patchAlertResultActionsSuccess = (newResult) => ({
  type: ALERT_RESULTS_PATCH_ACTIONS_SUCCESS,
  payload: newResult,
});

export const patchAlertResultActionsError = (errors) => ({
  type: ALERT_RESULTS_PATCH_ACTIONS_ERROR,
  payload: errors,
});

// * Search source names
export const searchSourceNames = (terms) => ({
  type: SEARCH_SOURCE_NAMES,
  payload: terms,
});

export const searchSourceNamesSuccess = (sourceNames) => ({
  type: SEARCH_SOURCE_NAMES_SUCCESS,
  payload: sourceNames,
});

export const searchSourceNamesError = (errors) => ({
  type: SEARCH_SOURCE_NAMES_ERROR,
  payload: errors,
});

// * Advanced search
export const alertResultsAdvancedSearch = (terms) => ({
  type: ALERT_RESULTS_ADVANCED_SEARCH,
  payload: terms,
});

export const alertResultsAdvancedSearchSuccess = (results) => ({
  type: ALERT_RESULTS_ADVANCED_SEARCH_SUCCESS,
  payload: results,
});

export const alertResultsAdvancedSearchError = (errors) => ({
  type: ALERT_RESULTS_ADVANCED_SEARCH_ERROR,
  payload: errors,
});

// * Patch result category
export const patchResultCategory = (values) => ({
  type: ALERT_RESULTS_PATCH_CATEGORY,
  payload: values,
});

export const patchResultCategorySuccess = (newResult) => ({
  type: ALERT_RESULTS_PATCH_CATEGORY_SUCCESS,
  payload: newResult,
});

export const patchResultCategoryError = (errors) => ({
  type: ALERT_RESULTS_PATCH_CATEGORY_ERROR,
  payload: errors,
});

export const patchResultCategoryReset = () => ({
  type: ALERT_RESULTS_PATCH_CATEGORY_RESET,
});

// * Search for results
export const searchForResults = (term) => ({
  type: ALERT_RESULTS_SEARCH,
  payload: term,
});

export const searchForResultsSuccess = (results) => ({
  type: ALERT_RESULTS_SEARCH_SUCCESS,
  payload: results,
});

export const searchForResultsError = (errors) => ({
  type: ALERT_RESULTS_SEARCH_ERROR,
  payload: errors,
});

// * Filter by sourceType
export const filterBySourceType = (params) => ({
  type: ALERT_RESULTS_FILTER_BY_SOURCE_TYPE,
  payload: params,
});

export const filterBySourceTypeSuccess = (results) => ({
  type: ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_SUCCESS,
  payload: results,
});

export const filterBySourceTypeError = (errors) => ({
  type: ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_ERROR,
  payload: errors,
});

/**
 * * FOLDERS
 */
// * Add folder
export const addFolder = (folder) => ({
  type: FOLDER_ADD,
  payload: folder,
});

export const addFolderSuccess = (updates) => ({
  type: FOLDER_ADD_SUCCESS,
  payload: updates,
});

export const addFolderError = (errors) => ({
  type: FOLDER_ADD_ERROR,
  payload: errors,
});

export const addFolderReset = () => ({
  type: FOLDER_ADD_RESET,
});

// * Get daily folder
export const getFolderDaily = (data) => ({
  type: FOLDER_GET_DAILY,
  payload: data,
});

export const getFolderDailySuccess = (dailyNews) => ({
  type: FOLDER_GET_DAILY_SUCCESS,
  payload: dailyNews,
});

export const getFolderDailyError = (errors) => ({
  type: FOLDER_GET_DAILY_ERROR,
  payload: errors,
});

// * Patch folder
export const patchFolder = (data) => ({
  type: FOLDER_PATCH,
  payload: data,
});

export const patchFolderSuccess = () => ({
  type: FOLDER_PATCH_SUCCESS,
});

export const patchFolderError = (errors) => ({
  type: FOLDER_PATCH_ERROR,
  payload: errors,
});

export const patchFolderReset = () => ({
  type: FOLDER_PATCH_RESET,
});

// * Create duplicated news
export const combineDuplicatedNews = (data) => ({
  type: FOLDER_COMBINE_DUPLICATIONS,
  payload: data,
});

export const combineDuplicatedNewsSuccess = () => ({
  type: FOLDER_COMBINE_DUPLICATIONS_SUCCESS,
});

export const combineDuplicatedNewsError = (errors) => ({
  type: FOLDER_COMBINE_DUPLICATIONS_ERROR,
  payload: errors,
});

export const combineDuplicatedNewsReset = () => ({
  type: FOLDER_COMBINE_DUPLICATIONS_RESET,
});

// * Publish folder
export const publishFolder = (data) => ({
  type: FOLDER_PUBLISH,
  payload: data,
});

export const publishFolderSuccess = () => ({
  type: FOLDER_PUBLISH_SUCCESS,
});

export const publishFolderError = (errors) => ({
  type: FOLDER_PUBLISH_ERROR,
  payload: errors,
});

export const publishFolderReset = () => ({
  type: FOLDER_PUBLISH_RESET,
});

// * Set folder to review
export const setFolderToReview = (data) => ({
  type: FOLDER_SET_REVIEW,
  payload: data,
});
export const setFolderToReviewSuccess = () => ({
  type: FOLDER_SET_REVIEW_SUCCESS,
});

export const setFolderToReviewError = (errors) => ({
  type: FOLDER_SET_REVIEW_ERROR,
  payload: errors,
});

export const setFolderToReviewReset = () => ({
  type: FOLDER_SET_REVIEW_RESET,
});

// * Remove item from folder
export const removeItemFromFolder = (data) => ({
  type: FOLDER_REMOVE_ITEM,
  payload: data,
});
export const removeItemFromFolderSuccess = () => ({
  type: FOLDER_REMOVE_ITEM_SUCCESS,
});

export const removeItemFromFolderError = (errors) => ({
  type: FOLDER_REMOVE_ITEM_ERROR,
  payload: errors,
});

export const removeItemFromFolderReset = () => ({
  type: FOLDER_REMOVE_ITEM_RESET,
});

// * Auditor chnage folder state
export const auditorChangeFolderState = (data) => ({
  type: FOLDER_CHANGE_STATE_AUDITOR,
  payload: data,
});
export const auditorChangeFolderStateSuccess = () => ({
  type: FOLDER_CHANGE_STATE_AUDITOR_SUCCESS,
});

export const auditorChangeFolderStateError = (errors) => ({
  type: FOLDER_CHANGE_STATE_AUDITOR_ERROR,
  payload: errors,
});

export const auditorChangeFolderStateReset = () => ({
  type: FOLDER_CHANGE_STATE_AUDITOR_RESET,
});
