import { Button, Form, Modal, Alert } from 'antd';
import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { EmailGroupSelector } from '../../../../../components/EmailGroupSelector';
import { ConfigContext } from '../../../../../context/config';
import { validateEmail } from '../../../../../utils/utils';

export const ReceiversModal = forwardRef(({
  modalProps,
  isLoading,
  emailGroupList,
  exportError,
  onOk,
  onCancel,
}, ref) => {

  const [formRef] = Form.useForm();
  const { submit, resetFields, setFields } = formRef;

  const { messageTranslator } = useContext(ConfigContext);

  const [emailGroups, setEmailGroups] = useState([]);

  const handleSubmitEmails = ({ email = [] }) => {
    let emailsError = [];
    if (!emailGroups.length && !email.length) emailsError = [messageTranslator("reports.view.send.modal.email.error.message", "reports")];
    else {
      onOk({
        receiversGroups: emailGroups,
        receivers: email.filter(em => validateEmail(em)).toString()
      });
    }
    setFields([{
      name: "email",
      errors: emailsError
    }]);
  }

  useImperativeHandle(ref, () => ({
    resetModal: () => {
      resetFields();
      setEmailGroups([]);
    }
  }),
    [resetFields]
  )

  return (
    <Modal
      {...modalProps}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={onCancel}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className='btn-wrapper'>
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={submit}
          >
            {messageTranslator("commons.send", "commons")}
          </Button>
        </span>
      ]}
    >
      <Form
        form={formRef}
        layout="vertical"
        onFinish={handleSubmitEmails}
      >
        {!!exportError?.general && exportError.general.map(error => (
              <Alert message={error ? messageTranslator(error, 'errors') : messageTranslator('FAILED_SEND_EMAIL', 'errors')} type="error" />
        ))}
        <EmailGroupSelector
          formRef={formRef}
          fieldName="email"
          emailGroupList={emailGroupList}
          onChange={setEmailGroups}
        />
      </Form>
    </Modal>
  );
});
