import { CATEGORIES_CREATE, CATEGORIES_CREATE_ERROR, CATEGORIES_CREATE_SUCCESS, CATEGORIES_DELETE, CATEGORIES_DELETE_ERROR, CATEGORIES_DELETE_SUCCESS, CATEGORIES_LIST, CATEGORIES_LIST_ERROR, CATEGORIES_LIST_SUCCESS, CATEGORIES_RESET_CREATE, CATEGORIES_RESET_DELETE, CATEGORIES_RESET_UPDATE, CATEGORIES_UPDATE, CATEGORIES_UPDATE_ERROR, CATEGORIES_UPDATE_SUCCESS } from "./types";

export const categoriesInitialState = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null
  },
  list: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null
  },
  delete: {
    isLoading: false,
    isDeleted: false,
    errors: null
  }
};

export const categoriesReducer = (state = categoriesInitialState, action) => {
  switch (action.type) {

    // * Create
    case CATEGORIES_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null
        }
      };

    case CATEGORIES_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true
        }
      };

    case CATEGORIES_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload
        }
      };

    case CATEGORIES_RESET_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: false,
          errors: null
        }
      };

    // * List
    case CATEGORIES_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null
        }
      };

    case CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.data,
          totalItems: action.payload.totalItems
        }
      };

    case CATEGORIES_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload
        }
      };

      // * Update
      case CATEGORIES_UPDATE:
        return {
          ...state,
          update: {
            ...state.update,
            isLoading: true,
            errors: null
          }
        };
  
      case CATEGORIES_UPDATE_SUCCESS:
        return {
          ...state,
          update: {
            ...state.update,
            isLoading: false,
            isUpdated: true
          }
        };
  
      case CATEGORIES_UPDATE_ERROR:
        return {
          ...state,
          update: {
            ...state.update,
            isLoading: false,
            errors: action.payload
          }
        };
  
      case CATEGORIES_RESET_UPDATE:
        return {
          ...state,
          update: {
            ...state.update,
            isUpdated: false,
            errors: null
          }
        };

        // * Delete
        case CATEGORIES_DELETE:
          return {
            ...state,
            delete: {
              ...state.delete,
              isLoading: true,
              errors: null
            }
          };
    
        case CATEGORIES_DELETE_SUCCESS:
          return {
            ...state,
            delete: {
              ...state.delete,
              isLoading: false,
              isDeleted: true
            }
          };
    
        case CATEGORIES_DELETE_ERROR:
          return {
            ...state,
            delete: {
              ...state.delete,
              isLoading: false,
              errors: action.payload
            }
          };
    
        case CATEGORIES_RESET_DELETE:
          return {
            ...state,
            delete: {
              ...state.delete,
              isDeleted: false,
              errors: null
            }
          };
  
    default:
      return state;
  }
};