import React, { createContext, useCallback, useState } from 'react';

export const AlertContext = createContext({});

export const AlertContextProvider = ({ children }) => {

  const [alertState, setAlert] = useState({
    stepCount: 0,
    data: {
      name: null,
      url: null,
      andTags: [],
      orTags: [],
      excludedTags: [],
      hashtags: [],
      mentions: [],
      twitter: null,
      facebook: null,
      instagram: null,
      youtube: null,
      followUrl: false,
      followFacebookUrl: false,
      followTwitterUrl: false,
      followYoutubeUrl: false,
      followInstagramUrl: false
    },
    source: {
      name: null,
      url: null,
    }
  });

  const updateCount = useCallback(
    (count) => setAlert(prevState => ({
      ...prevState,
      stepCount: count
    })), []);

  const addWebsite = useCallback(
    (name) => setAlert(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        name,
      }
    })), []);

  const addWebsiteURL = ({ url, followUrl }) => {
    setAlert(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        url,
        followUrl
      }
    }));
  };

  const addTags = (andTags, orTags, excludedTags, hashtags, mentions) => setAlert(prevState => ({
    ...prevState,
    data: {
      ...prevState.data,
      andTags,
      orTags,
      excludedTags,
      hashtags,
      mentions
    }
  }));

  const addSocialMedia = ({ twitter, facebook, instagram, youtube, followFacebookUrl, followTwitterUrl, followYoutubeUrl, followInstagramUrl }) => setAlert(prevState => ({
    ...prevState,
    data: {
      ...prevState.data,
      twitter,
      facebook,
      instagram,
      youtube,
      followFacebookUrl,
      followTwitterUrl,
      followYoutubeUrl,
      followInstagramUrl
    }
  }));

  /**
   * Source
   */
  const addSourceName = (name) => setAlert(prevState => ({
    ...prevState,
    source: {
      ...prevState.source,
      name
    }
  }));

  const addSourceUrl = (url) => setAlert(prevState => ({
    ...prevState,
    source: {
      ...prevState.source,
      url
    }
  }));

  return (
    <AlertContext.Provider value={{
      alertState,
      addWebsite,
      addWebsiteURL,
      updateCount,
      addTags,
      addSocialMedia,
      addSourceName,
      addSourceUrl
    }}>
      {children}
    </AlertContext.Provider>
  );
};
