import { removeItemFromArray, replaceItemInArray } from "../../utils/utils";
import {
  REPORT_CANCEL_SCHEDULE,
  REPORT_CANCEL_SCHEDULE_ERROR,
  REPORT_CANCEL_SCHEDULE_RESET,
  REPORT_CANCEL_SCHEDULE_SUCCESS,
  REPORT_CREATE,
  REPORT_CREATE_ERROR,
  REPORT_CREATE_RESET,
  REPORT_CREATE_SUCCESS,
  REPORT_DELETE,
  REPORT_DELETE_ERROR,
  REPORT_DELETE_RESET,
  REPORT_DELETE_SUCCESS,
  REPORT_EXPORT,
  REPORT_EXPORT_ERROR,
  REPORT_EXPORT_RESET,
  REPORT_EXPORT_SUCCESS,
  REPORT_GET_LIST,
  REPORT_GET_LIST_ERROR,
  REPORT_GET_LIST_SUCCESS,
  REPORT_GET_ONE,
  REPORT_GET_ONE_ERROR,
  REPORT_GET_ONE_SUCCESS,
  REPORT_UPDATE,
  REPORT_UPDATE_ERROR,
  REPORT_UPDATE_RESET,
  REPORT_UPDATE_SUCCESS,
  REPORT_GET_SCHEDULE,
  REPORT_GET_SCHEDULE_SUCCESS,
  REPORT_GET_SCHEDULE_ERROR,
} from "./types";

export const reportsInitialState = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null,
  },
  report: {
    data: null,
    isLoading: false,
    errors: null,
  },
  list: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null,
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null,
  },
  delete: {
    isLoading: false,
    isDeleted: false,
    errors: null,
  },
  export: {
    file: null,
    isLoading: false,
    isExported: false,
    errors: null,
  },
  schedule: {
    data: null,
    isLoading: false,
    errors: null,
  },
  cancelSchedule: {
    isLoading: false,
    isCancelled: false,
    errors: null,
  },
};

export const reportsReducer = (state = reportsInitialState, action) => {
  switch (action.type) {
    // * Create report
    case REPORT_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null,
        },
      };

    case REPORT_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
        },
        list: {
          ...state.list,
          data: [action.payload, ...state.list.data],
          totalItems: state.list.totalItems + 1,
        },
      };

    case REPORT_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload,
        },
      };

    case REPORT_CREATE_RESET:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: false,
          errors: null,
        },
      };

    // * Get report
    case REPORT_GET_ONE:
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: true,
          errors: null,
        },
      };

    case REPORT_GET_ONE_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: false,
          data: action.payload,
        },
      };

    case REPORT_GET_ONE_ERROR:
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Get reports
    case REPORT_GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null,
        },
      };

    case REPORT_GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.data,
          totalItems: action.payload.totalItems,
        },
      };

    case REPORT_GET_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Update report
    case REPORT_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
          errors: null,
        },
      };

    case REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true,
        },
        list: {
          ...state.list,
          data: replaceItemInArray(action.payload, state.list.data, "id"),
        },
      };

    case REPORT_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload,
        },
      };

    case REPORT_UPDATE_RESET:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null,
        },
      };

    // * Delete report
    case REPORT_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: true,
          errors: null,
        },
      };

    case REPORT_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          isDeleted: true,
        },
        list: {
          ...state.list,
          data: removeItemFromArray(action.payload, state.list.data),
          totalItems: state.list.totalItems - 1,
        },
      };

    case REPORT_DELETE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          errors: action.payload,
        },
      };

    case REPORT_DELETE_RESET:
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleted: false,
          errors: null,
        },
      };

    // * Export report
    case REPORT_EXPORT:
      return {
        ...state,
        export: {
          ...state.export,
          isLoading: true,
          errors: null,
        },
      };

    case REPORT_EXPORT_SUCCESS:
      return {
        ...state,
        export: {
          ...state.export,
          file: action.payload,
          isLoading: false,
          isExported: true,
          error: null
        },
      };

    case REPORT_EXPORT_ERROR:
      return {
        ...state,
        export: {
          ...state.export,
          isLoading: false,
          error: action.payload,
        },
      };

    case REPORT_EXPORT_RESET:
      return {
        ...state,
        export: {
          ...state.export,
          isExported: false,
          file: null,
          errors: null,
        },
      };

    // * Cancel schedule
    case REPORT_CANCEL_SCHEDULE:
      return {
        ...state,
        cancelSchedule: {
          ...state.cancelSchedule,
          isLoading: true,
          errors: null,
        },
      };

    case REPORT_CANCEL_SCHEDULE_SUCCESS:
      return {
        ...state,
        cancelSchedule: {
          ...state.cancelSchedule,
          isLoading: false,
          isCancelled: true,
        },
        report: {
          ...state.report,
          data: {
            ...(state.report.data || {}),
            scheduledReport: null,
          },
        },
      };

    case REPORT_CANCEL_SCHEDULE_ERROR:
      return {
        ...state,
        cancelSchedule: {
          ...state.cancelSchedule,
          isLoading: false,
          errors: action.payload,
        },
      };

    case REPORT_CANCEL_SCHEDULE_RESET:
      return {
        ...state,
        cancelSchedule: {
          ...state.cancelSchedule,
          isCancelled: false,
          errors: null,
        },
      };

    case REPORT_GET_SCHEDULE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          isLoading: false,
        },
      };

    case REPORT_GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: action.payload,
        isLoading: false,
      };

    case REPORT_GET_SCHEDULE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};
