import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../store/logout';
import { checkToken } from '../../context/Auth';
import { AuthContext } from '../../context/Auth';
import "./anonymLayout-styles.less";

const { Content } = Layout;

export const AnonymLayout = ({ children }) => {

  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  }, [dispatch]);

  return (
    <Layout className="full-height">
      <Content>
        {children}
      </Content>
    </Layout>
  );
};
