import {
  applyMiddleware, createStore
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import rootReducer from './root-reducer';
import sagas from './root-sagas';

function* rootSaga() {
  yield all(sagas);
}

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleware);
  const composeEnhancers = composeWithDevTools({});

  const store = createStore(rootReducer, composeEnhancers(middlewareEnhancer));
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;