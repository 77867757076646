import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { FOLDER_STATES, ROLES } from '../../../../../utils/constants';
// import './folder-banner.less';

export const CreateFolderBanner = ({
  items = 0,
  folder,
  currentUser,
  onAddFolderClick
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const renderCreateFolderBanner = (folder, meData) => {
    return !!(
      meData
      &&
      meData?.role.slug !== ROLES.SUPER_ADMIN
      &&
      (
        (!folder && meData?.role.title === ROLES.EDITOR)
        ||
        (folder &&
          ((meData?.role.title === ROLES.EDITOR && folder.moderationState === FOLDER_STATES.DRAFT)
            ||
            (meData?.role.title === ROLES.AUDITOR && folder.moderationState === FOLDER_STATES.NEEDS_REVIEW)
            ||
            (meData?.role.title === ROLES.PUBLISHER && folder.moderationState === FOLDER_STATES.PUBLISHER_CHECK))
        )
      )
    )
  };

  if (renderCreateFolderBanner(folder, currentUser))
    return (
      <Card
        className='folder-banner'
        size='small'
      >
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
            <Row justify="space-between" align='middle'>
              <Col flex="auto" xs={24}>
                <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.counter", "alerts")} <span className='nbre-alerts'>+{items}</span></Typography.Title>
              </Col>
              <Col flex="none" xs={24}>
                <span className="btn-wrapper">
                  <Button
                    onClick={onAddFolderClick}
                    key="add-folder"
                    disabled={!items}
                  >
                    {messageTranslator("alerts.results.banner.addToFolder", "alerts")}
                    <ArrowRightOutlined className='transformMiroir' />
                  </Button>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  return null;
};
