import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import './styles.less';

export const UncategorizedNews = (props) => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <Modal
      className="warner"
      footer={null}
      centered
      closeIcon={(
        <Tooltip title={messageTranslator("commons.close", "commons")}>
          <CloseOutlined />
        </Tooltip>
      )}
      {...props}
    >
      <div className="warner-icon-wrapper">
        <CloseCircleFilled />
        <Typography.Title level={2}>
          {messageTranslator("alerts.results.uncategorizedModal.title", "alerts")}
        </Typography.Title>
        <Typography.Text type="secondary">
          {messageTranslator("alerts.results.uncategorizedModal.desc", "alerts")}
        </Typography.Text>
      </div>
    </Modal>
  );
};
