export const ROLES_CREATE              = 'ROLES_CREATE';
export const ROLES_CREATE_SUCCESS      = 'ROLES_CREATE_SUCCESS';
export const ROLES_CREATE_ERROR        = 'ROLES_CREATE_ERROR';
export const ROLES_RESET_CREATED       = 'ROLES_RESET_CREATED';

export const ROLES_GET_BY_ID           = 'ROLES_GET_BY_ID';
export const ROLES_GET_BY_ID_SUCCESS   = 'ROLES_GET_BY_ID_SUCCESS';
export const ROLES_GET_BY_ID_ERROR     = 'ROLES_GET_BY_ID_ERROR';

export const ROLES_LIST                = 'ROLES_LIST';
export const ROLES_LIST_SUCCESS        = 'ROLES_LIST_SUCCESS';
export const ROLES_LIST_ERROR          = 'ROLES_LIST_ERROR';

export const ROLES_DELETE              = 'ROLES_DELETE';
export const ROLES_DELETE_SUCCESS      = 'ROLES_DELETE_SUCCESS';
export const ROLES_DELETE_ERROR        = 'ROLES_DELETE_ERROR';
export const ROLES_RESET_DELETED       = 'ROLES_RESET_DELETED';

export const ROLES_UPDATE              = 'ROLES_UPDATE';
export const ROLES_UPDATE_SUCCESS      = 'ROLES_UPDATE_SUCCESS';
export const ROLES_UPDATE_ERROR        = 'ROLES_UPDATE_ERROR';
export const ROLES_RESET_UPDATED       = 'ROLES_RESET_UPDATED';
