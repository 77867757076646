export const errorsTranslations = {
  ar: {
    "errors.label": "الأخطاء",
    "auth.login.badCredentials": "بيانات تسجيل الدخول غير صالحة",
    "auth.login.too_many_attempts": "عدد كبير جدا من محاولات الدخول الفاشلة، يرجى المحاولة مرة أخرى في وقت لاحق.",
    unauthorizedText: 'محتوى غير مصرح به',
    DUPLICATED_USERNAME: "إسم مستخدم مكرر",
    DUPLICATED_EMAIL: "بريد إلكتروني مكرر",
    DUPLICATED: "معطيات مكررة !",
    UNAUTHORIZED_OPERATION: "الرجاء التأكد من صحة كلمة المرور الحالية !",
    EMAIL_ADDRESS_DOES_NOT_EXIST: "لا وجود للبريد الإلكتروني",
    DUPLICATED_CATEGORY: "تصنيف مكرر",
    DUPLICATED_ROLE: "إسم صلاحيات مكرر",
    FAILED_SEND_EMAIL: 'الموقع غير قادر على إرسال البريد الإلكتروني ، يرجى الاتصال بالمسؤول.',
    FAILED_SEND_TEST_EMAIL: 'الموقع غير قادر على إرسال البريد الإلكتروني.'
  },
  en: {
    "auth.login.too_many_attempts": "Too many failed login attempts, please try again later.",
    "errors.label": "Errors",
    unauthorizedText: 'Unauthorized content',
    "auth.login.badCredentials": "Invalid credentials",
    DUPLICATED_USERNAME: "Duplicated username",
    DUPLICATED_EMAIL: "Duplicated Email",
    DUPLICATED: "Duplicated value !",
    UNAUTHORIZED_OPERATION: "Please make sure the current password is correct!",
    EMAIL_ADDRESS_DOES_NOT_EXIST: "Email address does not exist",
    DUPLICATED_CATEGORY: "Duplicated category",
    DUPLICATED_ROLE: "Duplicated role",
    FAILED_SEND_EMAIL: 'The website unable to send email, please contact the administrator.',
    FAILED_SEND_TEST_EMAIL: 'The website unable to send email.'
  }
};