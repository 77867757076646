import { Button, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { PermissionsHandler } from '../../../components/PermissionHandler';
import { SearchBar } from '../../../components/SearchBar';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { createCategory, deleteCategory, listCategories, resetCreateCategory, resetDeleteCategory, resetUpdateCategory, updateCategory } from '../../../store/categories/actions';
import { CAN_CREATE } from '../../../utils/permissions-utils';
import { getPageSearchQueries, pageChangeHandler, searchHandler } from '../../../utils/utils';
import { CategoriesList } from './components/CategoriesList';
import { CategoryModal } from './components/CategoryModal';
import { checkToken, AuthContext } from '../../../context/Auth';
import { logoutAction } from '../../../store/logout';

export const ListCategories = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const searchParams = useQuery(history);

  const { logout } = useContext(AuthContext);

  const {
    users: { me: { data: meData } },
    categories: {
      create: { isLoading, isCreated, errors: createErrors },
      list,
      update: { isLoading: loadingUpdate, isUpdated },
      delete: { isLoading: loadingDelete, isDeleted }
    }
  } = useSelector(state => state);

  const [isModalVisible, setModalVisible] = useState(() => false);
  const [selectedCategory, setSelectedCategory] = useState(() => null);
  const [page, setPage] = useState(() => 1);
  const [searchTerms, setSearchTerms] = useState({});

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    dispatch(listCategories(terms));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (isCreated) {
      handleCloseModal();
      dispatch(resetCreateCategory());
    }
  }, [dispatch, isCreated]);

  useEffect(() => {
    if (isUpdated) {
      handleCloseModal();
      dispatch(resetUpdateCategory());
    }
  }, [dispatch, isUpdated]);

  useEffect(() => {
    if (isDeleted) {
      handleCloseModal();
      dispatch(resetDeleteCategory());
    }
  }, [dispatch, isDeleted]);

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handleSearch = (terms) => searchHandler(history, terms);

  const handleSelectCategory = (category) => {
    handleOpenModal();
    setSelectedCategory(category);
  };

  const handleOpenModal = () => setModalVisible(true);

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedCategory(null);
  };

  const handleCreateCategory = (category) => selectedCategory ? dispatch(updateCategory({ ...category, id: selectedCategory.id })) : dispatch(createCategory(category));

  const handleDeleteCategory = (id) => dispatch(deleteCategory(id));

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={isLoading || loadingUpdate || loadingDelete || list.isLoading}>
        <PermissionsHandler
          resourceName="Alert"
          blocPermission={CAN_CREATE}
          userRole={meData?.role}
        >
          <div className='btn-wrapper'>
            <Button type="primary" onClick={handleOpenModal}>
              {messageTranslator("categories.list.new.text", "categories")}
            </Button>
          </div>
        </PermissionsHandler>

        <SearchBar
          label={route.title}
          history={history}
          searchTerms={searchTerms}
          page={page}
          onSearchTrigger={handleSearch}
        />

        <CategoriesList
          listData={list}
          role={meData?.role}
          currentPage={page}
          loadingDelete={loadingDelete}
          onPageChange={handlePageChange}
          onSelectCategory={handleSelectCategory}
          onDeleteCategory={handleDeleteCategory}
        />

        <CategoryModal
          visible={isModalVisible}
          isLoading={isLoading || loadingUpdate}
          selectedCategory={selectedCategory}
          createErrors={createErrors}
          onCancel={handleCloseModal}
          onOk={handleCreateCategory}
        />
      </Spin>
    </PageSkeleton>
  );
};
