export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const DATES = 'specific';

export const renderTimeList = (morningSuffix, eveningSufix, translator) => {
  morningSuffix = translator(morningSuffix, "commons");
  eveningSufix  = translator(eveningSufix, "commons");
  return [
    { label: `00 ${morningSuffix}`, value: "00" },
    { label: `01 ${morningSuffix}`, value: "01" },
    { label: `02 ${morningSuffix}`, value: "02" },
    { label: `03 ${morningSuffix}`, value: "03" },
    { label: `04 ${morningSuffix}`, value: "04" },
    { label: `05 ${morningSuffix}`, value: "05" },
    { label: `06 ${morningSuffix}`, value: "06" },
    { label: `07 ${morningSuffix}`, value: "07" },
    { label: `08 ${morningSuffix}`, value: "08" },
    { label: `09 ${morningSuffix}`, value: "09" },
    { label: `10 ${morningSuffix}`, value: "10" },
    { label: `11 ${morningSuffix}`, value: "11" },
    { label: `12 ${eveningSufix}`, value: "12" },
    { label: `13 ${eveningSufix}`, value: "13" },
    { label: `14 ${eveningSufix}`, value: "14" },
    { label: `15 ${eveningSufix}`, value: "15" },
    { label: `16 ${eveningSufix}`, value: "16" },
    { label: `17 ${eveningSufix}`, value: "17" },
    { label: `18 ${eveningSufix}`, value: "18" },
    { label: `19 ${eveningSufix}`, value: "19" },
    { label: `20 ${eveningSufix}`, value: "20" },
    { label: `21 ${eveningSufix}`, value: "21" },
    { label: `22 ${eveningSufix}`, value: "22" },
    { label: `23 ${eveningSufix}`, value: "23" },
  ]
};

export const showEveryInSelect = (repeatValue) => ["weekly", "monthly"].includes(repeatValue);

export const scheduleRepeat = [
  {
    key: DAILY,
    label: "reports.view.modal.schedule.repeat.daily",
    value: DAILY
  },
  {
    key: WEEKLY,
    label: "reports.view.modal.schedule.repeat.weekly",
    value: WEEKLY
  },
  {
    key: MONTHLY,
    label: "reports.view.modal.schedule.repeat.monthly",
    value: MONTHLY
  },
  {
    key: DATES,
    label: "reports.view.modal.schedule.repeat.dates",
    value: DATES
  }
];

export const scheduleEvery = {
  daily: "reports.view.modal.schedule.every.day",
  weekly: "reports.view.modal.schedule.every.week",
  monthly: "reports.view.modal.schedule.every.month"
};

export const scheduleDays = {
  weekly: [
    {
      value: "sunday",
      label: "reports.view.modal.schedule.days.sunday"
    },
    {
      value: "monday",
      label: "reports.view.modal.schedule.days.Monday"
    },
    {
      value: "tuesday",
      label: "reports.view.modal.schedule.days.Tuesday"
    },
    {
      value: "wednesday",
      label: "reports.view.modal.schedule.days.Wednesday"
    },
    {
      value: "thursday",
      label: "reports.view.modal.schedule.days.Thursday"
    },
    {
      value: "friday",
      label: "reports.view.modal.schedule.days.Friday"
    },
    {
      value: "saturday",
      label: "reports.view.modal.schedule.days.Saturday"
    }
  ]
};

export const weekDays = [
  {
    grouLabel: "reports.view.modal.schedule.weekDays.firstWeek",
    order: "1",
    values: [
      {
        label: "reports.view.modal.schedule.weekDays.firstSunday",
        value: "1,sunday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.firstMonday",
        value: "1,monday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.firstTuesday",
        value: "1,tuesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.firstWednesday",
        value: "1,wednesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.firstThursday",
        value: "1,thursday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.firstFriday",
        value: "1,friday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.firstSaturday",
        value: "1,saturday"
      }
    ]
  },
  {
    grouLabel: "reports.view.modal.schedule.weekDays.secondWeek",
    order: "2",
    values: [
      {
        label: "reports.view.modal.schedule.weekDays.secondSunday",
        value: "2,sunday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.secondMonday",
        value: "2,monday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.secondTuesday",
        value: "2,tuesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.secondWednesday",
        value: "2,wednesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.secondThursday",
        value: "2,thursday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.secondFriday",
        value: "2,friday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.secondSaturday",
        value: "2,saturday"
      }
    ]
  },
  {
    grouLabel: "reports.view.modal.schedule.weekDays.thurdWeek",
    order: "3",
    values: [
      {
        label: "reports.view.modal.schedule.weekDays.thurdSunday",
        value: "3,sunday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.thurdMonday",
        value: "3,monday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.thurdTuesday",
        value: "3,tuesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.thurdWednesday",
        value: "3,wednesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.thurdThursday",
        value: "3,thursday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.thurdFriday",
        value: "3,friday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.thurdSaturday",
        value: "3,saturday"
      }
    ]
  },
  {
    grouLabel: "reports.view.modal.schedule.weekDays.fourthWeek",
    order: "4",
    values: [
      {
        label: "reports.view.modal.schedule.weekDays.fourthSunday",
        value: "4,sunday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fourthMonday",
        value: "4,monday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fourthTuesday",
        value: "4,tuesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fourthWednesday",
        value: "4,wednesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fourthThursday",
        value: "4,thursday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fourthFriday",
        value: "4,friday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fourthSaturday",
        value: "4,saturday"
      }
    ]
  },
  {
    grouLabel: "reports.view.modal.schedule.weekDays.fifthWeek",
    order: "5",
    values: [
      {
        label: "reports.view.modal.schedule.weekDays.fifthSunday",
        value: "5,sunday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fifthMonday",
        value: "5,monday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fifthTuesday",
        value: "5,tuesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fifthWednesday",
        value: "5,wednesday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fifthThursday",
        value: "5,thursday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fifthFriday",
        value: "5,friday"
      },
      {
        label: "reports.view.modal.schedule.weekDays.fifthSaturday",
        value: "5,saturday"
      }
    ],
  }
];

export const reportFormats = [
  {
    value: "pdf",
    label: "PDF"
  },
  {
    value: "html",
    label: "HTML"
  }
];

const findInDate = (rep, value = "") => {
  if (rep === WEEKLY) return scheduleDays.weekly.find(el => el.value === value) || "";
  else {
    const order = value.split(',')[0];
    let element = weekDays.find(el => el.order === order);
    let values = [];
    if (element) values = element.values;
    return values.find(el => el.value === value) || "";
  }
};

/**
 * 
 * @param {string} lang 
 * @param {string} unit 
 * @param {number} separator 
 * @param {string} time 
 * @param {string} inValue 
 * @returns string
 */
export const renderSentence = (translator, lang, unit, separator, time, inValue, multiDates) => {
  if (!unit || !time) return null;

  const hours = time.split(' ')[0];

  let _time = "";

  if (lang === "ar") {

    _time = `${hours} ${time.includes("AM") ? "صباحاً" : "مساءً"}`;

    if (unit === DAILY) {
      if (!separator) return null;
      let every = '';
      if (separator === 1 || separator > 10) every = `${separator > 10 ? separator : ''} يوم`;
      else if (separator === 2) every = `يومين`;
      else every = `${separator} أيام`;
      return `سيتم ارسال التقرير كل ${every} في تمام الساعة ${_time}`;
    }

    else if (unit === WEEKLY) {
      if (!separator || !inValue) return null;
      let every = '';
      const inDate = translator(findInDate(unit, inValue).label, "reports");
      if (separator === 1 || separator > 10) every = `${separator > 10 ? separator : ''} أسبوع`;
      else if (separator === 2) every = `أسبوعين`;
      else every = `${separator} أسابيع`;
      return `سيتم ارسال التقرير كل ${every} في يوم ${inDate || ""} في تمام الساعة ${_time}`;
    }

    else if (unit === MONTHLY) {
      if (!inValue) return null;
      let every = '';
      let inDate = translator(findInDate(unit, inValue).label, "reports");
      if (separator === 1 || separator > 10) every = `${separator > 10 ? separator : ''} شهر`;
      else if (separator === 2) every = `شهرين`;
      else every = `${separator} أشهر`;
      return `سيتم ارسال التقرير في ${inDate || ""} من كل ${every} في تمام الساعة ${_time}`;
    }

    else if (unit === DATES) {
      if (!multiDates) return null;
      let datesNumber = '';
      if (multiDates === 1) datesNumber = 'تاريخ واحد';
      else if (multiDates === 2) datesNumber = 'تاريخين إثنبين';
      else if (multiDates > 2) datesNumber = `${multiDates} تواريخ`;
      else if (multiDates > 10) datesNumber = `${multiDates} تاريخ`;
      return `تم جدولة التقرير في ${datesNumber} في تمام الساعة ${_time}`;
    }
    else return null;
  }
  else {

    if (unit === DAILY) {
      if (!separator) return null;
      return `The report will be sent every ${unitSinglePlural(separator, 'day')} at ${time}`;
    }

    else if (unit === WEEKLY) {
      if (!separator || !inValue) return null;
      const inDate = translator(findInDate(unit, inValue).label, "reports");
      return `The report will be sent every ${inDate.toLowerCase()} of every ${unitSinglePlural(separator, 'week')} at ${time}`;
    }

    else if (unit === MONTHLY) {
      if (!inValue) return null;
      let inDate = translator(findInDate(unit, inValue).label, "reports");
      return `The report will be sent every ${inDate.toLowerCase()} of every ${unitSinglePlural(separator, 'month')} at ${time}`;
    }

    else if (unit === DATES) {
      if (!multiDates) return null;
      return `The report is scheduled for ${unitSinglePlural(multiDates, "date", true)}`;
    }

    else return null;
  }
};

/**
 * 
 * @param {number} separator Nth times the.
 * @param {string} unitName The word in which will be trasformed to plural.
 * @param {boolean} showSingleUnit Show the required number when it's equals to 1.
 * @returns string
 */
const unitSinglePlural = (separator, unitName, showSingleUnit = false) => `${showSingleUnit ? separator : separator < 2 ? '' : separator} ${(separator < 2) ? unitName : `${unitName}s`}`;
