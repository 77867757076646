import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmailGroupForm } from '../../../components/EmailGroupForm';
import { ImportEmailsModal } from '../../../components/ImportEmailsModal';
import { InboxMenu } from '../../../components/InboxMenu';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { MAIL_GROUP } from '../../../routes/paths';
import { deleteEmailGroup, deleteEmailGroupReset, getEmailGroupById, parseContactsFile, parseContactsFileReset, updateEmailGroup, updateEmailGroupReset } from '../../../store/emailGroups/actions';

export const UpdateEmailGroup = ({ route, history, match: { params: { id } } }) => {

  const importModalRef = useRef();

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const { emailGroups: {
    update,
    group,
    delete: { isLoading, isDeleted },
    parsedData
  } } = useSelector(state => state);

  const [isImportModalOpen, setImportModalOpen] = useState(false);

  useEffect(() => {
    if (id) dispatch(getEmailGroupById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (update.isUpdated) {
      dispatch(updateEmailGroupReset());
      history.push(MAIL_GROUP);
    }
  }, [update, history, dispatch]);

  useEffect(() => {
    if (parsedData.isParsed) {
      dispatch(parseContactsFileReset());
      handleCloseModal();
    }
  }, [parsedData, dispatch]);

  useEffect(() => {
    if (isDeleted) {
      dispatch(deleteEmailGroupReset());
      history.push(MAIL_GROUP);
    }
  }, [dispatch, history, isDeleted]);

  const handleOpenImportModal = () => setImportModalOpen(true);

  const handleCloseModal = () => {
    setImportModalOpen(false);
    importModalRef?.current?.resetModal();
  }

  const handleSubmitEmailGroup = (group) => dispatch(updateEmailGroup({ id, ...group }));

  const handleParseFile = (excelFile) => dispatch(parseContactsFile({ excelFile }));

  const handleDeleteGroup = () => dispatch(deleteEmailGroup(id));

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={update.isLoading || group.isLoading || isLoading || parsedData.isLoading}>
      <Row gutter={[30, 20]} justify="center" wrap="false" className='tabs-wrapper'>
        <Col className='inbox-menu-wrapper'>
          <InboxMenu history={history} />
        </Col>
        <Col className='inbox-settings-wrapper'>
          <EmailGroupForm
            title={messageTranslator("config.email.group.update.title", "config")}
            group={group}
            update={update}
            parsedData={parsedData}
            loadingDelete={isLoading}
            cancelButtonText={messageTranslator("commons.delete", "commons")}
            onDeleteGroup={handleDeleteGroup}
            onImportFile={handleOpenImportModal}
            onSubmitGroup={handleSubmitEmailGroup}
          />
        </Col>
      </Row>

      <ImportEmailsModal
        ref={importModalRef}
        modalProps={{
          title: messageTranslator("config.emailGroup.modal.emails.import.button", "config"),
          visible: isImportModalOpen
        }}
        isLoading={!!parsedData?.isLoading}
        onOk={handleParseFile}
        onCancel={handleCloseModal}
      />
      </Spin>
    </PageSkeleton>
  );
};
