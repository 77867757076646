import { replaceItemInArray } from "../../utils/utils";
import { RESET_IS_CREATED, USERS_LIST, USERS_LIST_ERROR, USERS_LIST_SUCCESS, USER_CHANGE_PASSWORD, USER_CHANGE_PASSWORD_ERROR, USER_CHANGE_PASSWORD_RESET, USER_CHANGE_PASSWORD_SUCCESS, USER_CREATE, USER_CREATE_ERROR, USER_CREATE_SUCCESS, USER_DELETE, USER_DELETE_ERROR, USER_DELETE_SUCCESS, USER_GET_BY_ID, USER_GET_BY_ID_ERROR, USER_GET_BY_ID_SUCCESS, USER_GET_ME, USER_GET_ME_ERROR, USER_GET_ME_SUCCESS, USER_RESET_IS_DELETED, USER_RESET_UPDATED, USER_UPDATE, USER_UPDATE_ERROR, USER_UPDATE_SUCCESS } from "./types";

export const userInitialState = {
  me: {
    data: null,
    isLoading: false,
    errors: null
  },
  create: {
    isLoading: false,
    isCreated: null,
    errors: null
  },
  listUsers: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null,
  },
  user: {
    data: null,
    isLoading: false,
    errors: null
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null
  },
  delete: {
    isLoading: false,
    isDeleted: false,
    errors: null
  },
  changePassword: {
    isLoading: false,
    isChanged: false,
    errors: null
  }
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case USER_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null
        }
      };

    case USER_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: action.payload
        },
        listUsers: {
          ...state.listUsers,
          data: [action.payload, ...state.listUsers.data],
          totalItems: state.listUsers.totalItems + 1
        }
      };

    case USER_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload
        }
      };

    case RESET_IS_CREATED:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: null
        }
      };
    
    // Get user
    case USER_GET_BY_ID:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
          errors: null
        }
      };

    case USER_GET_BY_ID_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          data: action.payload
        }
      };

    case USER_GET_BY_ID_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: action.payload
        }
      };

    // List users
    case USERS_LIST:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isLoading: true,
          errors: null
        }
      };

    case USERS_LIST_SUCCESS:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isLoading: false,
          data: action.payload.users,
          totalItems: action.payload.totalItems
        }
      };

    case USERS_LIST_ERROR:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isLoading: false,
          errors: action.payload
        }
      };

    // Update user
    case USER_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
          errors: null
        }
      };

    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true
        },
        listUsers: {
          ...state.listUsers,
          data: replaceItemInArray(action.payload, state.listUsers.data, 'id')
        },
        me: {
          ...state.me,
          data: state.me.data
          ?
          state.me.data.id === action.payload.id
          ?
          {
            ...action.payload,
            role: state.me.data.role
          }
          :
          state.me.data
          :
          null
        },
        user: {
          ...state.user,
          data: action.payload
        }
      };

    case USER_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload
        }
      };

    case USER_RESET_UPDATED:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null
        }
      };

    // Delete user
    case USER_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: true,
          errors: null
        }
      };

    case USER_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          isDeleted: true
        }
      };

    case USER_DELETE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          errors: action.payload
        }
      };

    case USER_RESET_IS_DELETED:
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleted: false,
          errors: null
        }
      };

      // Get me
    case USER_GET_ME:
      return {
        ...state,
        me: {
          ...state.me,
          isLoading: true,
          errors: null
        }
      };

    case USER_GET_ME_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          isLoading: false,
          data: action.payload
        }
      };

    case USER_GET_ME_ERROR:
      return {
        ...state,
        me: {
          ...state.me,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Change password
    case USER_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isLoading: true,
          errors: null
        }
      };

    case USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isLoading: false,
          isChanged: true
        }
      };

    case USER_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isLoading: false,
          errors: action.payload
        }
      };

    case USER_CHANGE_PASSWORD_RESET:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isChanged: false,
          errors: null
        }
      };

    default:
      return state;
  }
};