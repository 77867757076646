import { EditFilled, KeyOutlined } from '@ant-design/icons';
import { Col, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../context/config';
import { CHANGE_PASSWORD, UPDATE_USER } from '../../routes/paths';
import { extractProfileMenuKey } from '../../utils/utils';

export const ProfileMenu = ({ history, id }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const profileMenuItems = [
    {
      label: "users.profile.update.coords",
      link: UPDATE_USER,
      icon: <EditFilled />
    },
    {
      label: "users.profile.update.password",
      link: CHANGE_PASSWORD,
      icon: <KeyOutlined />
    }
  ];

  const [selectedKey, setSelectedKey] = useState([]);

  useEffect(() => {
    setSelectedKey([extractProfileMenuKey(history.location.pathname)]);
  }, [history]);

  const handleMenuClick = ({ key }) => setSelectedKey([key]);

  return (
    <Col className='inbox-menu-wrapper'>
      <Menu
          onClick={handleMenuClick}
          selectedKeys={selectedKey}
          mode="vertical"
          className="reports-menu"
        >
          {
            profileMenuItems.map(item => (
              <Menu.Item key={item.link} icon={item.icon}>
                <Link to={`${item.link}/${id}`}>
                  {messageTranslator(item.label, "users")}
                </Link>
              </Menu.Item>
            ))
          }
        </Menu>
    </Col>
  );
};
