import { RESET_IS_CREATED, USERS_LIST, USERS_LIST_ERROR, USERS_LIST_SUCCESS, USER_CHANGE_PASSWORD, USER_CHANGE_PASSWORD_ERROR, USER_CHANGE_PASSWORD_RESET, USER_CHANGE_PASSWORD_SUCCESS, USER_CREATE, USER_CREATE_ERROR, USER_CREATE_SUCCESS, USER_DELETE, USER_DELETE_ERROR, USER_DELETE_SUCCESS, USER_GET_BY_ID, USER_GET_BY_ID_ERROR, USER_GET_BY_ID_SUCCESS, USER_GET_ME, USER_GET_ME_ERROR, USER_GET_ME_SUCCESS, USER_RESET_IS_DELETED, USER_RESET_UPDATED, USER_UPDATE, USER_UPDATE_ERROR, USER_UPDATE_SUCCESS } from "./types";

/**
 * * CREATE USER
 */
export const createUser = (user) => ({
  type: USER_CREATE,
  payload: user
});

export const createUserSuccess = (user) => ({
  type: USER_CREATE_SUCCESS,
  payload: user
});

export const createUserError = (error) => ({
  type: USER_CREATE_ERROR,
  payload: error
});

export const resetIsCreated = () => ({
  type: RESET_IS_CREATED
});

// * Get user
export const getUserById = (id) => ({
  type: USER_GET_BY_ID,
  payload: id
});

export const getUserByIdSuccess = (user) => ({
  type: USER_GET_BY_ID_SUCCESS,
  payload: user
});

export const getUserByIdError = (error) => ({
  type: USER_GET_BY_ID_ERROR,
  payload: error
});

// * Get Me
export const getMe = () => ({
  type: USER_GET_ME
});

export const getMeSuccess = (me) => ({
  type: USER_GET_ME_SUCCESS,
  payload: me
});

export const getMeError = (error) => ({
  type: USER_GET_ME_ERROR,
  payload: error
});

// * Get users
export const getUsers = (params) => ({
  type: USERS_LIST,
  payload: params
});

export const getUsersSuccess = (users) => ({
  type: USERS_LIST_SUCCESS,
  payload: users
});

export const getUsersError = (error) => ({
  type: USERS_LIST_ERROR,
  payload: error
});

// * Update user
export const updateUser = (user) => ({
  type: USER_UPDATE,
  payload: user
});

export const updateUserSuccess = (user) => ({
  type: USER_UPDATE_SUCCESS,
  payload: user
});

export const updateUserError = (error) => ({
  type: USER_UPDATE_ERROR,
  payload: error
});

export const userResetUpdated = () => ({
  type: USER_RESET_UPDATED
});

// * Delete user
export const deleteUser = (id) => ({
  type: USER_DELETE,
  payload: id
});

export const deleteUserSuccess = () => ({
  type: USER_DELETE_SUCCESS
});

export const deleteUserError = (error) => ({
  type: USER_DELETE_ERROR,
  payload: error
});

export const resetUserDeleted = () => ({
  type: USER_RESET_IS_DELETED
});

// * Change password
export const changePassword = (passwords) => ({
  type: USER_CHANGE_PASSWORD,
  payload: passwords
});

export const changePasswordSuccess = () => ({
  type: USER_CHANGE_PASSWORD_SUCCESS
});

export const changePasswordError = (error) => ({
  type: USER_CHANGE_PASSWORD_ERROR,
  payload: error
});

export const changePasswordReset = () => ({
  type: USER_CHANGE_PASSWORD_RESET
});
