export const reportsTranslations = {
  ar: {
    "reports.menu.all.label": "جميع التقارير",
    "reports.menu.scheduled.label": "التقارير المجـــدولة",
    "reports.menu.unscheduled.label": "التقارير الغير مجـــدولة",
    "reports.menu.search.placeholder": "البحث بالتقارير",
    "reports.menu.button.addNewReport": "إضــافة تقرير جديد",
    "reports.modal.title": "إنشاء تقرير جديد",
    "reports.modal.subTitle": "الرجــاء اختيار التقرير المراد تجهيزه",
    "reports.modal.stepOne.button": "تقرير الملف الصحفي",
    "reports.modal.stepTwo.title": "إعدادات تقرير الملف الصحفي",
    "reports.modal.stepTwo.subTitle":
      "حدد المعلومات الذي تريد استخدامها في تقريرك والفترة الزمنية المطلوبة",
    "reports.modal.stepTwo.form.name.label": "اسم التقرير",
    "reports.modal.stepTwo.form.date.label": "حدد تاريخ الملف الصحفي",
    "reports.modal.stepTwo.form.alert.label": "اختار التنبيه",
    "reports.modal.stepTwo.form.about.label": "نبذة عن التقرير",
    "reports.modal.stepTwo.form.about.placeholder": "اكتب وصف عن التقرير",
    "reports.modal.stepTwo.form.create": "انشاء التقرير",

    "reports.view.header.menu.downloadPDF": "تحميل (PDF)",
    "reports.view.header.menu.downloadPPT": "تحميل (PPT)",
    "reports.view.header.menu.sendMailHTML": "إرسال في البريد (HTML)",
    "reports.view.header.menu.sendMail": "إرسال في البريد (PDF)",
    "reports.view.header.menu.delete": "حذف التقرير",
    "reports.view.header.menu.chedule.delete": "إلغاء الجدولة",

    "reports.view.header.menu.delete.modal.title": "تأكيد الحذف",
    "reports.view.header.menu.delete.modal.text":
      "هل أنت متأكد أنك تريد حذف التقرير",
    "reports.view.header.menu.schedule.delete.modal.text":
      "هل أنت متأكد أنك تريد حذف جدولة التقرير",
    "reports.view.header.menu.schedule.delete.success.text":
      "تم إلغاء جدولة التقرير",

    "reports.view.header.scheduling": "جدولة التقرير",
    "reports.edit.header.scheduling": "تعديل الجدولة",

    "reports.view.header.counter.all.label": "جميع الأخبار",
    "reports.view.header.counter.label": "عدد الأخبار المنشورة",

    "reports.list.card.label": "تقرير الملف الصحفي",
    "reports.list.card.scheduled": "مجدولة",
    "reports.list.card.createdAt": "حرر بتاريخ",
    "reports.list.card.fromDate": "من",
    "reports.list.card.toDate": "إلى",

    "reports.view.modal.schedule.repeat.label": "التكرار",
    "reports.view.modal.schedule.every.label": "كل",
    "reports.view.modal.schedule.on.label": "على",
    "reports.view.modal.schedule.in.label": "في",
    "reports.view.modal.schedule.recipients.label": "المستقبلين",
    "reports.view.modal.schedule.format.label": "صيغة التقرير",
    "reports.view.modal.schedule.btn.schedule": "جدولة",

    "reports.view.modal.schedule.repeat.daily": "يومياً",
    "reports.view.modal.schedule.repeat.weekly": "أسبوعياً",
    "reports.view.modal.schedule.repeat.monthly": "شهرياً",
    "reports.view.modal.schedule.repeat.dates": "تواريخ محددة",

    "reports.view.modal.schedule.every.day": "يوم",
    "reports.view.modal.schedule.every.week": "أسبوع",
    "reports.view.modal.schedule.every.month": "أشهر",

    "reports.view.modal.schedule.days.sunday": "الأحد",
    "reports.view.modal.schedule.days.Monday": "الإثنين",
    "reports.view.modal.schedule.days.Tuesday": "الثلثاء",
    "reports.view.modal.schedule.days.Wednesday": "الأربعاء",
    "reports.view.modal.schedule.days.Thursday": "الخميس",
    "reports.view.modal.schedule.days.Friday": "الجمعة",
    "reports.view.modal.schedule.days.Saturday": "السبت",

    "reports.view.modal.schedule.days.firstSunday": "أول يوم أحد",
    "reports.view.modal.schedule.days.firstMonday": "أول يوم إثنين",
    "reports.view.modal.schedule.days.firstTuesday": "أول يوم ثلاثاء",
    "reports.view.modal.schedule.days.firstWednesday": "أول يوم أربعاء",
    "reports.view.modal.schedule.days.firstThursday": "أول يوم خميس",
    "reports.view.modal.schedule.days.firstFriday": "أول يوم جمعة",
    "reports.view.modal.schedule.days.firstSaturday": "أول يوم سبت",

    "reports.view.modal.schedule.multiDates.placeholder": "إضافة تاريخ",

    "reports.view.modal.schedule.recipients.placeholder": "إضافة المزيد",
    "reports.view.modal.schedule.recipients.empty":
      "أدخل إسم المجموعة البريدية للبحث",

    "reports.list.delete.confirm": "هل أنت متأكد أنك تريد حذف التقرير",
    "reports.list.delete.message": "تم حذف التقرير بنجاح",

    // ? week days
    "reports.view.modal.schedule.weekDays.firstWeek": "أول أسبوع",
    "reports.view.modal.schedule.weekDays.secondWeek": "ثاني أسبوع",
    "reports.view.modal.schedule.weekDays.thurdWeek": "ثالث أسبوع",
    "reports.view.modal.schedule.weekDays.fourthWeek": "رابع أسبوع",
    "reports.view.modal.schedule.weekDays.fifthWeek": "خامس أسيوع",

    "reports.view.modal.schedule.weekDays.firstSunday": "أول يوم أحد",
    "reports.view.modal.schedule.weekDays.firstMonday": "أول يوم إثنين",
    "reports.view.modal.schedule.weekDays.firstTuesday": "أول يوم ثلاثاء",
    "reports.view.modal.schedule.weekDays.firstWednesday": "أول يوم أربعاء",
    "reports.view.modal.schedule.weekDays.firstThursday": "أول يوم خميس",
    "reports.view.modal.schedule.weekDays.firstFriday": "أول يوم جمعة",
    "reports.view.modal.schedule.weekDays.firstSaturday": "أول يوم سبت",

    "reports.view.modal.schedule.weekDays.secondSunday": "ثاني يوم أحد",
    "reports.view.modal.schedule.weekDays.secondMonday": "ثاني يوم إثنين",
    "reports.view.modal.schedule.weekDays.secondTuesday": "ثاني يوم ثلاثاء",
    "reports.view.modal.schedule.weekDays.secondWednesday": "ثاني يوم أربعاء",
    "reports.view.modal.schedule.weekDays.secondThursday": "ثاني يوم خميس",
    "reports.view.modal.schedule.weekDays.secondFriday": "ثاني يوم جمعة",
    "reports.view.modal.schedule.weekDays.secondSaturday": "ثاني يوم سبت",

    "reports.view.modal.schedule.weekDays.thurdSunday": "ثالث يوم أحد",
    "reports.view.modal.schedule.weekDays.thurdMonday": "ثالث يوم إثنين",
    "reports.view.modal.schedule.weekDays.thurdTuesday": "ثالث يوم ثلاثاء",
    "reports.view.modal.schedule.weekDays.thurdWednesday": "ثالث يوم أربعاء",
    "reports.view.modal.schedule.weekDays.thurdThursday": "ثالث يوم خميس",
    "reports.view.modal.schedule.weekDays.thurdFriday": "ثالث يوم جمعة",
    "reports.view.modal.schedule.weekDays.thurdSaturday": "ثالث يوم سبت",

    "reports.view.modal.schedule.weekDays.fourthSunday": "رابع يوم أحد",
    "reports.view.modal.schedule.weekDays.fourthMonday": "رابع يوم إثنين",
    "reports.view.modal.schedule.weekDays.fourthTuesday": "رابع يوم ثلاثاء",
    "reports.view.modal.schedule.weekDays.fourthWednesday": "رابع يوم أربعاء",
    "reports.view.modal.schedule.weekDays.fourthThursday": "رابع يوم خميس",
    "reports.view.modal.schedule.weekDays.fourthFriday": "رابع يوم جمعة",
    "reports.view.modal.schedule.weekDays.fourthSaturday": "رابع يوم سبت",

    "reports.view.modal.schedule.weekDays.fifthSunday": "خامس يوم أحد",
    "reports.view.modal.schedule.weekDays.fifthMonday": "خامس يوم إثنين",
    "reports.view.modal.schedule.weekDays.fifthTuesday": "خامس يوم ثلاثاء",
    "reports.view.modal.schedule.weekDays.fifthWednesday": "خامس يوم أربعاء",
    "reports.view.modal.schedule.weekDays.fifthThursday": "خامس يوم خميس",
    "reports.view.modal.schedule.weekDays.fifthFriday": "خامس يوم جمعة",
    "reports.view.modal.schedule.weekDays.fifthSaturday": "خامس يوم سبت",

    "reports.view.send.modal.title": "قائمة المستقبلين",
    "reports.view.send.modal.add.email.label": "إضافة بريد إلكتروني",
    "reports.view.send.modal.add.email.placeholder": "إضغط <<Enter>> للإضافة",
    "reports.view.send.modal.email.duplicated": "البريد الإلكتروني مكرر",
    "reports.view.send.modal.email.success.message": "تمت عملية الإرسال بنجاح",
    "report.schedule.form.update.success.message":
      "تم تعديل جدولة التقرير بنجاح",

    "reports.view.send.modal.email.error.message":
      "تحتاج إلى إضافة بريد إلكتروني واحد على الأقل",

    "reports.view.header.all.reports": "جميع التقارير",
  },
  en: {
    "reports.menu.all.label": "All reports",
    "reports.menu.scheduled.label": "Scheduled Reports",
    "reports.menu.unscheduled.label": "Unscheduled reports",
    "reports.menu.search.placeholder": "Search by reports",
    "reports.menu.button.addNewReport": "Add a new report",
    "reports.modal.title": "Create new report",
    "reports.modal.subTitle": "Please select the report to be processed",
    "reports.modal.stepOne.button": "Press folder report",
    "reports.modal.stepTwo.title": "Press folder Report Settings",
    "reports.modal.stepTwo.subTitle":
      "Select the information you want to use in your report and the required time period",
    "reports.modal.stepTwo.form.name.label": "Report name",
    "reports.modal.stepTwo.form.date.label":
      "Select the date of the press folder",
    "reports.modal.stepTwo.form.alert.label": "Choose Alert",
    "reports.modal.stepTwo.form.about.label": "About the report",
    "reports.modal.stepTwo.form.about.placeholder":
      "Write a description of the report",
    "reports.modal.stepTwo.form.create": "Create report",

    "reports.view.header.menu.downloadPDF": "Download (PDF)",
    "reports.view.header.menu.downloadPPT": "Download (PPT)",
    "reports.view.header.menu.sendMailHTML": "Send in the mail (HTML)",
    "reports.view.header.menu.sendMail": "Send in the mail (PDF)",
    "reports.view.header.menu.delete": "Delete report",
    "reports.view.header.menu.chedule.delete": "Delete schedule",

    "reports.view.header.menu.delete.modal.title": "Delete confirmation",
    "reports.view.header.menu.delete.modal.text":
      "Are you sure you want to delete the report",
    "reports.view.header.menu.schedule.delete.modal.text":
      "Are you sure you want to delete the schedule",
    "reports.view.header.menu.schedule.delete.success.text":
      "Report schedule has been cancelled",

    "reports.view.header.scheduling": "Report scheduling",
    "reports.edit.header.scheduling": "Report updating",

    "reports.view.header.counter.all.label": "All news",
    "reports.view.header.counter.label": "Number of published news",

    "reports.list.card.label": "Press folder report",
    "reports.list.card.scheduled": "Scheduled",
    "reports.list.card.createdAt": "Created At",
    "reports.list.card.fromDate": "From",
    "reports.list.card.toDate": "To",

    "reports.view.modal.schedule.repeat.label": "Repeat",
    "reports.view.modal.schedule.every.label": "Every",
    "reports.view.modal.schedule.on.label": "On",
    "reports.view.modal.schedule.in.label": "On",
    "reports.view.modal.schedule.recipients.label": "Recipients",
    "reports.view.modal.schedule.format.label": "Report format",
    "reports.view.modal.schedule.btn.schedule": "Schedule",

    "reports.view.modal.schedule.repeat.daily": "Daily",
    "reports.view.modal.schedule.repeat.weekly": "Weekly",
    "reports.view.modal.schedule.repeat.monthly": "Monthly",
    "reports.view.modal.schedule.repeat.dates": "Specific dates",

    "reports.view.modal.schedule.every.day": "Day",
    "reports.view.modal.schedule.every.week": "Week",
    "reports.view.modal.schedule.every.month": "Month",

    "reports.view.modal.schedule.days.sunday": "Sunday",
    "reports.view.modal.schedule.days.Monday": "Monday",
    "reports.view.modal.schedule.days.Tuesday": "Tuesday",
    "reports.view.modal.schedule.days.Wednesday": "Wednesday",
    "reports.view.modal.schedule.days.Thursday": "Thursday",
    "reports.view.modal.schedule.days.Friday": "Friday",
    "reports.view.modal.schedule.days.Saturday": "Saturday",

    "reports.view.modal.schedule.days.firstSunday": "First",
    "reports.view.modal.schedule.days.firstMonday": "First Monday",
    "reports.view.modal.schedule.days.firstTuesday": "First Tuesday",
    "reports.view.modal.schedule.days.firstWednesday": "First Wednesday",
    "reports.view.modal.schedule.days.firstThursday": "First Thursday",
    "reports.view.modal.schedule.days.firstFriday": "First Friday",
    "reports.view.modal.schedule.days.firstSaturday": "First Saturday",

    "reports.view.modal.schedule.multiDates.placeholder": "Add date",

    "reports.view.modal.schedule.recipients.placeholder": "Add more",
    "reports.view.modal.schedule.recipients.empty":
      "Enter email group name to search",

    "reports.list.delete.confirm": "Are you sure you want to delete the report",
    "reports.list.delete.message": "The report has been successfully deleted",

    // ? week days
    "reports.view.modal.schedule.weekDays.firstWeek": "First week",
    "reports.view.modal.schedule.weekDays.secondWeek": "Second week",
    "reports.view.modal.schedule.weekDays.thurdWeek": "Thurd week",
    "reports.view.modal.schedule.weekDays.fourthWeek": "Fourth week",
    "reports.view.modal.schedule.weekDays.fifthWeek": "Fifth week",

    "reports.view.modal.schedule.weekDays.firstSunday": "First sunday",
    "reports.view.modal.schedule.weekDays.firstMonday": "First monday",
    "reports.view.modal.schedule.weekDays.firstTuesday": "First tuesday",
    "reports.view.modal.schedule.weekDays.firstWednesday": "First wednesday",
    "reports.view.modal.schedule.weekDays.firstThursday": "First thursday",
    "reports.view.modal.schedule.weekDays.firstFriday": "First friday",
    "reports.view.modal.schedule.weekDays.firstSaturday": "First saturday",

    "reports.view.modal.schedule.weekDays.secondSunday": "Second sunday",
    "reports.view.modal.schedule.weekDays.secondMonday": "Second monday",
    "reports.view.modal.schedule.weekDays.secondTuesday": "Second tuesday",
    "reports.view.modal.schedule.weekDays.secondWednesday": "Second wednesday",
    "reports.view.modal.schedule.weekDays.secondThursday": "Second thursday",
    "reports.view.modal.schedule.weekDays.secondFriday": "Second friday",
    "reports.view.modal.schedule.weekDays.secondSaturday": "Second saturday",

    "reports.view.modal.schedule.weekDays.thurdSunday": "Thurd sunday",
    "reports.view.modal.schedule.weekDays.thurdMonday": "Thurd monday",
    "reports.view.modal.schedule.weekDays.thurdTuesday": "Thurd tuesday",
    "reports.view.modal.schedule.weekDays.thurdWednesday": "Thurd wednesday",
    "reports.view.modal.schedule.weekDays.thurdThursday": "Thurd thursday",
    "reports.view.modal.schedule.weekDays.thurdFriday": "Thurd friday",
    "reports.view.modal.schedule.weekDays.thurdSaturday": "Thurd saturday",

    "reports.view.modal.schedule.weekDays.fourthSunday": "Fourth sunday",
    "reports.view.modal.schedule.weekDays.fourthMonday": "Fourth monday",
    "reports.view.modal.schedule.weekDays.fourthTuesday": "Fourth tuesday",
    "reports.view.modal.schedule.weekDays.fourthWednesday": "Fourth wednesday",
    "reports.view.modal.schedule.weekDays.fourthThursday": "Fourth thursday",
    "reports.view.modal.schedule.weekDays.fourthFriday": "Fourth friday",
    "reports.view.modal.schedule.weekDays.fourthSaturday": "Fourth saturday",

    "reports.view.modal.schedule.weekDays.fifthSunday": "Fifth sunday",
    "reports.view.modal.schedule.weekDays.fifthMonday": "Fifth monday",
    "reports.view.modal.schedule.weekDays.fifthTuesday": "Fifth tuesday",
    "reports.view.modal.schedule.weekDays.fifthWednesday": "Fifth wednesday",
    "reports.view.modal.schedule.weekDays.fifthThursday": "Fifth thursday",
    "reports.view.modal.schedule.weekDays.fifthFriday": "Fifth friday",
    "reports.view.modal.schedule.weekDays.fifthSaturday": "Fifth saturday",

    "reports.view.send.modal.title": "Recipients list",
    "reports.view.send.modal.add.email.label": "Add email",
    "reports.view.send.modal.add.email.placeholder": "Press <<Enter>> to add",
    "reports.view.send.modal.email.duplicated": "Duplicated email",
    "reports.view.send.modal.email.success.message": "Submitted successfully",
    "report.schedule.form.update.success.message":
      "Schedule report updated successfully",

    "reports.view.send.modal.email.error.message":
      "You need to add at least one email",

    "reports.view.header.all.reports": "All reports",
  },
};
