import { message } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { createReport, createReportReset, deleteReport, getReports, updateReport, updateReportReset } from '../../../store/reports/actions';
import { REPORTS_CATEGORIES } from '../../../utils/constants';
import { getPageSearchQueries, pageChangeHandler, searchHandler } from '../../../utils/utils';
import { ReportModal } from './components/ReportModal';
import { Reports } from './components/Reports';
import { ReportsMenu } from './components/ReportsMenu';
import { logoutAction } from '../../../store/logout';
import { AuthContext, checkToken } from '../../../context/Auth';

export const ReportsList = ({ route, history, match: { params: { category } } }) => {

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  const searchParams = useQuery(history);

  const {
    reports: {
      create,
      list,
      update: { isUpdated },
      delete: { isDeleted }
    },
    users: { 
      me: { data: meData }
     }
  } = useSelector(state => state);

  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerms, setSearchTerms] = useState({});

  const handleCloseModal = useCallback(() => {
    history.push(history.location.pathname);
    setModalVisibility(false);
    setSelectedReport(null);
  }, [history]);

  useEffect(() => {
    if (history.location.hash === '#create') setModalVisibility(true);
  }, [history]);

  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    // const params = { page: 1 };
    if (category === REPORTS_CATEGORIES.SCHEDULED) terms.scheduled = true;
    else if (category === REPORTS_CATEGORIES.UNSCHEDULED) terms.scheduled = false;
    dispatch(getReports(terms));
  }, [dispatch, category, searchParams]);

  useEffect(() => {
    if (create.isCreated) {
      handleCloseModal();
      dispatch(createReportReset());
    }
  }, [dispatch, create.isCreated, handleCloseModal]);

  useEffect(() => {
    if (isUpdated) {
      dispatch(updateReportReset());
      handleCloseModal();
    }
  }, [isUpdated, dispatch, handleCloseModal]);

  useEffect(() => {
    if (isDeleted) {
      message.success(messageTranslator("reports.list.delete.message", "reports"));
    }
  }, [isDeleted, messageTranslator]);

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handleSelectReport = (report) => {
    setSelectedReport(report);
    handleOpenModal();
  };

  const handleOpenModal = () => setModalVisibility(true);

  const handleSubmitReport = (report) => dispatch(selectedReport ? updateReport({
    id: selectedReport.id,
    ...report
  }) : createReport(report));

  const handleDeleteReport = (id) => dispatch(deleteReport(id));

  const handleSubmitSearch = (terms) => {
    searchHandler(history, terms);
    setPage(1);
  }

  //if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
        <ReportsMenu
          history={history}
          page={page}
          searchTerms={searchTerms}
          onCreateNewReportClick={setModalVisibility}
          onSearch={handleSubmitSearch}
          user={meData}
        />
        <Reports
          reportsList={list}
          history={history}
          page={page}
          user={meData}
          onPageChange={handlePageChange}
          onSelectReport={handleSelectReport}
          onCreateNewReportClick={setModalVisibility}
          onDeleteReport={handleDeleteReport}
        />

        <ReportModal
          visible={isModalVisible}
          selectedReport={selectedReport}
          create={create}
          isUpdated={isUpdated}
          onSubmitReport={handleSubmitReport}
          onCancel={handleCloseModal}
        />
    </PageSkeleton>
  );
};
