export const notificationsTranslations = {
  ar: {
    "notifications.list.empty": "لا توجد إشعارات في الوقت الحالي",

    FOLDER_NEEDS_REVIEW_FROM_EDITOR: "قام المحرر بإرسال الملف لك لتتم مراجعته",
    FOLDER_PUBLISHER_CHECK_FROM_AUDITOR:
      "قام المدقق بإرسال الملف لك ليتم التحقق منه",
    FOLDER_NEEDS_REVIEW_FROM_PUBLISHER:
      "قام الناشر بإعادة الملف لك لتتم مراجعته",
    FOLDER_DRAFT_FROM_AUDITOR: "قام المدقق بإعادة الملف للصيغة الأولى",
    FOLDER_PUBLISHED: "لقد تم نشر الملف",
    FOLDER_UNPUBLISHED: "تم إعادة الملف بعد النشر",

    "notifications.list.type": "نوع الإشعار",
    Folder: "ملفات",
    "notifications.list.time.minutes": "د",
    "notifications.list.time.hours": "س",
    "notifications.list.time.days": "ي",
    "notifications.list.all": "جميع الإشعارات",
  },
  en: {
    "notifications.list.empty": "No notifications for the moment",

    FOLDER_NEEDS_REVIEW_FROM_EDITOR:
      "The editor sent you a folder to be reviewed",
    FOLDER_PUBLISHER_CHECK_FROM_AUDITOR:
      "The auditor sent you a folder to be checked",
    FOLDER_NEEDS_REVIEW_FROM_PUBLISHER:
      "The publisher sent you back a folder to be reviewed",
    FOLDER_DRAFT_FROM_AUDITOR: "The auditor has returned the folder as draft",
    FOLDER_PUBLISHED: "The folder has been published",
    FOLDER_UNPUBLISHED: "The folder has beem returned after publishing",

    "notifications.list.type": "Notification type",
    Folder: "Folders",
    "notifications.list.time.minutes": "m",
    "notifications.list.time.hours": "h",
    "notifications.list.time.days": "d",
    "notifications.list.all": "All Notifications",
  },
};
