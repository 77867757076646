import React from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "../PageTitle";

export const PageSkeleton = ({
	children,
	pageTitle
}) => {

	const { data } = useSelector(state => state.config.globalConfig);

	return (
		<>
			<PageTitle title={`${pageTitle} ${ data ? `- ${data.values.websiteName}` : ''}`} />
			{children}
		</>
	);
};