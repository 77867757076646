import { api } from "..";
import { makeValidUrl } from "../../utils/utils";

const URL = "/api/alerts";

const formatTags = (arr) => arr.map((tag) => ({ tagName: tag }));

export const createAlertApi = ({
  andTags,
  orTags,
  excludedTags,
  facebook,
  instagram,
  twitter,
  youtube,
  url,
  ...restData
}) => {
  let newData = {
    ...restData,
    andTags: formatTags(andTags),
    orTags: formatTags(orTags),
    excludedTags: formatTags(excludedTags)
  };

  if (facebook) newData.facebookUrl = makeValidUrl(facebook);

  if (instagram) newData.instagramUrl = makeValidUrl(instagram);

  if (twitter) newData.twitterUrl = makeValidUrl(twitter);

  if (youtube) newData.youtubeUrl = makeValidUrl(youtube);

  if (url) newData.url = url;

  return api.post(URL, newData);
};

export const getAlertsApi = ({ page = 1, name, search }) => {
  let query = `page=${page}`;
  if (name) query += `&name=${name}`;
  if (search) query += `&search=${search}`;
  return api.get(`${URL}?${query}`);
};

export const getAlertByIdApi = (id) => api.get(`${URL}/${id}`);

export const updateAlertApi = ({ id, ...alert }) =>
  api.put(`${URL}/${id}`, alert);

export const deleteAlertApi = (id) => api.delete(`${URL}/${id}`);
