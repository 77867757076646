import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const EntityLogo = () => {

  const { data } = useSelector(state => state.config.globalConfig);

  const [logo, setLogo] = useState("");

  useEffect(() => {
    setLogo(() => {
      if (!data?.values.entity_logo) return null;
      return `${process.env.REACT_APP_BASE_URL}${data.values.entity_logo.contentUrl}`
    });
  }, [data]);

  return (
    <>
    {logo && <div className="logo-wrapper">
      {
       <img src={logo} alt='entity logo' />
      }
    </div>}
    </>
  );
};
