import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InboxMenu } from '../../../components/InboxMenu';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { getEmailGroups } from '../../../store/emailGroups/actions';
import { getPageSearchQueries, pageChangeHandler } from '../../../utils/utils';
import { EmailGroupsList } from './components/EmailGroupsList';

export const MailGroup = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const searchParams = useQuery(history);

  const {
    emailGroups: {
      list,
    }
  } = useSelector(state => state);

  const [page, setPage] = useState(1);
  const [searchTerms, setSearchTerms] = useState({});

  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    dispatch(getEmailGroups(terms));
  }, [dispatch, searchParams]);

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={list.isLoading}>
        <Row gutter={[30, 20]} justify="center" wrap="false" className='tabs-wrapper'>
          <Col className='inbox-menu-wrapper'>
            <InboxMenu history={history} />
          </Col>
          <Col className='inbox-settings-wrapper'>
            <EmailGroupsList
              emailGroups={list}
              page={page}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Spin>
    </PageSkeleton>
  );
};
