import {
  REPORT_CANCEL_SCHEDULE,
  REPORT_CANCEL_SCHEDULE_ERROR,
  REPORT_CANCEL_SCHEDULE_RESET,
  REPORT_CANCEL_SCHEDULE_SUCCESS,
  REPORT_CREATE,
  REPORT_CREATE_ERROR,
  REPORT_CREATE_RESET,
  REPORT_CREATE_SUCCESS,
  REPORT_DELETE,
  REPORT_DELETE_ERROR,
  REPORT_DELETE_RESET,
  REPORT_DELETE_SUCCESS,
  REPORT_EXPORT,
  REPORT_EXPORT_ERROR,
  REPORT_EXPORT_RESET,
  REPORT_EXPORT_SUCCESS,
  REPORT_GET_LIST,
  REPORT_GET_LIST_ERROR,
  REPORT_GET_LIST_SUCCESS,
  REPORT_GET_ONE,
  REPORT_GET_ONE_ERROR,
  REPORT_GET_ONE_SUCCESS,
  REPORT_UPDATE,
  REPORT_UPDATE_ERROR,
  REPORT_UPDATE_RESET,
  REPORT_UPDATE_SUCCESS,
  REPORT_GET_SCHEDULE,
  REPORT_GET_SCHEDULE_SUCCESS,
  REPORT_GET_SCHEDULE_ERROR,
} from "./types";

// * Create report
export const createReport = (report) => ({
  type: REPORT_CREATE,
  payload: report,
});

export const createReportSuccess = (newReport) => ({
  type: REPORT_CREATE_SUCCESS,
  payload: newReport,
});

export const createReportError = (error) => ({
  type: REPORT_CREATE_ERROR,
  payload: error,
});

export const createReportReset = () => ({
  type: REPORT_CREATE_RESET,
});

// * Get report
export const getReport = (id) => ({
  type: REPORT_GET_ONE,
  payload: id,
});

export const getReportSuccess = (report) => ({
  type: REPORT_GET_ONE_SUCCESS,
  payload: report,
});

export const getReportError = (error) => ({
  type: REPORT_GET_ONE_ERROR,
  payload: error,
});

// * Get reports
/**
 *
 * @param {{ page: number, scheduled: boolean | undefined }} params Params needed to load reports
 * @returns Object
 */
export const getReports = (params) => ({
  type: REPORT_GET_LIST,
  payload: params,
});

export const getReportsSuccess = (reports) => ({
  type: REPORT_GET_LIST_SUCCESS,
  payload: reports,
});

export const getReportsError = (error) => ({
  type: REPORT_GET_LIST_ERROR,
  payload: error,
});

// * Update report
export const updateReport = (report) => ({
  type: REPORT_UPDATE,
  payload: report,
});

export const updateReportSuccess = (newReport) => ({
  type: REPORT_UPDATE_SUCCESS,
  payload: newReport,
});

export const updateReportError = (error) => ({
  type: REPORT_UPDATE_ERROR,
  payload: error,
});

export const updateReportReset = () => ({
  type: REPORT_UPDATE_RESET,
});

// * Delete report
export const deleteReport = (id) => ({
  type: REPORT_DELETE,
  payload: id,
});

export const deleteReportSuccess = (id) => ({
  type: REPORT_DELETE_SUCCESS,
  payload: id,
});

export const deleteReportError = (error) => ({
  type: REPORT_DELETE_ERROR,
  payload: error,
});

export const deleteReportReset = () => ({
  type: REPORT_DELETE_RESET,
});

// * Export report
export const exportReport = (params) => ({
  type: REPORT_EXPORT,
  payload: params,
});

export const exportReportSuccess = (data) => ({
  type: REPORT_EXPORT_SUCCESS,
  payload: data,
});

export const exportReportError = (error) => ({
  type: REPORT_EXPORT_ERROR,
  payload: error,
});

export const exportReportReset = () => ({
  type: REPORT_EXPORT_RESET,
});

// * Cancel schedule
export const cancelSchedule = (id) => ({
  type: REPORT_CANCEL_SCHEDULE,
  payload: id,
});

export const cancelScheduleSuccess = () => ({
  type: REPORT_CANCEL_SCHEDULE_SUCCESS,
});

export const cancelScheduleError = (error) => ({
  type: REPORT_CANCEL_SCHEDULE_ERROR,
  payload: error,
});

export const cancelScheduleReset = () => ({
  type: REPORT_CANCEL_SCHEDULE_RESET,
});

//Get schedule

export const getSchedule = (id) => ({
  type: REPORT_GET_SCHEDULE,
  payload: id,
});

export const getReportScheduleSuccess = (schedule) => ({
  type: REPORT_GET_SCHEDULE_SUCCESS,
  payload: schedule,
});

export const getReportScheduleError = (error) => ({
  type: REPORT_GET_SCHEDULE_ERROR,
  payload: error,
});
