import { EditOutlined, PlusCircleOutlined, TeamOutlined } from '@ant-design/icons';
import { Avatar, Card, List, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../../context/config';
import { NEW_MAIL_GROUP, UPDATE_MAIL_GROUP } from '../../../../../routes/paths';
import { tablePaginationOptions } from '../../../../../utils/utils';
import "./email-group-list.less";

export const EmailGroupsList = ({
  emailGroups: { data, totalItems },
  page,
  onPageChange
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const newEmailGroupCard = {
    key: "new-email-group",
    title: "config.emailGroup.title",
    link: NEW_MAIL_GROUP
  }

  const renderEmailGroup = (group) => group.key === "new-email-group" ? (
    <List.Item className='email-group-item'>
      <Card>
        <Link to={group.link}>
          <Space direction="vertical" align='center' size={30}>
            <PlusCircleOutlined style={{ fontSize: '40px' }}  />
            <Typography>{messageTranslator(group.title, "config")}</Typography>
          </Space>
        </Link>
      </Card>
    </List.Item>
  ) : (
    <List.Item className='email-group-item'>
      <Card>
        <Avatar size={41} icon={<TeamOutlined />} />
        <Typography className='blue-Text'>{group.name}</Typography>
        <Typography.Text type="secondary">{`${group.mails.length} ${messageTranslator("config.emailGroup.list.workersNumber", "config")}`}</Typography.Text>
        <Link to={`${UPDATE_MAIL_GROUP}/${group.id}`}>
          <EditOutlined className='edit-btn' />
        </Link>
      </Card>
    </List.Item>
  )


  return (
    <Card
      title={messageTranslator("config.menu.mailGroup.label", "config")}
      bordered={false}
    >
      <List
        className='default-list mail-list'
        grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
        dataSource={[newEmailGroupCard, ...data]}
        renderItem={renderEmailGroup}
        pagination={tablePaginationOptions({
          totalItems,
          pageSize: 20,
          currentPage: page,
          onPageChange,
          messageTranslator
        })}
      />
    </Card>
  );
};
