// * Create email config
export const CONFIG_CREATE         = 'CONFIG_CREATE';
export const CONFIG_CREATE_SUCCESS = 'CONFIG_CREATE_SUCCESS';
export const CONFIG_CREATE_ERROR   = 'CONFIG_CREATE_ERROR';
export const CONFIG_CREATE_RESET   = 'CONFIG_CREATE_RESET';

// * Get email config
export const CONFIG_GET         = 'CONFIG_GET';
export const CONFIG_GET_SUCCESS = 'CONFIG_GET_SUCCESS';
export const CONFIG_GET_ERROR   = 'CONFIG_GET_ERROR';

// * Update email config
export const CONFIG_UPDATE         = 'CONFIG_UPDATE';
export const CONFIG_UPDATE_SUCCESS = 'CONFIG_UPDATE_SUCCESS';
export const CONFIG_UPDATE_ERROR   = 'CONFIG_UPDATE_ERROR';
export const CONFIG_UPDATE_RESET   = 'CONFIG_UPDATE_RESET';

// * Send test email
export const EMAIL_CONFIG_SEND_TEST         = 'EMAIL_CONFIG_SEND_TEST';
export const EMAIL_CONFIG_SEND_TEST_SUCCESS = 'EMAIL_CONFIG_SEND_TEST_SUCCESS';
export const EMAIL_CONFIG_SEND_TEST_ERROR   = 'EMAIL_CONFIG_SEND_TEST_ERROR';
export const EMAIL_CONFIG_SEND_TEST_RESET   = 'EMAIL_CONFIG_SEND_TEST_RESET';

// * Load global config
export const GLOABL_CONFIG_LOAD         = 'GLOABL_CONFIG_LOAD';
export const GLOABL_CONFIG_LOAD_SUCCESS = 'GLOABL_CONFIG_LOAD_SUCCESS';
