import {
  ALERT_CREATE,
  ALERT_CREATE_ERROR,
  ALERT_CREATE_SUCCESS,
  ALERT_DELETE,
  ALERT_DELETE_ERROR,
  ALERT_DELETE_SUCCESS,
  ALERT_FIND_DUPLICATION,
  ALERT_FIND_DUPLICATION_ERROR,
  ALERT_FIND_DUPLICATION_SUCCESS,
  ALERT_GET_BY_ID,
  ALERT_GET_BY_ID_ERROR,
  ALERT_GET_BY_ID_SUCCESS,
  ALERT_GET_LIST,
  ALERT_GET_LIST_ERROR,
  ALERT_GET_LIST_SUCCESS,
  ALERT_RESET_CREATED,
  ALERT_RESET_DELETED,
  ALERT_RESET_DUPLICATION,
  ALERT_RESET_UPDATED,
  ALERT_UPDATE,
  ALERT_UPDATE_ERROR,
  ALERT_UPDATE_SUCCESS,
  ALERT_HANDLE_SELECT,
} from "./types";

// Create
export const createAlert = (data) => ({
  type: ALERT_CREATE,
  payload: data,
});

export const createAlertSuccess = (id) => ({
  type: ALERT_CREATE_SUCCESS,
  payload: id,
});

export const createAlertError = (error) => ({
  type: ALERT_CREATE_ERROR,
  payload: error,
});

export const resetCreateAlert = () => ({
  type: ALERT_RESET_CREATED,
});

// List
export const getAlertList = (params) => ({
  type: ALERT_GET_LIST,
  payload: params,
});

export const getAlertListSuccess = (data) => ({
  type: ALERT_GET_LIST_SUCCESS,
  payload: data,
});

export const getAlertListError = (error) => ({
  type: ALERT_GET_LIST_ERROR,
  payload: error,
});

// Get by id
export const getAlert = (id) => ({
  type: ALERT_GET_BY_ID,
  payload: id,
});

export const getAlertSuccess = (alert) => ({
  type: ALERT_GET_BY_ID_SUCCESS,
  payload: alert,
});

export const getAlertError = (error) => ({
  type: ALERT_GET_BY_ID_ERROR,
  payload: error,
});

// Find duplication
export const findDuplicatedAlert = (name) => ({
  type: ALERT_FIND_DUPLICATION,
  payload: name,
});

export const findDuplicatedAlertSuccess = (data) => ({
  type: ALERT_FIND_DUPLICATION_SUCCESS,
  payload: data,
});

export const findDuplicatedAlertError = (error) => ({
  type: ALERT_FIND_DUPLICATION_ERROR,
  payload: error,
});

export const resetDuplicatedAlert = () => ({
  type: ALERT_RESET_DUPLICATION,
});

// Update
export const updateAlert = (alert) => ({
  type: ALERT_UPDATE,
  payload: alert,
});

export const updateAlertSuccess = (alert) => ({
  type: ALERT_UPDATE_SUCCESS,
  payload: alert,
});

export const updateAlertError = (errors) => ({
  type: ALERT_UPDATE_ERROR,
  payload: errors,
});

export const updateAlertReset = () => ({
  type: ALERT_RESET_UPDATED,
});

// Delete alert
export const deleteAlert = (id) => ({
  type: ALERT_DELETE,
  payload: id,
});

export const deleteAlertSuccess = (id) => ({
  type: ALERT_DELETE_SUCCESS,
  payload: id,
});

export const deleteAlertError = (errors) => ({
  type: ALERT_DELETE_ERROR,
  payload: errors,
});

export const deleteAlertReset = () => ({
  type: ALERT_RESET_DELETED,
});

export const handleSelectedAlert = (data) => ({
  type: ALERT_HANDLE_SELECT,
  payload: data,
});
