import { NOTIFICATIONS_GET, NOTIFICATIONS_GET_ERROR, NOTIFICATIONS_GET_SUCCESS, NOTIFICATIONS_SET_SEEN, NOTIFICATIONS_SET_SEEN_ERROR, NOTIFICATIONS_SET_SEEN_SUCCESS } from "./types";

// * Get notifications
export const getNotifications = () => ({
  type: NOTIFICATIONS_GET
});

export const getNotificationsSuccess = (notifications) => ({
  type: NOTIFICATIONS_GET_SUCCESS,
  payload: notifications
});

export const getNotificationsError = (error) => ({
  type: NOTIFICATIONS_GET_ERROR,
  payload: error
});

// * Mark as seen
export const markNotificationsAsSeen = (id) => ({
  type: NOTIFICATIONS_SET_SEEN,
  payload: id
});

export const markNotificationsAsSeenSuccess = () => ({
  type: NOTIFICATIONS_SET_SEEN_SUCCESS
});

export const markNotificationsAsSeenError = (notif) => ({
  type: NOTIFICATIONS_SET_SEEN_ERROR,
  payload: notif
});
