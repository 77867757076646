import { put, takeLatest } from 'redux-saga/effects';
import { createCategoryApi, deleteCategoryApi, listCategoriesApi, updateCategoryApi } from "../../api/categories";
import { createCategoryError, createCategorySuccess, deleteCategoryError, deleteCategorySuccess, listCategories, listCategoriesError, listCategoriesSuccess, updateCategoryError, updateCategorySuccess } from "./actions";
import { CATEGORIES_CREATE, CATEGORIES_DELETE, CATEGORIES_LIST, CATEGORIES_UPDATE } from "./types";

// * Create
function* createCategorySaga({ payload: category }) {
  try {
    const res = yield createCategoryApi(category);
    yield put(createCategorySuccess(res.data));
    yield put(listCategories({ page: 1 }));
  } catch (error) {
    yield put(createCategoryError(error));
  }
};

export function* watchCreateCategory() {
  yield takeLatest(CATEGORIES_CREATE, createCategorySaga);
};

// * List
function* listCategoriesSaga({ payload: params }) {
  try {
    const res = yield listCategoriesApi(params);
    yield put(listCategoriesSuccess({
      data: res.data['hydra:member'],
      totalItems: res.data['hydra:totalItems']
    }))
  } catch (error) {
    yield put(listCategoriesError(error));
  }
};

export function* watchListCategories() {
  yield takeLatest(CATEGORIES_LIST, listCategoriesSaga);
};

// * Update
function* updateCategorySaga({ payload: category }) {
  try {
    const res = yield updateCategoryApi(category);
    yield put(updateCategorySuccess(res.data));
    yield put(listCategories({ page: 1 }));
  } catch (error) {
    yield put(updateCategoryError(error));
  }
};

export function* watchUpdateCategory() {
  yield takeLatest(CATEGORIES_UPDATE, updateCategorySaga);
};

// * Delete
function* deleteCategorySaga({ payload: id }) {
  try {
    yield deleteCategoryApi(id);
    yield put(deleteCategorySuccess(id));
    yield put(listCategories({ page: 1 }));
  } catch (error) {
    yield put(deleteCategoryError(error));
  }
};

export function* watchDeleteCategory() {
  yield takeLatest(CATEGORIES_DELETE, deleteCategorySaga);
};
