import { SUPER_ADMIN } from "../../utils/permissions-utils";

/**
 * * Check if the connected user has a resource & permission that matches route's resourse and permissions.
 */
export const hasPermission = (userRole, routePermissions, ownerId, meId) => {
  for (let key in routePermissions) {
    if (userRole.slug === SUPER_ADMIN) return true;
    const routePermission = routePermissions[key];
    const userPermission = userRole.permissions[key];
    if (!userPermission) return false;
    return (
      !!userPermission.filter((perm) =>
        perm.some((subPerm) => subPerm && routePermission.includes(subPerm))
      ) || ownerId === meId
    );
  }
};
