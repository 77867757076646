import { api } from "..";
import { constructTimeZone } from '../../utils/moment-utils';

const URL = `/api/folders`;

export const addFolderApi = (folder) => api.post(URL, folder);

export const patchFolderAPi = ({ id, ...data }) => api.patch(`${URL}/${id}`, data);

export const getDailyFolderApi = ({
  id,
  after = null,
  before = null,
  state
}) => {
  const [resultAfter, resultBefore] = constructTimeZone([0, 0, 0], [23, 59, 59], after, before);
  
  let query = `alert=${id}&createdAt[after]=${resultAfter}&createdAt[before]=${resultBefore}`;
  if (state) query += `&moderationState=${state}`;
  return api.get(`${URL}?${query}`);
};