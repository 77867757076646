import { CopyOutlined, MailOutlined, SettingOutlined, TeamOutlined, TwitterOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../context/config';
import * as paths from '../../routes/paths';

export const InboxMenu = ({ history }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const inboxMenuItems = [
    {
      key: paths.MAIL_CONFIG,
      label: "config.menu.item.label",
      link: paths.MAIL_CONFIG,
      icon: <MailOutlined />
    },
    {
      key: paths.MAIL_GROUP,
      label: "config.menu.mailGroup.label",
      link: paths.MAIL_GROUP,
      icon: <TeamOutlined />
    },
    {
      key: paths.GLOBAL_CONFIG,
      label: "config.menu.globalConfig.label",
      link: paths.GLOBAL_CONFIG,
      icon: <SettingOutlined />
    },
    {
      key: paths.SCRAPPING_CONFIG,
      label: "config.menu.scrapingConfig.label",
      link: paths.SCRAPPING_CONFIG,
      icon: <CopyOutlined />
    },
    {
      key: paths.TWITTER_API_CONFIG,
      label: "config.menu.twitterApi.label",
      link: paths.TWITTER_API_CONFIG,
      icon: <TwitterOutlined />
    },
    {
      key: paths.TWITTER_CRITERIA_CONFIG,
      label: "config.menu.twitterCriteria.label",
      link: paths.TWITTER_CRITERIA_CONFIG,
      icon: <TwitterOutlined />
    }
  ];

  const [openKey, setOpenKey] = useState([]);

  useEffect(() => {
    setOpenKey([history.location.pathname]);
  }, [history]);

  const handleMenuClick = ({ key }) => setOpenKey([key]);

  return (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={openKey}
      mode="inline"
    >
      {
        inboxMenuItems.map(item => (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link to={item.link}>
              {messageTranslator(item.label, "config")}
            </Link>
          </Menu.Item>
        ))
      }

    </Menu>
  );
};
