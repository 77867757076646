// * Get alert results
export const ALERT_RESULTS_GET = "ALERT_RESULTS_GET";
export const ALERT_RESULTS_GET_SUCCESS = "ALERT_RESULTS_GET_SUCCESS";
export const ALERT_RESULTS_GET_ERROR = "ALERT_RESULTS_GET_ERROR";

// * Get new alert results
export const ALERT_NEW_RESULTS_GET = "ALERT_NEW_RESULTS_GET";
export const ALERT_NEW_RESULTS_GET_SUCCESS = "ALERT_NEW_RESULTS_GET_SUCCESS";
export const ALERT_NEW_RESULTS_GET_ERROR = "ALERT_NEW_RESULTS_GET_ERROR";
export const ALERT_NEW_RESULTS_GET_RESET = "ALERT_NEW_RESULTS_GET_RESET";

// * Patch actions
export const ALERT_RESULTS_PATCH_ACTIONS = "ALERT_RESULTS_PATCH_ACTIONS";
export const ALERT_RESULTS_PATCH_ACTIONS_SUCCESS =
  "ALERT_RESULTS_PATCH_ACTIONS_SUCCESS";
export const ALERT_RESULTS_PATCH_ACTIONS_ERROR =
  "ALERT_RESULTS_PATCH_ACTIONS_ERROR";

// * Search source names
export const SEARCH_SOURCE_NAMES = "SEARCH_SOURCE_NAMES";
export const SEARCH_SOURCE_NAMES_SUCCESS = "SEARCH_SOURCE_NAMES_SUCCESS";
export const SEARCH_SOURCE_NAMES_ERROR = "SEARCH_SOURCE_NAMES_ERROR";

// * Advanced search
export const ALERT_RESULTS_ADVANCED_SEARCH = "ALERT_RESULTS_ADVANCED_SEARCH";
export const ALERT_RESULTS_ADVANCED_SEARCH_SUCCESS =
  "ALERT_RESULTS_ADVANCED_SEARCH_SUCCESS";
export const ALERT_RESULTS_ADVANCED_SEARCH_ERROR =
  "ALERT_RESULTS_ADVANCED_SEARCH_ERROR";

// * Patch result category
export const ALERT_RESULTS_PATCH_CATEGORY = "ALERT_RESULTS_PATCH_CATEGORY";
export const ALERT_RESULTS_PATCH_CATEGORY_SUCCESS =
  "ALERT_RESULTS_PATCH_CATEGORY_SUCCESS";
export const ALERT_RESULTS_PATCH_CATEGORY_ERROR =
  "ALERT_RESULTS_PATCH_CATEGORY_ERROR";
export const ALERT_RESULTS_PATCH_CATEGORY_RESET =
  "ALERT_RESULTS_PATCH_CATEGORY_RESET";

// * Search for results
export const ALERT_RESULTS_SEARCH = "ALERT_RESULTS_SEARCH";
export const ALERT_RESULTS_SEARCH_SUCCESS = "ALERT_RESULTS_SEARCH_SUCCESS";
export const ALERT_RESULTS_SEARCH_ERROR = "ALERT_RESULTS_SEARCH_ERROR";

// * Filter by sourceType
export const ALERT_RESULTS_FILTER_BY_SOURCE_TYPE =
  "ALERT_RESULTS_FILTER_BY_SOURCE_TYPE";
export const ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_SUCCESS =
  "ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_SUCCESS";
export const ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_ERROR =
  "ALERT_RESULTS_FILTER_BY_SOURCE_TYPE_ERROR";

/**
 * * FOLDERS
 */
// * Add folder
export const FOLDER_ADD = "FOLDER_ADD";
export const FOLDER_ADD_SUCCESS = "FOLDER_ADD_SUCCESS";
export const FOLDER_ADD_ERROR = "FOLDER_ADD_ERROR";
export const FOLDER_ADD_RESET = "FOLDER_ADD_RESET";

// * Get daily folder
export const FOLDER_GET_DAILY = "FOLDER_GET_DAILY";
export const FOLDER_GET_DAILY_SUCCESS = "FOLDER_GET_DAILY_SUCCESS";
export const FOLDER_GET_DAILY_ERROR = "FOLDER_GET_DAILY_ERROR";

// * Patch folder
export const FOLDER_PATCH = "FOLDER_PATCH";
export const FOLDER_PATCH_SUCCESS = "FOLDER_PATCH_SUCCESS";
export const FOLDER_PATCH_ERROR = "FOLDER_PATCH_ERROR";
export const FOLDER_PATCH_RESET = "FOLDER_PATCH_RESET";

// * Create duplicated news
export const FOLDER_COMBINE_DUPLICATIONS = "FOLDER_COMBINE_DUPLICATIONS";
export const FOLDER_COMBINE_DUPLICATIONS_SUCCESS =
  "FOLDER_COMBINE_DUPLICATIONS_SUCCESS";
export const FOLDER_COMBINE_DUPLICATIONS_ERROR =
  "FOLDER_COMBINE_DUPLICATIONS_ERROR";
export const FOLDER_COMBINE_DUPLICATIONS_RESET =
  "FOLDER_COMBINE_DUPLICATIONS_RESET";

// * Pubish folder
export const FOLDER_PUBLISH = "FOLDER_PUBLISH";
export const FOLDER_PUBLISH_SUCCESS = "FOLDER_PUBLISH_SUCCESS";
export const FOLDER_PUBLISH_ERROR = "FOLDER_PUBLISH_ERROR";
export const FOLDER_PUBLISH_RESET = "FOLDER_PUBLISH_RESET";

// * Set folder to review
export const FOLDER_SET_REVIEW = "FOLDER_SET_REVIEW";
export const FOLDER_SET_REVIEW_SUCCESS = "FOLDER_SET_REVIEW_SUCCESS";
export const FOLDER_SET_REVIEW_ERROR = "FOLDER_SET_REVIEW_ERROR";
export const FOLDER_SET_REVIEW_RESET = "FOLDER_SET_REVIEW_RESET";

// * Remove item from folder
export const FOLDER_REMOVE_ITEM = "FOLDER_REMOVE_ITEM";
export const FOLDER_REMOVE_ITEM_SUCCESS = "FOLDER_REMOVE_ITEM_SUCCESS";
export const FOLDER_REMOVE_ITEM_ERROR = "FOLDER_REMOVE_ITEM_ERROR";
export const FOLDER_REMOVE_ITEM_RESET = "FOLDER_REMOVE_ITEM_RESET";

// * Auditor chnage folder state
export const FOLDER_CHANGE_STATE_AUDITOR = "FOLDER_CHANGE_STATE_AUDITOR";
export const FOLDER_CHANGE_STATE_AUDITOR_SUCCESS =
  "FOLDER_CHANGE_STATE_AUDITOR_SUCCESS";
export const FOLDER_CHANGE_STATE_AUDITOR_ERROR =
  "FOLDER_CHANGE_STATE_AUDITOR_ERROR";
export const FOLDER_CHANGE_STATE_AUDITOR_RESET =
  "FOLDER_CHANGE_STATE_AUDITOR_RESET";
