import { Button, Card, Col, Divider, Form, Input, message, Row, Space } from 'antd';
import React, { useContext, useEffect } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { CONFIG_NAMES } from '../../../../../utils/constants';
import { generateConfigList } from '../../../../../utils/utils';

export const ScrapingSettingsForm = ({ config, onSubmitConfig }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  useEffect(() => {
    if (config.data) setFieldsValue({ ...config.data.values });
  }, [config, setFieldsValue]);

  const handleFormSubmit = (values) => {
    const newValues = {};
    if (values.twitter_scrap_frequency) newValues.twitter_scrap_frequency = values.twitter_scrap_frequency;
    if (values.keep_tweets_for) newValues.keep_tweets_for = values.keep_tweets_for;
    if (values.website_scrap_frequency) newValues.website_scrap_frequency = values.website_scrap_frequency;
    if (values.keep_website_news_for) newValues.keep_website_news_for = values.keep_website_news_for;

    if (!Object.keys(newValues).length) {
      message.error(messageTranslator("config.settings.form.error.message", "config"));
      return;
    }

    onSubmitConfig({
      name: CONFIG_NAMES.scrap,
      values: generateConfigList(newValues)
    })
  };

  return (
    <Card
      title={messageTranslator("config.menu.scrapingConfig.label", "config")}
      bordered={false}
    >
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
      >

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={`${messageTranslator("config.scrap.twitter.frequency", "config")} (${messageTranslator("config.scrap.inMinutes", "config")})`}
              name="twitter_scrap_frequency"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={`${messageTranslator("config.scrap.tweets.keep", "config")} (${messageTranslator("config.scrap.inDays", "config")})`}
              name="keep_tweets_for"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={`${messageTranslator("config.scrap.website.frequency", "config")} (${messageTranslator("config.scrap.inHours", "config")})`}
              name="website_scrap_frequency"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={`${messageTranslator("config.scrap.website.keep", "config")} (${messageTranslator("config.scrap.inDays", "config")})`}
              name="keep_website_news_for"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Space>
          <span className='btn-wrapper'>
            <Button type="primary" htmlType="submit">
              {messageTranslator("commons.save", "commons")}
            </Button>
          </span>
          <Button>
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </Space>
      </Form>
    </Card>
  );
};
