import { ConfigProvider } from 'antd';
import ar_EG from 'antd/es/locale/ar_EG';
import en_US from 'antd/es/locale/en_US';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import './App.less';
import { AuthContext } from './context/Auth';
import { ConfigContext } from './context/config';
import { Routes } from './routes';
import { getAlertList } from './store/alerts/actions';
import { loadGlobalConfig } from './store/config/actions';
import { getNotifications } from './store/notifications/actions';
import { getMe } from './store/users/actions';

export const App = () => {

  console.error = () => {};

  const dispatch = useDispatch();

  const { state: { isLoggedIn } } = useContext(AuthContext);

  const { configState } = useContext(ConfigContext);

  useEffect(() => {
    dispatch(loadGlobalConfig());
    if (isLoggedIn) {
      dispatch(getAlertList({ page: 1 }));
      dispatch(getMe());
      dispatch(getNotifications());
      setInterval(() => {
        dispatch(getNotifications());
      }, 60000);
    }
  }, [dispatch, isLoggedIn]);

  /* Intl */
  const lang = configState.language === 'ar' ? ar_EG : en_US;
  const direction = configState.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <ConfigProvider locale={lang} direction={direction}>
      <Router>
        <Routes
          authorized={isLoggedIn}
        />
        <Route exact path="/">
          <Redirect to="/alerts" />
        </Route>
      </Router>
    </ConfigProvider>
  );
};
