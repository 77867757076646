import { EditFilled, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Divider, Form, Input, Row, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FileUploader } from '../../../../../components/FileUploader';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { AuthContext } from '../../../../../context/Auth';
import { ConfigContext } from '../../../../../context/config';
import { mergeErrors } from '../../../../../utils/errors-utils';
import { CAN_DELETE, CAN_PUT } from '../../../../../utils/permissions-utils';
import "./user.less";

export const UserCard = ({
  user,
  me,
  update: { isUpdated, isLoading: loadingUpdate, errors },
  isLoading,
  onFormSubmit
}) => {

  const uploadRef = useRef();

  const { messageTranslator } = useContext(ConfigContext);
  const { state } = useContext(AuthContext);

  const REQUIRED_FIELD = messageTranslator("commons.required.message", "commons");
  const MINIMUM_LENGTH = messageTranslator("commons.minimumLength.two", "commons");

  const [formRef] = Form.useForm();
  const { setFieldsValue, getFieldsValue, setFields } = formRef;

  const [isUpdatable, setUpdatable] = useState(false);
  const [image, setImage] = useState("");
  const [imageError, setImageError] = useState(null);

  useEffect(() => {
    setFieldsValue({ ...user, image: user.image && user.image['@id'] });
    if (user.image) setImage(`${user.image.contentUrl}`);
  }, [user, setFieldsValue]);

  useEffect(() => {
    if (image) setImageError(null);
  }, [image]);

  useEffect(() => {
    if (isUpdated) handleCancelUpdate();
  }, [isUpdated]);
  
  useEffect(() => {
    if (errors) {
      const fields = mergeErrors(getFieldsValue(), errors, messageTranslator);
      setFields(fields);
    }
  }, [errors, getFieldsValue, messageTranslator, setFields]);

  const handleUpdatableForm = () => setUpdatable(prev => !prev);

  const handleFormSubmit = ({ image, ...values }) => {
    if (!image) {
      setImageError(messageTranslator("users.profile.card.avatar.error", "users"));
      return;
    }
    setImageError(null);
    onFormSubmit({
      ...values,
      image: image || {}
    });
  }

  const handleCancelUpdate = () => setUpdatable(false);

  // const handleRemoveAvatar = () => {
  //   setImage("");
  //   setFieldsValue({
  //     image: null
  //   });
  // };

  return (
    <Col className='inbox-settings-wrapper'>
      <Card
        title={<Typography.Title level={4}>{messageTranslator("users.profile.card.update.title", "users")}</Typography.Title>}
        loading={isLoading}
      >
        <Row gutter={[36, 36]} justify="center" align="middle">
          <Col className='p-relative'>
            <Avatar
              size={124}
              icon={!image && <UserOutlined />}
              src={`${image && `${process.env.REACT_APP_BASE_URL}${image}`}`}
              className={!!imageError ? "error" : ""}
            />
            {/* </Row>
        <Row gutter={16} justify="center" align="middle" className='actions-avatar'> */}
            <div className='actions-avatar'>
              <PermissionsHandler
                resourceName="User"
                blocPermission={CAN_DELETE}
                userRole={me?.role}
                connectedUserId={me?.id}
                ownerId={user.id}
              >
                {/* <Col>
              <Button
                type="primary"
                icon={<DeleteFilled />}
                size='small'
                danger
                onClick={handleRemoveAvatar}
              />
            </Col> */}
              </PermissionsHandler>
              <PermissionsHandler
                resourceName="User"
                blocPermission={CAN_PUT}
                userRole={me?.role}
                connectedUserId={me?.id}
                ownerId={user.id}
              >
                <FileUploader
                  ref={uploadRef}
                  token={state.token}
                  imageSrc={user.image?.contentUrl}
                  setFieldsValue={setFieldsValue}
                  fieldName="image"
                  hideLabel
                  onUploadChange={setImage}
                  customButtonTrigger={(
                    <Button
                      type="primary"
                      icon={<EditFilled />}
                      size='small'
                      className={isUpdatable ? "update-btn updatable" : "update-btn"}
                      onClick={handleUpdatableForm}
                    />
                  )}
                />
              </PermissionsHandler>
            </div>
          </Col>
        </Row>

        {
          !!imageError && (
            <Row justify="center">
              <Col>
                <Typography.Text type="danger">{imageError}</Typography.Text>
              </Col>
            </Row>
          )
        }

        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>

        <Form
          name="update-profile"
          form={formRef}
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Form.Item
            name="image"
            hidden
          >
            <Input />
          </Form.Item>
          <Row gutter={[36, 16]}>
            <Col md={12}>
              <Form.Item
                label={messageTranslator("users.list.labels.username", "users")}
                name="userName"
                rules={[
                  { required: true, message: REQUIRED_FIELD },
                  { min: 2, message: MINIMUM_LENGTH }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={messageTranslator("users.list.labels.email", "users")}
                name="email"
                rules={[
                  { required: true, message: REQUIRED_FIELD },
                  { type: 'email', message: messageTranslator("users.list.labels.invalidEmail", "users") }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[36, 16]}>
            <Col md={12}>
              <Form.Item
                label={messageTranslator("users.list.labels.firstName", "users")}
                name="firstName"
                rules={[
                  { required: true, message: REQUIRED_FIELD },
                  { min: 2, message: MINIMUM_LENGTH }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={messageTranslator("users.list.labels.lastName", "users")}
                name="lastName"
                rules={[
                  { required: true, message: REQUIRED_FIELD },
                  { min: 2, message: MINIMUM_LENGTH }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row justify="end">
            <Col>
              <span key="cancel" className='btn-wrapper white'>
                <Button onClick={handleCancelUpdate}>
                  {messageTranslator("commons.cancel", "commons")}
                </Button>
              </span>
            </Col>
            <Col>
              <span key="cancel" className='btn-wrapper'>
                <Button type="primary" loading={loadingUpdate} htmlType="submit">
                  {messageTranslator("commons.save", "commons")}
                </Button>
              </span>
            </Col>
          </Row>

        </Form>
      </Card>
    </Col>
  );
};
