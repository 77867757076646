import React, { useEffect, useState } from 'react';
import { SUPER_ADMIN } from '../../utils/permissions-utils';

/**
 * ### Permissions handler
 * #### Description:
 * This React component takes a number of props alongside with children elements. It run some tests to decide whether user has permissions to resource or not.
 * 
 * **Keep in mind:**
 * 1. Must has children in all cases.
 * 2. **resourceName**, **blocPermission** and **userRole** can stand alone. Their role is to check if user has permissions or not to X resources. They don't check posession. Any missing prop between the three of them you'll get an issue.
 * 3. **connectedUserId** and **ownerId** must work together alongside with the other three props (Cannot stand alone). When using one of them with the previous three props, you must add the other. Their role is to check if user has posession or not.
 * @param {*} param0 
 * @returns 
 */
export const PermissionsHandler = ({
  children,
  resourceName,
  blocPermission,
  userRole = {},
  connectedUserId,
  ownerId,
}) => {

  const [isPermitted, setPermitted] = useState(false);

  useEffect(() => {
    if (!!userRole.permissions) {
      const hasPermissions = userRole.permissions[resourceName]?.find(resource => resource.includes(blocPermission)) || [];

      const hasPosession = hasPermissions?.includes('ALL') || hasPermissions?.includes(null) || (connectedUserId === parseInt(ownerId) && hasPermissions?.includes('OWN'));

      const isAdmin = userRole.slug === SUPER_ADMIN;
      
      setPermitted(hasPosession || isAdmin);
    }
  }, [userRole, resourceName, blocPermission, connectedUserId, ownerId]);

  return <>{isPermitted && children}</>;
};