import { GET_COUNTRIES_STATS, GET_COUNTRIES_STATS_ERROR, GET_COUNTRIES_STATS_SUCCESS, GET_FEEDBACK_STATS, GET_FEEDBACK_STATS_ERROR, GET_FEEDBACK_STATS_SUCCESS, GET_HASHTAGS_STATS, GET_HASHTAGS_STATS_ERROR, GET_HASHTAGS_STATS_SUCCESS, GET_IMPORTANT_NEWS_STATS, GET_IMPORTANT_NEWS_STATS_ERROR, GET_IMPORTANT_NEWS_STATS_SUCCESS, GET_INFLUENCERS_STATS, GET_INFLUENCERS_STATS_ERROR, GET_INFLUENCERS_STATS_SUCCESS, GET_KEYWORD_STATS, GET_KEYWORD_STATS_ERROR, GET_KEYWORD_STATS_SUCCESS, GET_MONITORING_STATS, GET_MONITORING_STATS_ERROR, GET_MONITORING_STATS_SUCCESS } from "./types";

// * Countries
/**
 * 
 * @param {{
 * action: "comparison" | "count",
 * subject: "sentiment" | "source-type" | "hashtags" | "tags" | "countries" | "influencers",
 * type: "unit" | "period",
 * unit: string,
 * number: number,
 * limit: number,
 * after: Date,
 * before: Date,
 * alert: string
 * }} object 
 * @returns Redux Action
 */
export const getCountriesStats = (params) => ({
  type: GET_COUNTRIES_STATS,
  payload: params
});

export const getCountriesStatsSuccess = (data) => ({
  type: GET_COUNTRIES_STATS_SUCCESS,
  payload: data
});

export const getCountriesStatsError = (error) => ({
  type: GET_COUNTRIES_STATS_ERROR,
  payload: error
});

// * + Feedback
/**
 * 
 * @param {{
 * action: "comparison" | "count",
 * subject: "sentiment" | "source-type" | "hashtags" | "tags" | "countries" | "influencers",
 * type: "unit" | "period",
 * unit: string,
 * number: number,
 * after: Date,
 * before: Date,
 * alert: string
 * }} object 
 * @returns Redux Action
 */
export const getFeedbackStats = (params) => ({
  type: GET_FEEDBACK_STATS,
  payload: params
});

export const getFeedbackStatsSuccess = (data) => ({
  type: GET_FEEDBACK_STATS_SUCCESS,
  payload: data
});

export const getFeedbackStatsError = (error) => ({
  type: GET_FEEDBACK_STATS_ERROR,
  payload: error
});

// * Important news
/**
 * 
 * @param {{ dates: array, alert: string }} params 
 * @returns object
 */
export const getImportantNewsStats = (params) => ({
  type: GET_IMPORTANT_NEWS_STATS,
  payload: params
});

export const getImportantNewsStatsSuccess = (data) => ({
  type: GET_IMPORTANT_NEWS_STATS_SUCCESS,
  payload: data
});

export const getImportantNewsStatsError = (error) => ({
  type: GET_IMPORTANT_NEWS_STATS_ERROR,
  payload: error
});


// * Monitoring news
/**
 * 
 * @param {{
 * action: "comparison" | "count",
 * subject: "sentiment" | "source-type" | "hashtags" | "tags" | "countries" | "influencers",
 * type: "unit" | "period",
 * unit: string,
 * number: number,
 * after: Date,
 * before: Date,
 * alert: string
 * }} object 
 * @returns Redux Action
 */
export const getMonitoringStats = (params) => ({
  type: GET_MONITORING_STATS,
  payload: params
});

export const getMonitoringStatsSuccess = (data) => ({
  type: GET_MONITORING_STATS_SUCCESS,
  payload: data
});

export const getMonitoringStatsError = (error) => ({
  type: GET_MONITORING_STATS_ERROR,
  payload: error
});

// * Hashtags
/**
 * 
 * @param {{
 * action: "comparison" | "count",
 * subject: "sentiment" | "source-type" | "hashtags" | "tags" | "countries" | "influencers",
 * type: "unit" | "period",
 * unit: string,
 * number: number,
 * limit: number,
 * after: Date,
 * before: Date,
 * alert: string
 * }} object 
 * @returns Redux Action
 */
export const getHashtagsStats = (params) => ({
  type: GET_HASHTAGS_STATS,
  payload: params
});

export const getHashtagsStatsSuccess = (data) => ({
  type: GET_HASHTAGS_STATS_SUCCESS,
  payload: data
});

export const getHashtagsStatsError = (error) => ({
  type: GET_HASHTAGS_STATS_ERROR,
  payload: error
});

// * Keywords
/**
 * 
 * @param {{
 * action: "comparison" | "count",
 * subject: "sentiment" | "source-type" | "hashtags" | "tags" | "countries" | "influencers",
 * type: "unit" | "period",
 * unit: string,
 * number: number,
 * limit: number,
 * after: Date,
 * before: Date,
 * alert: string
 * }} object 
 * @returns Redux Action
 */
export const getTagsStats = (params) => ({
  type: GET_KEYWORD_STATS,
  payload: params
});

export const getTagsStatsSuccess = (data) => ({
  type: GET_KEYWORD_STATS_SUCCESS,
  payload: data
});

export const getTagsStatsError = (error) => ({
  type: GET_KEYWORD_STATS_ERROR,
  payload: error
});

// * Influencers
/**
 * 
 * @param {{
 * action: "comparison" | "count",
 * subject: "sentiment" | "source-type" | "hashtags" | "tags" | "countries" | "influencers",
 * type: "unit" | "period",
 * unit: string,
 * number: number,
 * limit: number,
 * after: Date,
 * before: Date,
 * alert: string
 * }} object 
 * @returns Redux Action
 */
export const getInfluencersStats = (params) => ({
  type: GET_INFLUENCERS_STATS,
  payload: params
});

export const getInfluencersStatsSuccess = (data) => ({
  type: GET_INFLUENCERS_STATS_SUCCESS,
  payload: data
});

export const getInfluencersStatsError = (error) => ({
  type: GET_INFLUENCERS_STATS_ERROR,
  payload: error
});
