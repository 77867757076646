import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { App } from './App';
import { AlertContextProvider } from './context/Alert';
import { AuthContextProvider } from './context/Auth';
import { ConfigContextProvider } from './context/config';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store';
import ErrorBoundary from './ErrorBoundary';

const store = configureStore();

const app = (
  <Provider store={store}>
    <AlertContextProvider>
      <AuthContextProvider>
        <ConfigContextProvider>
          <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          </ErrorBoundary>
        </ConfigContextProvider>
      </AuthContextProvider>
    </AlertContextProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
