export const colors = ['#FF2D55', '#FFB45E', '#00E096', '#0095FF', '#B10022', '#D77300', '#009765', '#3300FF', '#F7A9B8', '#F6E800', '#00E0C8', '#002743', '#CE2DFF', '#5EEAFF', '#D3D3D3', '#006D82'];

export const UNSEEN          = 'unseen';
export const BOOKMARKED      = 'bookmarked';
export const ARCHIVED        = 'archived';
export const DELETED         = 'deleted';
export const UNDESIRABLE     = 'undesirable';
export const ENABLED         = 'enabled';
export const ACTIVE          = 'active';
export const DAILY           = 'daily';
export const FOLDERS_ARCHIVE = 'folders-archive'

export const ALL       = 'all';
export const FACEBOOK  = 'facebook';
export const WEBSITE   = 'website';
export const TWITTER   = 'twitter';
export const LINKEDIN  = 'linkedin';
export const INSTAGRAM = 'instagram';
export const VIDEOS    = 'youtube';