import { Button, Col, Divider, Form, Input, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FileUploader } from '../../../../../components/FileUploader';
import { AuthContext } from '../../../../../context/Auth';
import { ConfigContext } from '../../../../../context/config';
import { isValidUrlRule } from '../../../../../utils/utils';

export const AddSourceModal = ({
  create: { isLoading },
  update: { isLoading: loadingUpdate },
  duplications: { name, url },
  selectedSource,
  onNameChange,
  translator,
  onUrlChange,
  onCheckSourceName,
  onCheckSourceUrl,
  onOk,
  onCancel,
  ...modalProps
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const { state } = useContext(AuthContext);

  const [form] = useForm();

  const uploadRef = useRef();

  const { submit, resetFields, setFields, setFieldsValue } = form;

  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    if (!modalProps.visible) {
      resetFields();
      setCanUpdate(true);
      if (uploadRef.current) uploadRef.current.resetImageUrl();
    }
  }, [modalProps.visible, resetFields]);

  useEffect(() => {
    if (name.length) setFields([{
      name: 'name',
      errors: [messageTranslator("sources.add.duplicatedSourceName", "sources")]
    }]);

    if (url.length) setFields([{
      name: 'url',
      errors: [messageTranslator("sources.add.duplicatedSourceWebsite", "sources")]
    }])
  }, [name, url, setFields, messageTranslator]);

  useEffect(() => {
    if (selectedSource) {
      setFieldsValue({ ...selectedSource, image: selectedSource.image ? selectedSource.image['@id'] : '' });
      setCanUpdate(!!selectedSource?.editable);
    }
  }, [selectedSource, setFieldsValue]);

  const handleFormFinish = ({ image, ...values }) => {
    if (image) values.image = image;
    onOk(values);
  };

  return (
    <Modal
      {...modalProps}
      title={translator(selectedSource ? "sources.modal.title.update" : "sources.modal.title.add", "sources")}
      onOk={onOk}
      onCancel={onCancel}
      getContainer={false}
      forceRender
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={onCancel}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className='btn-wrapper'>
          <Button
            key="submit"
            type="primary"
            loading={isLoading || loadingUpdate}
            onClick={submit}
          >
            {messageTranslator(selectedSource ? "commons.update" : "commons.add", "commons")}
          </Button>
        </span>
      ]}
    >
      <Form
        form={form}
        onFinish={handleFormFinish}
      >
        <Typography.Title level={4}>
          {messageTranslator("sources.add.info", "sources")}
        </Typography.Title>
        <Form.Item
          name="image"
          hidden
        >
          <Input />
        </Form.Item>
        <Row gutter={40}>
          <Col md={12} xs={24}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Input
                placeholder={messageTranslator("sources.add.name.placeholder", "sources")}
                onChange={onNameChange}
                onBlur={onCheckSourceName}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="url"
              rules={[{ required: false, message: messageTranslator("commons.required.message", "commons") }, () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
            >
              <Input
                placeholder={messageTranslator("sources.add.url.placeholder", "sources")}
                onChange={onUrlChange}
                onBlur={onCheckSourceUrl}
                disabled={!canUpdate}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Typography.Title level={4}>
          {messageTranslator("sources.add.socialMedia", "sources")}
        </Typography.Title>
        <Row gutter={40}>
          <Col md={12} xs={24}>
            <Form.Item
              name="twitterUrl"
              rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
            >
              <Input
                placeholder={messageTranslator("sources.add.twitter.placeholder", "sources")}
                disabled={!canUpdate}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
                name="youtubeUrl"
                rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
            >
              <Input
                  placeholder={messageTranslator("sources.add.youtube.placeholder", "sources")}
                  disabled={!canUpdate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        <FileUploader
          ref={uploadRef}
          label={messageTranslator("sources.add.image.title", "sources")}
          token={state.token}
          imageSrc={selectedSource?.image?.contentUrl}
          setFieldsValue={setFieldsValue}
          fieldName="image"
        />

      </Form>
    </Modal>
  );
};
