import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Modal, Select, Typography, Radio, Spin } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import React, { useContext, useState, useEffect } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { INBOX, REPORTS } from '../../../../../routes/paths';
import { FOLDER_STATES, ROLES } from '../../../../../utils/constants';
import { FOLDERS_ARCHIVE, DAILY } from '../../../../../utils/data/category';
import { FolderPublishedContent } from '../FolderPublishedContent';
import { getUsers } from '../../../../../store/users/actions';
import "./styles.less";

export const ChangeFolderStateModal = ({
  isUpdated,
  noticePlaceholder,
  alertId,
  reportId,
  currentAuditor,
  onSaveChanges,
  onModalClose,
  onUpdateReset,
  ...rest
}) => {

  const { messageTranslator } = useContext(ConfigContext);
  const [auditor, ] = useState(currentAuditor);
  const dispatch = useDispatch();

  const {
    users: {
      listUsers: { data: users, isLoading: loadingUsers }
    },
  } = useSelector(state => state);

  const radioList = [
    {
      label: "alerts.daily.banner.approveAndPublish",
      value: FOLDER_STATES.PUBLISHER_CHECK
    },
    {
      label: "alerts.daily.banner.returnToEditor",
      value: FOLDER_STATES.DRAFT
    },
    {
      label: "alerts.daily.banner.publish",
      value: FOLDER_STATES.PUBLISHED
    }
  ];

  const [selectedOption, setSelectedOption] = useState();
  const [form] = Form.useForm();

  const { submit, resetFields } = form;

  useEffect(() => {
    if(selectedOption === FOLDER_STATES.PUBLISHER_CHECK) {
      handleFetchPublishers();
    } else  if(selectedOption === FOLDER_STATES.DRAFT) {
      handleFetchEditors();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (!rest.visible) {
      resetFields();
    }
  }, [rest.visible, resetFields]);

  const handleFormFinish = () => {

    selectedOption === FOLDER_STATES.PUBLISHER_CHECK && onSaveChanges({ 
      auditor: currentAuditor?.['@id'],
      moderationState: selectedOption, 
      publisher: form.getFieldValue(ROLES.PUBLISHER), 
      publisherNotes: form.getFieldValue("publisherNotes") ? form.getFieldValue("publisherNotes") : "", 
    });

    selectedOption === FOLDER_STATES.DRAFT && onSaveChanges({ 
          auditor: currentAuditor?.['@id'],
          moderationState: selectedOption, 
          editor: form.getFieldValue(ROLES.EDITOR), 
          editorNotes: form.getFieldValue("editorNotes") ? form.getFieldValue("editorNotes") : "", 
    });

    selectedOption === FOLDER_STATES.PUBLISHED && onSaveChanges({ auditor: auditor, moderationState: selectedOption });
    
  };

  //const handleSaveChanges = () => onSaveChanges({ moderationState: selectedOption });

  const handleFetchEditors = () => {
    dispatch(getUsers({
      page: 1,
      role: ROLES.EDITOR.toUpperCase()
    }));
  };

  const handleFetchAuditors = () => {
    dispatch(getUsers({
      page: 1,
      role: ROLES.AUDITOR.toUpperCase()
    }));
  };

  const handleFetchPublishers = () => {
    dispatch(getUsers({
      page: 1,
      role: ROLES.PUBLISHER.toUpperCase()
    }));
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    form.resetFields();
  };

  const handleCloseModal = () => {
    onUpdateReset();
    onModalClose(false);
  }

  return (
    <Modal
      forceRender
      getContainer={false}
      title={!isUpdated && messageTranslator("alerts.daily.banner.sendFolder", "alerts")}
      onCancel={handleCloseModal}
      centered={isUpdated}
      footer={isUpdated ? null : [
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={handleCloseModal}
          >
            إلغاء
          </Button>
        </span>,
        <span key="ok" className="btn-wrapper">
          <Button
            key="submit"
            type="primary"
            onClick={selectedOption === FOLDER_STATES.PUBLISHED ? handleFormFinish : submit}
          >
            {messageTranslator("commons.send", "commons")}
          </Button>
        </span>
      ]}
      {...rest}
    >
      <Spin spinning={loadingUsers}>
      {
        isUpdated ? (
          <FolderPublishedContent
            className="small-modal"
            status={selectedOption}
            linkTo={`${INBOX}/${alertId}/${DAILY}`}
            linkToArchive={`${INBOX}/${alertId}/${FOLDERS_ARCHIVE}`}
            linkToReport={`${REPORTS}/all/${reportId}`}
            onModalClose={() => {
              onUpdateReset();
              onModalClose(false);
            }}
          />
        ) : (
        <>
          <Radio.Group
            className='folder-options-radio'
            onChange={handleRadioChange}
            defaultValue={selectedOption}
          >
            {
              radioList.map(option => (
                <Radio
                  key={option.value}
                  value={option.value}
                >
                  {messageTranslator(option.label, "alerts")}
                </Radio>
              ))
            }
          </Radio.Group>
          {selectedOption === FOLDER_STATES.PUBLISHER_CHECK && <div>
          <Typography.Title level={4} type="secondary">{messageTranslator("alerts.view.banner.choosePublisher", "alerts")}</Typography.Title>
          <Form
            form={form}
            name="send-to-publisher"
            onFinish={handleFormFinish}
          >
            <Form.Item
              name={ROLES.PUBLISHER}
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} 
                placeholder={messageTranslator("alerts.view.banner.placeholder.choosePublisher", "alerts")}
                loading={loadingUsers}
              >
                {
                  users?.map(user => (
                    <Select.Option key={user['@id']} value={user['@id']}>{user.firstName} {user.lastName}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item name={"publisherNotes"}>
              <Input.TextArea
                placeholder={`${messageTranslator(noticePlaceholder, "alerts")}...`}
                autoSize={{ minRows: 6, maxRows: 12 }}
              />
            </Form.Item>
          </Form></div>
          }
          {selectedOption === FOLDER_STATES.DRAFT && <div>
          <Typography.Title level={4} type="secondary">{messageTranslator("alerts.view.banner.chooseEditor", "alerts")}</Typography.Title>
          <Form
            form={form}
            name="send-back-to-editor"
            onFinish={handleFormFinish}
          >
            <Form.Item
              name={ROLES.EDITOR}
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} 
                placeholder={messageTranslator("alerts.view.banner.placeholder.chooseEditor", "alerts")}
                loading={loadingUsers}
              >
                {
                  users.map(user => (
                    <Select.Option key={user['@id']} value={user['@id']}>{user.firstName} {user.lastName}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item name={"editorNotes"}>
              <Input.TextArea
                placeholder={`${messageTranslator(noticePlaceholder, "alerts")}...`}
                autoSize={{ minRows: 6, maxRows: 12 }}
              />
            </Form.Item>
          </Form></div>
        }
          </>
        )
      }
      </Spin>
    </Modal>
  );
};
