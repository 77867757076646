import { CalendarOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useState, useEffect } from 'react';
import { ConfigContext } from '../../context/config';
import { ISO_DATE } from '../../utils/moment-utils';
import './styles.less';

export const DateSelector = ({ theme = 'dark', isArabic, onDateApply, daily }) => {

  const { messageTranslator } = useContext(ConfigContext);
  
  const [isOpen, setOpen] = useState(false);
  const [terms, setTerms] = useState("");
  const [date, setDate] = useState({
    momentDate: moment(),
    strDate: moment().format(ISO_DATE)
  });

  useEffect(() => {
    let x = document?.querySelectorAll('.daily-folder-category-list');

    if(window?.location?.pathname.split("/")[3] !== "folders-archive") {

    x?.forEach((item, index) => {
      let lists = item?.querySelectorAll(".ant-list-items")
      
      lists?.forEach((list, ind) => {
        var found = false;
        let li = list?.querySelectorAll(".ant-list-item");
        li?.forEach((el, i) => {
          if(!el?.innerText?.includes(terms)) {
            el.style.display = "none";
            //found = false;
          } else {
            el.style.display = "flex";
            found = true;
          }  
        });

        let foundedItem;
        if(!found) {
          foundedItem = document?.getElementById(item.id);
          foundedItem.style.display = 'none';
        } else {
          foundedItem = document?.getElementById(item.id);
          foundedItem.style.display = 'block';
        }
      });
    });

  }

  }, [terms]); 

  
  const handleOpenCalendar = () => setOpen(prev => !prev);

  const onDateChange = (value, mode) => setDate(prev => ({
    ...prev,
    strDate: mode
  }));

  const handleDateApply = () => {
    if (date) {
      setDate(prev => ({
    ...prev,
    momentDate: date.strDate
  }));
      onDateApply(date.strDate);
      setOpen(false);
    }
  };   

  const extraFooter = () => (
    <span className='btn-wrapper'>
      <Button type="primary" onClick={handleDateApply}>{messageTranslator("commons.apply", "commons")}</Button>
    </span>
  );

  return (
    <Row justify='space-between' align='middle' className={`news-header-list-wrapper ${theme}`}>
      <Col>
        <Typography.Title level={5}>
          {messageTranslator("alerts.daily.header.date", "alerts")} {moment(date?.momentDate).format('DD MMMM YYYY')}{isArabic && 'م'}
        </Typography.Title>
      </Col>
      <Col className={`${daily ? 'daily-data-picker' : 'data-picker'}`}>
      {(window?.location?.pathname.split("/")[3] !== "folders-archive") && <input 
      className="searchbar" 
      type="text" 
      placeholder={messageTranslator("commons.searchBar.placeholder", "commons")} 
      name="search" 
      onChange={(e) => setTerms(e.target.value)} 
      />}
        <DatePicker bordered={false}
          showToday={false}
          open={isOpen}
          renderExtraFooter={extraFooter}
          onChange={onDateChange}
        />
        <CalendarOutlined onClick={handleOpenCalendar} />
      </Col>
    </Row>
  );
};
