export const commonsTranslations = {
  ar: {
    // ? Fall back
    "commons.fallback.message": "المعذرة حصل خطأ ما",
    "commons.fallback.logout": "تسجيل الخروج",

    // ? Pagination
    "commons.pagination.next": "التالي",
    "commons.pagination.prev": "السابق",

    // ? Header
    "commons.header.accountSettings": "إعدادات الحساب",
    "commons.header.logout": "تسجيل الخروج",
    "commons.header.myPage": "صفحتي",
    "commons.header.chnagePassword": "تغيير كلمة المرور",
    "commons.header.editAndMonitor": "حساب تحرير ورصد",
    "commons.header.auditor": "حساب مراجع",
    "commons.header.superAdmin": "حساب مشرف",
    "commons.header.publisher": "حساب ناشر",

    // ? Search bar
    "commons.searchBar.placeholder": "ابحث هنا",

    "commons.date": "تاريخ محدد",

    "commons.reset": "إعادة الضبط",
    "commons.apply": "تطبيق",

    "commons.delete.confirm": "تأكيد الحذف",
    "commons.cancel": "إلغاء",
    "commons.add": "إضافة",
    "commons.create": "إنشاء",
    "commons.update": "تعديل",
    "commons.delete": "حذف",
    "commons.send": "إرسال",
    "commons.save": "حفــظ",
    "commons.close": "إغلاق",
    "commons.deleting.progress": "جاري الحذف",

    "commons.required.message": "هذه الخانة إجبارية !",
    "commons.validEmail.message": "البريد الإلكتروني غير صالح !",
    "commons.isNumber.message": "يجب أن يكون هذا الحقل رقميًا !",
    "commons.error": "خطأ",

    "commons.morning": "صباحاً",
    "commons.evening": "مساءاً",

    "commons.minimumLength.two": "حرفان هو الحد الأدنى !",

    "commons.url.invalidText":
      "الرابط غير صالح. الرجاء إدخال رابط صالح للعمل !",

    "commons.upload.thumbnail.text": "تحميل صورة",
    "commons.upload.noSelectedFile": "لا يوجد ملف محدد",

    "commons.tags.optional": "كلمات إختيارية",
    "commons.tags.required": "كلمات إجبارية",
    "commons.tags.excluded": "كلمات مستثناة",
    "commons.tags.hashtags": "هاشتاقات",
    "commons.tags.mentions": "الإشارات",
    "commons.tags.warning.required": "الرجاء إدخال كلمات إختيارية أو إجبارية !",
    "commons.tags.warning.duplicated": "كلمات مفتاحية مكررة",
  },
  en: {
    //? Fallback
    "commons.fallback.message": "Sorry, something went wrong",
    "commons.fallback.logout": "Logout",

    // ? Pagination
    "commons.pagination.next": "Next",
    "commons.pagination.prev": "Previous",

    // ? Header
    "commons.header.accountSettings": "Account settings",
    "commons.header.logout": "Logout",
    "commons.header.myPage": "My page",
    "commons.header.chnagePassword": "Change password",
    "commons.header.editAndMonitor": "Editing and monitoring account",
    "commons.header.auditor": "Auditor account",
    "commons.header.superAdmin": "Admin account",
    "commons.header.publisher": "Publisher account",

    // ? Search bar
    "commons.searchBar.placeholder": "Search",

    "commons.date": "Specific date",

    "commons.reset": "Reset",
    "commons.apply": "Apply",

    "commons.delete.confirm": "Yes, delete",
    "commons.cancel": "Cancel",
    "commons.add": "Add",
    "commons.create": "Create",
    "commons.update": "Update",
    "commons.delete": "Delete",
    "commons.send": "Send",
    "commons.save": "Save",
    "commons.close": "Close",
    "commons.deleting.progress": "Deleting",

    "commons.required.message": "This field is required !",
    "commons.validEmail.message": "This email is not valid !",
    "commons.isNumber.message": "This field must be numeric !",
    "commons.error": "Error",

    "commons.morning": "AM",
    "commons.evening": "PM",

    "commons.minimumLength.two": "Two characters minimum!",

    "commons.url.invalidText": "Invalid URL. Please enter a valid one !",

    "commons.upload.thumbnail.text": "Upload image",
    "commons.upload.noSelectedFile": "No selected file",

    "commons.tags.optional": "Optional words",
    "commons.tags.required": "Required words",
    "commons.tags.excluded": "Excluded words",
    "commons.tags.hashtags": "Hashtags",
    "commons.tags.mentions": "mentions",
    "commons.tags.warning.required":
      "Please enter optional or required words !",
    "commons.tags.warning.duplicated": "Duplicated keywords",
  },
};
