import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row, Tag, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { AlertContext } from '../../../../../context/Alert';
import { ConfigContext } from '../../../../../context/config';
import { checkDuplicatedItemsInArray } from '../../../../../utils/utils';

const renderDuplicatedTags = (tags) => tags.map(tag => <Tag key={tag} color="#f50">{tag}</Tag>);

export const TagsStep = ({
  onNext,
  onPrev,
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const INPUT_TAG_PLACEHOLDER = messageTranslator("alerts.add.stepper.stepThree.tag.placeholder", "alerts");

  const { addTags } = useContext(AlertContext);

  const [optionalTags, setOptionalTags] = useState([]);
  const [requiredTags, setRequiredTags] = useState([]);
  const [excludedTags, setExcludedTags] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [mentions, setMentions] = useState([]);

  const [duplicatedTags, setDuplicatedTags] = useState([]);

  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (optionalTags.length && requiredTags.length && excludedTags.length) setError(false);
    setDuplicatedTags(checkDuplicatedItemsInArray([...optionalTags, ...requiredTags, ...excludedTags]))
  }, [optionalTags, requiredTags, excludedTags]);

  const handleNext = () => {
    if (optionalTags.length || requiredTags.length) {
      setError(false);
      addTags(requiredTags, optionalTags, excludedTags, hashtags, mentions);
      onNext();
    } else setError(true);
  };

  return (
    <>
      <Row align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <div className="img-step-wrapper">
            <img src="/images/step2.png" alt="Step Tow" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} className="bg-white">
          <Row className="step-content" align="middle">
            <Col className="full-width">
              <Scrollbar>
                <div className="content-wrapper">
                  <Row align='middle'>
                    <Col>
                      <div className="entete">
                        <div className="step-title">
                          {messageTranslator("alerts.add.stepper.stepThree.tagsSettings", "alerts")}
                        </div>
                        <div className="step-desc">
                          {messageTranslator("alerts.add.stepper.stepThree.tagsDescription", "alerts")}
                        </div>
                      </div>

                      <Typography.Title level={4}>
                        {messageTranslator("commons.tags.optional", "commons")}
                      </Typography.Title>
                      <Typography.Title level={5}>
                        {messageTranslator("alerts.add.stepper.stepThree.optionalTags.desc", "alerts")}
                      </Typography.Title>
                      {/* Optional tags */}
                      <TagsInput
                        value={optionalTags}
                        onChange={setOptionalTags}
                        inputProps={{
                          placeholder: INPUT_TAG_PLACEHOLDER
                        }}
                      />
                      {/* Optional tags */}

                      <Typography.Title level={4}>
                        {messageTranslator("commons.tags.required", "commons")}
                      </Typography.Title>
                      <Typography.Title level={5}>
                        {messageTranslator("alerts.add.stepper.stepThree.requiredTags.desc", "alerts")}
                      </Typography.Title>
                      {/* Required tags */}
                      <TagsInput
                        value={requiredTags}
                        onChange={setRequiredTags}
                        inputProps={{
                          placeholder: INPUT_TAG_PLACEHOLDER
                        }}
                      />
                      {/* Required tags */}

                      <Typography.Title level={4}>
                        {messageTranslator("commons.tags.excluded", "commons")}
                      </Typography.Title>
                      <Typography.Title level={5}>
                        {messageTranslator("alerts.add.stepper.stepThree.excludedTags.desc", "alerts")}
                      </Typography.Title>
                      {/* Excluded tags */}
                      <TagsInput
                        value={excludedTags}
                        onChange={setExcludedTags}
                        inputProps={{
                          placeholder: INPUT_TAG_PLACEHOLDER
                        }}
                      />
                      {/* Excluded tags */}

                      <Typography.Title level={4}>
                        {messageTranslator("commons.tags.hashtags", "commons")}
                      </Typography.Title>
                      <Typography.Title level={5}>
                        {messageTranslator("alerts.add.stepper.stepThree.hashtags.desc", "alerts")}
                      </Typography.Title>
                      {/* hashtags */}
                      <TagsInput
                          value={hashtags}
                          onChange={setHashtags}
                          inputProps={{
                            placeholder: INPUT_TAG_PLACEHOLDER
                          }}
                      />
                      {/* hashtags */}


                      <Typography.Title level={4}>
                        {messageTranslator("commons.tags.mentions", "commons")}
                      </Typography.Title>
                      <Typography.Title level={5}>
                        {messageTranslator("alerts.add.stepper.stepThree.mentions.desc", "alerts")}
                      </Typography.Title>
                      {/* hashtags */}
                      <TagsInput
                          value={mentions}
                          onChange={setMentions}
                          inputProps={{
                            placeholder: INPUT_TAG_PLACEHOLDER
                          }}
                      />
                      {/* hashtags */}

                      <Row>
                        <Col span={24}>
                          {
                            hasError && (
                              <Alert
                                message={messageTranslator("commons.error", "commons")}
                                description={messageTranslator("commons.tags.warning.required", "commons")}
                                type="error"
                                showIcon
                              />
                            )
                          }
                          {
                            !!duplicatedTags.length && (
                              <Alert
                                message={messageTranslator("commons.tags.warning.duplicated", "commons")}
                                description={renderDuplicatedTags(duplicatedTags)}
                                type="error"
                                showIcon
                              />
                            )
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Scrollbar>
            </Col>
          </Row>

          <div className="nav-step-wrapper">
            <Row justify="space-between" className="full-width">
              <Col>
                <Button
                  type="primary"
                  onClick={onPrev}
                >
                  <ArrowLeftOutlined className='transformMiroir' /> {messageTranslator("commons.pagination.prev", "commons")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={handleNext}
                >
                  {messageTranslator("commons.pagination.next", "commons")} <ArrowRightOutlined className='transformMiroir' />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
