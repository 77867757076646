import { Card, Col, Row, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../context/config';
import "./categoriesCounter.less";

export const CategoriesCounter = ({ categorizedNews }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (categorizedNews) {
      let counter = 0;
      categorizedNews.forEach(category => {
        counter += category.data.length;
      });
      setTotalItems(counter);
    }
  }, [categorizedNews]);

  return (
    <div className='categories-wrapper'>
      <Row gutter={[10, 30]} justify="center" align='stretch'>
        <Col key="total-items" flex='20%'>
          <Card className='categorie-item'>
            <Row justify="space-between" align="middle" dir='vertical'>
              <Col flex="1 0 100%">
                <Typography.Title level={4}>{messageTranslator("reports.view.header.counter.all.label", "reports")}</Typography.Title>
              </Col>
              <Col flex="1 0 100%">
                <Typography.Text>
                  {messageTranslator("reports.view.header.counter.label", "reports")}
                </Typography.Text>
                <Typography.Title level={1} className='number-text'>{totalItems}</Typography.Title>
              </Col>
            </Row>
          </Card>
        </Col>
        {
          categorizedNews.map(category => (
            <Col key={category.id} flex='20%'>
              <Card className='categorie-item'>
                <Row justify="space-between" align="middle" dir='vertical'>
                  <Col flex="1 0 100%">
                    <Typography.Title level={4}>{category.name}</Typography.Title>
                  </Col>
                  <Col flex="1 0 100%">
                    <Typography.Text>
                      {messageTranslator("reports.view.header.counter.label", "reports")}
                    </Typography.Text>
                    <Typography.Title level={1} className='number-text'>{category.data.length}</Typography.Title>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
        }
      </Row>
    </div>
  );
};
