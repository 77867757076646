import { CloseOutlined } from '@ant-design/icons';
import { Carousel, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { AlertContext } from '../../../context/Alert';
import { ConfigContext } from '../../../context/config';
import { ALERTS, INBOX } from '../../../routes/paths';
import { createAlert, findDuplicatedAlert, resetCreateAlert, resetDuplicatedAlert } from '../../../store/alerts/actions';
import "./alerts-step.less";
import { LanguageStep } from './components/LanguageStep';
import { SocialMediaStep } from './components/SocialMediaStep';
import { TagsStep } from './components/TagsStep';
import { WebsiteStep } from './components/WebsiteStep';
import { WebsiteUrlStep } from './components/WebsiteUrlStep';

const STEPS_COUNT = 5;

export const NewAlert = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const carouselRef = useRef();

  const dispatch = useDispatch();

  const { alertState, updateCount } = useContext(AlertContext);
  const {
    users: { me: { data: meData } },
    alerts: {
      create: { isCreated, isLoading },
      duplicated: { data: duplicatedAlert, isLoading: dataLoading }
    }
  } = useSelector(state => state);

  useEffect(() => {
    return () => dispatch(resetCreateAlert());
  }, [dispatch]);

  useEffect(() => {
    if (isCreated) {
      history.push(`${INBOX}/${isCreated}`);
    }
  }, [isCreated, history]);

  // Stepper
  const handleNextClick = useCallback(
    () => {
      updateCount(alertState.stepCount + 1);
      if (alertState.stepCount + 1 !== STEPS_COUNT) carouselRef?.current.next();
      duplicatedAlert !== null && dispatch(resetDuplicatedAlert());
    }, [alertState.stepCount, dispatch, duplicatedAlert, updateCount]);

  const handlePrevClick = () => {
    updateCount(alertState.stepCount - 1);
    carouselRef?.current.prev();
  };

  const handleCheckWebsiteName = (name) => dispatch(findDuplicatedAlert(name));

  const handleSiteInputChange = () => duplicatedAlert !== null && dispatch(resetDuplicatedAlert());

  const handleFinishClick = (data) => dispatch(createAlert(data));

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={isLoading || dataLoading}>
        <div className='new-alert'>
          <Link to={ALERTS} className="close-new-alert-btn">
            <CloseOutlined />
          </Link>
          <Carousel
            ref={carouselRef}
            effect='fade'
            dots={true}
            className='steps'
          >
            <WebsiteStep
              duplicatedWebsite={duplicatedAlert}
              isLoading={dataLoading}
              onNext={handleNextClick}
              onCheckWebsiteName={handleCheckWebsiteName}
              onInputChange={handleSiteInputChange}
            />
            <WebsiteUrlStep
              onNext={handleNextClick}
              onPrev={handlePrevClick}
              onInputChange={handleSiteInputChange}
            />
            <TagsStep
              onNext={handleNextClick}
              onPrev={handlePrevClick}
            />
            <SocialMediaStep
              onNext={handleNextClick}
              onPrev={handlePrevClick}
            />
            <LanguageStep
              onFinish={handleFinishClick}
              onPrev={handlePrevClick}
              isLoading={isLoading}
            />
          </Carousel>
        </div>
      </Spin>
    </PageSkeleton>
  );
};
