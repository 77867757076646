import { Card, Col, Row, Spin } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { sendResetRequest, sendResetRequestReset } from '../../../store/resetPassword/actions';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { RequestSent } from './components/RequestSent';
import "./forget-password.less";

export const ForgotPassword = ({ route }) => {

  const formRef = useRef();

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const {
    request: { isSent, errors, isLoading }
  } = useSelector(state => state.resetPassword);

  useEffect(() => {
    if (isSent) {
      formRef?.current?.resetForm();
    }
  }, [isSent, dispatch]);

  const handleResetPassword = (values) => dispatch(sendResetRequest(values));

  const reSendRequest = () => dispatch(sendResetRequestReset());

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={isLoading}>
        <Row justify="center" align="middle" className='forget-password-wrapper full-height'>
          <Col span={22}>
            <Card className='card-small'>
              <div className="header-forget-pwd">
                <h1 className="h1">
                  {messageTranslator("auth.forgot.form.title", "auth")}
                </h1>
              </div>
              {
                isSent ? (
                  <RequestSent
                    onResend={reSendRequest}
                  />
                ) : (
                  <ForgotPasswordForm
                    ref={formRef}
                    errors={errors}
                    onSubmit={handleResetPassword}
                  />
                )
              }
            </Card>
          </Col>
        </Row>
      </Spin>
    </PageSkeleton>
  );
};
