import { CATEGORIES_CREATE, CATEGORIES_CREATE_ERROR, CATEGORIES_CREATE_SUCCESS, CATEGORIES_DELETE, CATEGORIES_DELETE_ERROR, CATEGORIES_DELETE_SUCCESS, CATEGORIES_LIST, CATEGORIES_LIST_ERROR, CATEGORIES_LIST_SUCCESS, CATEGORIES_RESET_CREATE, CATEGORIES_RESET_DELETE, CATEGORIES_RESET_UPDATE, CATEGORIES_UPDATE, CATEGORIES_UPDATE_ERROR, CATEGORIES_UPDATE_SUCCESS } from "./types";

// * Create
export const createCategory = (category) => ({
  type: CATEGORIES_CREATE,
  payload: category
});

export const createCategorySuccess = (category) => ({
  type: CATEGORIES_CREATE_SUCCESS,
  payload: category
});

export const createCategoryError = (errors) => ({
  type: CATEGORIES_CREATE_ERROR,
  payload: errors
});

export const resetCreateCategory = () => ({
  type: CATEGORIES_RESET_CREATE
});

// * List
export const listCategories = (params) => ({
  type: CATEGORIES_LIST,
  payload: params
});

export const listCategoriesSuccess = (categories) => ({
  type: CATEGORIES_LIST_SUCCESS,
  payload: categories
});

export const listCategoriesError = (errors) => ({
  type: CATEGORIES_LIST_ERROR,
  payload: errors
});

// * Update
export const updateCategory = (category) => ({
  type: CATEGORIES_UPDATE,
  payload: category
});

export const updateCategorySuccess = (category) => ({
  type: CATEGORIES_UPDATE_SUCCESS,
  payload: category
});

export const updateCategoryError = (errors) => ({
  type: CATEGORIES_UPDATE_ERROR,
  payload: errors
});

export const resetUpdateCategory = () => ({
  type: CATEGORIES_RESET_UPDATE
});

// * Delete
export const deleteCategory = (id) => ({
  type: CATEGORIES_DELETE,
  payload: id
});

export const deleteCategorySuccess = (id) => ({
  type: CATEGORIES_DELETE_SUCCESS,
  payload: id
});

export const deleteCategoryError = (errors) => ({
  type: CATEGORIES_DELETE_ERROR,
  payload: errors
});

export const resetDeleteCategory = () => ({
  type: CATEGORIES_RESET_DELETE
});
