import { ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../../context/config';

export const LoginForm = ({ hasResetPassword, onFormSubmit, isLoading, language, errors }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();

  const onFinish = (credentials) => onFormSubmit(credentials);
  return (
    <Col xs={{ span: 24 }} lg={{ span: 10 }}>
      <Row justify="center" align="middle" className="form-wrapper full-height">
        <Col flex="auto">
          <h2 className="h1">
            {messageTranslator("auth.login.loginText", "auth")}
          </h2>
          {
            hasResetPassword && (
              <Alert
                showIcon
                type="success"
                message={messageTranslator("auth.reset.form.login.success", "auth")}
              />
            )
          }

          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              login: '',
              password: '',
              remember: false
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="login"
              rules={[{ required: true, message: messageTranslator("auth.login.warn.enterLogin", "auth") }]}
            >
              <Input
                className="form-item-height"
                placeholder={messageTranslator("auth.login.emailPlaceholder", "auth")}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: messageTranslator("auth.login.warn.enterPassword", "auth") }]}
            >
              <Input.Password
                className="form-item-height"
                placeholder={messageTranslator("auth.login.passwordPlaceholder", "auth")}
              />
            </Form.Item>

            <Row justify="space-between" className='baseline'>
              <Col>
                <Form.Item name="remember" valuePropName="checked" className="small-check">
                  <Checkbox>{messageTranslator("auth.login.rememberMe", "auth")}</Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Link to="/forgot-password">
                  {messageTranslator("auth.login.forgetPassword", "auth")}
                </Link>
              </Col>
            </Row>

            {
              !!errors && (
                <Form.Item>
                  <Alert
                    message={messageTranslator(errors["general"] && errors["general"][0].startsWith("Too many failed login attempts") ? "auth.login.too_many_attempts" : "auth.login.badCredentials", "errors")}
                    type="error"
                    showIcon
                    closable
                  />
                </Form.Item>
              )
            }

            <Form.Item>
              <Button
                className="form-item-height"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                block
              >
                {messageTranslator("auth.login.submitText", "auth")}  <ArrowRightOutlined className='transformMiroir' />
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Col>
  )
};
