import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../../context/config';
// import './folder-banner.less';

export const EditorFolderBanner = ({
  counter,
  checkedItems,
  backTo,
  openDuplicationModal,
  openReviewModal
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <Card
      className='folder-banner'
      size='small'
    >
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
          <Row justify="space-between" align='middle'>
            <Col flex="auto" xs={24}>
              <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.counter", "alerts")} <span className='nbre-alerts'>+{counter}</span></Typography.Title>
            </Col>

            <Col flex="none" xs={24}>
              <span className="btn-wrapper blue">
                <Link to={backTo}>
                  <Button
                    key="add-news"
                    type="link"
                  >
                    <PlusOutlined />
                    {messageTranslator("alerts.daily.banner.addNews", "alerts")} 
                  </Button>
                </Link>
              </span>
              <span className="btn-wrapper blue">
                <Button
                  onClick={() => openDuplicationModal(true)}
                  disabled={!checkedItems}
                  key="duplicated-news"
                >
                  <PlusOutlined />
                  {messageTranslator("alerts.daily.banner.duplicated", "alerts")}
                </Button>
              </span>
              <span className="btn-wrapper">
                <Button
                  onClick={openReviewModal}
                  key="add-folder"
                >
                  <SendOutlined />
                  {messageTranslator("alerts.daily.banner.sendToReview", "alerts")}
                </Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
