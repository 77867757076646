import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import { AlertContext } from '../../../../../context/Alert';
import { ConfigContext } from '../../../../../context/config';
import { isValidUrlRule } from '../../../../../utils/utils';

export const SocialMediaStep = ({
  onNext,
  onPrev,
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = useForm();

  const { setFieldsValue, getFieldValue } = form;

  const { addSocialMedia } = useContext(AlertContext);

  const CHECKBOX_TEXT = messageTranslator("alerts.update.checkbox.label", "alerts")

  const handleNext = (values) => {
    addSocialMedia(values);
    onNext();
  };

  const handleCheckboxChange = ({ target: { id: field } }) => setFieldsValue({
    [field]: !getFieldValue(field)
  });

  return (
    <Form
      form={form}
      onFinish={(values) => handleNext(values)}
    >
      <Row align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <div className="img-step-wrapper">
            <img src="/images/step3.png" alt="Step Three" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} className="bg-white">
          <Row className="step-content" align="middle">
            <Col className="full-width">
              <Scrollbar mobileNative={false}>
                <div className="content-wrapper">
                  <Row align='middle'>
                    <Col>
                      <div className="entete">
                        <div className="step-title">
                          {messageTranslator("alerts.add.stepper.stepFour.social.title", "alerts")}
                        </div>
                        <div className="step-desc">
                          {messageTranslator("alerts.add.stepper.stepFour.social.title", "alerts")}
                        </div>
                      </div>
                      <h1>
                        {messageTranslator("alerts.add.stepper.stepFour.social.twitter", "alerts")}
                      </h1>
                      <Form.Item
                        name="twitter"
                        className='mb-0'
                        rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="followTwitterUrl"
                      >
                        <Checkbox onChange={handleCheckboxChange}>
                          <Typography.Text className='smallTypo'>{CHECKBOX_TEXT}</Typography.Text>
                        </Checkbox>
                      </Form.Item>
                      {/* To be added later */}
                      {/* <h1>
                        {messageTranslator("alerts.add.stepper.stepFour.social.facebook", "alerts")}
                      </h1>
                      <Form.Item
                        name="facebook"
                        className='mb-0'
                        rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="followFacebookUrl"
                      >
                        <Checkbox onChange={handleCheckboxChange}>
                          <Typography.Text className='smallTypo'>{CHECKBOX_TEXT}</Typography.Text>
                        </Checkbox>
                      </Form.Item>
                      <h1>
                        {messageTranslator("alerts.add.stepper.stepFour.social.instagram", "alerts")}
                      </h1>
                      <Form.Item
                        name="instagram"
                        className='mb-0'
                        rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="followInstagramUrl"
                      >
                        <Checkbox onChange={handleCheckboxChange}>
                          <Typography.Text className='smallTypo'>{CHECKBOX_TEXT}</Typography.Text>
                        </Checkbox>
                      </Form.Item> */}
                      <h1>
                        {messageTranslator("alerts.add.stepper.stepFour.social.youtube", "alerts")}
                      </h1>
                      <Form.Item
                        name="youtube"
                        className='mb-0'
                        rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="followYoutubeUrl"
                      >
                        <Checkbox onChange={handleCheckboxChange}>
                          <Typography.Text className='smallTypo'>{CHECKBOX_TEXT}</Typography.Text>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Scrollbar>
            </Col>
          </Row>


          <div className="nav-step-wrapper">
            <Row justify="space-between" className="full-width">
              <Col>
                <Button
                  type="primary"
                  onClick={onPrev}
                >
                  <ArrowLeftOutlined className='transformMiroir' /> {messageTranslator("commons.pagination.prev", "commons")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType='submit'
                >
                  {messageTranslator("commons.pagination.next", "commons")} <ArrowRightOutlined className='transformMiroir' />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
