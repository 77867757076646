import { api } from '..';

const URL = "/api/mail-groups";
const PARSING_URL = '/api/excel-import-emails';

export const createEmailGroupApi = (group) => api.post(URL, group);

export const getEmailGroupsApi = (params) => {
  let query = `${URL}`;
  if (params.search) query += `?search=${params.search}`;
  return api.get(query);
}

export const getEmailGroupByIdApi = (id) => api.get(`${URL}/${id}`);

export const updateEmailGroupApi = ({ id, action, ...newGroup }) => api.put(`${URL}/${id}`, newGroup);

export const deleteEmailGroupApi = (id) => api.delete(`${URL}/${id}`);

export const parseContactsFileApi = (fileIRI) => api.post(PARSING_URL, fileIRI);
