import { Button, message, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { PermissionsHandler } from '../../../components/PermissionHandler';
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { SearchBar } from '../../../components/SearchBar';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { NEW_ALERT } from '../../../routes/paths';
import { deleteAlert, deleteAlertReset, findDuplicatedAlert, getAlertList, updateAlert, updateAlertReset } from '../../../store/alerts/actions';
import { CAN_CREATE } from '../../../utils/permissions-utils';
import { getPageSearchQueries, pageChangeHandler, searchHandler } from '../../../utils/utils';
import { ListAlerts } from './components/ListAlerts';
import { UpdateAlert } from './components/UpdateAlert';

export const Alerts = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const searchParams = useQuery(history);

  const {
    users: { me: { data: meData } },
    alerts: {
      list,
      delete: { isLoading, isDeleted },
      duplicated,
      update: { isLoading: updateLoading, isUpdated }
    }
  } = useSelector(state => state);

  const [isUpdateModalOpen, setUpdateModalOpen] = useState(() => false);
  const [selectedAlert, setSelectedAlert] = useState(() => null);
  const [page, setPage] = useState(1);
  const [searchTerms, setSearchTerms] = useState({});

  const handleCloseModal = useCallback(
    () => {
      history.push(history.location.pathname);
      setSelectedAlert(null);
      setUpdateModalOpen(false);
    }, [history]);

  useEffect(() => {
    if (history.location.hash) {
      const hashId = parseInt(Array.from(history.location.hash).slice(1,).join(""));
      const alert = list.data.find(el => el.id === hashId);
      handleOpenModal(alert);
    }
  }, [history, list]);

  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    dispatch(getAlertList(terms));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (isDeleted) dispatch(deleteAlertReset());
  }, [dispatch, isDeleted]);

  useEffect(() => {
    if (isUpdated) {
      message.success(messageTranslator("alerts.list.update.success.message", "alerts"));
      handleCloseModal();
      dispatch(updateAlertReset());
    }
  }, [isUpdated, dispatch, handleCloseModal, messageTranslator]);

  const handleOpenModal = (alert) => {
    setSelectedAlert(alert);
    setUpdateModalOpen(true);
  };

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handleSearch = (terms) => searchHandler(history, terms);

  const handleDeleteAlert = (id) => dispatch(deleteAlert(id));

  const checkAlertDuplication = (name) => dispatch(findDuplicatedAlert(name));

  const handleAlertEdit = (alert) => dispatch(updateAlert({ ...alert, id: selectedAlert.id }));

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={list.isLoading || isLoading || duplicated.isLoading || updateLoading}>
        <PermissionsHandler
          resourceName="Alert"
          blocPermission={CAN_CREATE}
          userRole={meData?.role}
        >
          <div className='btn-wrapper'>
            <Link to={NEW_ALERT}>
              <Button type="primary">
                {messageTranslator("alerts.new.text", "alerts")}
              </Button>
            </Link>
          </div>
        </PermissionsHandler>

        <SearchBar
          page={page}
          label={route.title}
          history={history}
          searchTerms={searchTerms}
          onLoadSearchTerms={setSearchTerms}
          onSearchTrigger={handleSearch}
        />

        <ListAlerts
          listData={list}
          role={meData?.role}
          loadingDelete={isLoading}
          onSelectAlert={handleOpenModal}
          onPageChange={handlePageChange}
          onDeleteALert={handleDeleteAlert}
        />

        <UpdateAlert
          visible={isUpdateModalOpen}
          selectedALert={selectedAlert}
          duplication={duplicated}
          isLoading={updateLoading}
          onNameChange={checkAlertDuplication}
          onOk={handleAlertEdit}
          onCancel={handleCloseModal}
        />
      </Spin>
    </PageSkeleton>
  );
};
