import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import isUrl from 'is-url';
import React, { useContext, useState } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import { AlertContext } from '../../../../../context/Alert';
import { ConfigContext } from '../../../../../context/config';
import { isValidUrlRule } from '../../../../../utils/utils';

export const WebsiteUrlStep = ({
  isLoading,
  onNext,
  onPrev,
  onInputChange
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = useForm();

  const { addWebsiteURL } = useContext(AlertContext);
  const [hasErrors, setErrors] = useState(false);

  const handleNext = (websiteURL, followUrl) => {
    if (websiteURL) {
      if (isUrl(`http://${websiteURL}`) || isUrl(`https://${websiteURL}`)) {
        addWebsiteURL({
          url: websiteURL.includes('http') ? websiteURL : `http://${websiteURL}`,
          followUrl
        });
        onNext();
        setErrors(null);
      }
      else setErrors(messageTranslator("alerts.add.stepper.stepTwo.invalidUrl", "alerts"));
    } else {
      onNext();
    }
  };

  const handleCheckboxChange = (e) => {
    if (form) form.setFieldsValue({
      followUrl: !form.getFieldValue('followUrl')
    });
  };

  return (
    <Form
      form={form}
      initialValues={{
        name: '',
        followUrl: false
      }}
      onFinish={({ name, followUrl }) => handleNext(name, followUrl)}
    >
      <Row align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <div className="img-step-wrapper">
            <img src="/images/step1.png" alt="Step One" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} className="bg-white">
          <Row className="step-content" align="middle">
            <Col className="full-width">
              <Scrollbar>
                <div className="content-wrapper">
                  <Row align='middle'>
                    <Col>
                      <div className="entete">
                        <div className="step-title">
                          {messageTranslator("alerts.add.stepper.stepTwo.title", "alerts")}
                        </div>
                        <div className="step-desc">
                          {messageTranslator("alerts.add.stepper.stepTwo.label", "alerts")}
                        </div>
                      </div>
                      <Form.Item
                        name="name"
                        rules={[
                          () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))
                        ]}
                      >
                        <Input onChange={onInputChange} />
                      </Form.Item>
                      <Form.Item
                        name="followUrl"
                      >
                        <Checkbox onChange={handleCheckboxChange}>
                          <Typography.Text className='normalTypo'>
                            {messageTranslator("alerts.update.checkbox.label", "alerts")}
                          </Typography.Text>
                          <br />
                          <Typography.Text className='smallTypo'>
                            {messageTranslator("alerts.update.checkbox.description", "alerts")}
                          </Typography.Text>
                        </Checkbox>
                      </Form.Item>
                      {
                        hasErrors && (
                          <Alert
                            message={messageTranslator("commons.error", "commons")}
                            description={hasErrors}
                            type="error"
                            showIcon
                          />
                        )
                      }
                    </Col>
                  </Row>
                </div>
              </Scrollbar>

            </Col>
          </Row>

          <div className="nav-step-wrapper">
            <Row justify="space-between" className="full-width">
              <Col>
                <Button
                  type="primary"
                  onClick={onPrev}
                >
                  <ArrowLeftOutlined className='transformMiroir' /> {messageTranslator("commons.pagination.prev", "commons")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  loading={isLoading}
                  htmlType='submit'
                >
                  {messageTranslator("commons.pagination.next", "commons")} <ArrowRightOutlined className='transformMiroir' />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
