export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";

export const DASHBOARD = "/dashboard";

export const ALERTS = "/alerts";
export const NEW_ALERT = `${ALERTS}/new`;

export const PUBLIC_FOLDER = "/alert";

export const INBOX = `/inbox`;

export const USERS = "/users";
export const UPDATE_USER = `${USERS}/update`;
export const CHANGE_PASSWORD = `${USERS}/change-password`;

export const ROLES = `/roles`;
export const NEW_ROLE = `${ROLES}/new`;
export const UPDATE_ROLE = `${ROLES}/update`;

export const SOURCES = "/sources";
export const NEW_SOURCE = `${SOURCES}/new`;

export const CATEGORIES = "/categories";

export const CONFIG = `/config`;
export const MAIL_CONFIG = `${CONFIG}/mail-config`;
export const MAIL_GROUP = `${CONFIG}/mail-group`;
export const NEW_MAIL_GROUP = `${MAIL_GROUP}/new`;
export const UPDATE_MAIL_GROUP = `${MAIL_GROUP}/update`;
export const GLOBAL_CONFIG = `${CONFIG}/global-config`;
export const SCRAPPING_CONFIG = `${CONFIG}/scrapping-config`;
export const TWITTER_API_CONFIG = `${CONFIG}/twitter-api-config`;
export const TWITTER_CRITERIA_CONFIG = `${CONFIG}/twitter-criteria-config`;

export const REPORTS = "/reports";

export const NOTIFICATONS = "/notifications";
