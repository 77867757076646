import { Col, Row, Spin } from 'antd';
import React from 'react';
import { SentimentCard } from '../SentimentCard';

export const SentimentStats = ({ unit, feedbacks, isLoading }) => {

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16,16]}>

        <Col xs={24} md={8}>
          <SentimentCard
            colors={{
              growth: '#21D59B',
              steady: '#808080',
              decay: '#c41717'
            }}
            lineColor="#FF0000"
            unit={unit}
            label="stats.sentiment.negative"
            feedbackUnit={feedbacks?.unit}
            data={feedbacks?.results.negative}
          />
        </Col>

        <Col xs={24} md={8}>
          <SentimentCard
            colors={{
              growth: '#21D59B',
              steady: '#808080',
              decay: '#c41717'
            }}
            lineColor="#808080"
            unit={unit}
            label="stats.sentiment.neutral"
            feedbackUnit={feedbacks?.unit}
            data={feedbacks?.results.neutral}
          />
        </Col>

        <Col xs={24} md={8}>
          <SentimentCard
            colors={{
              growth: '#21D59B',
              steady: '#808080',
              decay: '#c41717'
            }}
            lineColor="#90EACD"
            unit={unit}
            label="stats.sentiment.positive"
            feedbackUnit={feedbacks?.unit}
            data={feedbacks?.results.positive}
          />
        </Col>

      </Row>
    </Spin>
  )
}
