import { EMAIL_GROUP_CREATE, EMAIL_GROUP_CREATE_ERROR, EMAIL_GROUP_CREATE_RESET, EMAIL_GROUP_CREATE_SUCCESS, EMAIL_GROUP_DELETE, EMAIL_GROUP_DELETE_ERROR, EMAIL_GROUP_DELETE_RESET, EMAIL_GROUP_DELETE_SUCCESS, EMAIL_GROUP_GET, EMAIL_GROUP_GET_ERROR, EMAIL_GROUP_GET_ONE, EMAIL_GROUP_GET_ONE_ERROR, EMAIL_GROUP_GET_ONE_SUCCESS, EMAIL_GROUP_GET_SUCCESS, EMAIL_GROUP_PARSE_FILE, EMAIL_GROUP_PARSE_FILE_ERROR, EMAIL_GROUP_PARSE_FILE_RESET, EMAIL_GROUP_PARSE_FILE_SUCCESS, EMAIL_GROUP_UPDATE, EMAIL_GROUP_UPDATE_ERROR, EMAIL_GROUP_UPDATE_RESET, EMAIL_GROUP_UPDATE_SUCCESS } from "./types";

// * Create email group
export const createEmailGroup = (group) => ({
  type: EMAIL_GROUP_CREATE,
  payload: group
});

export const createEmailGroupSuccess = (newGroup) => ({
  type: EMAIL_GROUP_CREATE_SUCCESS,
  payload: newGroup
});

export const createEmailGroupError = (errors) => ({
  type: EMAIL_GROUP_CREATE_ERROR,
  payload: errors
});

export const createEmailGroupReset = () => ({
  type: EMAIL_GROUP_CREATE_RESET
});

// * Get email groups
export const getEmailGroups = (params) => ({
  type: EMAIL_GROUP_GET,
  payload: params
});

export const getEmailGroupsSuccess = (groups) => ({
  type: EMAIL_GROUP_GET_SUCCESS,
  payload: groups
});

export const getEmailGroupsError = (errors) => ({
  type: EMAIL_GROUP_GET_ERROR,
  payload: errors
});

// * Get email group
export const getEmailGroupById = (id) => ({
  type: EMAIL_GROUP_GET_ONE,
  payload: id
});

export const getEmailGroupByIdSuccess = (group) => ({
  type: EMAIL_GROUP_GET_ONE_SUCCESS,
  payload: group
});

export const getEmailGroupByIdError = (error) => ({
  type: EMAIL_GROUP_GET_ONE_ERROR,
  payload: error
});

// * Update email group
export const updateEmailGroup = (group) => ({
  type: EMAIL_GROUP_UPDATE,
  payload: group
});

export const updateEmailGroupSuccess = (newGroup) => ({
  type: EMAIL_GROUP_UPDATE_SUCCESS,
  payload: newGroup
});

export const updateEmailGroupError = (errors) => ({
  type: EMAIL_GROUP_UPDATE_ERROR,
  payload: errors
});

export const updateEmailGroupReset = () => ({
  type: EMAIL_GROUP_UPDATE_RESET
});

// * Delete email group
export const deleteEmailGroup = (id) => ({
  type: EMAIL_GROUP_DELETE,
  payload: id
});

export const deleteEmailGroupSuccess = () => ({
  type: EMAIL_GROUP_DELETE_SUCCESS
});

export const deleteEmailGroupError = (errors) => ({
  type: EMAIL_GROUP_DELETE_ERROR,
  payload: errors
});

export const deleteEmailGroupReset = () => ({
  type: EMAIL_GROUP_DELETE_RESET
});

// * Parse contacts file
export const parseContactsFile = (fileIRI) => ({
  type: EMAIL_GROUP_PARSE_FILE,
  payload: fileIRI
});

export const parseContactsFileSuccess = (parsedData) => ({
  type: EMAIL_GROUP_PARSE_FILE_SUCCESS,
  payload: parsedData
});

export const parseContactsFileError = (error) => ({
  type: EMAIL_GROUP_PARSE_FILE_ERROR,
  payload: error
});

export const parseContactsFileReset = () => ({
  type: EMAIL_GROUP_PARSE_FILE_RESET
});
