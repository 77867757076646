import {
  CONFIG_CREATE,
  CONFIG_CREATE_ERROR,
  CONFIG_CREATE_RESET,
  CONFIG_CREATE_SUCCESS,
  CONFIG_GET,
  CONFIG_GET_ERROR,
  CONFIG_GET_SUCCESS,
  CONFIG_UPDATE,
  CONFIG_UPDATE_ERROR,
  CONFIG_UPDATE_RESET,
  CONFIG_UPDATE_SUCCESS,
  EMAIL_CONFIG_SEND_TEST,
  EMAIL_CONFIG_SEND_TEST_ERROR,
  EMAIL_CONFIG_SEND_TEST_RESET,
  EMAIL_CONFIG_SEND_TEST_SUCCESS,
  GLOABL_CONFIG_LOAD_SUCCESS,
} from "./types";

export const configInitialState = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null,
  },
  globalConfig: {
    data: null,
  },
  config: {
    data: null,
    isLoading: false,
    errors: null,
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null,
  },
  testEmail: {
    isLoading: false,
    isSent: false,
    errors: null,
  },
};

export const configReducer = (state = configInitialState, action) => {
  switch (action.type) {
    // * Create email config
    case CONFIG_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null,
        },
      };

    case CONFIG_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
        },
      };

    case CONFIG_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload,
        },
      };

    case CONFIG_CREATE_RESET:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: false,
          errors: null,
        },
      };

    // * Get email config
    case CONFIG_GET:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: true,
          errors: null,
        },
      };

    case CONFIG_GET_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          data: action.payload,
        },
      };

    case CONFIG_GET_ERROR:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Update config
    case CONFIG_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
          errors: null,
        },
      };

    case CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true,
        },
        config: {
          ...state.config,
          data: action.payload,
        },
      };

    case CONFIG_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload,
        },
      };

    case CONFIG_UPDATE_RESET:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null,
        },
      };

    // * Send test email
    case EMAIL_CONFIG_SEND_TEST:
      return {
        ...state,
        testEmail: {
          ...state.testEmail,
          isLoading: true,
          errors: null,
        },
      };

    case EMAIL_CONFIG_SEND_TEST_SUCCESS:
      return {
        ...state,
        testEmail: {
          ...state.testEmail,
          isLoading: false,
          isSent: true,
          errors: null,
        },
      };

    case EMAIL_CONFIG_SEND_TEST_ERROR:
      return {
        ...state,
        testEmail: {
          ...state.testEmail,
          isLoading: false,
          errors: action.payload,
        },
      };

    case EMAIL_CONFIG_SEND_TEST_RESET:
      return {
        ...state,
        testEmail: {
          ...state.testEmail,
          isSent: false,
          errors: null,
        },
      };

    case GLOABL_CONFIG_LOAD_SUCCESS:
      return {
        ...state,
        globalConfig: {
          ...state.globalConfig,
          data: action.payload,
        },
      };

    default:
      return state;
  }
};
