import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR, FORGET_PASSWORD_RESET, FORGET_PASSWORD_SUCCESS, RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_RESET, RESET_PASSWORD_SUCCESS } from "./types";

// * Reset password
export const sendResetRequest = (email) => ({
  type: FORGET_PASSWORD,
  payload: email
});

export const sendResetRequestSuccess = () => ({
  type: FORGET_PASSWORD_SUCCESS
});

export const sendResetRequestError = (error) => ({
  type: FORGET_PASSWORD_ERROR,
  payload: error
});

export const sendResetRequestReset = () => ({
  type: FORGET_PASSWORD_RESET
});

// * Reset password
export const resetPassword = (params) => ({
  type: RESET_PASSWORD,
  payload: params
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
});

export const resetPasswordError = (error) => ({
  type: RESET_PASSWORD_ERROR,
  payload: error
});

export const resetPasswordReset = () => ({
  type: RESET_PASSWORD_RESET
});
