export const authTranslations = {
  ar: {
    "auth.login.backToHomePage": "الرجوع للصفحة الرئيسية",
    "auth.login.welcomeToFolder": "مرحباً بك في",
    "auth.login.signToContinue": "يرجى تسجيل الدخول للمتابعة",
    "auth.login.loginText": "تسجيل الدخول",
    "auth.login.emailPlaceholder": "إسم المستخدم أو البريد الإلكتروني",
    "auth.login.passwordPlaceholder": "كلمة المرور",
    "auth.login.warn.enterLogin": "من فضلك أدخل إسم المستخدم أو بريدك الإلكتروني!",
    "auth.login.warn.enterPassword": "من فضلك أدخل كلمة المرور !",
    "auth.login.rememberMe": "تذكرني",
    "auth.login.forgetPassword": "نسيت كلمة المرور؟",
    "auth.login.submitText": "إستمرار",

    "auth.forgot.form.title": "استعادة كلمة المرور",
    "auth.forgot.form.email.placeholder": "البريد الإلكتروني",
    "auth.forgot.form.send.text": "ارسال",
    "auth.forgot.form.back.text": "الرجوع لصفحة الدخول",
    "auth.forgot.form.success.message": "لقد تم إرسال رابط إعادة تعيين كلمة المرور لبريدك الإلكتروني",
    "auth.forgot.form.resent.text": "إعادة إرسال رابط إعادة تعيين كلمة المرور",

    "auth.reset.form.title": "استعادة كلمة المرور",
    "auth.reset.form.subtitle": "قم بكتابة كلمة المرور الجديدة الخاصة بك",
    "auth.reset.form.password.new.placeholder": "كلمة المرور الجديدة",
    "auth.reset.form.password.confirm.placeholder": "تأكيد كلمة المرور الجديدة",
    "auth.reset.form.password.min.length": "كلمة العبور يجب أن لا تقل عن 8 أحرف !",
    "auth.reset.form.passwordsNotMatch": "كلمتي العبور غير متطابقتين !",

    "auth.reset.form.error.noToken": "انقر هنا للحصول على رابط إعادة التعيين",

    "auth.reset.form.login.success": "تمت إعادة تعيين كلمة المرور الخاصة بك بنجاح"
  },
  en: {
    "auth.login.backToHomePage": "Back to home page",
    "auth.login.welcomeToFolder": "Welcome to",
    "auth.login.signToContinue": "Please login to continue",
    "auth.login.loginText": "Login",
    "auth.login.emailPlaceholder": "Username or Email",
    "auth.login.passwordPlaceholder": "Password",
    "auth.login.warn.enterLogin": "Please enter your username or your email!",
    "auth.login.warn.enterPassword": "Please enter your password!",
    "auth.login.rememberMe": "Remember me",
    "auth.login.forgetPassword": "Forgot your password?",
    "auth.login.submitText": "Continue",

    "auth.forgot.form.title": "Restore password",
    "auth.forgot.form.email.placeholder": "Email",
    "auth.forgot.form.send.text": "Send",
    "auth.forgot.form.back.text": "Back to login page",
    "auth.forgot.form.success.message": "A password reset link has been sent to your email",
    "auth.forgot.form.resent.text": "Resend password reset link",

    "auth.reset.form.title": "Restore password",
    "auth.reset.form.subtitle": "Type your new password",
    "auth.reset.form.password.new.placeholder": "New password",
    "auth.reset.form.password.confirm.placeholder": "Confirm the new password",
    "auth.reset.form.password.min.length": "Minimum length 8 characters!",
    "auth.reset.form.passwordsNotMatch": "Passwords doesn't match!",

    "auth.reset.form.error.noToken": "Click here to get a reset link",

    "auth.reset.form.login.success": "Your password has been successful reset"
  }
};