import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LOGO_PATH } from '../../utils/constants';

export const Logo = ({ hasLink = false }) => {

  const { data } = useSelector(state => state.config.globalConfig);

  const [logo, setLogo] = useState(LOGO_PATH);

  useEffect(() => {
    setLogo(() => {
      if (!data?.values.logo) return LOGO_PATH;
      return `${process.env.REACT_APP_BASE_URL}${data.values.logo.contentUrl}`
    });
  }, [data]);

  return (
    <div className="logo-wrapper">
      {
        hasLink ? (
          <Link to="/">
            <img src={logo} alt='logo' />
          </Link>
        ) : (
          <img src={logo} alt='logo' />
        )
      }

    </div>
  );
};
