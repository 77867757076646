import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker, Input } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { ISO_DATE } from '../../../../../utils/moment-utils';
import './styles.less';

export const MultiDatesPicker = ({ onDateChange }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [dates, setDates] = useState([]);

  useEffect(() => {
    onDateChange(dates);
  }, [dates, onDateChange]);

  const onDatePickerChange = (value) => setDates(prev => ([
    ...prev,
    moment(value).format(ISO_DATE)
  ]));

  const disableSelectedDate = (date) => dates.includes(moment(date).format(ISO_DATE));

  const handleRemoveDate = (date) => setDates(prev => ([
    ...prev.filter(d => d !== date)
  ]));

  const renderTagDate = (dates) => dates.map((date, index) => (
    <span
      key={date}
      className="multi-dates-tag"
    >
      <CloseCircleFilled
        className="multi-dates-remove-icon"
        onClick={() => handleRemoveDate(date)}
      />
      {date}
    </span>
  ));

  return (
    <div className="multi-dates-wrapper">
      <div className="input-imitater">
        <div className="date-tags-wrapper">
          {renderTagDate(dates)}
          <div className="input-date-wrapper">
            <Input
              placeholder={messageTranslator("reports.view.modal.schedule.multiDates.placeholder", "reports")}
            />
            <DatePicker
              onChange={onDatePickerChange}
              disabledDate={disableSelectedDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
