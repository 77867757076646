import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ConfigContext } from '../../../../context/config';
import './styles.less';

export const NewsFooter = () => {

  const { messageTranslator } = useContext(ConfigContext);

  const { config: { globalConfig: { data: globalConfig } } } = useSelector(state => state);

  return (
    <Footer>
      <Divider></Divider>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
          <Row justify='space-between' align='middle'>
            <Col>
              <Typography.Text strong>{messageTranslator("routes.footer.copyrights", "routes")} {moment().format("YYYY")} ©.</Typography.Text>
            </Col>
            {
              globalConfig && (
                <Col>
                  <Space size={5}>
                    { !!globalConfig.values.facebook_url && <a href={globalConfig.values.facebook_url} rel="noreferrer" target="_blank"><FacebookOutlined /></a> }
                    { !!globalConfig.values.twitter_url && <a href={globalConfig.values.twitter_url} rel="noreferrer" target="_blank"><TwitterOutlined /></a> }
                    { !!globalConfig.values.linkedin_url && <a href={globalConfig.values.linkedin_url} rel="noreferrer" target="_blank"><LinkedinOutlined /></a> }
                    { !!globalConfig.values.instagram_url && <a href={globalConfig.values.instagram_url} rel="noreferrer" target="_blank"><InstagramOutlined /></a> }
                    { !!globalConfig.values.youtube_url && <a href={globalConfig.values.youtube_url} rel="noreferrer" target="_blank"><YoutubeOutlined /></a> }
                  </Space>
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    </Footer>
  );
};
