import { Button, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { PermissionsHandler } from '../../../components/PermissionHandler';
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { SearchBar } from '../../../components/SearchBar';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { getRoles } from '../../../store/roles/actions';
import { createUser, deleteUser, getUsers, resetIsCreated, resetUserDeleted, updateUser, userResetUpdated } from '../../../store/users/actions';
import { CAN_CREATE } from '../../../utils/permissions-utils';
import { getPageSearchQueries, pageChangeHandler, searchHandler } from '../../../utils/utils';
import { UserModal } from './components/UserModal';
import { UsersList } from './components/UsersList';
import { AuthContext, checkToken } from '../../../context/Auth';
import { logoutAction } from '../../../store/logout';

export const ListUsers = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const [isModalVisible, setModalVisible] = useState(() => false);
  const [selectedUser, setSelectedUser] = useState(() => null);
  const [page, setPage] = useState(() => 1);
  const [searchTerms, setSearchTerms] = useState({});

  const dispatch = useDispatch();

  const searchParams = useQuery(history);

  const {
    users: {
      me: { data: meData },
      create: { isCreated, ...restCreate },
      listUsers: { totalItems, isLoading, data },
      delete: { isLoading: deleteLoading, errors: deleteErrors },
      update: { isUpdated, ...restUpdate }
    },
    roles: { list }
  } = useSelector(state => state);

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    dispatch(getUsers(terms));
    dispatch(getRoles({}));
  }, [dispatch, searchParams]);

  useEffect(() => {
    return () => dispatch(resetUserDeleted());
  }, [dispatch]);

  useEffect(() => {
    if (isCreated || isUpdated) {
      dispatch(resetIsCreated());
      dispatch(userResetUpdated());
      handleCloseModal();
    }
  }, [isCreated, isUpdated, dispatch]);

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handleSearch = (terms) => searchHandler(history, terms);

  const handleOpenModal = () => setModalVisible(true);

  const handleCreateUser = (user) => dispatch(
    selectedUser ? updateUser({
      ...user,
      id: selectedUser.id
    }) : createUser(user)
  );

  const handleCloseModal = () => {
    setSelectedUser(null);
    setModalVisible(false);
  };

  const handleUserDelete = (id) => dispatch(deleteUser(id));

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  // * Update
  const handleSelectedUser = (user) => {
    setSelectedUser(user);
    handleOpenModal();
  };

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={restCreate.isLoading || isLoading || deleteLoading || restUpdate.isLoading}>
        <PermissionsHandler
          resourceName="User"
          blocPermission={CAN_CREATE}
          userRole={meData?.role}
        >
          <div className='btn-wrapper'>
            <Button type="primary" onClick={handleOpenModal}>
              {messageTranslator("users.create.button.text", "users")}
            </Button>
          </div>
        </PermissionsHandler>

        <SearchBar
          label={route.title}
          history={history}
          page={page}
          searchTerms={searchTerms}
          onSearchTrigger={handleSearch}
        />

        <UsersList
          totalItems={totalItems}
          data={data}
          currentPage={page}
          role={meData?.role}
          onSelectUser={handleSelectedUser}
          onPageChange={handlePageChange}
          deleteLoading={deleteLoading}
          deleteErrors={deleteErrors}
          onDeleteUser={handleUserDelete}
        />
        <UserModal
          visible={isModalVisible}
          roles={list}
          userToUpdate={selectedUser}
          createUser={restCreate}
          updateUser={restUpdate}
          onOk={handleCreateUser}
          onCancel={handleCloseModal}
        />
      </Spin>
    </PageSkeleton>
  );
};
