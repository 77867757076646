export const permissionsTranslations = {
  ar: {
    ROLE_DEFAULT_USER: 'مستخدم إفتراضي',
    ROLE_SUPER_ADMIN: 'مشرف متميز',

    Permission: 'الصلاحيات',
    Role: 'ملف الصلاحيات',
    User: 'المستخدمين',
    AlertItemResult: 'نتائج التنبيهات',
    Alert: 'التنبيهات',
    Tag: 'الكلمات المفتاحية',
    Source: 'المصادر',
    Category: 'التصنيفات',
    Folder: 'الملفات الصحفية',
    Config: 'الإعدادات',
    MailGroup: 'المجموعات البريدية',
    FolderReport: 'تقارير الملفات الصحفية',

    CAN_PUT: 'صلاحية التعديل',
    CAN_CREATE: 'صلاحية الإنشاء',
    CAN_VIEW: 'صلاحية الرؤية',
    CAN_LIST: 'صلاحية الجدولة',
    CAN_DELETE: 'صلاحية الحذف',

    OWN: 'الخاصة بالمستخدم'
  },
  en: {
    ROLE_DEFAULT_USER: 'Default user',
    ROLE_SUPER_ADMIN: 'Super admin',

    Permission: 'Permissions',
    Role: 'Roles',
    User: 'Users',
    AlertItemResult: 'Alert item results',
    Alert: 'Alerts',
    Tag: 'Tags',
    Source: 'Sources',
    Category: 'Categories',
    Folder: 'News folder',
    Config: 'Configurations',
    MailGroup: 'Email groups',
    FolderReport: 'News folders reports',

    CAN_PUT: 'Can update',
    CAN_CREATE: 'Can create',
    CAN_VIEW: 'Can view',
    CAN_LIST: 'Can list',
    CAN_DELETE: 'Can delete',

    OWN: 'Own'
  }
};
