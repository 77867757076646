import { Form, message, Select, Tag } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ConfigContext } from '../../context/config';
import { validateEmail } from '../../utils/utils';

export const EmailGroupSelector = ({
  formRef,
  fieldName,
  emailGroupList,
  onChange
}) => {


  const { setFieldsValue, setFields, getFieldValue } = formRef;

  const multiSelectRef = useRef();

  const { messageTranslator } = useContext(ConfigContext);

  const [emailGroups, setEmailGroups] = useState([]);

  useEffect(() => {
    onChange(emailGroups);
  }, [emailGroups, onChange]);

  const tagRender = ({ label, value }) => {
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        style={{ marginRight: 3 }}
        closable
        onClose={(e) => handleRemoveEmail(label)}
      >
        {label}
      </Tag>
    );
  }

  const handleKeyPress = (event) => {
    if (event.key !== "Enter") return;
    const item = emailGroupList.find(el => el.name.toLowerCase().includes(event.target.value.toLowerCase()));
    if (item) return;
    const values = getFieldValue([`${fieldName}`]) || [];
    if (!validateEmail(event.target.value)) {
      message.error(messageTranslator("commons.validEmail.message", "commons"));
      setFields([{
        name: fieldName,
        errors: [messageTranslator("commons.validEmail.message", "commons")]
      }]);
      return;
    }
    if (isDuplicatedEmail(event.target.value, values)) {
      message.error(messageTranslator("reports.view.send.modal.email.duplicated", "reports"));
      setFields([{
        name: fieldName,
        errors: [messageTranslator("reports.view.send.modal.email.duplicated", "reports")]
      }]);
      return;
    }
    setFields([{
        name: fieldName,
        value: [...values, event.target.value],
        errors: []
      }]);
    multiSelectRef?.current?.blur();
    multiSelectRef?.current?.focus();
  }

  const isDuplicatedEmail = (email, emailsList = []) => emailsList.includes(email);

  const handleSelectedOption = (key, option) => {
    setEmailGroups(prev => ([...prev, option.iri]));
  }

  const handleDeselectOption = (key, option) => {
    setEmailGroups(prev => prev.filter(iri => iri !== option.iri));
  };

  const removeSelectedGroups = (list, selectedList) => list.map(group => {
    if (selectedList.includes(group.iri)) return false;
    return { value: group.name, iri: group['@id'] };
  })

  const handleRemoveEmail = (email) => {
    if (!validateEmail(email)) {
      const item = emailGroupList.find(el => el.name === email);
      if (item) setEmailGroups(prev => prev.filter(el => el !== item['@id']));
    }
    setFieldsValue({
      [fieldName]: getFieldValue(fieldName).filter(name => name !== email)
    });
  }

  return (
    <Form.Item
      name={fieldName}
      label={messageTranslator("reports.view.modal.schedule.recipients.label", "reports")}
      rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
    >
      <Select
        ref={multiSelectRef}
        mode="multiple"
        placeholder={messageTranslator("alerts.add.stepper.stepThree.tag.placeholder", "alerts")}
        showArrow
        tagRender={tagRender}
        style={{ width: '100%' }}
        options={removeSelectedGroups(emailGroupList, emailGroups)}
        notFoundContent={null}
        onSelect={handleSelectedOption}
        onDeselect={handleDeselectOption}
        onInputKeyDown={handleKeyPress}
      />
    </Form.Item>
  )
}
