import { ArrowLeftOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Col, Row, Select } from 'antd';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../context/config';
import { CONFIG_SWITCH_LANGUAGE } from '../../context/config/dispatch-config-state';
import { advancedSearchLanguages } from '../../utils/data/languages';
import { Logo } from '../Logo';

const { Option } = Select;

export const AuthRightBlock = () => {

  const { data } = useSelector(state => state.config.globalConfig);

  const { configState, configDispatcher, messageTranslator } = useContext(ConfigContext);

  const handleSwitchLanguage = (value) => configDispatcher(CONFIG_SWITCH_LANGUAGE, value);

  return (
    <Col xs={{ span: 24 }} lg={{ span: 14 }}>
      <div className="intro-wrapper">
        <div className="top-wrapper">
          <Row justify="space-between" align="bottom">
            <Col>
              <Link to="/">
                <ArrowLeftOutlined className='transformMiroir' /> {
                  messageTranslator("auth.login.backToHomePage", "auth")
                }
              </Link>
            </Col>
            <Col>
              <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} 
                id="select-language"
                defaultValue={configState.language}
                className="select-language"
                onChange={handleSwitchLanguage}
                value={configState.language}
              >
                {
                  advancedSearchLanguages.map(lang => (
                    <Option key={lang.value} value={lang.value}>{messageTranslator(lang.label, "constants")}</Option>
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>

        <div className="intro-content">
          <Logo />
          <h1>{messageTranslator("auth.login.welcomeToFolder", "auth")} {data?.values.websiteName || ""}</h1>
          <span className="slogan">{messageTranslator("auth.login.signToContinue", "auth")}</span>
        </div>
      </div>
    </Col>
  );
};
