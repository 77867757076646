import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../context/config';
import { sentimentOptions } from '../../utils/constants';
import { ISO_DATE, renderRanges } from '../../utils/moment-utils';
import { translateArrayOfObjects } from '../../utils/utils';
import './styles.less';

const { RangePicker } = DatePicker;

/**
 * ### Description:
 * SearchBar is a component meant to search for any keyword in any entity.
 * 
 * ### Possible Props:
 * 
 * **1. label:** The label for the search bar based on what kind of text you want to display in any page.
 * 
 * **2. extraLabel:** It's possible that you want to add an extra text to the previous one.
 * 
 * **3. icon:** Adds an icon next to the search bar. If the icon is true, that means you have filters to add a specific serach terms.
 * 
 * **4. page:** The current page of your pagination.
 * 
 * **5. inMenu:** Represents the location of the searchBar as a serachBar or just a search field inside a menu.
 * 
 * **6. onSearchTrigger:** A callback function triggered when user press enter in the search input. It returns the search terms.
 */
export const SearchBar = ({
  searchTerms,
  label = "",
  extraLabel,
  inMenu = false,
  icon = false,
  onSearchTrigger
}) => {

  const { configState, messageTranslator } = useContext(ConfigContext);

  const [isAdvancedSearch, setAdvancedSearch] = useState(() => false);
  const handleAdvancedSearchState = () => setAdvancedSearch(prevState => !prevState);

  const [form] = Form.useForm();
  const { resetFields, getFieldValue, setFieldsValue } = form;

  useEffect(() => {
    const { page, ...terms } = searchTerms;
    if (terms && Object.keys(terms).length) {
      if (terms.sentiments || terms.dates) setAdvancedSearch(true);
      setFieldsValue({
        search: searchTerms.search || undefined,
        sentiments: searchTerms.sentiments || [],
        dates: searchTerms.from && searchTerms.to ? [
          moment(searchTerms.from),
          moment(searchTerms.to)
        ] : null
      });
    }
  }, [searchTerms, setFieldsValue]);

  const handleResetForm = () => {
    resetFields();
    onSearchTrigger({});
    setAdvancedSearch(false);
  };

  const handleSearch = (event) => {
    if (!event.target.value) onSearchTrigger({});
  }

  const handleSubmitSearch = () => {
    onSearchTrigger({
      search: getFieldValue('search'),
      page: 1
    })
  };

  const handleAdvancedSearch = ({ dates, ...values }) => {
    // if (dates || values.sentiments) {
      
    // }
    if (dates) {
      values.from = moment(dates[0]).format(ISO_DATE);
      values.to = moment(dates[1]).format(ISO_DATE);
    }
    onSearchTrigger({
      ...values,
      page: 1
    });
  };

  return (
    <div className={`${isAdvancedSearch ? "searech-wrapper active" : "searech-wrapper"} ${inMenu ? "in-menu" : ""}`}>
      <Form
        layout='vertical'
        size='small'
        className='filters-form'
        onFinish={handleAdvancedSearch}
        form={form}
      >
        <Row justify="space-between" align="middle">
          <Col className='search-title'>{messageTranslator(label, "routes")} {!!extraLabel && extraLabel}</Col>
          <Col>
            <Form.Item
              name="search"
            >
              <Input
                size='small'
                placeholder={messageTranslator("commons.searchBar.placeholder", "commons")}
                suffix={<SearchOutlined onClick={handleSubmitSearch} />}
                onChange={handleSearch}
                onPressEnter={handleSubmitSearch}
                allowClear
              />
            </Form.Item>
          </Col>
          {
            icon && <Col className='icon-filters'>
              <FilterOutlined onClick={handleAdvancedSearchState} className={isAdvancedSearch ? "active" : ""} />
            </Col>
          }
        </Row>
        {
          isAdvancedSearch && (
            <Card className="filer-source-wrapper">
              <Row gutter={24}>
                <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 6, offset: 1 }}>
                  <Form.Item
                    label={messageTranslator("commons.date", "commons")}
                    name="dates"
                  >
                    <RangePicker
                    ranges={renderRanges(messageTranslator, configState.language)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 10 }} xl={{ span: 6, offset: 2 }}>
                  <Form.Item
                    label={messageTranslator("constants.sentiments.label", "constants")}
                    name="sentiments"
                  >
                    <Checkbox.Group options={translateArrayOfObjects(sentimentOptions, 'label', messageTranslator, "constants")} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row justify="end">
                <Col>
                  <span className='btn-wrapper white'>
                    <Button htmlType="button" onClick={handleResetForm}>
                      {messageTranslator("commons.reset", "commons")}
                    </Button>
                  </span>
                  <span className='btn-wrapper'>
                    <Button
                      type="primary"
                      htmlType='submit'
                    >
                      {messageTranslator("commons.apply", "commons")}
                    </Button>
                  </span>
                </Col>
              </Row>
            </Card>
          )
        }
      </Form>
    </div>
  );
};
