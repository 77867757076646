import { Col, Layout, Row } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppContent } from '../../components/AppContent';
import { NewsFooter } from './components/NewsFooter';
import { NewsHeader } from './components/NewsHeader';
import { logoutAction } from '../../store/logout';
import { checkToken } from '../../context/Auth';
import { AuthContext } from '../../context/Auth';

export const AnonymNews = ({ children }) => {

  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  return (
    <Layout className="full-height main-layout">
      <NewsHeader />
      <Layout>
        <AppContent>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }}>
              {children}
            </Col>
          </Row>
        </AppContent>
      </Layout>
      <NewsFooter />
    </Layout>
  );
};
