import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Divider, Form, Input, Popconfirm, Row, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../context/config';
import { mergeErrors } from '../../utils/errors-utils';
import { validateEmail } from '../../utils/utils';
import "./email-group-form.less";

export const EmailGroupForm = ({
  title,
  create,
  update,
  group,
  parsedData,
  loadingDelete,
  cancelButtonText,
  onImportFile,
  onSubmitGroup,
  onDeleteGroup
}) => {

  const { isLoading: loadingCreate, errors: createErrors } = create || {};
  const { isLoading: loadingUpdate, errors: updateErrors } = update || {};
  const { isLoading: loadingGroup, data: emailGroup } = group || {};
  const { isLoading: loadingParsedData, data: parsedEmails } = parsedData || {};

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();
  const { getFieldValue, setFields, resetFields, getFieldsValue, setFieldsValue } = form;
  const [emails, setEmails] = useState([]);
  const [editable, setEditable] = useState(null);
  const [newEmail, setNewEmail] = useState(null);

  useEffect(() => {
    if (parsedEmails) setEmails(prev => ([
      ...parsedEmails.emails.split(',').filter(email => !prev.includes(email)),
      ...prev
    ]));
  }, [parsedEmails]);

  useEffect(() => {
    if (emailGroup) {
      setFieldsValue({ name: emailGroup.name });
      setEmails(emailGroup.mails);
    }
  }, [emailGroup, setFieldsValue]);

  useEffect(() => {
    if (createErrors) {
      const fields = mergeErrors(getFieldsValue(), createErrors, messageTranslator);
      setFields(fields);
    }
  }, [createErrors, getFieldsValue, messageTranslator, setFields]);

  useEffect(() => {
    if (updateErrors) {
      const fields = mergeErrors(getFieldsValue(), updateErrors, messageTranslator);
      setFields(fields);
    }
  }, [updateErrors, getFieldsValue, messageTranslator, setFields]);

  const handleChangeNewEmail = (event) => setNewEmail(event.target.value);

  const checkDuplicatedEmail = (list, email) => list.includes(email);

  const handleAddMember = () => {
    const email = getFieldValue("email");
    if (!validateEmail(email)) {
      setFields([{
        name: "email",
        value: email,
        errors: [messageTranslator("config.emailGroup.modal.email.invalid", "config")]
      }]);
      return false;
    }
    if (checkDuplicatedEmail(emails, email)) {
      setFields([{
        name: "email",
        value: email,
        errors: [messageTranslator("config.emailGroup.modal.email.duplicated", "config")]
      }]);
      return false;
    }
    else if (email) {
      setEmails(prev => ([email, ...prev]));
      setFields([{
        name: "email",
        errors: []
      }]);
      resetFields(["email"]);
    }
  };

  const handleUpdateEmail = (email) => {
    setEditable(email);
    setNewEmail(email);
  };

  const handleRemoveEmail = (email) => setEmails(prev => prev.filter(_email => _email !== email));

  const handleSaveChanges = () => {
    setEmails(prev => prev.map(_eml => _eml === editable ? newEmail : _eml));
    setEditable(null);
    setNewEmail(null);
  };

  const handleSubmitForm = ({ name }) => {
    if (!emails.length) {
      setFields([{
        name: "email",
        errors: [messageTranslator("config.emailGroup.modal.email.required.min", "config")]
      }]);
      return false;
    }
    onSubmitGroup({
      name,
      mails: emails.map(em => ({ mail: em }))
    });
  };

  const handleReset = () => {
    resetFields();
    setEmails([]);
  };

  return (
    <Card
      title={title}
      loading={!!loadingGroup || !!loadingParsedData || !!loadingDelete}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitForm}
      >
        <Row gutter={20} align="middle">
          <Col flex="140px">
            <Avatar size={105} icon={<TeamOutlined />} className="avatar-group-mail" />
          </Col>
          <Col flex="auto">
            <Form.Item
              name="name"
              label={messageTranslator("config.emailGroup.modal.name.label", "config")}
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Form.Item
          name="email"
          label={messageTranslator("config.emailGroup.modal.emails.label", "config")}
        >
          <Row gutter={20}>
            <Col span={18}>
              <Input />
            </Col>
            <Col span={6}>
              <span key="cancel" className='btn-wrapper blue'>
                <Button type="primary" onClick={handleAddMember} className="add-member-btn">
                  {messageTranslator("commons.add", "commons")}
                </Button>
              </span>
            </Col>
          </Row>
        </Form.Item>

        <Row>
          <Col span={24}>
            <Button
              icon={<PlusOutlined />}
              onClick={onImportFile}
              className="import-btn"
            >
              {messageTranslator("config.emailGroup.create.emails.import.button", "config")}
            </Button>
          </Col>
        </Row>

        {
          emails.length ? emails.map(email => (
            <Row key={email} gutter={[5, 20]} className="group-mail-item">
              <Col md={2}>
                <UserOutlined />
              </Col>
              <Col md={19} className="text-left">
                {
                  editable === email ? (
                    <>
                      <Input value={newEmail} onChange={handleChangeNewEmail} />
                    </>
                  ) : (
                    <Typography>{email}</Typography>
                  )
                }
              </Col>
              {
                editable === email ? (
                  <Col md={1}>
                    <CheckOutlined onClick={handleSaveChanges} />
                  </Col>
                ) : (
                  <>
                    <Col md={1}>
                      <EditOutlined onClick={() => handleUpdateEmail(email)} />
                    </Col>
                    <Col md={1}>
                      <CloseOutlined onClick={() => handleRemoveEmail(email)} />
                    </Col>
                  </>
                )
              }
            </Row >
          )) : (
            <Space direction="vertical" className='email-group-empty'>
              <UserOutlined />
              <Typography.Text type="secondary">{messageTranslator("config.emailGroup.modal.empty.text", "config")}</Typography.Text>
            </Space>
          )
        }

        <Row justify="end" style={{ marginTop: "30px" }}>
          <Col>
            <Popconfirm
              title={messageTranslator(onDeleteGroup ? "config.emailGroup.delete.confirm.text" : "config.emailGroup.reset.confirm.text", "config")}
              onConfirm={onDeleteGroup || handleReset}
              okText={messageTranslator("commons.delete.confirm", "commons")}
              cancelText={messageTranslator("commons.cancel", "commons")}
            >
              <span key="cancel" className='btn-wrapper white'>
                <Button
                  key="back"
                  // onClick={handleReset}
                >
                  {cancelButtonText}
                </Button>
              </span>
            </Popconfirm>
            <span key="ok" className='btn-wrapper'>
              <Button
                key="submit"
                type="primary"
                loading={loadingCreate || loadingUpdate}
                htmlType="submit"
              >
                {messageTranslator("commons.save", "commons")}
              </Button>
            </span>
          </Col>
        </Row>

      </Form>

    </Card>
  );
};
