import { BarsOutlined, ClockCircleFilled, DeleteFilled, EditFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Popconfirm, Row, Space, Typography, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../../context/config';
import { tablePaginationOptions } from '../../../../../utils/utils';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { CAN_CREATE, CAN_PUT, CAN_DELETE } from '../../../../../utils/permissions-utils';
import { patchMomentArabicLocal, patchMomentEnglishLocal } from "../../../../../utils/moment-utils.js";
import moment from 'moment';
import "./reportsList.less";


export const Reports = ({
  history,
  reportsList: { data: reports, isLoading,totalItems },
  page,
  user,
  onPageChange,
  onSelectReport,
  onCreateNewReportClick,
  onDeleteReport
}) => {

  const { messageTranslator, configState } = useContext(ConfigContext);

  const [, setArabic] = useState(false);

  useEffect(() => {
    if (configState.language === "ar") {
      setArabic(true);
      patchMomentArabicLocal();
    } else {
      setArabic(false);
      patchMomentEnglishLocal();
    } 
  }, [configState.language]);

  const handleNewReportClick = () => onCreateNewReportClick(true);

  const newReportCardContent = {
    type: "new",
    text: messageTranslator("reports.menu.button.addNewReport", "reports"),
    action: handleNewReportClick
  };

  const renderReportCard = (report) => report.type && report.type === "new" ? (
    <PermissionsHandler
      resourceName="FolderReport"
      blocPermission={CAN_CREATE}
      userRole={user?.role}
    >
      <div className="ant-list-item">
        <Card onClick={report.action} className='new-report'>
          <Space direction="vertical" align='center' size={30}>
            <PlusCircleOutlined />
            <Typography.Text type="secondary">
              {report.text}
            </Typography.Text>
          </Space>
        </Card>
      </div>
    </PermissionsHandler>
  ) : (
    <List.Item className='report-item'>
      <Card>
        <Row gutter={[10, 10]} align="middle">
          <Col flex="auto">
            <Typography className='blue-Text'>
              {messageTranslator("reports.list.card.label", "reports")}
            </Typography>
          </Col>
          <Col flex="31px">
            <BarsOutlined className='circle-icon' />
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary" className='title-report'>
              <Link to={`${history.location.pathname}/${report.id}`}>
                {report.name}
              </Link>
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary" className='info'>
              {messageTranslator("reports.list.card.createdAt", "reports")} {moment(report.createdAt).add(3, 'hours').format('DD MMMM YYYY, hh:mm')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary" className='info'>
              {report.description}
            </Typography.Text>
          </Col>
          <Col flex="auto">
            {
              report.scheduled && (
                <Button icon={<ClockCircleFilled />} className="report-btn">
                  {messageTranslator("reports.list.card.scheduled", "reports")}
                </Button>
              )
            }

          </Col>
          <PermissionsHandler
            resourceName="FolderReport"
            blocPermission={CAN_DELETE}
            userRole={user?.role}
          >
            <Col flex="24px">
              <Popconfirm
                title={`${messageTranslator("reports.list.delete.confirm", "reports")} ${report.name}`}
                onConfirm={() => onDeleteReport(report.id)}
                okText={messageTranslator("commons.delete.confirm", "commons")}
                cancelText={messageTranslator("commons.cancel", "commons")}
              >
                <DeleteFilled
                  className='delete-btn'
                />
              </Popconfirm>
            </Col>
          </PermissionsHandler>
          <PermissionsHandler
            resourceName="FolderReport"
            blocPermission={CAN_PUT}
            userRole={user?.role}
          >
            <Col flex="24px">
              <EditFilled onClick={() => onSelectReport(report)} />
            </Col>
          </PermissionsHandler>
        </Row>
      </Card>
    </List.Item>
  );

  return (
    <Spin spinning={isLoading}>
    <div className="reports-wrapper">
      <List
        dataSource={[newReportCardContent, ...reports]}
        renderItem={renderReportCard}
        pagination={tablePaginationOptions({
          totalItems,
          pageSize: 20,
          currentPage: page,
          onPageChange,
          messageTranslator
        })}
      />
    </div>
    </Spin>
  );
};
