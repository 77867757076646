import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Typography } from 'antd';
import { PageSkeleton } from '../../components/PageSkeleton';
import { NotificationsList } from './components';
import { markNotificationsAsSeen } from '../../store/notifications/actions';
import { pageChangeHandler } from '../../utils/utils';
import { AuthContext, checkToken } from '../../context/Auth';
import { logoutAction } from '../../store/logout';

import { ConfigContext } from '../../context/config';

export const AllNotifications = ({ route, history }) => {

  const dispatch = useDispatch();
  
  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const {Title} = Typography;

  const [page, setPage] = useState(1);
  const [searchTerms, ] = useState({});
  const [, setUnseenNotifs] = useState(0);
  const {
      notifications: {
        list,
      }
  } = useSelector(state => state);

  const { data: notifications, totalItems } = list;

  useEffect(() => {
      if(!checkToken()[1]) {
        logout();
        dispatch(logoutAction());
      }
  });

  useEffect(() => {
      setUnseenNotifs(() => notifications?.filter(notif => !notif.seen).length);
  }, [notifications]);

  const handleMarkAsSeen = (id) => dispatch(markNotificationsAsSeen(id));

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Title level={3}>{messageTranslator(route.title, "routes")}</Title>
            </Col>
            <Col span={24}>
            <NotificationsList 
                currentPage={page}
                totalItems={totalItems}
                notifications={notifications}
                onPageChange={handlePageChange}
                onClick={handleMarkAsSeen} 
            />
            </Col>
        </Row>
    </PageSkeleton>
  )
};
