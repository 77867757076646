export const sourcesTranslations = {
  ar: {
    "sources.button.newSource": "إضافة مصدر جديد",
    "sources.modal.title.add": "إضافة مصدر جديد",
    "sources.modal.title.update": "تعديل المصدر",
    "sources.list.siteName": "إسم الموقع",
    "sources.list.sourceUrl": "رابط المصدر",
    "sources.list.socialMedia": "حسابات التواصل",
    "sources.list.sourceImage": "صورة المصدر",
    "sources.list.monitoring": "عدد الرصد",
    "sources.list.activation": "تفعيل",
    "sources.list.confirmEdit": "تعديل المصدر",
    "sources.list.confirmDelete": "حذف المصدر",
    "sources.list.confirmDelete.text": "تأكيد حذف المصدر",
    "sources.emtyText": "لا يوجد مصادر مضافة حالياً",
    "sources.results.emtyText": "لا يوجد نتائج لهذا المصدر حالياً",

    "sources.add.info": "معلومات عامة عن الجهة",
    "sources.add.duplicatedSourceName": "إسم الجهة متواجد حاياً !",
    "sources.add.duplicatedSourceWebsite": "الموقع الإلكتروني متواجد حاياً !",
    "sources.add.name.placeholder": "إسم المصدر",
    "sources.add.url.placeholder": "الموقع الإلكتروني",
    "sources.add.socialMedia": "حسابات التواصل الإجتماعي",
    "sources.add.facebook.placeholder": "حساب فيسبوك",
    "sources.add.twitter.placeholder": "حساب تويتر",
    "sources.add.instagram.placeholder": "حساب إنستغرام",
    "sources.add.youtube.placeholder": "حساب يوتيوب",
    "sources.add.image.title": "صورة المصدر",

    "sources.add.image.required": "الرجاء تحميل صورة المصدر"
  },
  en: {
    "sources.button.newSource": "Add a new source",
    "sources.modal.title.add": "Add a new source",
    "sources.modal.title.update": "Update source",
    "sources.list.siteName": "Site name",
    "sources.list.sourceUrl": "Source url",
    "sources.list.socialMedia": "Social accounts",
    "sources.list.sourceImage": "Source image",
    "sources.list.monitoring": "Monitoring",
    "sources.list.activation": "Activation",
    "sources.list.confirmEdit": "Update source",
    "sources.list.confirmDelete": "Delete source",
    "sources.list.confirmDelete.text": "Confirm deleting source",
    "sources.emtyText": "No added sources currently",
    "sources.results.emtyText": "No results for this source currently",

    "sources.add.info": "Source global informations",
    "sources.add.duplicatedSourceName": "Source name already exists !",
    "sources.add.duplicatedSourceWebsite": "Source website already exists !",
    "sources.add.name.placeholder": "Source name",
    "sources.add.url.placeholder": "Website",
    "sources.add.socialMedia": "Social media accounts",
    "sources.add.facebook.placeholder": "Facebook account",
    "sources.add.twitter.placeholder": "Twitter account",
    "sources.add.instagram.placeholder": "Instagram account",
    "sources.add.youtube.placeholder": "Youtube account",
    "sources.add.image.title": "Source image",

    "sources.add.image.required": "Please upload the source image"
  }
};
