import { Badge, Card, Space, Typography, Spin } from 'antd';
import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from 'chart.js';
import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { ConfigContext } from '../../../../context/config';
import { generateDateNames, generateDaysFromRange } from '../../../../utils/utils';
import { statsSegments } from "../../../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const NewsMonitoringStats = ({ unit, dates, data, isLoading }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
    },
  };

  let transformObjectToData;
  let transformSocialToData;

  if(unit === statsSegments.week) {
    transformObjectToData = (data) => Object.keys(data).map(key => data[key].count);
    transformSocialToData = (data) => Object.keys(data).map(key => data[key]);
  } else {
    transformObjectToData = (data) => Object.keys(data).map(key => data[key].count).reverse();
    transformSocialToData = (data) => Object.keys(data).map(key => data[key]).reverse();
  }

  
  const combineSocialMedia = (medias) => {
    let combinedMedias = {};
    Object.keys(medias).forEach(key => {
      Object.keys(medias[key]).forEach(_key => {
        if (combinedMedias[_key]) combinedMedias[_key] += medias[key][_key].count;
        else combinedMedias[_key] = medias[key][_key].count
      })
    });
    return combinedMedias;
  }

  const { website, ...social } = data ? data.results : {};

  const graphData = {
    labels: dates?.length ? generateDaysFromRange({dates, messageTranslator, unit: data?.unit, number: data?.unit_number}) : generateDateNames(combineSocialMedia(social), unit, messageTranslator),
    datasets: [
      {
        label: "",
        data: website ? transformObjectToData(website) : [],
        backgroundColor: '#29CB97'
      },
      {
        label: '',
        data: social ? transformSocialToData(combineSocialMedia(social)) : [],
        backgroundColor: '#0062FF'
      },
    ],
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        className="monitoring-stats static-card1"
      >
        <Typography.Title level={3}>{messageTranslator("stats.monitoring.card.title", "stats")}</Typography.Title>
        <Space size={30}>
          <Badge color="#0062FF" size={20} text={messageTranslator("stats.monitoring.card.socialMedia.text", "stats")} />
          <Badge color="#29CB97" text={messageTranslator("stats.monitoring.card.newsPaper.text", "stats")} />
        </Space>
        <Bar
          options={options}
          data={graphData}
        />
      </Card>
    </Spin>
  )
}
