import { Card, List, Space, Typography, Spin } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../context/config';

export const PopularTags = ({ keywords, isLoading }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const mapObjectToArray = (data) => {
    if (!data?.results) return [];
    return Object.keys(data.results).map(key => ({ tagName: key, count: data.results[key] }));
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        title={messageTranslator("stats.tags.card.title", "stats")}
        className="static-card"
      >
        <List
          dataSource={mapObjectToArray(keywords)}
          renderItem={(item, index) => (
            <List.Item>
              <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Space>
                  <Typography.Text type='secondary'>
                    {index + 1}
                  </Typography.Text>
                  <Typography.Text type='secondary'>
                    {item.tagName}
                  </Typography.Text>
                </Space>
                <Typography.Text type='secondary'>
                  {item.count}
                </Typography.Text>
              </Space>
            </List.Item>
          )}
        />
      </Card>
    </Spin>
  )
}
