/**
 *
 * @param {object} fieldsValues: The current values of the submitted form.
 * @param {object} errors: The errors returned by the server.
 * @param {function} translator: The translation function.
 * @returns Array of fields values & errors
 */
export const mergeErrors = (fieldsValues, errors, translator) => {
  if (!fieldsValues || !errors) return;
  let fields = [];
  for (let key in errors) {
    fields.push({
      value: fieldsValues[key],
      name: key,
      errors: errors[key].map((error) => translator(error, "errors")),
    });
  }
  return fields;
};

export const parseAxiosError = (error) => {
  // if not response
  if (!error.response) return { general: [error.message] };

  const data = error.response.data;
  if (data.violations) return violationsErrorTransformer(data.violations);
  if (data.detail) return { general: [data.detail.split(":")[-1]] };
  if (Array.isArray(data)) return { general: data };
  return { general: [error.response.data.message] };
};

const violationsErrorTransformer = (violations) => {
  const errors = {};
  violations.forEach(({ propertyPath, message }) => {
    errors[propertyPath]
      ? errors[propertyPath].push(message)
      : (errors[propertyPath] = [message]);
  });
  return errors;
};

export const blockErrors = (errors) => {
  let newErrrorsArray = [];
  for (let key in errors) {
    newErrrorsArray.push(errors[key]);
  }
  return newErrrorsArray;
};
