import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles.less';

export const CustomSegmented = ({ options, onChange, defaultValue }) => {

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const handleButtonClick = (value) => {
    onChange && onChange(value);
    setSelected(value);
  }

  return (
    <div className="custom-segmented-wrapper">
      {
        options.map(option => (
          <Button
            key={option.value}
            className={`custom-segmented-button ${selected && selected === option.value ? 'active' : ''}`}
            onClick={() => handleButtonClick(option.value)}
          >
            {option.label}
          </Button>
        ))
      }
    </div>
  )
}
