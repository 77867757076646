import { api } from "..";

const URL = '/api/sources';

export const createSourceApi = (source) => api.post(URL, source);

export const getSourcesApi = ({ name, url, page = 1, search }) => {
  let query = `page=${page}`;
  if (name) query += `&name=${name}`;
  if (url) query += `&url=${url}`;
  if (search) query += `&search=${search}`;
  return api.get(`${URL}?${query}`);
};

export const getSourceByIdApi = (id) => api.get(`${URL}/${id}`);

export const updateSourceApi = ({id, ...source}) => api.put(`${URL}/${id}`, source);

export const deleteSourceApi = (id) => api.delete(`${URL}/${id}`);

export const patchSourceApi = ({ id, ...data }) => api.patch(`${URL}/${id}`, data);
