import { Col, Layout, Row } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppContent } from '../../components/AppContent';
import { AppHeader } from '../../components/AppHeader';
import { logoutAction } from '../../store/logout';
import { checkToken } from '../../context/Auth';
import { AuthContext } from '../../context/Auth';

export const MainLayout = ({ children }) => {

  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  return (
    <Layout className="authenticated-mode full-height main-layout">
      <AppHeader />
      <Layout>
        <AppContent>
          <Row>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
              {children}
            </Col>
          </Row>
        </AppContent>
      </Layout>
    </Layout>
  )
}
