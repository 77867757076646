import moment from "moment-timezone";

export const DATE_FORMAT = "DD/MM/YYYY";

export const ISO_DATE = "YYYY-MM-DD";

export const ISO_DATE_TZ = `${ISO_DATE}THH:mm:ss`; //'d-m-Y H:i:sO';

/**
 *
 * @param {array} after
 * @param {array} before
 * @param {date} givenDate
 * @returns
 */
export const constructTimeZone = (
  after,
  before,
  givenAfter = null,
  givenBefore = null
) => {
  const momentAfter = givenAfter ? moment(givenAfter) : moment();
  const momentBefore = givenBefore ? moment(givenBefore) : moment();

  return [
    `${momentAfter
      .tz("Asia/Riyadh")
      .set({ hour: after[0], minute: after[1], second: after[2] })
      .utc()
      .format(ISO_DATE_TZ)}`,
    `${momentBefore
      .tz("Asia/Riyadh")
      .set({ hour: before[0], minute: before[1], second: before[2] })
      .utc()
      .format(ISO_DATE_TZ)}`,
  ];
};

export const patchMomentArabicLocal = () =>
  moment.updateLocale("en", {
    months: [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    week: {
      dow: 0,
    },
  });

export const patchMomentEnglishLocal = () =>
  moment.updateLocale("en", {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Augets",
      "September",
      "October",
      "November",
      "December",
    ],
    week: {
      dow: 0,
    },
  });

/**
 *
 * @param {function} messageTranslator Translation function
 * @returns moment object
 */

const firstColumn = (translator) => ({
  [translator("alerts.view.filters.calendar.today", "alerts")]: [
    moment(),
    moment(),
  ],
  [translator("alerts.view.filters.calendar.yesterday", "alerts")]: [
    moment().subtract(1, "day"),
    moment().subtract(1, "day"),
  ],
  [translator("alerts.view.filters.calendar.last7Days", "alerts")]: [
    moment().subtract(7, "days"),
    moment(),
  ],
});

const secondColumn = (translator) => ({
  [translator("alerts.view.filters.calendar.lastWeek", "alerts")]: [
    moment().subtract(1, "weeks").startOf("week"),
    moment().subtract(1, "weeks").endOf("week"),
  ],
  [translator("alerts.view.filters.calendar.last30Days", "alerts")]: [
    moment().subtract(30, "days"),
    moment(),
  ],
  [translator("alerts.view.filters.calendar.lastMonth", "alerts")]: [
    moment().subtract(1, "months").startOf("month"),
    moment().subtract(1, "months").endOf("month"),
  ],
});

export const renderRanges = (messageTranslator, lang) => {
  if (lang === "ar")
    return {
      ...secondColumn(messageTranslator),
      ...firstColumn(messageTranslator),
    };
  return {
    ...firstColumn(messageTranslator),
    ...secondColumn(messageTranslator),
  };
};

moment().subtract(1, "months").startOf("month");
