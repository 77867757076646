import { put, takeLatest } from 'redux-saga/effects';
import { resetPasswordApi, sendResetRequestApi } from '../../api/auth';
import { resetPasswordError, resetPasswordSuccess, sendResetRequestError, sendResetRequestSuccess } from './actions';
import { FORGET_PASSWORD, RESET_PASSWORD } from './types';

// * Request reset link
function* forgetPasswordSaga({ payload: email }) {
  try {
    yield sendResetRequestApi(email);
    yield put(sendResetRequestSuccess());
  } catch (error) {
    yield put(sendResetRequestError(error));
  }
};

export function* watchForgetPassword() {
  yield takeLatest(FORGET_PASSWORD, forgetPasswordSaga);
};

// * Reset password
function* resetPasswordSaga({ payload: params }) {
  try {
    yield resetPasswordApi(params);
    yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordError(error));
  }
};

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
};
