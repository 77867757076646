// * Create email group
export const EMAIL_GROUP_CREATE         = 'EMAIL_GROUP_CREATE';
export const EMAIL_GROUP_CREATE_SUCCESS = 'EMAIL_GROUP_CREATE_SUCCESS';
export const EMAIL_GROUP_CREATE_ERROR   = 'EMAIL_GROUP_CREATE_ERROR';
export const EMAIL_GROUP_CREATE_RESET   = 'EMAIL_GROUP_CREATE_RESET';

// * Get mail groups
export const EMAIL_GROUP_GET         = 'EMAIL_GROUP_GET';
export const EMAIL_GROUP_GET_SUCCESS = 'EMAIL_GROUP_GET_SUCCESS';
export const EMAIL_GROUP_GET_ERROR   = 'EMAIL_GROUP_GET_ERROR';

// * Get email group
export const EMAIL_GROUP_GET_ONE         = 'EMAIL_GROUP_GET_ONE';
export const EMAIL_GROUP_GET_ONE_SUCCESS = 'EMAIL_GROUP_GET_ONE_SUCCESS';
export const EMAIL_GROUP_GET_ONE_ERROR   = 'EMAIL_GROUP_GET_ONE_ERROR';

// * Update mail group
export const EMAIL_GROUP_UPDATE         = 'EMAIL_GROUP_UPDATE';
export const EMAIL_GROUP_UPDATE_SUCCESS = 'EMAIL_GROUP_UPDATE_SUCCESS';
export const EMAIL_GROUP_UPDATE_ERROR   = 'EMAIL_GROUP_UPDATE_ERROR';
export const EMAIL_GROUP_UPDATE_RESET   = 'EMAIL_GROUP_UPDATE_RESET';

// * Delete mail group
export const EMAIL_GROUP_DELETE         = 'EMAIL_GROUP_DELETE';
export const EMAIL_GROUP_DELETE_SUCCESS = 'EMAIL_GROUP_DELETE_SUCCESS';
export const EMAIL_GROUP_DELETE_ERROR   = 'EMAIL_GROUP_DELETE_ERROR';
export const EMAIL_GROUP_DELETE_RESET   = 'EMAIL_GROUP_DELETE_RESET';

// * Parse contacts file
export const EMAIL_GROUP_PARSE_FILE         = 'EMAIL_GROUP_PARSE_FILE';
export const EMAIL_GROUP_PARSE_FILE_SUCCESS = 'EMAIL_GROUP_PARSE_FILE_SUCCESS';
export const EMAIL_GROUP_PARSE_FILE_ERROR   = 'EMAIL_GROUP_PARSE_FILE_ERROR';
export const EMAIL_GROUP_PARSE_FILE_RESET   = 'EMAIL_GROUP_PARSE_FILE_RESET';