import { CalendarOutlined, CheckCircleOutlined, CreditCardOutlined, DatabaseOutlined, DeleteOutlined, ExclamationCircleOutlined, FolderOutlined, SettingOutlined, StarOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Menu, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ConfigContext } from '../../../../context/config';
import { CINFIG_SET_ALERT_FRAGMENT } from '../../../../context/config/dispatch-config-state';
import { INBOX, NEW_ALERT } from '../../../../routes/paths';
import { ACTIVE, ARCHIVED, BOOKMARKED, DAILY, DELETED, FOLDERS_ARCHIVE, UNDESIRABLE, UNSEEN } from '../../../../utils/data/category';
import { extractPageCategory } from '../../../../utils/utils';
import './sidebar.less';

const { Sider } = Layout;

export const AppSider = ({
  listAlerts,
  alertIRI,
  totalItems
}) => {

  const history = useHistory();

  const { messageTranslator, configState, configDispatcher } = useContext(ConfigContext);

  const [selectedKey, setSelectedKey] = useState(() => ACTIVE);

  useEffect(() => {
    if (configState.alertSubPage) {
      setPageSelectedKey(configState.alertSubPage);
    }
  }, [configState.alertSubPage]);

  useEffect(() => {
    setPageSelectedKey(history.location.pathname);
  }, [history]);

  const setPageSelectedKey = (pathname) => setSelectedKey(extractPageCategory(pathname));

  const handleSelectedItem = (item) => {
    setSelectedKey(item.key);
    configDispatcher(CINFIG_SET_ALERT_FRAGMENT, item.key);
  };

  const handleAlertsListChange = (id) => {
    let fragments = history.location.pathname.split('/').filter(e => e);
    fragments[1] = id;
    if(fragments.length > 2) fragments.pop();
    fragments = fragments.join('/')
    history.push(`/${fragments}`);
  };

  const siderItems = [
    {
      key: ACTIVE,
      link: `${INBOX}/${configState.alertId}`,
      text: messageTranslator("routes.sidebar.items.all", "routes"),
      icon: <CreditCardOutlined />
    },
    {
      key: DAILY,
      link: `${INBOX}/${configState.alertId}/${DAILY}`,
      text: messageTranslator("routes.sidebar.items.daily", "routes"),
      icon: <CalendarOutlined />
    },
    {
      key: UNSEEN,
      link: `${INBOX}/${configState.alertId}/${UNSEEN}`,
      text: messageTranslator("routes.sidebar.items.unseen", "routes"),
      icon: <CheckCircleOutlined />
    },
    {
      key: BOOKMARKED,
      link: `${INBOX}/${configState.alertId}/${BOOKMARKED}`,
      text: messageTranslator("routes.sidebar.items.bookmarked", "routes"),
      icon: <StarOutlined />
    },
    {
      key: ARCHIVED,
      link: `${INBOX}/${configState.alertId}/${ARCHIVED}`,
      text: messageTranslator("routes.sidebar.items.archived", "routes"),
      icon: <FolderOutlined />
    },
    {
      key: DELETED,
      link: `${INBOX}/${configState.alertId}/${DELETED}`,
      text: messageTranslator("routes.sidebar.items.deleted", "routes"),
      icon: <DeleteOutlined />
    },
    {
      key: UNDESIRABLE,
      link: `${INBOX}/${configState.alertId}/${UNDESIRABLE}`,
      text: messageTranslator("routes.sidebar.items.undesirable", "routes"),
      icon: <ExclamationCircleOutlined />
    },
    {
      key: FOLDERS_ARCHIVE,
      link: `${INBOX}/${configState.alertId}/${FOLDERS_ARCHIVE}`,
      text: messageTranslator("routes.sidebar.items.foldersArchive", "routes"),
      icon: <DatabaseOutlined />
    }
  ];

  return (
    <Sider theme="light">
      <div className="info-page">
        <Row justify="space-between" align='middle'>
          <Col span={21} className='active-alert'>
            <Select suffixIcon={<div style={{ pointerEvents: 'none' }}><img style={{ marginBottom: "8px" }} src="https://img.icons8.com/material-rounded/24/undefined/expand-arrow--v1.png" alt='arrow_down' /></div>} 
              value={alertIRI}
              onChange={handleAlertsListChange}
              style={{ width: 220 }}
            >
              {
                listAlerts.map(option => (
                  <Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>
                ))
              }
            </Select>
          </Col>
          <Col span={2} align="center">
            <Link to={`/alerts#${alertIRI}`}>
              <SettingOutlined />
            </Link>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col flex="auto" className='label-alerts'><b>{totalItems}</b> {messageTranslator("routes.sidebar.label.alerts.count", "routes")}</Col>
        </Row>
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        onSelect={handleSelectedItem}
      >
        {
          siderItems.map(item => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.link}>
                {item.text}
              </Link>
            </Menu.Item>
          ))
        }
      </Menu>
      <div className="btn-sidebar-wrapper">
        <Link to={NEW_ALERT}>
          <Button type="primary">
            {messageTranslator("alerts.new.text", "alerts")}
          </Button>
        </Link>
      </div>
    </Sider>
  )
}
