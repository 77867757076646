export const categoriesTranslations = {
  ar: {
    "categories.list.new.text": "إضافة تصنيف جديد",
    "categories.list.empty": "لا يوجد تصنيفات مضافة حالياً",
    "categories.list.label.name": "إسم التصنيف",
    "categories.list.label.icon": "أيقونة التصنيف",
    "categories.list.label.newsNumber": "عدد الأخبار المضافة",
    "categories.list.label.color": "اللون",
    "categories.list.tooltip.update": "تعديل التصنيف",
    "categories.list.tooltip.delete": "حذف التصنيف",
    "categories.list.delete.confirm": "تأكيد حذف التصنيف",
    "categories.add.title": "إضافة تصنيف جديد",
    "categories.update.title": "تعديل تصنيف",
    "categories.form.label.general": "معلومات عامة عن التصنيف",
    "categories.form.name.placeholder": "إسم التصنيف",
    "categories.form.order.placeholder": "ترتيب التصنيف",
    "categories.form.icon.label": "أيقونة التصنيف",
    "categories.form.color.label": "لون التصنيف",

    "categories.form.image.error": "الرجاء تحميل صورة التصنيف"
  },
  en: {
    "categories.list.new.text": "Add new category",
    "categories.list.empty": "There are no added categories",
    "categories.list.label.name": "Name",
    "categories.list.label.icon": "Icon",
    "categories.list.label.newsNumber": "Number of added news",
    "categories.list.label.color": "Color",
    "categories.list.tooltip.update": "Update category",
    "categories.list.tooltip.delete": "Delete category",
    "categories.list.delete.confirm": "Are you sure you want to delete",
    "categories.add.title": "Add new category",
    "categories.update.title": "Update category",
    "categories.form.label.general": "Category's general information",
    "categories.form.name.placeholder": "Category name",
    "categories.form.order.placeholder": "Category order",
    "categories.form.icon.label": "Category icon",
    "categories.form.color.label": "Category color",

    "categories.form.image.error": "Please upload category icon"
  }
};