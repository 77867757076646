import { Button, Card, Col, Divider, Form, Input, message, Row, Space, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../context/config';
import { CONFIG_NAMES } from '../../../../utils/constants';
import { generateConfigList, dataSelectAR, dataSelectEN } from '../../../../utils/utils';
import { patchMomentArabicLocal, patchMomentEnglishLocal } from '../../../../utils/moment-utils';

export const TwitterCriteriaSettingsForm = ({ config, onSubmitConfig }) => {

  const { messageTranslator, configState } = useContext(ConfigContext);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const [isArabic, setArabic] = useState(false);

  useEffect(() => {
    if (configState.language === "ar") {
      setArabic(true);
      patchMomentArabicLocal();
    } else {
      setArabic(false);
      patchMomentEnglishLocal();
    } 
  }, [configState.language]);

  useEffect(() => {
    if (config.data) setFieldsValue({ ...config.data.values });
  }, [config, setFieldsValue]);

  const handleFormSubmit = (values) => {
    const newValues = {};
    if (values.minimum_retweets) newValues.minimum_retweets = values.minimum_retweets;
    if (values.minimum_likes) newValues.minimum_likes = values.minimum_likes;
    if (values.min_followers_count) newValues.min_followers_count = values.min_followers_count;
    if (values.min_status_count) newValues.min_status_count = values.min_status_count;
    if (values.acc_created_before) newValues.acc_created_before = values.acc_created_before;

    if (!Object.keys(newValues).length) {
      message.error(messageTranslator("config.settings.form.error.message", "config"));
      return;
    }

    onSubmitConfig({
      name: CONFIG_NAMES.twitterCriteria,
      values: generateConfigList(newValues)
    })
  };

  return (
    <Card
      title={messageTranslator("config.menu.twitterCriteria.label", "config")}
      bordered={false}
    >
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
      >

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitterCriteria.minimumRetweets.label", "config")}
              name="minimum_retweets"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitterCriteria.minimumLikes.label", "config")}
              name="minimum_likes"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitterCriteria.minFollowersCount.label", "config")}
              name="min_followers_count"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitterCriteria.minStatusCount.label", "config")}
              name="min_status_count"
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[30, 30]}>
          <Col md={24} xs={24}>
          <Form.Item
              name="acc_created_before"
              label={messageTranslator("config.twitterCriteria.accountCreationBefore.label", "config")}
            >
              <Select>
                {
                  isArabic && dataSelectAR.map(date => (
                    <Select.Option key={date['key']} value={`${date.key}`}>
                      {date.label}
                    </Select.Option>
                  ))
                }
                {
                  !isArabic && dataSelectEN.map(date => (
                    <Select.Option key={date['key']} value={`${date.key}`}>
                      {date.label}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Space>
          <span className='btn-wrapper'>
            <Button type="primary" htmlType="submit">
              {messageTranslator("commons.save", "commons")}
            </Button>
          </span>
          <Button>
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </Space>
      </Form>
    </Card>
  );
};
