import { CaretDownOutlined, CaretUpOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, Form, InputNumber, Modal, Row, Select, Typography } from 'antd';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { EmailGroupSelector } from '../../../../../components/EmailGroupSelector';
import { ConfigContext } from '../../../../../context/config';
import { validateEmail } from '../../../../../utils/utils';
import { MultiDatesPicker } from '../MultiDatesPicker';
import { DAILY, DATES, MONTHLY, renderSentence, renderTimeList, reportFormats, scheduleDays, scheduleEvery, scheduleRepeat, showEveryInSelect, weekDays, WEEKLY } from './data';
import "./scheduleModal.less";

export const ScheduleModal = forwardRef(({
  schedule,
  report,
  isLoading,
  emailGroupList,
  onOk
}, ref) => {


  const { configState, messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();
  const { submit, setFieldsValue, setFields, resetFields } = form;

  const [isOpen, setOpen] = useState(false);
  const [unit, setUnit] = useState("");
  const [separator, setSeparator] = useState(0);
  const [day, setDay] = useState("");
  const [hour, setHour] = useState("");
  const [dates, setDates] = useState(0);
  const [emailGroups, setEmailGroups] = useState([]);

  useEffect(() => {

    let newArr = [];
    if(schedule?.receiversList?.includes(",")) {
      newArr.push(...schedule?.receiversList?.split(","));
    } else {
      newArr.push(schedule?.receiversList);
    }

    if(schedule?.receiversGroups?.["0"]?.name) {
      newArr.push(schedule?.receiversGroups?.["0"]?.name);
    }

    if(!schedule?.receiversList && !schedule?.receiversList) {
      newArr = [];
    }

    setFieldsValue({
      unit: schedule?.unit ? schedule?.unit : null,
      dates:  schedule?.dates ? schedule?.dates : null,
      separator: schedule?.separator ? schedule.separator : null,
      day: schedule?.day ? schedule?.day : null,
      hour: schedule?.hour ? schedule?.hour : null,
      recipients: newArr ? newArr : null,
      extension: schedule?.extension ? schedule?.extension : null
     });
  }, [schedule, setFieldsValue]);

  useEffect(() => {
    if (!isOpen) setFields([]);
  }, [isOpen, setFields]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => setOpen(true);

  const handleFormFinish = ({ recipients, ...values }) => {

    if (values.unit === MONTHLY) {
      values.day = day.split(',')[1];
      values.week = parseInt(day.split(',')[0]);
    }
    else if (values.unit === WEEKLY) values.day = day;

    else if (values.unit === DATES) values.dates = values.dates.toString();

    const formattedEmails = getGroupsAndEmails(recipients);

    onOk({
      ...values,
      ...formattedEmails,
    });
  }

  // * Data logic
  const handleUnitChange = (value) => {
    setUnit(value);
    setDay("");
    setFieldsValue({
      day: ''
    });
  };

  const getGroupsAndEmails = (emails) => {
    const receiversList = emails.filter(email => validateEmail(email)).toString();
    const receiversGroups = emailGroups;

    return {
      receiversList,
      receiversGroups
    };
  }

  const handleDayChange = (value) => setDay(value);

  const handleSeparatorChange = (value) => {
    setSeparator(value);
    setFieldsValue({
      separator: value
    })
  };

  const handleDatesChange = (dates) => {
    setDates(dates.length);
    setFieldsValue({ dates });
  };

  const handleHourChange = (value, option) => setHour(value);
  // * Data logic

  const resetFormFields = () => {
    resetFields();
    setUnit("");
    setDay("");
    setSeparator(0);
    setHour("");
    setDates(0);
  };

  useImperativeHandle(ref, () => ({
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
    resetFields: resetFormFields
  }));

  return (
    <Modal
      visible={isOpen}
      className="small-modal"
      onCancel={handleCloseModal}
      forceRender
      title={!report?.scheduled ? messageTranslator("reports.view.header.scheduling", "reports") : messageTranslator("reports.edit.header.scheduling", "reports")}
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={handleCloseModal}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className='btn-wrapper'>
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={submit}
          >
            {messageTranslator("commons.add", "commons")}
          </Button>
        </span>
      ]}
    >
      <Form
        form={form}
        onFinish={handleFormFinish}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="unit"
              label={messageTranslator("reports.view.modal.schedule.repeat.label", "reports")}
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} onChange={handleUnitChange}>
                {
                  scheduleRepeat.map(option => (
                    <Select.Option
                      key={option.key}
                      value={option.value}
                    >
                      {messageTranslator(option.label, "reports")}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='space-between'>
          {
            unit === DATES
              ?
              (
                <Col span={24}>
                  <Form.Item
                    name="dates"
                    rules={[{ required: unit === DATES, message: messageTranslator("commons.required.message", "commons") }]}
                  >
                    <MultiDatesPicker
                      onDateChange={handleDatesChange}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <>
                  <Col md={8}>
                    <Form.Item
                      name="separator"
                      label={messageTranslator("reports.view.modal.schedule.every.label", "reports")}
                      className='horizontal-item'
                      rules={[{ required: unit !== DATES, message: messageTranslator("commons.required.message", "commons") }]}
                    >
                      <InputNumber
                          controls={{ upIcon: <CaretUpOutlined />, downIcon: <CaretDownOutlined /> }}
                          min={1}
                          onChange={handleSeparatorChange}
                        />
                    </Form.Item>
                    <Row gutter={20}>
                      <Col>
                        <Typography style={{ marginBottom: "16px" }}>
                          {
                            unit && messageTranslator(scheduleEvery[unit], "reports")
                          }
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  {
                    showEveryInSelect(unit) && (
                      <Col md={15}>
                        <Form.Item
                          name="day"
                          label={messageTranslator("reports.view.modal.schedule.in.label", "reports")}
                          className='horizontal-item'
                          rules={[{ required: [WEEKLY, MONTHLY].includes(unit), message: messageTranslator("commons.required.message", "commons") }]}
                        >
                          <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} onChange={handleDayChange}>
                            {
                              unit === "monthly"
                                ?
                                weekDays.map(week => (
                                  <Select.OptGroup key={week.grouLabel} label={messageTranslator(week.grouLabel, "reports")}>
                                    {
                                      week.values.map(day => (
                                        <Select.Option key={day.value} value={day.value}>
                                          {messageTranslator(day.label, "reports")}
                                        </Select.Option>
                                      ))
                                    }
                                  </Select.OptGroup>
                                ))
                                :
                                scheduleDays[unit]?.map(option => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {messageTranslator(option.label, "reports")}
                                  </Select.Option>
                                ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    )
                  }
                </>
              )
          }
        </Row>

        <Row justify='end'>
          <Col span={24}>
            <Form.Item
              name="hour"
              label={messageTranslator("reports.view.modal.schedule.on.label", "reports")}
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
              className='horizontal-item'
            >
              <Select
                options={renderTimeList("commons.morning", "commons.evening", messageTranslator)}
                onChange={handleHourChange}
              />
            </Form.Item>
          </Col>
          {
            (!!configState && !!unit && !!hour)
            &&
            (
              ([WEEKLY, MONTHLY].includes(unit) && !!separator && !!day)
              ||
              (unit === DAILY && !!separator)
              ||
              (unit === DATES && !!dates)
            )
            &&
            (
              <Col span={22}>
                <Typography className='schedule-msg'>
                  <CheckCircleFilled />
                  {renderSentence(messageTranslator, configState.language, unit, separator, hour, day, dates)}
                </Typography>
              </Col>
            )
          }

        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <EmailGroupSelector
              formRef={form}
              fieldName="recipients"
              emailGroupList={emailGroupList}
              onChange={setEmailGroups}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name="extension"
              label={messageTranslator("reports.view.modal.schedule.format.label", "reports")}
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                options={reportFormats}
              />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Modal>
  );
});
