import { put, takeLatest } from 'redux-saga/effects';
import { getImportantNewsStatsApi, getStatsApi } from '../../api/stats';
import { getCountriesStatsError, getCountriesStatsSuccess, getFeedbackStatsError, getFeedbackStatsSuccess, getHashtagsStatsError, getHashtagsStatsSuccess, getImportantNewsStatsError, getImportantNewsStatsSuccess, getInfluencersStatsError, getInfluencersStatsSuccess, getMonitoringStatsError, getMonitoringStatsSuccess, getTagsStatsError, getTagsStatsSuccess } from './actions';
import { GET_COUNTRIES_STATS, GET_FEEDBACK_STATS, GET_HASHTAGS_STATS, GET_IMPORTANT_NEWS_STATS, GET_INFLUENCERS_STATS, GET_KEYWORD_STATS, GET_MONITORING_STATS } from './types';

// * Countries
function* getCountriesStatsSaga({ payload: params }) {
  try {
    const res = yield getStatsApi(params);
    yield put(getCountriesStatsSuccess(res.data));
  } catch (error) {
    yield put(getCountriesStatsError(error));
  }
}

export function* watchGetCountriesStats() {
  yield takeLatest(GET_COUNTRIES_STATS, getCountriesStatsSaga);
}

// * Feedback
function* getFeedbackStatsSaga({ payload: params }) {
  try {
    const res = yield getStatsApi(params);
    yield put(getFeedbackStatsSuccess(res.data));
  } catch (error) {
    yield put(getFeedbackStatsError(error));
  }
}

export function* watchGetFeedbackStats() {
  yield takeLatest(GET_FEEDBACK_STATS, getFeedbackStatsSaga);
}

// * Important news
function* getImportantNewsStatsSaga({ payload: params }) {
  try {
    const res = yield getImportantNewsStatsApi(params);
    yield put(getImportantNewsStatsSuccess(res.data['hydra:member']));
  } catch (error) {
    yield put(getImportantNewsStatsError(error));
  }
}

export function* watchGetImportantNewsStats() {
  yield takeLatest(GET_IMPORTANT_NEWS_STATS, getImportantNewsStatsSaga);
}

// * Monitoring
function* getMonitoringStatsSaga({ payload: params }) {
  try {
    const res = yield getStatsApi(params);
    yield put(getMonitoringStatsSuccess(res.data));
  } catch (error) {
    yield put(getMonitoringStatsError(error));
  }
}

export function* watchGetMonitoringStats() {
  yield takeLatest(GET_MONITORING_STATS, getMonitoringStatsSaga);
}

// * Important news
function* getHashtagsStatsSaga({ payload: params }) {
  try {
    const res = yield getStatsApi(params);
    yield put(getHashtagsStatsSuccess(res.data));
  } catch (error) {
    yield put(getHashtagsStatsError(error));
  }
}

export function* watchGetHashtagsStats() {
  yield takeLatest(GET_HASHTAGS_STATS, getHashtagsStatsSaga);
}

// * Important news
function* getKeywordsStatsSaga({ payload: params }) {
  try {
    const res = yield getStatsApi(params);
    yield put(getTagsStatsSuccess(res.data));
  } catch (error) {
    yield put(getTagsStatsError(error));
  }
}

export function* watchGetKeywordsStats() {
  yield takeLatest(GET_KEYWORD_STATS, getKeywordsStatsSaga);
}

// * influencers
function* getInfluencersStatsSaga({ payload: params }) {
  try {
    const res = yield getStatsApi(params);
    yield put(getInfluencersStatsSuccess(res.data));
  } catch (error) {
    yield put(getInfluencersStatsError(error));
  }
}

export function* watchGetInfluencersStats() {
  yield takeLatest(GET_INFLUENCERS_STATS, getInfluencersStatsSaga);
}
