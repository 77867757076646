import { removeItemFromArray, replaceItemInArray } from "../../utils/utils";
import {
  ALERT_CREATE,
  ALERT_CREATE_ERROR,
  ALERT_CREATE_SUCCESS,
  ALERT_DELETE,
  ALERT_DELETE_ERROR,
  ALERT_DELETE_SUCCESS,
  ALERT_FIND_DUPLICATION,
  ALERT_FIND_DUPLICATION_ERROR,
  ALERT_FIND_DUPLICATION_SUCCESS,
  ALERT_GET_BY_ID,
  ALERT_GET_BY_ID_ERROR,
  ALERT_GET_BY_ID_SUCCESS,
  ALERT_GET_LIST,
  ALERT_GET_LIST_ERROR,
  ALERT_GET_LIST_SUCCESS,
  ALERT_RESET_CREATED,
  ALERT_RESET_DELETED,
  ALERT_RESET_DUPLICATION,
  ALERT_RESET_UPDATED,
  ALERT_UPDATE,
  ALERT_UPDATE_ERROR,
  ALERT_UPDATE_SUCCESS,
  ALERT_HANDLE_SELECT,
} from "./types";

export const alertInitialState = {
  create: {
    isCreated: null,
    isLoading: false,
    errors: null,
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null,
  },
  list: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null,
  },
  alert: {
    data: null,
    isLoading: false,
    errors: null,
  },
  duplicated: {
    data: null,
    identifiers: [],
    isLoading: false,
    errors: null,
  },
  delete: {
    isLoading: false,
    isDeleted: false,
    errors: null,
  },
  select: {
    data: null,
    errors: null,
  },
};

export const alertReducer = (state = alertInitialState, action) => {
  switch (action.type) {
    case ALERT_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: action.payload,
        },
      };

    case ALERT_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_RESET_CREATED:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: null,
          errors: null,
        },
      };

    case ALERT_GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.list,
          totalItems: action.payload.totalItems,
          isLoading: false,
        },
      };

    case ALERT_GET_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_GET_BY_ID:
      return {
        ...state,
        alert: {
          ...state.alert,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        alert: {
          ...state.alert,
          isLoading: false,
          data: action.payload,
        },
      };

    case ALERT_GET_BY_ID_ERROR:
      return {
        ...state,
        alert: {
          ...state.alert,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_FIND_DUPLICATION:
      return {
        ...state,
        duplicated: {
          ...state.duplicated,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_FIND_DUPLICATION_SUCCESS:
      return {
        ...state,
        duplicated: {
          ...state.duplicated,
          isLoading: false,
          data: action.payload.data,
          identifiers: action.payload.identifiers,
        },
      };

    case ALERT_FIND_DUPLICATION_ERROR:
      return {
        ...state,
        duplicated: {
          ...state.duplicated,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_RESET_DUPLICATION:
      return {
        ...state,
        duplicated: {
          ...state.duplicated,
          data: null,
          errors: null,
        },
      };

    case ALERT_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
        },
      };

    case ALERT_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true,
        },
        list: {
          ...state.list,
          data: replaceItemInArray(action.payload, state.list.data, "id"),
        },
      };

    case ALERT_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_RESET_UPDATED:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null,
        },
      };

    // Delete alert
    case ALERT_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: true,
          errors: null,
        },
      };

    case ALERT_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          isDeleted: true,
        },
        list: {
          ...state.list,
          data: removeItemFromArray(action.payload, state.list.data),
          totalItems: state.list.totalItems - 1,
        },
      };

    case ALERT_DELETE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ALERT_RESET_DELETED:
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleted: false,
          errors: null,
        },
      };

    case ALERT_HANDLE_SELECT:
      return {
        ...state,
        select: {
          data: action.payload,
        },
      };

    default:
      return state;
  }
};
