import { BellOutlined, DownOutlined, MenuOutlined, LogoutOutlined, SettingOutlined, UserOutlined, LineOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Col, Drawer, Dropdown, Layout, Menu, Row, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/Auth';
import { ConfigContext } from '../../context/config';
import { CONFIG_SWITCH_LANGUAGE } from '../../context/config/dispatch-config-state';
import { useWindowResize } from '../../hooks/mobile-query';
import { routes } from '../../routes';
import { INBOX, UPDATE_USER } from '../../routes/paths';
import { logoutAction } from '../../store/logout';
import { markNotificationsAsSeen } from '../../store/notifications/actions';
import { advancedSearchLanguages } from '../../utils/data/languages';
import { Logo } from '../Logo';
import { Notifications } from '../Notifications';
import { hasPermission } from '../PermissionHandler/route-permission-handler';
import './header.less';

const { Header } = Layout;

const headerProfileItems = [
  {
    key: "account-settings",
    title: 'commons.header.accountSettings',
    link: UPDATE_USER,
    icon: <SettingOutlined />
  }
];

const renderHeaderProfileItems = (items, translator, userId) => items.map(item => (
  <Menu.Item key={item.key}>
    <Link to={`${item.link}/${userId}`}>
      <Row>
        <Col span={16}>{translator(item.title, "commons")}
        </Col>
        <Col span={8}>
          <Row justify="end">
            <Col>
              {item.icon}
            </Col>
          </Row>
        </Col>
      </Row>
    </Link>
  </Menu.Item>
));

const renderHeaderProfileMenu = (logoutCallback, translator, userid = '', userData) => (
  <Menu className='sub-menu ant-form-small'>
    <Menu.Item key={userid}>
      {userData?.role && <div className='role'>
        {/*translator("commons.header.editAndMonitor", "commons")*/}
        {userData?.role["id"] === "5" && translator("commons.header.publisher", "commons")}
        {userData?.role["id"] === "1" && translator("commons.header.superAdmin", "commons")}
        {userData?.role["id"] === "4" && translator("commons.header.auditor", "commons")}
        {userData?.role["id"] === "3" && translator("commons.header.editAndMonitor", "commons")}
      </div>}
    </Menu.Item>
    {renderHeaderProfileItems(headerProfileItems, translator, userid)}
    <Menu.Item key="logout" className='logout-link'>
      <span className="btn-wrapper">
        <Button
          onClick={() => logoutCallback()}
          key="add-folder"
          type="primary"
          danger
        >
          {translator("commons.header.logout", "commons")}
          <LogoutOutlined className='transformMiroir' />
        </Button>
      </span>
    </Menu.Item>
  </Menu>
);

const renderLanguageList = (currentLang, list, translator, handleLangMenuClick) => (
  <Menu
    className='sub-menu ant-form-small'
    onClick={handleLangMenuClick}
    selectedKeys={[currentLang]}
  >
    {
      list.map(lang => (
        <Menu.Item key={lang.value}>
          {translator(lang.label, "constants")}
        </Menu.Item>
      ))
    }
  </Menu>
);

export const AppHeader = () => {

  const { isMobile } = useWindowResize();
  const [selectedKey, setSelectedKey] = useState(() => []);
  const [unseenNotifs, setUnseenNotifs] = useState(0);

  const dispatch = useDispatch();

  const {
    config: { globalConfig: { data: globalConfig } },
    alerts: {
      list: { data: listAlerts }
    },
    users: { me: { data: meData } },
    notifications: {
      list: { data: notificationsList }
    }
  } = useSelector(state => state);

  const { logout } = useContext(AuthContext);
  const { configState: { language, alertSubPage }, messageTranslator, configDispatcher } = useContext(ConfigContext);


  useEffect(() => {
    const frags = window.location.pathname.split("/").filter(frag => frag);
    let key = `/${frags[0]}`;
    setSelectedKey([key]);
  }, [alertSubPage]);

  useEffect(() => {
    setUnseenNotifs(() => notificationsList?.filter(notif => !notif.seen).length);
  }, [notificationsList]);

  const handleSelectedItem = (item) => setSelectedKey([item.key]);

  const handleLogout = () => {
    logout();
    dispatch(logoutAction());
  };

  const handleLangMenuClick = ({ key }) => configDispatcher(CONFIG_SWITCH_LANGUAGE, key);

  const handleMarkAsSeen = (id) => dispatch(markNotificationsAsSeen(id));

  const renderHeaderItems = (routes, role) => {
    return routes[3].routes.map(route => {
      if (!route.hasHeaderLink) return null;
      if (!hasPermission(role, route.resources)) return null;
      let path = route.path;
      if (path.includes('reports') && path.includes(':category'))
        path = path.replace(":category", "all");
      else if (path.includes(INBOX)) {
        const alert = listAlerts[0];
        if (!alert && !globalConfig?.values.inbox_link) return null;
        //path = path.replace(":id", alert?.id);
        path = path.replace(":id", globalConfig?.values.inbox_link || alert.id);
      } 

      // path += `/${globalConfig?.values.inbox_link}/${DAILY}`;
      return (
        <Menu.Item key={route.key} icon={route.icon}>
          <Link to={path}>{messageTranslator(route.title, "routes")}</Link>
        </Menu.Item>
      );
    });
  };

  const [stateDrawer, setStateDrawer] = useState({ visible: false });

  const showDrawer = () => {
    setStateDrawer({
      visible: true,
    });
  };

  const onCloseDrawer = () => {
    setStateDrawer({
      visible: false,
    });
  };

  return (
    <Header>
      {
        isMobile
          ?
          <Row justify='space-between' wrap={false}>
            <Col flex="75px">
              <Logo />
            </Col>
            <Col >

              <Menu
                mode="verticake"
                className='profile-menu'
              >
                <Menu.Item
                  key="notifications"
                  className='notifs-wrapper'
                >
                  <Notifications
                    notifications={notificationsList}
                    onClick={handleMarkAsSeen}
                  >
                    <Badge count={unseenNotifs} className="">
                      <Button className='notis-btn'>
                        <BellOutlined />
                      </Button>
                    </Badge>
                  </Notifications>
                </Menu.Item>
      </Menu> 
            </Col>
            <Col flex="52px">
              <Button type="primary" onClick={showDrawer} icon={<MenuOutlined />} >
              </Button>
              <Drawer
                title={<Logo />}
                placement="right"
                width="100%"
                className="burger-menu"
                onClose={onCloseDrawer}
                visible={stateDrawer.visible}
              >
                <Space direction="vertical" size={30} className="full-width">

                 <Menu
                    mode="vertical"
                    className='profile-menu'
                  >
                    <Dropdown
                      key="languages"
                      overlay={renderLanguageList(language, advancedSearchLanguages, messageTranslator, handleLangMenuClick)}
                    >
                      <Menu.Item key="languages-item">
                        {language}
                      </Menu.Item>
                    </Dropdown>

                    <Dropdown
                      overlay={renderHeaderProfileMenu(handleLogout, messageTranslator, meData?.id)}
                    >
                      <Menu.Item
                        key="profile"
                        className='profile-item'
                        icon={(
                          <Avatar
                            icon={meData?.image ? null : <UserOutlined />}
                            src={meData?.image ? `${process.env.REACT_APP_BASE_URL}${meData.image.contentUrl}` : null}
                            size={38}
                          />
                        )}
                      >
                        <span className='profile-content'>
                          <span className='profile'>
                            <span className='name'>{`${meData?.firstName} ${meData?.lastName}`}</span><br />
                          </span>
                        </span>
                        <DownOutlined className='icon' />
                      </Menu.Item>
                    </Dropdown>
                  </Menu>

                  <Menu
                    mode="vertical"
                    defaultSelectedKeys={selectedKey}
                    selectedKeys={selectedKey}
                    onSelect={handleSelectedItem}
                  >

                    {meData && renderHeaderItems(routes, meData.role)}

                        </Menu> 
                </Space>
              </Drawer>
            </Col>
          </Row>
          :
          <Row justify='space-between' wrap={false}>
            <Col flex="75px">
              <Logo />
            </Col>
            <Col flex="auto" className='principal-menu'>

              <Menu
                mode="horizontal"
                defaultSelectedKeys={selectedKey}
                selectedKeys={selectedKey}
                onSelect={handleSelectedItem}
              >

                {meData && renderHeaderItems(routes, meData.role)}

              </Menu> 
            </Col>
            <Col flex="1 1 auto">
              <Menu
                mode="horizontal"
                className='profile-menu'
              >
                <Menu.Item
                  key="notifications"
                  className='notifs-wrapper'
                >
                  <Notifications
                    notifications={notificationsList}
                    onClick={handleMarkAsSeen}
                  >
                    <Badge count={unseenNotifs} className="">
                      <Button className='notis-btn'>
                        <BellOutlined />
                      </Button>
                    </Badge>
                  </Notifications>
                </Menu.Item>

                <Dropdown
                  key="languages"
                  overlay={renderLanguageList(language, advancedSearchLanguages, messageTranslator, handleLangMenuClick)}
                >
                  <Menu.Item key="languages-item">
                    {language}
                  </Menu.Item>
                </Dropdown>

                <Dropdown
                  overlay={renderHeaderProfileMenu(handleLogout, messageTranslator, meData?.id, meData)}
                >
                  <Menu.Item
                    key="profile"
                    className='profile-item'
                    icon={(
                      <Avatar
                        icon={meData?.image ? null : <UserOutlined />}
                        src={meData?.image ? `${process.env.REACT_APP_BASE_URL}${meData.image.contentUrl}` : null}
                        size={38}
                      />
                    )}
                  >
                    <span className='profile-content'>
                      <span className='profile'>
                        <span className='name'>{`${meData?.firstName} ${meData?.lastName}`}</span>
                      </span>
                    </span>
                    <DownOutlined className='icon' />
                  </Menu.Item>
                </Dropdown>
              </Menu>

            </Col>
          </Row>
      }
    </Header>
  );
};
