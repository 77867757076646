import { Card, Col, Row, Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { ROLES } from '../../../../../utils/constants';
// import './folder-banner.less';

export const AdminBanner = ({
  currentUser,
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const renderNotAuthorizedBanner = (meData) => {
    return !!(
      meData
      &&
      meData?.role?.slug === ROLES.SUPER_ADMIN
    )
  };

  if (renderNotAuthorizedBanner(currentUser))
    return (
      <Card
        className='folder-banner'
        size='small'
      >
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
            <Row justify="space-between" align='middle'>
              <Col flex="auto" align="middle" xs={24}>
                <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.superAdmin.notAuthorized", "alerts")}</Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  return null;
};
