import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Layout, Row } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppContent } from '../../components/AppContent';
import { AppHeader } from '../../components/AppHeader';
import { useWindowResize } from '../../hooks/mobile-query';
import { getAlertList } from '../../store/alerts/actions';
import { AppSider } from './components/AppSider';
import { checkToken } from '../../context/Auth';
import { AuthContext } from '../../context/Auth';
import { logoutAction } from '../../store/logout';

export const NotificationsLayout = ({ children }) => {

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  const {
    alerts: {
      alert: { data },
      list: { data: listALerts }
    },
    alertResults: {
      results: { totalItems }
    }
  } = useSelector(state => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlertList({ page: 1 }));
  }, [dispatch]);

  const { isMobile } = useWindowResize();
  const [stateSideBar, setStateSideBar] = useState({ visible: false });

  const showSideBar = () => {
    setStateSideBar({
      visible: true,
    });
  };

  const onCloseSideBar = () => {
    setStateSideBar({
      visible: false,
    });
  };

  return (
    <Layout className="authenticated-mode full-height main-layout notification-layout">
      <AppHeader />
      <Layout>
        {
          isMobile
            ?
            <>
              <Button className='sidebar-btn' type="primary" onClick={showSideBar} icon={<MenuUnfoldOutlined className='transformMiroir' />}></Button>
              <Drawer
                placement="left"
                width={266}
                className="sidebar-menu"
                onClose={onCloseSideBar}
                visible={stateSideBar.visible}
              >
                <AppSider
                  alertIRI={data?.id}
                  listAlerts={listALerts}
                  totalItems={totalItems}
                />
              </Drawer>
            </>
            :
            <AppSider
              alertIRI={data?.id}
              listAlerts={listALerts}
              totalItems={totalItems}
            />
        }
        <Layout>
          <AppContent>
            <Row>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
                {children}
              </Col>
            </Row>
          </AppContent>
        </Layout>
      </Layout>
    </Layout>
  )
}
