import { replaceItemInArray } from "../../utils/utils";
import { EMAIL_GROUP_CREATE, EMAIL_GROUP_CREATE_ERROR, EMAIL_GROUP_CREATE_RESET, EMAIL_GROUP_CREATE_SUCCESS, EMAIL_GROUP_DELETE, EMAIL_GROUP_DELETE_ERROR, EMAIL_GROUP_DELETE_RESET, EMAIL_GROUP_DELETE_SUCCESS, EMAIL_GROUP_GET, EMAIL_GROUP_GET_ERROR, EMAIL_GROUP_GET_ONE, EMAIL_GROUP_GET_ONE_ERROR, EMAIL_GROUP_GET_ONE_SUCCESS, EMAIL_GROUP_GET_SUCCESS, EMAIL_GROUP_PARSE_FILE, EMAIL_GROUP_PARSE_FILE_ERROR, EMAIL_GROUP_PARSE_FILE_RESET, EMAIL_GROUP_PARSE_FILE_SUCCESS, EMAIL_GROUP_UPDATE, EMAIL_GROUP_UPDATE_ERROR, EMAIL_GROUP_UPDATE_RESET, EMAIL_GROUP_UPDATE_SUCCESS } from "./types";

export const emailGroupInitialState = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null
  },
  list: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null
  },
  group: {
    data: null,
    isLoading: false,
    errors: null
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null
  },
  delete: {
    isLoading: false,
    isDeleted: false,
    errors: null
  },
  parsedData: {
    data: null,
    isLoading: false,
    isParsed: false,
    errors: null
  }
};

export const emailGroupReducer = (state = emailGroupInitialState, action) => {
  switch (action.type) {
    case EMAIL_GROUP_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null
        }
      };

    case EMAIL_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true
        },
        list: {
          ...state.list,
          data: [action.payload, ...state.list.data],
          totalItems: state.list.totalItems + 1
        }
      };

    case EMAIL_GROUP_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload
        }
      };

    case EMAIL_GROUP_CREATE_RESET:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: false,
          errors: null
        }
      };

    // * Get email groups
    case EMAIL_GROUP_GET:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null
        }
      };

    case EMAIL_GROUP_GET_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.data,
          totalItems: action.payload.totalItems
        }
      };

    case EMAIL_GROUP_GET_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Get email group
    case EMAIL_GROUP_GET_ONE:
      return {
        ...state,
        group: {
          ...state.group,
          isLoading: true,
          errors: null
        }
      };
      
    case EMAIL_GROUP_GET_ONE_SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          isLoading: false,
          data: action.payload
        }
      };
      
    case EMAIL_GROUP_GET_ONE_ERROR:
      return {
        ...state,
        group: {
          ...state.group,
          isLoading: false,
          errors: action.payload
        }
      };

    // * Update email group
    case EMAIL_GROUP_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
          errors: null
        }
      };

    case EMAIL_GROUP_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true,
        },
        list: {
          ...state.list,
          data: replaceItemInArray(action.payload, state.list.data, 'id')
        }
      };

    case EMAIL_GROUP_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload
        }
      };

    case EMAIL_GROUP_UPDATE_RESET:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null
        }
      };

    // * Delete email group
    case EMAIL_GROUP_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: true,
          errors: null
        }
      };

    case EMAIL_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          isDeleted: true
        }
      };

    case EMAIL_GROUP_DELETE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          errors: action.payload
        }
      };

    case EMAIL_GROUP_DELETE_RESET:
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleted: false,
          errors: null
        }
      };

    // * Parse contacts file
    case EMAIL_GROUP_PARSE_FILE:
      return {
        ...state,
        parsedData: {
          ...state.parsedData,
          isLoading: true,
          errors: null
        }
      };

    case EMAIL_GROUP_PARSE_FILE_SUCCESS:
      return {
        ...state,
        parsedData: {
          ...state.parsedData,
          isLoading: false,
          isParsed: true,
          data: action.payload
        }
      };

    case EMAIL_GROUP_PARSE_FILE_ERROR:
      return {
        ...state,
        parsedData: {
          ...state.parsedData,
          isLoading: false,
          error: action.payload
        }
      };

    case EMAIL_GROUP_PARSE_FILE_RESET:
      return {
        ...state,
        parsedData: {
          ...state.parsedData,
          isParsed: false,
          errors: null
        }
      };
  
    default:
      return state;
  }
};
