import { Col, DatePicker, Divider, Row, Select, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import { CustomSegmented } from '../../../../api/CustomSegmented';
import { ConfigContext } from '../../../../context/config';
import { statsSegments } from '../../../../utils/constants';
import { renderRanges } from '../../../../utils/moment-utils';

export const DashboardHeader = ({ defaultUnit, alerts, onUnitChange, onDateChange, onAlertChange }) => {

  const { configState, messageTranslator } = useContext(ConfigContext);

  const [dates, setDates] = useState([]);

  const segmentOptions = [
    { label: messageTranslator("stats.header.segmented.month", "stats"), value: statsSegments.month },
    { label: messageTranslator("stats.header.segmented.week", "stats"), value: statsSegments.week },
    { label: messageTranslator("stats.header.segmented.day", "stats"), value: statsSegments.day }
  ];

  const handleCategoryChange = (value) => onAlertChange(value);

  const handleSegmentedChange = (value) => {
    onUnitChange(value);
    setDates([]);
  }

  const handleDateChange = (dates, dateStrings) => {
    onDateChange(dates, dateStrings);
    setDates(dates || [])
  }

  return (
    <>
      <Row
        align="bottom"
      >
        <Col xs={24} lg={8}>
          <Typography.Title level={3}>{messageTranslator("stats.header.title", "stats")}</Typography.Title>
        </Col>
        <Col xs={24} lg={16}>
          <Row gutter={[16, 16]} justify="end" align='middle'>
            <Col xs={24} md={8}>
              <Select
                defaultValue={"all"}
                onChange={handleCategoryChange}
                className='filter-dashboard'
              >

                <Select.Option key="all" value="all">
                  {messageTranslator("stats.header.select.all", "stats")}
                </Select.Option>
                
              {
                alerts.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))
              }
              </Select>
            </Col>
            <Col>
              <DatePicker.RangePicker
                ranges={renderRanges(messageTranslator, configState.language)}
                onChange={handleDateChange}
                className='stat-data-picker'
                value={dates}
              />
            </Col>
            <Col>
              <CustomSegmented
                options={segmentOptions}
                defaultValue={defaultUnit}
                onChange={handleSegmentedChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
    </>
  );
};
