import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR, FORGET_PASSWORD_RESET, FORGET_PASSWORD_SUCCESS, RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_RESET, RESET_PASSWORD_SUCCESS } from "./types";

export const resetPasswordInitialState = {
  request: {
    isLoading: false,
    isSent: false,
    errors: null
  },
  reset: {
    isLoading: false,
    isReset: false,
    errors: null
  }
};

export const resetPasswordReducer = (state = resetPasswordInitialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        request: {
          ...state.request,
          isLoading: true,
          errors: null
        }
      };

    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          isLoading: false,
          isSent: true
        }
      };

    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        request: {
          ...state.request,
          isLoading: false,
          errors: action.payload
        }
      };

    case FORGET_PASSWORD_RESET:
      return {
        ...state,
        request: {
          ...state.request,
          isSent: false,
          errors: null
        }
      };

    // * Reset password
    case RESET_PASSWORD:
      return {
        ...state,
        reset: {
          ...state.reset,
          isLoading: true,
          errors: null
        }
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset: {
          ...state.reset,
          isLoading: false,
          isReset: true
        }
      };

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        reset: {
          ...state.reset,
          isLoading: false,
          errors: action.payload
        }
      };

    case RESET_PASSWORD_RESET:
      return {
        ...state,
        reset: {
          ...state.reset,
          isReset: false,
          errors: null
        }
      };
  
    default:
      return state;
  }
};
