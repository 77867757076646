import { PERMISSIONS_GET, PERMISSIONS_GET_ERROR, PERMISSIONS_GET_SUCCESS } from "./types";

export const permissionsInitialState = {
  list: [],
  isLoading: false,
  errors: null
};

export const permissionsReducer = (state = permissionsInitialState, action) => {
  switch (action.type) {
    case PERMISSIONS_GET:
      return {
        ...state,
        isLoading: true,
        errors: null
      };

    case PERMISSIONS_GET_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false
      };

    case PERMISSIONS_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      };
  
    default:
      return state;
  }
};
