import { Button, Card, Col, Divider, Form, Input, message, Row, Select, Space } from 'antd';
import React, { useContext, useEffect } from 'react';
import { FileUploader } from '../../../../../components/FileUploader';
import { AuthContext } from '../../../../../context/Auth';
import { ConfigContext } from '../../../../../context/config';
import { CONFIG_NAMES } from '../../../../../utils/constants';
import { generateConfigList, isValidUrlRule, makeValidUrl } from '../../../../../utils/utils';

export const GlobalConfigForm = ({ config, alerts, onSubmitConfigs}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const { state } = useContext(AuthContext);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  useEffect(() => {

    if (config.data) setFieldsValue({
      ...config.data.values,
      logo: config.data?.values?.logo ? config.data.values.logo['@id'] : null,
      entityLogo: config.data?.values?.entityLogo ? config.data.values.entityLogo['@id'] : null,
      default_category_image: config.data?.values?.default_category_image ? config.data.values.default_category_image['@id'] : null,
      default_source_image: config.data?.values?.default_source_image ? config.data.values.default_source_image['@id'] : null
    })
  }, [config, setFieldsValue]);

  const extractIdfromIRI = (iri) => {
    let frags = [];
    if(typeof(iri) === 'string'){
      frags = iri.split("/");
    }else if(typeof(iri) === 'object'){
      frags = iri?.['@id'].split("/");
    }
    return frags[frags.length - 1];
  }

  const handleFormSubmit = (values) => {

    let newValues = {};

    if (values.logo) newValues.logo = extractIdfromIRI(values.logo);
    if (values.entity_logo) newValues.entity_logo = extractIdfromIRI(values.entity_logo);
    if (values.default_category_image) newValues.default_category_image = extractIdfromIRI(values.default_category_image);
    if (values.default_source_image) newValues.default_source_image = extractIdfromIRI(values.default_source_image)
    if (values.websiteName) newValues.websiteName = values.websiteName;
    if (values.inbox_link) newValues.inbox_link = values.inbox_link;

    if (values.twitter_url) newValues.twitter_url = makeValidUrl(values.twitter_url);
    if (values.facebook_url) newValues.facebook_url = makeValidUrl(values.facebook_url);
    if (values.youtube_url) newValues.youtube_url = makeValidUrl(values.youtube_url);
    if (values.instagram_url) newValues.instagram_url = makeValidUrl(values.instagram_url);
    if (values.linkedin_url) newValues.linkedin_url = makeValidUrl(values.linkedin_url);

    if (!Object.keys(newValues).length) {
      message.error(messageTranslator("config.settings.form.error.message", "config"));
      return;
    }
    onSubmitConfigs({
      name: CONFIG_NAMES.global,
      values: generateConfigList(newValues)
    });
  }

  return (
    <Card
      title={messageTranslator("config.menu.globalConfig.label", "config")}
      bordered={false}
    >
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
      >

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              hidden
              name="logo"
            >
              <Input />
            </Form.Item>
            <FileUploader
              label={messageTranslator("config.global.logo", "config")}
              token={state.token}
              imageSrc={config.data?.values?.logo?.contentUrl || ''}
              setFieldsValue={setFieldsValue}
              fieldName="logo"
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              hidden
              name="entity_logo"
            >
              <Input />
            </Form.Item>
            <FileUploader
              label={messageTranslator("config.global.entityLogo", "config")}
              token={state.token}
              imageSrc={config.data?.values?.entity_logo?.contentUrl || ''}
              setFieldsValue={setFieldsValue}
              fieldName="entity_logo"
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              name="websiteName"
              label={messageTranslator("config.global.websiteName", "config")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="inbox_link"
              label={messageTranslator("config.global.incoming.url", "config")}
            >
              <Select>
                {
                  alerts.map(alert => (
                    <Select.Option key={alert['@id']} value={`${alert.id}`}>
                      {alert.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              hidden
              name="default_category_image"
            >
              <Input />
            </Form.Item>
            <FileUploader
              label={messageTranslator("config.global.category.image.default", "config")}
              token={state.token}
              imageSrc={config.data?.values?.default_category_image?.contentUrl || ''}
              setFieldsValue={setFieldsValue}
              fieldName="default_category_image"
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              hidden
              name="default_source_image"
            >
              <Input />
            </Form.Item>
            <FileUploader
              label={messageTranslator("config.global.source.image.default", "config")}
              token={state.token}
              imageSrc={config.data?.values?.default_source_image?.contentUrl || ''}
              setFieldsValue={setFieldsValue}
              fieldName="default_source_image"
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.global.twitter.url", "config")}
              name="twitter_url"
              rules={[
                () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.global.facebook.url", "config")}
              name="facebook_url"
              rules={[
                () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.global.youtube.url", "config")}
              name="youtube_url"
              rules={[
                () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.global.instagram.url", "config")}
              name="instagram_url"
              rules={[
                () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.global.linkedin.url", "config")}
              name="linkedin_url"
              rules={[
                () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Space>
          <span className='btn-wrapper'>
            <Button type="primary" htmlType="submit">
              {messageTranslator("commons.save", "commons")}
            </Button>
          </span>
          <Button>
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </Space>
      </Form>
    </Card>
  );
};

