
/**
 * 
 * @param {string} token: Bearer token to add in the request headers.
 * @param {boolean} hasCustomButton: When you give a custom buton.
 * @returns Upload props
 */
export const uploadProps = (token, hasCustomButton, accept, file) => ({
  name: "file",
  action: `${process.env.REACT_APP_BASE_URL}/api/media_objects`,
  showUploadList: false,
  className: `rounded-preview ${hasCustomButton ? "no-upload-style" : ""}`,
  listType: file ? "text" : "picture-card",
  headers: { authorization: `Bearer ${token}` },
  method: 'POST',
  accept
});

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

// export const beforeUpload = (file) => {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//   if (!isJpgOrPng) {
//     message.error('You can only upload JPG/PNG file!');
//   }
//   // const isLt2M = file.size / 1024 / 1024 < 2;
//   // if (!isLt2M) {
//   //   message.error('Image must smaller than 2MB!');
//   // }
//   return isJpgOrPng /*&& isLt2M*/;
// };

export const generateFileName = (fileURL) => {
  const frags = fileURL.split("/");
  return frags[frags.length - 1];
};

/**
 * 
 * @param {FileData} info: Uploaded files informations.
 * @param {function} setLoadingUpload: Loading state dispatcher.
 * @param {function} setImageUrl: Dispatch image url state.
 * @param {function} setFieldsValue: Dispatch image field value.
 * @param {function} fieldName: Field name in which will be dispatched.
 * @returns void
 */
export const handleFileChange = (info, setLoadingUpload, setImageUrl, setFieldValue, fieldName, showFileName) => {
  if (info.file.status === 'uploading') {
    setLoadingUpload(true);
    return;
  }
  if (info.file.status === 'done') {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (imageUrl) => {
      setLoadingUpload(false);
      setImageUrl(info.file.response.contentUrl);
      setFieldValue({
        [fieldName]: info.file.response["@id"],
        [showFileName]: info.file.name
      })
    });
  }
};