import { Card, Col, Row, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { FORGOT_PASSWORD, LOGIN } from '../../../routes/paths';
import { resetPassword, resetPasswordReset } from '../../../store/resetPassword/actions';
import { ResetPasswordForm } from './components/ResetPasswordForm';

export const ResetPassword = ({ route, history }) => {

  const query = useQuery(history);

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const {
    resetPassword: {
      reset: { isLoading, isReset }
    }
  } = useSelector(state => state);

  const [resetToken, setResetToken] = useState(null);
  const [hasError, setError] = useState(null);

  useEffect(() => {
    if (query.has("token")) setResetToken(query.get("token"));
    else setError("auth.reset.form.error.noToken");
  }, [query]);

  useEffect(() => {
    if (isReset) {
      dispatch(resetPasswordReset());
      history.push(`${LOGIN}?reset-password="success"`);
    }
  }, [isReset, dispatch, history]);

  const handleSubmitPassword = (password) => dispatch(resetPassword({ resetToken, password }));

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={isLoading}>
        <Row justify="center" align="middle" className='forget-password-wrapper full-height'>
          <Col span={22}>
            <Card className='card-small'>
              {
                !!hasError ? (
                  <Link to={FORGOT_PASSWORD}>
                    {messageTranslator(hasError, "auth")}
                  </Link>
                ) : (
                  <>
                    <div className="header-forget-pwd">
                      <h1 className="h1">
                        {messageTranslator("auth.reset.form.title", "auth")}
                      </h1>
                      <Typography.Title level={5} type="secondary">
                        {messageTranslator("auth.reset.form.subtitle", "auth")}
                      </Typography.Title>
                    </div>
                    <ResetPasswordForm
                      isLoading={isLoading}
                      onSubmitPassword={handleSubmitPassword}
                    />
                  </>
                )
              }
            </Card>
          </Col>
        </Row>
      </Spin>
    </PageSkeleton>
  );
};
