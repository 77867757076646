import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { getResultIdFromIRI } from '../../../../../utils/results-utils';
import './index.less';

export const AddNoteForm = ({
  result,
  stateDispatcher,
  onToggleCheckedItems,
  onSubmitNote
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();

  const resultId = getResultIdFromIRI(result['@id']);

  const handleFormFinish = (values) => onSubmitNote({
    id: resultId,
    ...values
  });

  return (
    <Form
      name={`note-form-${resultId}`}
      className='note-form-wrapper'
      size='small'
      form={form}
      onFinish={handleFormFinish}
      initialValues={{
        auditorNotes: ''
      }}
    >
      <Row gutter={30} justify='space-between' align='middle'>
        <Col flex='auto'>
          <Form.Item
            name="auditorNotes"
          >
            <Input
              placeholder={messageTranslator("alerts.daily.addNote.placeholder", "alerts")}
            />
          </Form.Item>
        </Col>
        <Col flex='100px'>
          <Form.Item>
            <span className="btn-wrapper">
              <Button
                htmlType="submit"
              >
                {messageTranslator("commons.save", "commons")}
              </Button>
            </span>
          </Form.Item>
        </Col>
        <Col flex='20px'>
          <CloseOutlined onClick={() => onToggleCheckedItems(result, stateDispatcher, '@id')} />
        </Col>
      </Row>
    </Form>
  );
};
