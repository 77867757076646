import { Button, Col, Divider, Form, Input, Modal, Row, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ColorPicker } from '../../../../../components/ColorPicker';
import { FileUploader } from '../../../../../components/FileUploader';
import { AuthContext } from '../../../../../context/Auth';
import { ConfigContext } from '../../../../../context/config';
import { colors } from '../../../../../utils/data/category';
import { mergeErrors } from '../../../../../utils/errors-utils';

export const CategoryModal = ({
  isLoading,
  selectedCategory,
  createErrors,
  onOk,
  onCancel,
  ...modalProps
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const { state } = useContext(AuthContext);

  const [form] = Form.useForm();

  const uploadRef = useRef();

  const { setFieldsValue, setFields, submit, resetFields, getFieldsValue } = form;

  const [selectedColor, setSelectedColor] = useState(() => colors[0]);

  useEffect(() => {
    setFieldsValue({ color: colors[0] });
  }, [setFieldsValue]);

  useEffect(() => {
    if (selectedCategory) {
      setFieldsValue({ ...selectedCategory, image: selectedCategory.image ? selectedCategory.image['@id'] : null });
      setSelectedColor(selectedCategory.color);
    }
  }, [selectedCategory, setFieldsValue]);

  useEffect(() => {
    if (!modalProps.visible) {
      resetFields();
      if (uploadRef.current) uploadRef.current.resetImageUrl();
    }
  }, [resetFields, modalProps.visible]);

  useEffect(() => {
    if (createErrors) {
      const fields = mergeErrors(getFieldsValue(), createErrors, messageTranslator);
      setFields(fields);
    }
  }, [createErrors, getFieldsValue, messageTranslator, setFields]);

  const handleSelectedColor = (color) => {
    setFieldsValue({ color });
    setSelectedColor(color);
  };

  const handleFormFinish = ({ image, ...values }) => {
    if (image) values.image = image;
    onOk({
      ...values,
      order: parseInt(values.order),
      color: selectedColor
    });
  };

  return (
    <Modal
      {...modalProps}
      title={messageTranslator(selectedCategory ? "categories.update.title" : "categories.add.title", "categories")}
      onOk={onOk}
      onCancel={onCancel}
      getContainer={false}
      forceRender
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={onCancel}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className="btn-wrapper">
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={submit}
          >
            {messageTranslator(selectedCategory ? "commons.update" : "commons.add", "commons")}
          </Button>
        </span>
      ]}
    >
      <Form
        form={form}
        onFinish={handleFormFinish}
      >
        <Typography.Title level={4}>
          {messageTranslator("categories.form.label.general", "categories")}
        </Typography.Title>
        <Form.Item
          name="image"
          hidden
        >
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Input placeholder={messageTranslator("categories.form.name.placeholder", "categories")} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="order"
              rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
            >
              <Input type="number" placeholder={messageTranslator("categories.form.order.placeholder", "categories")} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={16}>
          <Col md={12} xs={24}>
            <FileUploader
              ref={uploadRef}
              label={messageTranslator("categories.form.icon.label", "categories")}
              token={state.token}
              imageSrc={selectedCategory?.image?.contentUrl}
              setFieldsValue={setFieldsValue}
              fieldName="image"
            />

          </Col>
          <Col md={12} xs={24}>
            <Typography.Title level={4}>
              {messageTranslator("categories.form.color.label", "categories")}
            </Typography.Title>
            <Form.Item
              name="color"
            >
              <Row gutter={[8, 12]}>
                {colors.map(color => (
                  <Col key={color} md={2}>
                    <ColorPicker
                      color={color}
                      selected={selectedColor}
                      onClick={handleSelectedColor}
                    />
                  </Col>
                ))}
              </Row>

            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
