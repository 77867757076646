import { SCRAP_RESULTS_GET, SCRAP_RESULTS_GET_ERROR, SCRAP_RESULTS_GET_SUCCESS } from "./types";

export const scrapResultsInitialState = {
  list: {
    isLoading: false,
    data: [],
    totalItems: 0,
    errors: null
  }
};

export const scrapResultsReducer = (state = scrapResultsInitialState, action) => {
  switch (action.type) {
    case SCRAP_RESULTS_GET:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null
        }
      };

    case SCRAP_RESULTS_GET_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.data,
          totalItems: action.payload.totalItems
        }
      };
    
    case SCRAP_RESULTS_GET_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload
        }
      };
  
    default:
      return state;
  }
};