import axios from "axios";
import { parseAxiosError } from "../utils/errors-utils";
import { TOKEN_NAME } from "../utils/token-utils";
import Cookies from "universal-cookie";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000,
});

const cookies = new Cookies();

/**
 * Handle Axios requests and put auth token in headers
 */
api.interceptors.request.use(
  (request) => {
    //const token = localStorage.getItem(TOKEN_NAME) || sessionStorage.getItem(TOKEN_NAME);
    const token = localStorage.getItem(TOKEN_NAME) || cookies.get(TOKEN_NAME);
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Handle Axios response errors
 */
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(parseAxiosError(error));
  }
);
