import { Row, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyData } from '../../../components/EmptyData';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { PermissionsHandler } from '../../../components/PermissionHandler';
import { ProfileMenu } from '../../../components/ProfileMenu';
import { ConfigContext } from '../../../context/config';
import { changePassword, changePasswordReset, getUserById } from '../../../store/users/actions';
import { CAN_PUT } from '../../../utils/permissions-utils';
import { PasswordCard } from './components/PasswordCard';
import { AuthContext, checkToken } from '../../../context/Auth';
import { logoutAction } from '../../../store/logout';

export const ChangePassword = ({ history, match: { params: { id } } }) => {

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const dispatch = useDispatch();

  const {
    user: { data: user, isLoading: loadingUser },
    me: { data: meData },
    changePassword: changeUserPassword,
  } = useSelector(state => state.users);

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  useEffect(() => {
    !!id && dispatch(getUserById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (changeUserPassword.isChanged) dispatch(changePasswordReset());
  }, [changeUserPassword.isChanged, dispatch]);

  const handleChangePassword = (passwords) => dispatch(changePassword(passwords));

  return (
    <PageSkeleton pageTitle={`${user?.firstName} ${user?.lastName}`}>
      <Spin spinning={loadingUser || changeUserPassword.isLoading}>
        {
          user ? (
            <Row gutter={[30, 20]} justify="center" wrap="false" className='tabs-wrapper'>
              <PermissionsHandler
                resourceName="User"
                blocPermission={CAN_PUT}
                userRole={meData?.role}
                connectedUserId={meData?.id}
                ownerId={id}
              >
                <ProfileMenu
                  history={history}
                  id={id}
                />
              </PermissionsHandler>
              <PasswordCard
                changePassword={changeUserPassword}
                onSubmit={handleChangePassword}
              />
            </Row>
          ) : (<EmptyData
            description={messageTranslator("users.empty", "users")}
          />)
        }
      </Spin>
    </PageSkeleton>
  );
};
