import { put, takeLatest } from "redux-saga/effects";
import {
  getNotificationsApi,
  markNotificationSeenApi,
} from "../../api/notifications";
import {
  getNotifications,
  getNotificationsError,
  getNotificationsSuccess,
  markNotificationsAsSeenError,
  markNotificationsAsSeenSuccess,
} from "./actions";
import { NOTIFICATIONS_GET, NOTIFICATIONS_SET_SEEN } from "./types";

// * Get notifs
function* getNotificationsSaga() {
  try {
    const res = yield getNotificationsApi();
    yield put(
      getNotificationsSuccess({
        list: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(getNotificationsError());
  }
}

export function* watchGetNotifications() {
  yield takeLatest(NOTIFICATIONS_GET, getNotificationsSaga);
}

// * Mark as seen
function* markNotificationSeenSaga({ payload: id }) {
  try {
    yield markNotificationSeenApi(id);
    yield put(getNotifications());
    yield put(markNotificationsAsSeenSuccess());
  } catch (error) {
    yield put(markNotificationsAsSeenError(error));
  }
}

export function* watchMarkNotificationSeen() {
  yield takeLatest(NOTIFICATIONS_SET_SEEN, markNotificationSeenSaga);
}
