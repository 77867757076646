import { put, takeLatest } from "redux-saga/effects";
import {
  createConfigApi,
  getConfigApi,
  sendTestEMailApi,
  updateConfigApi,
} from "../../api/config";
import { CONFIG_NAMES } from "../../utils/constants";
import {
  createConfigError,
  createConfigSuccess,
  getConfigError,
  getConfigSuccess,
  loadGlobalConfigSuccess,
  sendTestEmailError,
  sendTestEmailSuccess,
  updateConfigError,
  updateConfigSuccess,
} from "./actions";
import {
  CONFIG_CREATE,
  CONFIG_GET,
  CONFIG_UPDATE,
  EMAIL_CONFIG_SEND_TEST,
  GLOABL_CONFIG_LOAD,
} from "./types";

// * Create email config
function* createConfigSaga({ payload: config }) {
  try {
    yield createConfigApi(config);
    yield put(createConfigSuccess());
  } catch (error) {
    yield put(createConfigError(error));
  }
}

export function* watchCreateConfig() {
  yield takeLatest(CONFIG_CREATE, createConfigSaga);
}

// * Get email config
function* getConfigSaga({ payload: name }) {
  try {
    const res = yield getConfigApi(name);
    yield put(getConfigSuccess(res.data));
  } catch (error) {
    yield put(getConfigError(error));
  }
}

export function* watchGetConfig() {
  yield takeLatest(CONFIG_GET, getConfigSaga);
}

// * Update email config
function* updateConfigSaga({ payload: config }) {
  try {
    const res = yield updateConfigApi(config);
    yield put(updateConfigSuccess(res.data));
  } catch (error) {
    yield put(updateConfigError(error));
  }
}

export function* watchUpdateConfig() {
  yield takeLatest(CONFIG_UPDATE, updateConfigSaga);
}

// * Send email test
function* sendEmailTestSaga({ payload: email }) {
  try {
    yield sendTestEMailApi(email);
    yield put(sendTestEmailSuccess());
  } catch (error) {
    yield put(sendTestEmailError(error));
  }
}

export function* watchSendEmailTest() {
  yield takeLatest(EMAIL_CONFIG_SEND_TEST, sendEmailTestSaga);
}

// * Send email test
function* loadGlobalConfigSaga() {
  try {
    const res = yield getConfigApi(CONFIG_NAMES.global);
    yield put(loadGlobalConfigSuccess(res.data));
  } catch (error) {}
}

export function* watchLoadGlobalConfig() {
  yield takeLatest(GLOABL_CONFIG_LOAD, loadGlobalConfigSaga);
}
