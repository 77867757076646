import { Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { ColorPicker } from '../../../../../components/ColorPicker';
import { ConfigContext } from '../../../../../context/config';
import { colors } from '../../../../../utils/data/category';
import { mergeErrors } from '../../../../../utils/errors-utils';
import { OWN } from '../../../../../utils/permissions-utils';

const getPermissionsResources = (arr) => {
  let newArr = [];
  arr.forEach(el => {
    if (!newArr.includes(el.resource)) newArr.push(el.resource);
  });
  return newArr;
};

const getResourcePermissions = (arr, resource) => arr.filter(el => el.resource === resource);

export const RoleFormModal = ({
  modalProps,
  permissions,
  role,
  errors,
  isLoading,
  onCancel,
  onOk
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [resources, setResources] = useState([]);
  const [checked, setChecked] = useState([]);
  const [selectedColor, setSelectedColor] = useState(() => colors[0]);

  const [form] = useForm();
  const { submit, setFieldsValue, getFieldsValue, setFields, resetFields } = form;

  useEffect(() => {
    setFieldsValue({ color: colors[0] });
  }, [setFieldsValue]);

  useEffect(() => {
    if (role) {
      setFieldsValue({
        title: role.title,
        color: role.color
      });
      setChecked(role.permissions.map(perm => perm['@id']));
      setSelectedColor(role.color);
    }
  }, [role, setFieldsValue]);

  useEffect(() => {
    setResources(getPermissionsResources(permissions));
  }, [permissions]);

  useEffect(() => {
    if (errors) {
      const values = mergeErrors(getFieldsValue(), errors, messageTranslator);
      !!setFields && !!values?.length && setFields(values);
    }
  }, [errors, getFieldsValue, messageTranslator, setFields]);

  const handleSelectedColor = (color) => {
    setFieldsValue({ color });
    setSelectedColor(color);
  };

  const handleCheckboxCheck = (option) => setChecked(prevState => {
    if (checked.includes(option['@id'])) return prevState.filter(el => el !== option['@id']);
    return [...prevState, option['@id']];
  });

  const onFinish = (values) => onOk({
    ...values,
    permissions: checked
  });

  const handleFinishFailed = () => message.error(messageTranslator("roles.add.message.error", "roles"));

  const handleCancel = () => {
    onCancel();
    resetFields();
    setChecked([]);
  }

  return (
    <Modal
      {...modalProps}
      title={(
        <Typography.Title level={2}>{messageTranslator(role ? "roles.update.form.title" : "roles.add.form.title", "roles")}</Typography.Title>
      )}
      getContainer={false}
      forceRender
      onOk={onOk}
      onCancel={handleCancel}
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={handleCancel}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className='btn-wrapper'>
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            disabled={isLoading}
            onClick={submit}
          >
            {messageTranslator(role ? "commons.update" : "commons.add", "commons")}
          </Button>
        </span>
      ]}
    >
      <Form
        form={form}
        name="addUser"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={handleFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="title"
          rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
        >
          <Input placeholder={messageTranslator("roles.add.label.title", "roles")} />
        </Form.Item>
        <Form.Item
          name="color"
          rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
        >
          <Row gutter={[8, 12]}>
            {colors.map(color => (
              <Col key={color} md={2}>
                <ColorPicker
                  color={color}
                  selected={selectedColor}
                  onClick={handleSelectedColor}
                />
              </Col>
            ))}
          </Row>
        </Form.Item>
        {
          resources.map(resource => (
            <Row key={resource}>
              <Col span={24}>
                <Divider orientation="right">
                  {messageTranslator(resource, "permissions")}
                </Divider>
                {
                  getResourcePermissions(permissions, resource).map(el => (
                    <Row key={el.id}>
                      <Col>
                        <Checkbox
                          checked={checked.includes(el['@id'])}
                          onChange={() => handleCheckboxCheck(el)}
                        >
                          {`
                            ${messageTranslator(el.permission, "permissions")} 
                            ${!!(el.possessor === OWN) ? `(${messageTranslator(OWN, "permissions")})` : ''}
                          `}
                        </Checkbox>
                      </Col>
                    </Row>
                  ))
                }
              </Col>
            </Row>
          ))
        }
      </Form>
    </Modal>
  );
};
