import { ArrowRightOutlined, ClockCircleFilled, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Dropdown, Menu, message, Modal, Row, Space, Typography, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import { ConfigContext } from '../../../../../context/config';
import { REPORTS } from '../../../../../routes/paths';
import { cancelScheduleReset } from '../../../../../store/reports/actions';
import { REPORT_FORMAT } from '../../../../../utils/constants';
import { ALL } from '../../../../../utils/data/category';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { CAN_CREATE, CAN_DELETE } from '../../../../../utils/permissions-utils';
import { patchMomentArabicLocal, patchMomentEnglishLocal } from '../../../../../utils/moment-utils';
import "./reportHeader.less";

const DELETE_REPORT = "DELETE_REPORT";
const DELETE_SCHEDULE = "DELETE_SCHEDULE";

export const ReportHeader = ({
  report,
  delete: { isLoading },
  isCancelledSchedule,
  user,
  onExportReport,
  onScheduleClick,
  onDeleteReport,
  onCancelSchedule
}) => {

  const [isModalVisible, setModalVisibility] = useState(false);
  const [action, setAction] = useState("");

  const { messageTranslator, configState } = useContext(ConfigContext);
  const [, setArabic] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (configState.language === "ar") {
      setArabic(true);
      patchMomentArabicLocal();
    } else {
      setArabic(false);
      patchMomentEnglishLocal();
    } 
  }, [configState.language]);

  useEffect(() => {
    if (isCancelledSchedule) {
      handleCloseModal();
      setAction("");
      dispatch(cancelScheduleReset());
      message.success(messageTranslator("reports.view.header.menu.schedule.delete.success.text", "reports"));
    }
  }, [isCancelledSchedule, dispatch, messageTranslator]);

  const handleDeleteReport = () => {
    setAction(DELETE_REPORT);
    setModalVisibility(true);
  };

  const handleDeleteChedule = () => {
    setAction(DELETE_SCHEDULE);
    setModalVisibility(true);
  };

  const menuOptions = (hasScheduledReport) => {
    let options = [
      {
        key: "pdf",
        label: "reports.view.header.menu.downloadPDF",
        onClick: () => onExportReport({ format: REPORT_FORMAT.pdf })
      },
      !report.disableHtmlMail && {
        key: "html",
        label: "reports.view.header.menu.sendMailHTML",
        onClick: () => onExportReport({ format: REPORT_FORMAT.html })
      },
      {
        key: "mail",
        label: "reports.view.header.menu.sendMail",
        onClick: () => onExportReport({ format: REPORT_FORMAT.mail })
      },
      {
        key: "delete",
        label: "reports.view.header.menu.delete",
        onClick: handleDeleteReport
      }
    ];

    if (hasScheduledReport) options.push({
      key: "deleteSch",
      label: "reports.view.header.menu.chedule.delete",
      onClick: handleDeleteChedule
    });
    else options = options.filter(opt => opt.key && opt.key !== "deleteSch");
    return options;
  };

  const handleCloseModal = () => setModalVisibility(false);

  const handleConfirmDelete = () => action === DELETE_REPORT ? onDeleteReport(report.id) : onCancelSchedule(report?.scheduledReport?.id);
  const renderMenuOptions = (options) => (
    <Menu>
      {
        options.map(({ label, onClick, ...rest }) => {
          return rest["key"] === "delete" || rest["key"] === "deleteSch" ? 
          <PermissionsHandler
            resourceName="FolderReport"
            blocPermission={CAN_DELETE}
            userRole={user?.role}
            {...rest}
          >
            <Menu.Item {...rest} onClick={() => onClick(true)}>
              {messageTranslator(label, "reports")}
            </Menu.Item> 
          </PermissionsHandler>
          : <Menu.Item {...rest} onClick={() => onClick(true)}>
            {messageTranslator(label, "reports")}
          </Menu.Item>
        })
      }
    </Menu>
  );

  return (
    <>
      <div className='report-header-wrapper'>
        <Row gutter={8} justify="space-between" align='middle'>

          <Col>
            <Dropdown
              overlay={renderMenuOptions(menuOptions(!!report?.scheduledReport))}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button icon={<MoreOutlined />} className='more-btn' />
            </Dropdown>
          </Col>

          <PermissionsHandler
            resourceName="FolderReport"
            blocPermission={CAN_CREATE}
            userRole={user?.role}
          >
            <Col className='actions-wrapper'>
              <ul className='action-list'>
                <li>
                  <Avatar size="small" />
                </li>
                <li>
                  <Avatar size="small" />
                </li>
                <li>
                  <Link to={`${REPORTS}/all#create`}>
                    <Avatar
                      size="small"
                      icon={<PlusOutlined />}
                    />
                  </Link>
                </li>
              </ul>
            </Col>

            <Col flex='auto'>
              <Space>
                <Button
                    icon={<ClockCircleFilled />}
                    onClick={onScheduleClick}
                    className='planing-report-btn'
                >
                  {!!report?.scheduledReport ? messageTranslator("reports.edit.header.scheduling", "reports") : messageTranslator("reports.view.header.scheduling", "reports")}
                </Button>
              </Space>
            </Col>
          </PermissionsHandler>

          <Col>
            <Space>
              <Link to={`${REPORTS}/${ALL}`}>
                <Button className='reports-all'>
                  {messageTranslator("reports.view.header.all.reports", "reports")}
                  <ArrowRightOutlined className="transformMiroir" />
                </Button>
              </Link>
            </Space>
          </Col>

        </Row>

        <div className="info-report">
          <Row justify="center" align="middle">
            <Col>
              <Avatar size={85}>
                <img src="/images/logo-rvb.png" alt="Logo" />
              </Avatar>
              <Typography.Title level={3}>{report.name}</Typography.Title>
              <Typography.Paragraph type="secondary" className='desc-report'>{report.description}</Typography.Paragraph>
              <Typography.Paragraph type="secondary" className='info'>
                {messageTranslator("reports.list.card.createdAt", "reports")} {moment(report.createdAt).tz("Asia/Riyadh").format('DD MMMM YYYY, HH:mm')}
              </Typography.Paragraph>
              {!moment(report.startDate).tz("Asia/Riyadh").isSame(moment(report.toDate).tz("Asia/Riyadh"), 'date') && (
                  <Typography.Paragraph type="secondary" className='info'>
                    {messageTranslator("reports.list.card.fromDate", "reports")} {moment(report.startDate).tz("Asia/Riyadh").format('DD MMMM YYYY')} {messageTranslator("reports.list.card.toDate", "reports")} {moment(report.toDate).tz("Asia/Riyadh").format('DD MMMM YYYY')}
                  </Typography.Paragraph>
              )}
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        title={messageTranslator("reports.view.header.menu.delete.modal.title", "reports")}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <span key="cancel" className='btn-wrapper white'>
            <Button
              key="back"
              onClick={handleCloseModal}
            >
              {messageTranslator("commons.cancel", "commons")}
            </Button>
          </span>,
          <span key="ok" className='btn-wrapper'>
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={handleConfirmDelete}
            >
              {messageTranslator("commons.delete.confirm", "commons")}
            </Button>
          </span>
        ]}
      >
        <Typography.Text>
          {messageTranslator(action === DELETE_REPORT ? "reports.view.header.menu.delete.modal.text" : "reports.view.header.menu.schedule.delete.modal.text", "reports")} {report.name}
        </Typography.Text>
      </Modal>

    </>
  );
};
