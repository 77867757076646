import { removeItemFromArray, replaceItemInArray } from "../../utils/utils";
import { ROLES_CREATE, ROLES_CREATE_ERROR, ROLES_CREATE_SUCCESS, ROLES_DELETE, ROLES_DELETE_ERROR, ROLES_DELETE_SUCCESS, ROLES_GET_BY_ID, ROLES_GET_BY_ID_ERROR, ROLES_GET_BY_ID_SUCCESS, ROLES_LIST, ROLES_LIST_ERROR, ROLES_LIST_SUCCESS, ROLES_RESET_CREATED, ROLES_RESET_DELETED, ROLES_RESET_UPDATED, ROLES_UPDATE, ROLES_UPDATE_ERROR, ROLES_UPDATE_SUCCESS } from "./types";

export const rolesInitialState = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null
  },
  role: {
    data: null,
    isLoading: false,
    errors: null
  },
  list: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null
  },
  delete: {
    isLoading: false,
    isDeleted: false,
    errors: null
  }
};

export const rolesReducer = (state = rolesInitialState, action) => {
  switch (action.type) {
    case ROLES_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null
        }
      };

    case ROLES_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true
        },
        list: {
          ...state.list,
          data: [action.payload, ...state.list.data],
          totalItems: state.list.totalItems + 1
        }
      };

    case ROLES_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload
        }
      };

    case ROLES_RESET_CREATED:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: false,
          errors: null
        }
      };

    case ROLES_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null
        }
      };

    case ROLES_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.roles,
          totalItems: action.payload.totalItems
        }
      };

    case ROLES_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload
        }
      };

    case ROLES_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: true,
          errors: null
        }
      };

    case ROLES_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false
        },
        list: {
          ...state.list.data,
          data: removeItemFromArray(action.payload, state.list.data),
          totalItems: state.list.totalItems - 1
        }
      };

    case ROLES_DELETE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          errors: action.payload
        }
      };

    case ROLES_RESET_DELETED:
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleted: false,
          errors: null
        }
      };

    case ROLES_GET_BY_ID:
      return {
        ...state,
        role: {
          ...state.role,
          isLoading: true,
          errors: null
        }
      };

    case ROLES_GET_BY_ID_SUCCESS:
      return {
        ...state,
        role: {
          ...state.role,
          isLoading: false,
          data: action.payload
        }
      };

    case ROLES_GET_BY_ID_ERROR:
      return {
        ...state,
        role: {
          ...state.role,
          isLoading: false,
          errors: action.payload
        }
      };

    case ROLES_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
          errors: null
        }
      };

    case ROLES_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true
        },
        list: {
          ...state.list,
          data: replaceItemInArray(action.payload, state.list.data, 'id')
        }
      };

    case ROLES_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload
        }
      };

    case ROLES_RESET_UPDATED:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null
        }
      };
  
    default:
      return state;
  }
};
