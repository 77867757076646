import axios from "axios";
import { api } from "..";
import { formatArrayToUrl } from "../../utils/api-utils";
import {
  ACTIVE,
  ARCHIVED,
  BOOKMARKED,
  DELETED,
  UNDESIRABLE,
  UNSEEN,
} from "../../utils/data/category";

const URL = "api/alert_results";

const createAlertResultCategory = (category) => {
  switch (category) {
    case UNSEEN:
      return "seen=false";

    case BOOKMARKED:
      return "bookmarked=true";

    case ARCHIVED:
      return `status=${ARCHIVED}`;

    case DELETED:
      return `status=${DELETED}`;

    case UNDESIRABLE:
      return `status=${UNDESIRABLE}`;

    default:
      return `status=${ACTIVE}`;
  }
};

export const getNewAlertResultsApi = ({ id, newsDate }) => {
  return api.get(`${URL}?page=1&alert=${id}&status=active`);
};

export const getAlertResultsApi = ({ page, id, category }) => {
  const term = createAlertResultCategory(category);
  return api.get(`${URL}?page=${page}&alert=${id}&${term}`);
};

export const searchForSourceNamesApi = ({term, id }) => {
  let query = `sources-names?alert=${id}`;
  if (term) query += `&keyword=${term}`;
  return api.get(`${URL}/${query}`);
};

export const advancedSearchApi = (terms) => {
  let requestTerms = `page=${terms.page}&alert=${terms.id}`;

  if (!terms.status) requestTerms += `&status=active`;
  else if (terms.status === UNSEEN) requestTerms += `&seen=false`;
  else if (terms.status === BOOKMARKED) requestTerms += `&bookmarked=true`;
  else requestTerms += `&status=${terms.status}`;

  if (terms.search) requestTerms += `&search=${terms.search}`;
  if (terms.from) requestTerms += `&createdAt[after]=${terms.from} 00:00:00`;
  if (terms.to) requestTerms += `&createdAt[before]=${terms.to} 23:59:59`;
  if (terms.sourceName?.length)
    requestTerms += formatArrayToUrl(terms.sourceName, "sourceName");
  if (terms.sources?.length)
    requestTerms += formatArrayToUrl(terms.sources, "sourceType");
  if (terms.languages?.length)
    requestTerms += formatArrayToUrl(terms.languages, "language");
  if (terms.sentiments?.length)
    requestTerms += formatArrayToUrl(terms.sentiments, "sentiment");

  return api.get(`${URL}?${requestTerms}`);
};

export const patchResultsApi = ({ id, ...data }) =>
  api.patch(`${URL}/${id}`, data);

export const resultsSearchApi = (term) => api.get(`${URL}?search=${term}`);

export const filterBySourceTypeApi = ({
  id,
  page = 1,
  sourceType,
  category,
}) => {
  let query = `?alert=${id}&page=${page}&sourceType=${sourceType}`;

  if (!category) query += "&status=active";
  else if (category === UNSEEN) query += `&seen=false`;
  else if (category === BOOKMARKED) query += `&bookmarked=true`;
  else query += `&status=${category}`;
  return api.get(`${URL}${query}`);
};
