import { CaretDownOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ConfigContext } from '../../../../../context/config';
import { CINFIG_SET_ALERT_FRAGMENT } from '../../../../../context/config/dispatch-config-state';
import { FOLDER_STATES, ROLES } from '../../../../../utils/constants';
import { DAILY } from '../../../../../utils/data/category';
import "./index.less";

export const SendFolderToReviewModal = ({
  title,
  noticePlaceholder,
  alertId,
  currentPublisher,
  listUsers: { data: users, isLoading: loadingUsers },
  isLoading,
  isUpdated,
  onResetUpdate,
  onModalClose,
  onOk,
  ...rest
}) => {

  const { messageTranslator, configDispatcher } = useContext(ConfigContext);

  const [form] = Form.useForm();

  const { submit, resetFields } = form;

  const handleFormFinish = (data) => onOk({
    ...data,
    publisher: currentPublisher?.['@id'],
    auditorNotes: form.getFieldValue("auditorNotes") ? form.getFieldValue("auditorNotes") : "",
    auditor: form.getFieldValue(ROLES.AUDITOR),
    moderationState: FOLDER_STATES.NEEDS_REVIEW
  });

  const handleCloseModal = () => {
    onResetUpdate();
    onModalClose();
    configDispatcher(CINFIG_SET_ALERT_FRAGMENT, DAILY);
  };

  useEffect(() => {
    if (!rest.visible) resetFields();
  }, [rest.visible, resetFields]);

  const renderSuccessContent = () => (
    <Space direction='vertical' size={22} className="confirm-send full-width">
      <CheckCircleFilled />
      <Typography.Title level={2}>
        {messageTranslator("alerts.view.banner.sentToReview", "alerts")}
      </Typography.Title>
      <Typography.Text type="secondary">{messageTranslator("alerts.view.banner.openFolder", "alerts")} <Link to={`/inbox/${alertId}/${DAILY}`} onClick={handleCloseModal}>{messageTranslator("alerts.view.banner.clickHere", "alerts")}</Link></Typography.Text>
    </Space>
  );

  return (
    <Modal
      title={!isUpdated && messageTranslator(title, "alerts")}
      onOk={onOk}
      className="small-modal" 
      onCancel={onModalClose}
      getContainer={false}
      forceRender
      centered={isUpdated}
      footer={isUpdated ? null : [
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={onModalClose}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className="btn-wrapper">
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={submit}
          >
            {messageTranslator("commons.send", "commons")}
          </Button>
        </span>
      ]}
      {...rest}
    >
      {
        isUpdated ?
          renderSuccessContent() : (
            <>
              <Typography.Title level={4} type="secondary">{messageTranslator("alerts.view.banner.chooseAuditor", "alerts")}</Typography.Title>
              <Form
                form={form}
                name="send-folder-to-review"
                onFinish={handleFormFinish}
              >
                <Form.Item
                  name={ROLES.AUDITOR}
                  rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
                >
                  <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} 
                    placeholder={messageTranslator("alerts.view.banner.placeholder.chooseAuditor", "alerts")}
                    loading={loadingUsers}
                  >
                    {
                      users.map(user => (
                        <Select.Option key={user['@id']} value={user['@id']}>{user.firstName} {user.lastName}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item name={"auditorNotes"}>
                  <Input.TextArea
                    placeholder={`${messageTranslator(noticePlaceholder, "alerts")}...`}
                    autoSize={{ minRows: 6, maxRows: 12 }}
                  />
                </Form.Item>
              </Form>
            </>
          )
      }
    </Modal>
  )
}
