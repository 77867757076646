import { Card, Typography, Spin } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../context/config';
import './styles.less';

export const PopularHashTags = ({ hashtags, isLoading }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const getClassName = (index) => {
    if (index < 3) return "level-one";
    if (index < 5) return "level-two";
    if (index < 7) return "level-three";
    return "level-four";
  }

  return (
    <Spin spinning={isLoading}>
      <Card
        title={messageTranslator("stats.hastags.card.title", "stats")}
        className="static-card hastags-card"
      >
        <div className='tags-list'>
          {
            hashtags ? Object.keys(hashtags.results).map((key, index) => (
              <Typography.Link key={key}
                href={`https://twitter.com/hashtag/${key}?src=hashtag_click`}
                target="_blank"
                className={getClassName(index + 1)}
              >
                #{key}
              </Typography.Link>
            )) : null
          }
        </div>
      </Card>
    </Spin>
  );
};
