// * Create
export const CATEGORIES_CREATE         = 'CATEGORIES_CREATE';
export const CATEGORIES_CREATE_SUCCESS = 'CATEGORIES_CREATE_SUCCESS';
export const CATEGORIES_CREATE_ERROR   = 'CATEGORIES_CREATE_ERROR';
export const CATEGORIES_RESET_CREATE   = 'CATEGORIES_RESET_CREATE';

// * List
export const CATEGORIES_LIST           = 'CATEGORIES_LIST';
export const CATEGORIES_LIST_SUCCESS   = 'CATEGORIES_LIST_SUCCESS';
export const CATEGORIES_LIST_ERROR     = 'CATEGORIES_LIST_ERROR';

// * Update
export const CATEGORIES_UPDATE         = 'CATEGORIES_UPDATE';
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS';
export const CATEGORIES_UPDATE_ERROR   = 'CATEGORIES_UPDATE_ERROR';
export const CATEGORIES_RESET_UPDATE   = 'CATEGORIES_RESET_UPDATE';

// * Delete
export const CATEGORIES_DELETE         = 'CATEGORIES_DELETE';
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS';
export const CATEGORIES_DELETE_ERROR   = 'CATEGORIES_DELETE_ERROR';
export const CATEGORIES_RESET_DELETE   = 'CATEGORIES_RESET_DELETE';