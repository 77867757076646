import { CaretDownOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Select, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import TagsInput from 'react-tagsinput';
import { ConfigContext } from '../../../../../context/config';
import { languages } from '../../../../../utils/data/languages';
import { isValidUrlRule, makeValidUrl } from '../../../../../utils/utils';

const tagsFlatten = (arr) => arr.map(({ tagName }) => (tagName));
const reObjectTags = (arr) => arr.map(tag => ({ tagName: tag }));

export const UpdateAlert = ({
  onOk,
  onCancel,
  selectedALert,
  duplication: { data, identifiers },
  isLoading,
  onNameChange,
  ...modalProps
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();
  const { submit, setFieldsValue, setFields } = form;

  const [optionalTags, setOptionalTags] = useState([]);
  const [requiredTags, setRequiredTags] = useState([]);
  const [excludedTags, setExcludedTags] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [mentions, setMentions] = useState([]);

  const [followUrl, setFollowUrl] = useState(false);
  const [followTwitterUrl, setFollowTwitterUrl] = useState(false);
  const [followYoutubeUrl, setFollowYoutubeUrl] = useState(false);

  useEffect(() => {
    if (!!data === true && !identifiers.includes(selectedALert?.id)) setFields([{
      name: 'name',
      errors: [messageTranslator("alerts.add.duplicated.name", "alerts")]
    }]);
  }, [data, identifiers, setFields, selectedALert, messageTranslator]);

  useEffect(() => {
    if (selectedALert) {
      setFieldsValue({
        ...selectedALert,
        languages: selectedALert.languages.map(it => it['@id'])
      });
      setFollowUrl(selectedALert.followUrl);
      setFollowTwitterUrl(selectedALert.followTwitterUrl);
      setFollowYoutubeUrl(selectedALert.followYoutubeUrl);
      setOptionalTags(tagsFlatten(selectedALert.orTags));
      setRequiredTags(tagsFlatten(selectedALert.andTags));
      setExcludedTags(tagsFlatten(selectedALert.excludedTags));
      setHashtags(selectedALert.hashtags || [])
      setMentions(selectedALert.mentions || [])
    }
  }, [selectedALert, setFieldsValue, form]);

  const onFinish = ({ url, facebookUrl, twitterUrl, instagramUrl, youtubeUrl, ...values }) => {
    if (
      (data === true && identifiers.includes(selectedALert?.id))
      ||
      (data === false)
      ||
      optionalTags.length
      ||
      requiredTags.length
    ) {
      let newAlert = {
        ...values,
        followUrl,
        followTwitterUrl,
        followYoutubeUrl,
        andTags: reObjectTags(requiredTags),
        orTags: reObjectTags(optionalTags),
        excludedTags: reObjectTags(excludedTags),
        hashtags: hashtags,
        mentions: mentions
      };
      newAlert.twitterUrl = twitterUrl ? makeValidUrl(twitterUrl) : '';
      newAlert.instagramUrl = instagramUrl ? makeValidUrl(instagramUrl) : '';
      newAlert.facebookUrl = facebookUrl ? makeValidUrl(facebookUrl) : '';
      newAlert.youtubeUrl  = youtubeUrl ? makeValidUrl(youtubeUrl) : '';
      newAlert.url = url ? makeValidUrl(url) : '';
      onOk(newAlert);
    }
  };

  const handleCheckboxChange = (e, dispatcher) => dispatcher(state => !state);

  const handleNameChange = (event) => onNameChange(event.target.value);

  return (
    <Modal
      {...modalProps}
      title={messageTranslator("alerts.update.modal.title", "alerts")}
      onOk={onOk}
      onCancel={onCancel}
      getContainer={false}
      forceRender
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={onCancel}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className='btn-wrapper'>
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={submit}
          >
            {messageTranslator("commons.update", "commons")}
          </Button>
        </span>
      ]}
    >
      <Form
        form={form}
        name='update-alert'
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={[24, 8]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("alerts.update.name.label", "alerts")}
              name="name"
              rules={[
                { required: true, message: messageTranslator("commons.required.message", "commons") }
              ]}
            >
              <Input
                placeholder={messageTranslator("alerts.update.name.placeholder", "alerts")}
                onChange={handleNameChange}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("alerts.update.url.label", "alerts")}
              name="url"
              rules={[
                () => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))
              ]}
            >
              <Input placeholder={messageTranslator("alerts.update.url.placeholder", "alerts")} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              name="followUrl"
            >
              <Checkbox
                onChange={(event) => handleCheckboxChange(event, setFollowUrl)}
                checked={followUrl}
              >
                <Typography.Text className='normalTypo'>
                  {messageTranslator("alerts.update.checkbox.label", "alerts")}
                </Typography.Text>
                <br />
                <Typography.Text className='smallTypo'>
                  {messageTranslator("alerts.update.checkbox.description", "alerts")}
                </Typography.Text>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 8]}>
          <Col md={12} xs={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={messageTranslator("alerts.add.socialMedia.twitter", "alerts")}
                  name="twitterUrl"
                  rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
                >
                  <Input placeholder={messageTranslator("alerts.add.socialMedia.twitter", "alerts")} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="followTwitterUrl"
                >
                  <Checkbox
                    onChange={(event) => handleCheckboxChange(event, setFollowTwitterUrl)}
                    checked={followTwitterUrl}
                  >
                    <Typography.Text className='normalTypo'>
                      {messageTranslator("alerts.update.checkbox.label", "alerts")}
                    </Typography.Text>
                    {/* <br />
                    <Typography.Text className='smallTypo'>
                      {messageTranslator("alerts.update.checkbox.description", "alerts")}
                    </Typography.Text> */}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

          </Col>
          <Col md={12} xs={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={messageTranslator("alerts.add.socialMedia.youtube", "alerts")}
                  name="youtubeUrl"
                  rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
                >
                  <Input placeholder={messageTranslator("alerts.add.socialMedia.youtube", "alerts")} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="followYoutubeUrl"
                >
                  <Checkbox
                    onChange={(event) => handleCheckboxChange(event, setFollowYoutubeUrl)}
                    checked={followYoutubeUrl}
                  >
                    <Typography.Text className='normalTypo'>
                      {messageTranslator("alerts.update.checkbox.label", "alerts")}
                    </Typography.Text>
                    {/* <br />
                    <Typography.Text className='smallTypo'>
                      {messageTranslator("alerts.update.checkbox.description", "alerts")}
                    </Typography.Text> */}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {/* <Form.Item
              label={messageTranslator("alerts.add.socialMedia.facebook", "alerts")}
              name="facebookUrl"
              rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
            >
              <Input placeholder={messageTranslator("alerts.add.socialMedia.facebook", "alerts")} />
            </Form.Item> */}
          </Col>
        </Row>
        {/* <Row gutter={[24, 8]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("alerts.add.socialMedia.instagram", "alerts")}
              name="instagramUrl"
              rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
            >
              <Input placeholder={messageTranslator("alerts.add.socialMedia.instagram", "alerts")} />
            </Form.Item> */}
        {/* <Form.Item
              label={messageTranslator("alerts.add.socialMedia.youtube", "alerts")}
              name="youtubeUrl"
              rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
            >
              <Input placeholder={messageTranslator("alerts.add.socialMedia.youtube", "alerts")} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            {/* <Form.Item
              label={messageTranslator("alerts.add.socialMedia.instagram", "alerts")}
              name="instagramUrl"
              rules={[() => isValidUrlRule(messageTranslator("commons.url.invalidText", "commons"))]}
            >
              <Input placeholder={messageTranslator("alerts.add.socialMedia.instagram", "alerts")} />
            </Form.Item> 
          </Col>
        </Row>*/}
        <Row gutter={[24, 8]}>
          <Col md={12} xs={24}>
            <Form.Item>
              <TagsInput
                value={optionalTags}
                onChange={setOptionalTags}
                inputProps={{
                  placeholder: messageTranslator("commons.tags.optional", "commons")
                }}
              />

              {
                !optionalTags.length && !requiredTags.length && (
                  <Alert
                    type="error"
                    showIcon
                    message={messageTranslator("commons.tags.warning.required", "commons")}
                  />
                )
              }
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item>
              <TagsInput
                value={requiredTags}
                onChange={setRequiredTags}
                inputProps={{
                  placeholder: messageTranslator("commons.tags.required", "commons")
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item>
              <TagsInput
                value={excludedTags}
                onChange={setExcludedTags}
                inputProps={{
                  placeholder: messageTranslator("commons.tags.excluded", "commons")
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item>
              <TagsInput
                  value={hashtags}
                  onChange={setHashtags}
                  inputProps={{
                    placeholder: messageTranslator("commons.tags.hashtags", "commons")
                  }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 8]}>
          <Col md={12} xs={24}>
            <Form.Item>
              <TagsInput
                  value={mentions}
                  onChange={setMentions}
                  inputProps={{
                    placeholder: messageTranslator("commons.tags.mentions", "commons")
                  }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("alerts.add.langs.label", "alerts")}
              name="languages"
              rules={[
                { required: true, message: messageTranslator("commons.required.message", "commons") }
              ]}
            >
              <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} 
                mode="multiple"
                allowClear
                placeholder={messageTranslator("alerts.add.langs.placeholder", "alerts")}
              >
                {languages.map(lang => (
                  <Select.Option key={lang.value} value={lang.value}>
                    {messageTranslator(lang.label, "constants")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
