import { put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  advancedSearchApi,
  filterBySourceTypeApi,
  getAlertResultsApi,
  getNewAlertResultsApi,
  patchResultsApi,
  resultsSearchApi,
  searchForSourceNamesApi,
} from "../../api/alert-results";
import {
  addFolderApi,
  getDailyFolderApi,
  patchFolderAPi,
} from "../../api/folder";
import {
  addFolderError,
  addFolderSuccess,
  alertResultsAdvancedSearchError,
  alertResultsAdvancedSearchSuccess,
  auditorChangeFolderStateError,
  auditorChangeFolderStateSuccess,
  combineDuplicatedNewsError,
  combineDuplicatedNewsSuccess,
  filterBySourceTypeError,
  filterBySourceTypeSuccess,
  getNewAlertResultsSuccess,
  getNewAlertResultsError,
  getAlertResultsError,
  getAlertResultsSuccess,
  getFolderDaily,
  getFolderDailyError,
  getFolderDailySuccess,
  patchAlertResultActionsError,
  patchAlertResultActionsSuccess,
  patchFolderError,
  patchFolderSuccess,
  patchResultCategoryError,
  patchResultCategorySuccess,
  publishFolderError,
  publishFolderSuccess,
  removeItemFromFolderError,
  removeItemFromFolderSuccess,
  searchForResultsError,
  searchForResultsSuccess,
  searchSourceNamesError,
  searchSourceNamesSuccess,
  setFolderToReviewSuccess,
} from "./actions";
import {
  ALERT_RESULTS_ADVANCED_SEARCH,
  ALERT_RESULTS_FILTER_BY_SOURCE_TYPE,
  ALERT_RESULTS_GET,
  ALERT_NEW_RESULTS_GET,
  ALERT_RESULTS_PATCH_ACTIONS,
  ALERT_RESULTS_PATCH_CATEGORY,
  ALERT_RESULTS_SEARCH,
  FOLDER_ADD,
  FOLDER_CHANGE_STATE_AUDITOR,
  FOLDER_COMBINE_DUPLICATIONS,
  FOLDER_GET_DAILY,
  FOLDER_PATCH,
  FOLDER_PUBLISH,
  FOLDER_REMOVE_ITEM,
  FOLDER_SET_REVIEW,
  SEARCH_SOURCE_NAMES,
} from "./types";

// * Get alert results
function* getAlertResultsSaga({ payload: params }) {
  try {
    const res = yield getAlertResultsApi(params);
    yield put(
      getAlertResultsSuccess({
        results: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(getAlertResultsError(error));
  }
}

export function* watchGetALertResults() {
  yield takeLatest(ALERT_RESULTS_GET, getAlertResultsSaga);
}

// * Get new alert results
function* getNewAlertResultsSaga({ payload: params }) {
  try {
    const res = yield getNewAlertResultsApi(params);
    yield put(
      getNewAlertResultsSuccess({
        newResults: res.data["hydra:member"],
        newAlerts: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(getNewAlertResultsError(error));
  }
}

export function* watchGetNewALertResults() {
  yield takeLatest(ALERT_NEW_RESULTS_GET, getNewAlertResultsSaga);
}

// * Set bookmarks
function* setAlertBookmarked({
  payload: { alertId, page, category, onSuccess, ...params },
}) {
  try {
    const res = yield patchResultsApi(params);
    yield put(
      patchAlertResultActionsSuccess({
        data: res.data,
        category,
        params,
      })
    );
    if (onSuccess)
      yield put(
        onSuccess({
          id: alertId,
        })
      );
  } catch (error) {
    yield put(patchAlertResultActionsError(error));
  }
}

export function* watchSetAlertBookmarked() {
  yield takeLatest(ALERT_RESULTS_PATCH_ACTIONS, setAlertBookmarked);
}

// * Search source names
function* searchSourceNamesSaga({ payload: terms }) {
  try {
    const res = yield searchForSourceNamesApi(terms);
    yield put(searchSourceNamesSuccess(res.data));
  } catch (error) {
    yield put(searchSourceNamesError(error));
  }
}

export function* watchSearchSourceNames() {
  yield takeEvery(SEARCH_SOURCE_NAMES, searchSourceNamesSaga);
}

// * Advanced search
function* advancedSearchSaga({ payload: terms }) {
  try {
    const res = yield advancedSearchApi(terms);
    yield put(
      alertResultsAdvancedSearchSuccess({
        data: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(alertResultsAdvancedSearchError(error));
  }
}

export function* watchAdvancedSearch() {
  yield takeLatest(ALERT_RESULTS_ADVANCED_SEARCH, advancedSearchSaga);
}

// * Patch result category
function* patchResultCategorySaga({
  payload: { alertId, onSuccess, ...values },
}) {
  try {
    const res = yield patchResultsApi(values);
    yield put(patchResultCategorySuccess(res.data));
    if (onSuccess) onSuccess();
  } catch (error) {
    yield put(patchResultCategoryError(error));
  }
}

export function* watchPatchResultCategory() {
  yield takeLatest(ALERT_RESULTS_PATCH_CATEGORY, patchResultCategorySaga);
}

// * Search for results
function* searchForResultsSaga({ payload: term }) {
  try {
    const res = yield resultsSearchApi(term);
    yield put(
      searchForResultsSuccess({
        data: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(searchForResultsError(error));
  }
}

export function* watchSearchForResults() {
  yield takeEvery(ALERT_RESULTS_SEARCH, searchForResultsSaga);
}

// * Filter by sourceType
function* filterBySourceNameSaga({ payload: params }) {
  try {
    const res = yield filterBySourceTypeApi(params);
    yield put(
      filterBySourceTypeSuccess({
        data: res.data["hydra:member"],
        totalItems: res.data["hydra:totalItems"],
      })
    );
  } catch (error) {
    yield put(filterBySourceTypeError(error));
  }
}

export function* watchFilterBySourceName() {
  yield takeLatest(ALERT_RESULTS_FILTER_BY_SOURCE_TYPE, filterBySourceNameSaga);
}

/**
 * * FOLDERS
 */
// * Add folder
function* addFolderSaga({ payload: folder }) {
  try {
    const res = yield addFolderApi(folder);
    yield put(addFolderSuccess(res.data));
  } catch (error) {
    yield put(addFolderError(error));
  }
}

export function* watchAddFolder() {
  yield takeLatest(FOLDER_ADD, addFolderSaga);
}

// * Get daily folder
function* getFolderDailySaga({ payload: data }) {
  try {
    const res = yield getDailyFolderApi(data);
    yield put(getFolderDailySuccess(res.data["hydra:member"][0]));
  } catch (error) {
    yield put(getFolderDailyError(error));
  }
}

export function* watchGetFolderDaily() {
  yield takeLatest(FOLDER_GET_DAILY, getFolderDailySaga);
}

// * Patch folder
function* updateFolderSaga({ payload: { alertId, ...data } }) {
  try {
    yield patchFolderAPi(data);
    yield put(patchFolderSuccess());
    yield put(
      getFolderDaily({
        id: alertId,
      })
    );
  } catch (error) {
    yield put(patchFolderError(error));
  }
}

export function* watchUpdateFolder() {
  yield takeLatest(FOLDER_PATCH, updateFolderSaga);
}

// * Create duplication
function* combineDuplicatedNewsSaga({ payload: { alertId, ...data } }) {
  try {
    yield patchResultsApi(data);
    yield put(combineDuplicatedNewsSuccess());
    yield put(
      getFolderDaily({
        id: alertId,
      })
    );
  } catch (error) {
    yield put(combineDuplicatedNewsError(error));
  }
}

export function* watchCombineDuplicatedNews() {
  yield takeLatest(FOLDER_COMBINE_DUPLICATIONS, combineDuplicatedNewsSaga);
}

// * Publish folder
function* publishFolderSaga({ payload: { alertId, ...data } }) {
  try {
    yield patchFolderAPi(data);
    yield put(publishFolderSuccess());
    yield put(
      getFolderDaily({
        id: alertId,
      })
    );
  } catch (error) {
    yield put(publishFolderError(error));
  }
}

export function* watchPublishFolder() {
  yield takeLatest(FOLDER_PUBLISH, publishFolderSaga);
}

// * Review folder
function* reviweFolderSaga({ payload: { alertId, onSuccess, ...data } }) {
  try {
    yield patchFolderAPi(data);
    yield put(setFolderToReviewSuccess());
    yield !!onSuccess && put(onSuccess());
  } catch (error) {
    yield put(publishFolderError(error));
  }
}

export function* watchReviewFolder() {
  yield takeLatest(FOLDER_SET_REVIEW, reviweFolderSaga);
}

// * Remove item from folder
function* removeItemFromFolderSaga({ payload: { alertId, ...data } }) {
  try {
    yield patchFolderAPi(data);
    yield put(removeItemFromFolderSuccess());
    yield put(
      getFolderDaily({
        id: alertId,
      })
    );
  } catch (error) {
    yield put(removeItemFromFolderError(error));
  }
}

export function* watchRemoveFromFolder() {
  yield takeLatest(FOLDER_REMOVE_ITEM, removeItemFromFolderSaga);
}

// * Auditor chnage folder state
function* auditorChangeFolderStateSaga({ payload: { alertId, ...data } }) {
  try {
    yield patchFolderAPi(data);
    yield put(auditorChangeFolderStateSuccess());
    yield put(
      getFolderDaily({
        id: alertId,
      })
    );
  } catch (error) {
    yield put(auditorChangeFolderStateError(error));
  }
}

export function* watchAuditorChangeFolderState() {
  yield takeLatest(FOLDER_CHANGE_STATE_AUDITOR, auditorChangeFolderStateSaga);
}
