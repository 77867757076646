import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Col, Popconfirm, Row, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColorPicker } from '../../../../../components/ColorPicker';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { ConfigContext } from '../../../../../context/config';
import { PAGE_SIZE } from '../../../../../utils/constants';
import { DATE_FORMAT } from '../../../../../utils/moment-utils';
import { CAN_DELETE, CAN_PUT } from '../../../../../utils/permissions-utils';
import { listIndexer, tablePaginationOptions } from '../../../../../utils/utils';

const Column = Table;

export const RolesList = ({
  data,
  role: meRole,
  totalItems,
  currentPage,
  onSelectRole,
  onPageChange,
  onDeleteRole
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <Table
      dataSource={[...data]}
      pagination={tablePaginationOptions({ totalItems, pageSize: 30, currentPage, onPageChange, messageTranslator })}
      locale={{
        emptyText: messageTranslator("roles.list.empty.text", "roles")
      }}
      rowKey={rec => rec['@id']}
      scroll={{ x: true }}
    >
      <Column
        key="number"
        title="#"
        render={(role, rec, index) => <span>{listIndexer(index, PAGE_SIZE, currentPage)}</span>}
        width={120}
      />
      <Column
        key="color"
        title={messageTranslator("roles.list.label.color", "roles")}
        render={role => <ColorPicker inList color={role.color} />}
        width={120}
      />
      <Column
        key="title"
        title={messageTranslator("roles.list.label.title", "roles")}
        render={role => <Typography.Text>{role.title}</Typography.Text>}
      />
      <Column
        key="createdAt"
        title={messageTranslator("roles.list.label.createdAt", "roles")}
        render={role => <Typography.Text>{moment(role.createdAt).format(DATE_FORMAT)}</Typography.Text>
        }
      />
      <Column
        key="actions"
        width={70}
        render={role => (
          role.editable && <Row gutter={[8, 8]}>
            <PermissionsHandler
              resourceName="Role"
              blocPermission={CAN_PUT}
              userRole={meRole}
            >
              <Col>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("roles.list.tooltip.update", "roles")}
                >
                  <EditFilled onClick={() => onSelectRole(role)} />
                </Tooltip>
              </Col>
            </PermissionsHandler>
            <PermissionsHandler
              resourceName="Role"
              blocPermission={CAN_DELETE}
              userRole={meRole}
            >
              <Col>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("roles.list.tooltip.delete", "roles")}
                >
                  <Popconfirm
                    title={`${messageTranslator("roles.list.tooltip.delete.confirm", "roles")} ${role.title}`}
                    onConfirm={(e) => onDeleteRole(role.id)}
                    okText={messageTranslator("commons.delete.confirm", "commons")}
                    cancelText={messageTranslator("commons.cancel", "commons")}
                  >
                    <Link to="#">
                      <DeleteFilled />
                    </Link>
                  </Popconfirm>
                </Tooltip>
              </Col>
            </PermissionsHandler>
          </Row>
        )
        }
      />
    </Table>
  );
};
