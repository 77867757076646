import { put, takeLatest } from 'redux-saga/effects';
import { createEmailGroupApi, deleteEmailGroupApi, getEmailGroupByIdApi, getEmailGroupsApi, parseContactsFileApi, updateEmailGroupApi } from '../../api/emailGroup';
import { createEmailGroupError, createEmailGroupSuccess, deleteEmailGroupError, deleteEmailGroupSuccess, getEmailGroupByIdError, getEmailGroupByIdSuccess, getEmailGroupsError, getEmailGroupsSuccess, parseContactsFileError, parseContactsFileSuccess, updateEmailGroupError, updateEmailGroupSuccess } from './actions';
import { EMAIL_GROUP_CREATE, EMAIL_GROUP_DELETE, EMAIL_GROUP_GET, EMAIL_GROUP_GET_ONE, EMAIL_GROUP_PARSE_FILE, EMAIL_GROUP_UPDATE } from './types';

// * Create email group
function* createEmailGroupSaga({ payload: group }) {
  try {
    const res = yield createEmailGroupApi(group);
    yield put(createEmailGroupSuccess(res.data));
  } catch (error) {
    yield put(createEmailGroupError(error));
  }
};

export function* watchCreateEmailGroup() {
  yield takeLatest(EMAIL_GROUP_CREATE, createEmailGroupSaga);
};

// * Get email groups
function* getEmailGroupsSaga({ payload: params }) {
  try {
    const res = yield getEmailGroupsApi(params);
    yield put(getEmailGroupsSuccess({
      data: res.data['hydra:member'],
      totalItems: res.data['hydra:totalItems']
    }));
  } catch (error) {
    yield put(getEmailGroupsError(error));
  }
};

export function* watchGetEmailGroups() {
  yield takeLatest(EMAIL_GROUP_GET, getEmailGroupsSaga);
};

// * Get email group
function* getEmailGroupByIdSaga({ payload: id }) {
  try {
    const res = yield getEmailGroupByIdApi(id);
    yield put(getEmailGroupByIdSuccess(res.data));
  } catch (error) {
    yield put(getEmailGroupByIdError(error));
  }
};

export function* watchGetEmailGroup() {
  yield takeLatest(EMAIL_GROUP_GET_ONE, getEmailGroupByIdSaga);
};

// * Update email group
function* updateEmailGroupSaga({ payload: group }) {
  try {
    const res = yield updateEmailGroupApi(group);
    yield put(updateEmailGroupSuccess(res.data));
  } catch (error) {
    yield put(updateEmailGroupError(error));
  }
};

export function* watchUpdateEmailGroup() {
  yield takeLatest(EMAIL_GROUP_UPDATE, updateEmailGroupSaga);
};

// * Delete email group
function* deleteEmailGroupSaga({ payload: id }) {
  try {
    yield deleteEmailGroupApi(id);
    yield put(deleteEmailGroupSuccess());
  } catch (error) {
    yield put(deleteEmailGroupError(error));
  }
};

export function* watchDeleteEmailGroup() {
  yield takeLatest(EMAIL_GROUP_DELETE, deleteEmailGroupSaga);
};

// * Update email group
function* parseContactFileSaga({ payload: fileIRI }) {
  try {
    const res = yield parseContactsFileApi(fileIRI);
    yield put(parseContactsFileSuccess(res.data));
  } catch (error) {
    yield put(parseContactsFileError(error));
  }
};

export function* watchParseContactsFile() {
  yield takeLatest(EMAIL_GROUP_PARSE_FILE, parseContactFileSaga);
};
