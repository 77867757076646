import { Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConfigContext } from '../../../../../context/config';
import { CINFIG_SET_ALERT_FRAGMENT } from '../../../../../context/config/dispatch-config-state';
import { INBOX, REPORTS } from '../../../../../routes/paths';
import { FOLDERS_ARCHIVE } from '../../../../../utils/data/category';
import { FolderPublishedContent } from '../FolderPublishedContent';

export const PublishedModal = ({ alertId, reportId, history, visible, resetFolderUpdate, status }) => {

  const { configDispatcher } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  const handleCloseModal = () => {
    setVisible(false);
    dispatch(resetFolderUpdate());
    configDispatcher(CINFIG_SET_ALERT_FRAGMENT, FOLDERS_ARCHIVE);
  };

  return (
    <Modal
      visible={isVisible}
      className="small-modal"
      footer={null}
      centered
      onCancel={handleCloseModal}
    >
      <FolderPublishedContent
        linkTo={`${INBOX}/${alertId}/folders-archive`}
        linkToReport={`${REPORTS}/all/${reportId}`}
        status={status}
        onModalClose={handleCloseModal}
      />
    </Modal>
  )
}
