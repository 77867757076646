export const emptyCheckedNews = (array, dispatcher) => {
  dispatcher([]);
};

export const findItemByKey = (array, item, key) =>
  array.find((arrItem) => arrItem[key] === item[key]);

export const getResultIdFromIRI = (iri) => {
  const splittedIRI = iri.split("/");
  return splittedIRI[splittedIRI.length - 1];
};

// * Alert result
export const toggleAddToArray = (
  result,
  dispatcher,
  compareKey,
  removeFrom
) => {
  dispatcher((prev) => {
    if (findItemByKey(prev, result, compareKey)) {
      !!removeFrom &&
        removeFrom((prevState) =>
          prevState.filter((i) => i[compareKey] !== result[compareKey])
        );
      return prev.filter((el) => el[compareKey] !== result[compareKey]);
    }
    return [...prev, result];
  });
};

export const addNewsToFolder = (result, dispatcher) => {
  if (result.folder) return;
  dispatcher((prevState) => {
    if (findItemByKey(prevState, result, "id")) {
      return prevState.filter((item) => item.id !== result.id);
    }
    return [...prevState, result];
  });
};

// * Folder news
export const toggleAddToFolderArray = (result, dispatcher) => {
  dispatcher((prev) => {
    if (
      prev.length &&
      prev.every((item) => item.category["@id"] !== result.category["@id"])
    )
      return prev;
    if (findItemByKey(prev, result, "@id"))
      return prev.filter((el) => el["@id"] !== result["@id"]);
    return [...prev, result];
  });
};

export const AddNewsToGroup = (result, dispatcher) =>
  dispatcher((prevState) => {
    if (
      prevState.some((item) => item.category["@id"] !== result.category["@id"])
    )
      return prevState;
    if (prevState.find((item) => item["@id"] === result["@id"])) {
      return prevState.filter((item) => item["@id"] !== result["@id"]);
    }
    return [...prevState, result];
  });
