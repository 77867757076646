import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { sentimentOptions } from '../../../../../utils/constants';
import { getResultIdFromIRI } from '../../../../../utils/results-utils';

const { Option } = Select;

export const UpdateResultCategoryFrom = ({
  result,
  categories,
  stateDispatcher,
  onToggleCheckedItems,
  onSubmitUpdates
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();

  return (
    <Form
      name={`form-result-${getResultIdFromIRI(result['@id'])}`}
      form={form}
      className='form-item-edit-wrapper'
      size='small'
      initialValues={{
        id: getResultIdFromIRI(result['@id']),
        category: result.category ? result.category['@id'] : null,
        sentiment: result.sentiment
      }}
      onFinish={(values) => onSubmitUpdates(values)}
    >
      <Form.Item
        name="id"
        noStyle
      >
        <Input
          type="hidden"
        />
      </Form.Item>
      <Form.Item
        name="category"
        label={messageTranslator("alerts.daily.result.updateCategory.cat.label", "alerts")}
      >
        <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
          allowClear
        >
          {
            categories.map(category => <Option key={category['@id']} value={category['@id']}>{category.name}</Option>)
          }
        </Select>
      </Form.Item>

      <Form.Item
        name="sentiment"
        label={messageTranslator("alerts.daily.result.updateCategory.sentiment.label", "alerts")}
      >
        <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
          allowClear
        >
          {
            sentimentOptions.map(sent => (
              <Option key={sent.value} value={sent.value}>{messageTranslator(sent.label, "constants")}</Option>
            ))
          }
        </Select>
      </Form.Item>

      <Form.Item>
        <span className="btn-wrapper">
          <Button htmlType="submit">
          {messageTranslator(result.category ? "commons.update" : "commons.save", "commons")}
          </Button>
        </span>
      </Form.Item>
      <CloseOutlined onClick={() => onToggleCheckedItems(result, stateDispatcher, 'id')} />
    </Form>
  )
}
