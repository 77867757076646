import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateSelector } from '../../../components/DateSelector';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { getFolderDaily } from '../../../store/alert-results/actions';
import { FOLDER_STATES } from '../../../utils/constants';
import { ISO_DATE, patchMomentArabicLocal } from '../../../utils/moment-utils';
import { NewsList } from './components/NewsList';

export const DailyFolder = ({ route, history, match: { params: { id } } }) => {

  const { configState, messageTranslator } = useContext(ConfigContext);

  const [isArabic, setArabic] = useState(false);

  const dispatch = useDispatch();

  const {
    config: { globalConfig: { data: globalConfig } },
    alertResults: {
        folders: {
          folder: { data: folderData, isLoading }
        }
      }
  } = useSelector(state => state);

  useEffect(() => {
    if (configState.language === 'ar') {
      setArabic(true);
      patchMomentArabicLocal();
    } else setArabic(false);
  }, [configState.language]);

  useEffect(() => {
    dispatch(getFolderDaily({
      id,
      after: moment().format(ISO_DATE),
      before: moment().format(ISO_DATE),
      state: FOLDER_STATES.PUBLISHED
    }));
  }, [dispatch, id]);

  const handleGetNews = (date) => {
    if (date) dispatch(getFolderDaily({
      id,
      after: date,
      before: date,
      state: FOLDER_STATES.PUBLISHED
    }));
  };

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <DateSelector
              isArabic={isArabic}
              onDateApply={handleGetNews}
              daily
            />
          </Col>
        </Row>

        <NewsList
          globalConfig={globalConfig}
          folderData={folderData}
        />
      </Spin>
    </PageSkeleton>
  );
};
