import { api } from "..";

const URL = '/api/roles';

export const createRoleApi = (role) => api.post(URL, role);

export const getRoleByIdApi = (id) => api.get(`${URL}/${id}`);

export const getRolesApi = ({ page = 1, search = null }) => {
  let query = `page=${page}`;
  if (search) query += `&search=${search}`;
  return api.get(`${URL}?${query}`);
};

export const updateRoleApi = ({id, ...role}) => api.put(`${URL}/${id}`, role);

export const deleteRoleApi = (id) => api.delete(`${URL}/${id}`);