import {
  SCHEDULE_ADD,
  SCHEDULE_ADD_ERROR,
  SCHEDULE_ADD_RESET,
  SCHEDULE_ADD_SUCCESS,
  SCHEDULE_UPDATE,
  SCHEDULE_UPDATE_SUCCESS,
  SCHEDULE_UPDATE_RESET,
  SCHEDULE_UPDATE_ERROR,
} from "./types";

export const schedulesInitialState = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null,
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null,
  },
};

export const scheduleReducer = (state = schedulesInitialState, action) => {
  switch (action.type) {
    case SCHEDULE_ADD:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null,
        },
      };

    case SCHEDULE_ADD_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
        },
      };

    case SCHEDULE_ADD_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload,
        },
      };

    case SCHEDULE_ADD_RESET:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: false,
          errors: null,
        },
      };

    case SCHEDULE_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
          errors: null,
        },
      };

    case SCHEDULE_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true,
          errors: null,
        },
      };

    case SCHEDULE_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload,
        },
      };

    case SCHEDULE_UPDATE_RESET:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null,
        },
      };

    default:
      return state;
  }
};
