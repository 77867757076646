import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../components/PageSkeleton';
import { ConfigContext } from '../../context/config';
import { getCountriesStats, getFeedbackStats, getHashtagsStats, getImportantNewsStats, getInfluencersStats, getMonitoringStats, getTagsStats } from '../../store/stats/actions';
import { statsSegments, statsTypes } from '../../utils/constants';
import { CountriesMap } from './components/CountriesMap';
import { DashboardHeader } from './components/DashboardHeader';
import { ImportantNews } from './components/ImportantNews';
import { MostInfluencers } from './components/MostInfluencers';
import { NewsMonitoringStats } from './components/NewsMonitoringStats';
import { PopularHashTags } from './components/PopularHashTags';
import { PopularTags } from './components/PopularTags';
import { ReactionsStats } from './components/ReactionsStats';
import { SentimentStats } from './components/SentimentStats';
import "./dashboard.less";

const UNIT = statsSegments.month;
const NUMBER_OF_ITEMS = 12;

export const Dashboard = ({ route, match: { params: { id } } }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const {
    config: { globalConfig: { data: globalConfig } },
    stats: {
      countries: { data: countriesStats, isLoading: loadingCountries },
      sentiments: { data: feedbackStats, isLoading: loadingFeedback },
      importantNews: { data: importantNewsStats, isLoading: loadingNews },
      monitoring: { data: monitoringData, isLoading: loadingMonitoring },
      hashtags: { data: hashtagsStats, isLoading: loadingHashtags },
      keywords: { data: keywordsStats, isLoading: loadingKeywords },
      influencers: { data: influencersData, isLoading: loadingInfluencers }
    },
    alerts: {
      list: { data: listAlerts },
    }
  } = useSelector(state => state);

  const [unit, setUnit] = useState(UNIT);
  const [dates, setDates] = useState([]);
  const [momentDates, setMomentDates] = useState([]);
  const [alert, setAlert] = useState("all");

  useEffect(() => {
    const type = dates?.length ? statsTypes.period : statsTypes.unit;
    dispatch(getCountriesStats({
      action: "count",
      subject: "countries",
      type,
      unit,
      number: NUMBER_OF_ITEMS,
      limit: 5,
      after: dates[0],
      before: dates[1],
      alert
    }));
    dispatch(getFeedbackStats({
      action: "comparison",
      subject: "sentiment",
      type,
      unit,
      number: NUMBER_OF_ITEMS,
      after: dates[0],
      before: dates[1],
      alert
    }));
    dispatch(getImportantNewsStats({
      type,
      unit,
      after: dates[0],
      before: dates[1],
      alert
    }));
    dispatch(getHashtagsStats({
      action: "count",
      subject: "hashtags",
      type,
      unit,
      limit: 10,
      number: NUMBER_OF_ITEMS,
      after: dates[0],
      before: dates[1],
      alert
    }));
    dispatch(getTagsStats({
      action: "count",
      subject: "tags",
      type,
      unit,
      limit: 9,
      number: NUMBER_OF_ITEMS,
      after: dates[0],
      before: dates[1],
      alert
    }));
    dispatch(getInfluencersStats({
      action: "count",
      subject: "influencers",
      type,
      unit,
      limit: 6,
      number: NUMBER_OF_ITEMS,
      after: dates[0],
      before: dates[1],
      alert
    }));
    dispatch(getMonitoringStats({
      action: "comparison",
      subject: "source-type",
      type,
      unit,
      number: NUMBER_OF_ITEMS,
      after: dates[0],
      before: dates[1],
      alert
    }));
  }, [dispatch, unit, dates, alert]);

  const handleSegmentedChange = (value) => {
    setUnit(value);
    setDates([]);
    setMomentDates([]);
  };
  const getUnitByDateInterval = (startDate, endDate) =>  {
    if(endDate.diff(startDate, 'days') >= 60) {
      return 'month'
    }
    if(endDate.diff(startDate, 'days') >= 12) {
      return 'week'
    }
    return 'day'
  }
  const handleDateChange = (dates, dateStrings) => {
    setDates(dates ? [dateStrings[0], dateStrings[1]] : []);
    setMomentDates(dates ? dates : []);
    setUnit(dates ? getUnitByDateInterval(dates[0], dates[1]) : UNIT);
  }

  return (
    <PageSkeleton
      pageTitle={messageTranslator(route.title, "routes")}
    >
      {/*<Spin spinning={loadingCountries || loadingFeedback || loadingNews || loadingHashtags || loadingKeywords || loadingInfluencers || loadingMonitoring}>*/}

        <Row gutter={[8, 24]}>

          <Col span={24}>
            <DashboardHeader
              defaultUnit={unit}
              alerts={listAlerts}
              onUnitChange={handleSegmentedChange}
              onDateChange={handleDateChange}
              onAlertChange={setAlert}
            />
          </Col>

          <Col span={24}>
            <SentimentStats
              unit={unit}
              feedbacks={feedbackStats}
              isLoading={loadingFeedback}
            />
          </Col>

          <Col span={24}>
            <ReactionsStats
              unit={unit}
              dates={momentDates}
              feedbacks={feedbackStats}
              isLoading={loadingFeedback}
            />
          </Col>

          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col xs={24} md={8}>
                <ImportantNews
                  globalConfig={globalConfig}
                  news={importantNewsStats}
                  isLoading={loadingNews}
                />
              </Col>
              <Col xs={24} md={16}>
                <NewsMonitoringStats
                  unit={unit}
                  dates={momentDates}
                  data={monitoringData}
                  isLoading={loadingMonitoring}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col xs={24} md={8}>
                <PopularHashTags
                  hashtags={hashtagsStats}
                  isLoading={loadingHashtags}
                />
              </Col>
              <Col xs={24} md={8}>
                <PopularTags
                  keywords={keywordsStats}
                  isLoading={loadingKeywords}
                />
              </Col>
              <Col xs={24} md={8}>
                <MostInfluencers
                  influencers={influencersData}
                  isLoading={loadingInfluencers}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <CountriesMap
              countries={countriesStats}
              isLoading={loadingCountries}
            />
          </Col>

        </Row>

      {/*</Spin>*/}
    </PageSkeleton>
  );
};
