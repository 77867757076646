import { useContext} from 'react';
import { Tag } from 'antd';
import { ConfigContext } from '../../../../../context/config';



export const NewAlertsButton = ({ items }) => {

    const { messageTranslator } = useContext(ConfigContext);
 
    if(items === 1) return(
        <Tag color="#00e096" data-aos="fade-up">{messageTranslator("alerts.new.prefix", "alerts")} {messageTranslator("alerts.newAlert.text", "alerts")} {items}</Tag>
    );

    if(items > 1) return(
        <Tag color="#00e096" data-aos="fade-up">{messageTranslator("alerts.new.prefix", "alerts")} {items} {messageTranslator("alerts.newAlerts.text", "alerts")}</Tag>
    );

    return <p>test</p>
};