import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React from 'react';
import './styles.less';

export const TablePagination = (current, type, originalElement, messageTranslator) => {

  if (type === 'prev') {
    return <span><ArrowLeftOutlined className='transformMiroir' /> {messageTranslator("commons.pagination.prev", "commons")}</span>;
  }
  if (type === 'next') {
    return <span>{messageTranslator("commons.pagination.next", "commons")} <ArrowRightOutlined className='transformMiroir' /></span>;
  }
  return originalElement;
}
