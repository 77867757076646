import React, { createContext, useCallback, useState } from 'react';
import { translator } from '../../i18n';
import { LANG } from '../../utils/constants';
import { dispatchConfigState } from './dispatch-config-state';

export const ConfigContext = createContext({});

export const ConfigContextProvider = ({ children }) => {

  const [configState, setConfigState] = useState({
    language: localStorage.getItem(LANG) || 'ar',
    alertId: null,
    advancedSearchTerms: null,
    alertResultPage: 1,
    searchAttemps: 0,
    alertSubPage: null
  });

  const configDispatcher = useCallback(
    (actionType, payload) => setConfigState(prevState => dispatchConfigState(prevState, actionType, payload)), []
  );

  const messageTranslator = useCallback(
    /**
     * 
     * @param {string} key: The key of the translation we want to get.
     * @param {string} _module: The module in which the key belongs.
     * @returns function
     */
    (key, _module) => translator(key, _module, configState.language), [configState.language]);

  return (
    <ConfigContext.Provider value={{
      configState,
      configDispatcher,
      messageTranslator
    }}>
      {children}
    </ConfigContext.Provider>
  )
}
