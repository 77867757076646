import { CaretDownOutlined, CheckCircleFilled, DownOutlined, FilterOutlined, GlobalOutlined, MenuOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Divider, Dropdown, Form, Input, Menu, Row, Select, Space } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../context/config';
import { sentimentOptions } from '../../utils/constants';
import { ALL, TWITTER, VIDEOS, WEBSITE } from '../../utils/data/category';
import { advancedSearchLanguages } from '../../utils/data/languages';
import { ISO_DATE, renderRanges } from '../../utils/moment-utils';
import { translateArrayOfObjects } from '../../utils/utils';
import './filters.less';

const { Search } = Input;

const { RangePicker } = DatePicker;

export const AlertsFilter = ({
  sourceNames: { isLoading, data: sourceNameList },
  searchTerms,
  searchValue,
  isLoadingSimpleSearch,
  onSearchInputSubmit,
  onSourceTypeChange,
  onSourceNameInputChange,
  onSubmitAdvancedSearch
}) => {

  const { configState, messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();

  const { resetFields, setFieldsValue } = form;

  const [isAdvancedSearch, setAdvancedSearch] = useState(() => false);

  const [sourceMenuLabel, setSourceMenuLabel] = useState(messageTranslator("alerts.view.filters.list.sources.all", "alerts"));
  const [selectedSourceType, setSelectedSourceType] = useState(() => ALL);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (searchTerms.search) setInputValue(searchTerms.search);
  }, [searchTerms.search]);

  useEffect(() => {
    const { page, ...terms } = searchTerms;
    if (terms && Object.keys(terms).length) {
      setFieldsValue({
        ...searchTerms,
        dates: searchTerms.from && searchTerms.to ? [
          moment(searchTerms.from),
          moment(searchTerms.to)
        ] : null
      });
    }
    else resetFields();
  }, [searchTerms, setFieldsValue, resetFields]);

  const handleSearchInputChange = (event) => setInputValue(event.target.value);

  const handleSelectSourceType = ({ key }) => {
    const source = allSources.find(source => source.key === key);
    if (source) setSourceMenuLabel(source.title);
    setSelectedSourceType(key);
    onSourceTypeChange(key);
  };

  const handleAdvancedSearch = () => setAdvancedSearch(prevState => !prevState);

  const handleSourceNameChange = (term) => onSourceNameInputChange(term);

  const handleSubmitAdvancedSearch = ({ dates, ...values }) => {
    onSubmitAdvancedSearch({
      ...values,
      search: searchValue,
      from: dates ? moment(dates[0]).format(ISO_DATE) : null,
      to: dates ? moment(dates[1]).format(ISO_DATE) : null
    });
    setAdvancedSearch(false);
  };

  const handleResetForm = () => {
    setInputValue('');
    resetFields();
    onSubmitAdvancedSearch();
    setAdvancedSearch(false);
  };

  const sourceOptions = [
    { label: messageTranslator("alerts.view.filters.sources.news", "alerts"), value: "website" },
    // { label: messageTranslator("alerts.view.filters.sources.instagram", "alerts"), value: "instagram" },
    // { label: messageTranslator("alerts.view.filters.sources.facebook", "alerts"), value: "facebook" },
    { label: messageTranslator("alerts.view.filters.sources.twitter", "alerts"), value: "twitter" },
    // { label: messageTranslator("alerts.view.filters.sources.linkedin", "alerts"), value: "linkedin" },
    { label: messageTranslator("alerts.view.filters.sources.youtube", "alerts"), value: "youtube" }
  ];

  const allSources = [
    {
      key: ALL,
      title: messageTranslator("alerts.view.filters.list.sources.all", "alerts"),
      icon: <MenuOutlined />
    },
    {
      key: WEBSITE,
      title: messageTranslator("alerts.view.filters.list.sources.websites", "alerts"),
      icon: <GlobalOutlined />
    },
    {
      key: TWITTER,
      title: messageTranslator("alerts.view.filters.list.sources.twitter", "alerts"),
      icon: <TwitterOutlined />
    },
    // {
    //   key: FACEBOOK,
    //   title: messageTranslator("alerts.view.filters.list.sources.facebook", "alerts"),
    //   icon: <FacebookOutlined />
    // },
    // {
    //   key: LINKEDIN,
    //   title: messageTranslator("alerts.view.filters.list.sources.linkedin", "alerts"),
    //   icon: <LinkedinOutlined />
    // },
    // {
    //   key: INSTAGRAM,
    //   title: messageTranslator("alerts.view.filters.list.sources.instagram", "alerts"),
    //   icon: <InstagramOutlined />
    // },
    {
      key: VIDEOS,
      title: messageTranslator("alerts.view.filters.list.sources.videos", "alerts"),
      icon: <YoutubeOutlined />
    }
  ];

  const simpleFilter = (
    <Menu
      selectedKeys={[selectedSourceType]}
      onClick={handleSelectSourceType}
    >
      {
        allSources.map(({ key, title, icon }, index, orgArray) => (
          <React.Fragment key={key}>
            <Menu.Item key={key}>
              <Link to="#">
                {icon} {title}
              </Link>
            </Menu.Item>
            {
              index + 1 !== orgArray.length && <Menu.Divider />
            }
          </React.Fragment>
        ))
      }
    </Menu >
  );

  return (
    <div className="filter-wrapper">
      <Card className='wrapper-search'>
        <Row align='middle' wrap={false}>
          <Col flex="auto">
            <Search
              value={inputValue}
              loading={isLoadingSimpleSearch}
              placeholder={messageTranslator("alerts.view.filters.search.placeholder", "alerts")}
              onChange={handleSearchInputChange}
              onPressEnter={onSearchInputSubmit}
            />
          </Col>
          <Col flex="36px">
            <Space>
              <CheckCircleFilled />
              <FilterOutlined onClick={handleAdvancedSearch} className={isAdvancedSearch ? "active" : ""} />
            </Space>
          </Col>
        </Row>
      </Card>
      <Dropdown overlay={simpleFilter} trigger={['click']} gutter={[16, 16]} className='label-sources-list full-width' overlayClassName='sources-list'>
        <Button onClick={e => e.preventDefault()}>
          {sourceMenuLabel} <DownOutlined />
        </Button>
      </Dropdown>
      <Form
        layout='vertical'
        size='small'
        className='filters-form'
        onFinish={handleSubmitAdvancedSearch}
        form={form}
      >
        {
          isAdvancedSearch && (
            <Card className="wrapper-filer">
              <Row gutter={24}>
                <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 6, offset: 1 }}>
                  <Row gutter={[16, 40]}>
                    <Col span={24}>
                      <Form.Item
                        label={messageTranslator("alerts.view.filters.dates.label", "alerts")}
                        name="dates"
                      >
                        <RangePicker
                          ranges={renderRanges(messageTranslator, configState.language)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="sourceName"
                        label={messageTranslator("alerts.view.filters.newsSources.label", "alerts")}
                      >
                        <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                          mode="multiple"
                          onSearch={handleSourceNameChange}
                          loading={isLoading}
                        >
                          {
                            sourceNameList.map(sourceName => (
                              <Select.Option key={sourceName} value={sourceName}>{sourceName}</Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 6, offset: 2 }}>
                  <Row gutter={[16, 40]}>
                    <Col span={24}>
                      <Form.Item
                        label={messageTranslator("alerts.list.langs", "alerts")}
                        name="languages"
                      >
                        <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} 
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                        >
                          {advancedSearchLanguages.map(lang => (
                            <Select.Option key={lang.value} value={lang.value}>
                              {messageTranslator(lang.label, "constants")}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={messageTranslator("alerts.view.filters.sentiment.label", "alerts")}
                        name="sentiments"
                      >
                        <Checkbox.Group options={translateArrayOfObjects(sentimentOptions, 'label', messageTranslator, "constants")} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 6, offset: 2 }}>
                  <Form.Item
                    label={messageTranslator("alerts.view.filters.sources.label", "alerts")}
                    name="sources"
                  >
                    <Checkbox.Group options={sourceOptions} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row justify="end">
                <Col>
                  <span className='btn-wrapper white'>
                    <Button htmlType="button" onClick={handleResetForm}>
                      {messageTranslator("commons.reset", "commons")}
                    </Button>
                  </span>
                  <span className='btn-wrapper'>
                    <Button
                      type="primary"
                      htmlType='submit'
                    >
                      {messageTranslator("commons.apply", "commons")}
                    </Button>
                  </span>
                </Col>
              </Row>
            </Card>
          )
        }
      </Form>
    </div>
  );
};
