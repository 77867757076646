import { Layout } from 'antd';
import React from 'react';

const {Content} = Layout;

export const AppContent = ({children}) => {
  return (
    <Content className="application-content">
        {children}
    </Content>
  );
};
