import { Button, Col, Modal, Radio, Row, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { getResultIdFromIRI } from '../../../../../utils/results-utils';
import "./duplicated-news.less";

export const DuplicatedNewsModal = ({
  duplications,
  parentToUpdate,
  onModalClose,
  onSaveChanges,
  onCancelGroupDuplications,
  ...rest
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const renderFooter = (structure, parentToUpdate, handleSaveChanges) => (
    <>
      <Button
        key="back"
        onClick={() => onModalClose(false)}
      >
        {messageTranslator("commons.cancel", "commons")}
      </Button>
      {
        !!parentToUpdate && (
          <span className="btn-wrapper">
            <Button
              key="back"
              type="primary"
              danger
              onClick={handleCancelGroup}
            >
              {messageTranslator("alerts.daily.banner.cancelDuplications", "alerts")}
            </Button>
          </span>
        )
      }
      <span className="btn-wrapper">
        <Button
          key="submit"
          type="primary"
          onClick={handleSaveChanges}
        >
          {messageTranslator(parentToUpdate ? "commons.update" : "commons.save", "commons")}
        </Button>
      </span>
    </>
  );

  const [duplicatedItems, setDuplicatedItems] = useState([]);

  const [structure, setStructure] = useState({
    parentDuplicated: null,
    childrenDuplicated: []
  });

  useEffect(() => {
    setDuplicatedItems(() => {
      if (parentToUpdate) return [parentToUpdate.parentDuplicated, ...parentToUpdate.childrenDuplicated];
      return duplications;
    });
  }, [duplications, parentToUpdate, setDuplicatedItems]);

  useEffect(() => {
    if (parentToUpdate) setStructure({
      parentDuplicated: parentToUpdate.parentDuplicated['@id'],
      childrenDuplicated: getIRIOnly(parentToUpdate.childrenDuplicated)
    });
  }, [parentToUpdate]);

  const getIRIOnly = (arr) => arr.map(item => item['@id']);

  const getChildren = (elements, parent) => getIRIOnly(elements.filter(i => i['@id'] !== parent));

  const handleStructure = ({ target: { value } }) => {
    setStructure(() => ({
      parentDuplicated: value,
      childrenDuplicated: getChildren(duplicatedItems, value)
    }));
  };

  const handleSaveChanges = () => onSaveChanges({
    ...structure,
    id: getResultIdFromIRI(structure.parentDuplicated),
    childrenDuplicated: structure.childrenDuplicated
  });

  const handleCancelGroup = () => onCancelGroupDuplications({
    id: getResultIdFromIRI(parentToUpdate.parentDuplicated['@id']),
    childrenDuplicated: []
  });

  return (
    <Modal
      title={messageTranslator("alerts.daily.banner.duplicated", "alerts")}
      {...rest}
      footer={renderFooter(structure, parentToUpdate, handleSaveChanges)}
      onCancel={() => onModalClose(false)}
    >
      <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.duplicationModal.label", "alerts")}</Typography.Title>
      <Radio.Group
        className="radio-group"
        onChange={handleStructure}
        value={structure.parentDuplicated}
      >
        <Space direction="vertical" size={0}>
          {
            duplicatedItems.map(duplic => (
              <Radio
                key={duplic['@id']}
                value={duplic['@id']}
              >
                <Row gutter={[30, 30]}>
                  <Col>
                    <Typography.Text type="secondary">{duplic.sourceName}</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text>{duplic.title}</Typography.Text>
                  </Col>
                </Row>
              </Radio>
            ))
          }
        </Space>
      </Radio.Group>
    </Modal>
  );
};
