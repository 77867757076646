import { Button, message, Spin } from "antd";
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { PermissionsHandler } from "../../../components/PermissionHandler";
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { SearchBar } from '../../../components/SearchBar';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { ConfigContext } from "../../../context/config";
import { useQuery } from "../../../hooks/query-params";
import { getPermissions } from "../../../store/permissions/actions";
import { createRole, deleteRole, getRoles, resetRoleCreated, resetRoleUpdated, updateRole } from '../../../store/roles/actions';
import { CAN_CREATE } from "../../../utils/permissions-utils";
import { getPageSearchQueries, pageChangeHandler, searchHandler } from "../../../utils/utils";
import { RoleFormModal } from "./components/RoleFormModal";
import { RolesList } from './components/RolesList';
import { AuthContext, checkToken } from "../../../context/Auth";
import { logoutAction } from "../../../store/logout";

export const ListRoles = ({ route, history }) => {

  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const dispatch = useDispatch();

  const searchParams = useQuery(history);

  const {
    users: { me: { data: meData } },
    permissions: { list },
    roles: {
      list: { data, totalItems, isLoading },
      create: { isLoading: loadingCreateRole, isCreated, errors: createErrors },
      update: { isUpdated, isLoading: loadingUpdate, errors: updateErrors },
      delete: { isLoading: loadingDelete }
    }
  } = useSelector(state => state);

  const [page, setPage] = useState(() => 1);
  const [searchTerms, setSearchTerms] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    if(!checkToken()[1]) {
      logout();
      dispatch(logoutAction());
    }
  });

  useEffect(() => {
    const terms = getPageSearchQueries(searchParams);
    setSearchTerms(terms);
    setPage(parseInt(terms.page));
    dispatch(getRoles(terms));
    dispatch(getPermissions());
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (isCreated) {
      message.success(messageTranslator("roles.create.message.success", "roles"));
      dispatch(resetRoleCreated());
      handleCloseModal();
    }
  }, [dispatch, isCreated, messageTranslator]);

  useEffect(() => {
    if (isUpdated) {
      message.success(messageTranslator("roles.update.message.success", "roles"));
      dispatch(resetRoleUpdated());
      handleCloseModal();
    }
  }, [dispatch, isUpdated, messageTranslator]);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleSelectRole = (role) => {
    setSelectedRole(role);
    handleOpenModal();
  };

  const handleCancel = () => {
    setSelectedRole(null);
    handleCloseModal();
  };

  const handleSubmit = (role) => dispatch(selectedRole ? updateRole({
    ...role,
    id: selectedRole.id
  }) : createRole(role))

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handleSearch = (terms) => searchHandler(history, terms);

  const handleDeleteRole = (id) => dispatch(deleteRole(id));

  const handleCreateRole = () => handleOpenModal();

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={isLoading || loadingDelete}>
        <PermissionsHandler
          resourceName="Role"
          blocPermission={CAN_CREATE}
          userRole={meData?.role}
        >
          <div className='btn-wrapper'>
            <Button type="primary" onClick={handleCreateRole}>
              {messageTranslator("roles.list.new.text", "roles")}
            </Button>
          </div>
        </PermissionsHandler>

        <SearchBar
          label={route.title}
          history={history}
          searchTerms={searchTerms}
          page={page}
          onSearchTrigger={handleSearch}
        />

        <RolesList
          data={data}
          role={meData?.role}
          currentPage={page}
          totalItems={totalItems}
          onSelectRole={handleSelectRole}
          onPageChange={handlePageChange}
          onDeleteRole={handleDeleteRole}
        />

        <RoleFormModal
          modalProps={{
            visible: openModal
          }}
          isLoading={loadingCreateRole || loadingUpdate}
          permissions={list}
          role={selectedRole}
          errors={createErrors || updateErrors}
          onCancel={handleCancel}
          onOk={handleSubmit}
        />
      </Spin>
    </PageSkeleton>
  );
};
