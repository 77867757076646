import { api } from "..";

const URL = "api/users";

export const createUserApi = (user) => api.post(URL, user);

export const ListUsersApi = ({ search, page = 1, role }) => {
  let query = `page=${page}`;
  if (search) query += `&search=${decodeURI(search)}`;
  if (role) query += `&role=${role}`;
  return api.get(`${URL}?${query}`);
};

export const getUserByIdApi = (id) => api.get(`${URL}/${id}`);

export const updateUserApi = ({ id, ...user }) => api.put(`${URL}/${id}`, user);

export const deleteUserApi = (id) => api.delete(`${URL}/${id}`);

export const getMeApi = () => api.get(`${URL}/me`);

export const changePasswordApi = (passwords) =>
  api.post(`${URL}/reset-new-password`, passwords);
