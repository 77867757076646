import { LoadingOutlined } from '@ant-design/icons';
import { Space, Typography, Upload } from 'antd';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { ConfigContext } from '../../context/config';
import * as fileUpload from '../../utils/upload-file';

/**
 * The custom component in which we upload files to the server.
 * 
 * ### Props list:
 * 
 * 1. **token:** Bearer authorization that will be added to the header's request.
 * 2. **label:** The text will be displayed on top of the uploader.
 * 3. **imageSrc:** The image source (URL) if the action is UPDATE.
 * 4. **setFieldsValue:** Dispatcher function updates a given Form field name value with the image URL.
 * 5. **fieldName:** The field name in which will be dispatched by the function **setFieldsValue**.
 * 6. **hideLabel:** Show / hide the label showin empty file / filename.
 * 7. **hideAvatarInnerLabel:** Show / hide the label inside the avatar.
 */
export const FileUploader = forwardRef(({
  token,
  label = "",
  imageSrc,
  setFieldsValue,
  fieldName,
  showFileName = "",
  hideLabel = false,
  hideAvatarInnerLabel = false,
  accept = ".jpg,.png",
  file = false,
  customButtonTrigger,
  onUploadChange
}, ref) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [imageUrl, setImageUrl] = useState(() => '');
  const [loadingUpload, setLoadingUpload] = useState(() => false);

  useEffect(() => {
    if (onUploadChange) onUploadChange(imageUrl);
  }, [imageUrl, onUploadChange]);

  useEffect(() => {
    if (imageSrc) {
      setImageUrl(imageSrc);
    }
  }, [imageSrc]);

  useImperativeHandle(ref, () => ({
    resetImageUrl() {
      setImageUrl("");
    }
  }));

  const uploadButton = (
    <div>
      {loadingUpload ? <LoadingOutlined /> : <div style={{ marginTop: 8, fontSize: 12 }}>{!hideAvatarInnerLabel && messageTranslator("commons.upload.thumbnail.text", "commons")}</div>}
    </div>
  );

  return (
    <>
      <Typography.Title level={4}>{label}</Typography.Title>
      <Space>
        <Upload
          {...fileUpload.uploadProps(token, !!customButtonTrigger, accept, file)}
          // beforeUpload={fileUpload.beforeUpload}
          onChange={(info) => fileUpload.handleFileChange(info, setLoadingUpload, setImageUrl, setFieldsValue, fieldName, showFileName)}
        >
          {
            customButtonTrigger || file
              ?
              customButtonTrigger
              :
              imageUrl
                ?
                <img src={`${process.env.REACT_APP_BASE_URL}/${imageUrl}`} alt="avatar" style={{ width: '100%' }} />
                :
                uploadButton
          }
        </Upload>
        <Typography.Text type="secondary" className="upload-info">
          {
            !hideLabel
            &&
            ((!!imageUrl && fileUpload.generateFileName(imageUrl))
              ||
              messageTranslator("commons.upload.noSelectedFile", "commons"))
          }
        </Typography.Text>
      </Space>
    </>
  );
});
