import { CheckCircleFilled } from '@ant-design/icons';
import { Card, Col, Row, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { FOLDER_STATES, ROLES } from '../../../../../utils/constants';
import './info-banner.less';

export const InformBanner = ({
  folder,
  currentUser
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const renderMessage = (status) => {
    switch (status) {

      case FOLDER_STATES.DRAFT:
        return "alerts.daily.banner.inform.draft";

      case FOLDER_STATES.NEEDS_REVIEW:
        return "alerts.daily.banner.inform.review";

      case FOLDER_STATES.PUBLISHER_CHECK:
        return "alerts.daily.banner.inform.publisher_check";
      case FOLDER_STATES.PUBLISHED:
        return "alerts.daily.banner.inform.published"

      default:
        return null;
    }
  };


  if (
    folder && currentUser
    &&
    (
      (currentUser.role.title === ROLES.EDITOR && ![FOLDER_STATES.DRAFT].includes(folder.moderationState))
      ||
      (currentUser.role.title === ROLES.AUDITOR && ![FOLDER_STATES.NEEDS_REVIEW].includes(folder.moderationState))
      ||
      (currentUser.role.title === ROLES.PUBLISHER && ![FOLDER_STATES.PUBLISHER_CHECK, FOLDER_STATES.PUBLISHED].includes(folder.moderationState))
    )
  )
    return (
      <Card
        className='folder-banner'
      >
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
            <Row justify="space-between" align='middle'>
              <Col flex="auto" xs={24}>
                <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.counter", "alerts")} <span className='nbre-alerts'>+{folder.folderItems.length}</span></Typography.Title>
              </Col>

              <Col flex="none" xs={24}>
                <Space className='info-banner' size={16}>
                  <CheckCircleFilled />
                  {messageTranslator(renderMessage(folder.moderationState), "alerts")}
                </Space>
              </Col>

            </Row>
          </Col>
        </Row>
      </Card>
    );

  if(!folder && currentUser &&
  (
    (currentUser.role.title === ROLES.AUDITOR)
    ||
    (currentUser.role.title === ROLES.PUBLISHER)
  )) return <Card
              className='folder-banner'
            >
              <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
                  <Row justify="space-between" align='middle'>
                    <Col flex="auto" align="middle" xs={24}>
                      <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.folderNotCreated", "alerts")}</Typography.Title>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>;

  return null;
};
