import { Avatar, Badge, Col, List, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { SCRAP_FALLBACK_IMAGE } from '../../../../../utils/constants';
import { tablePaginationOptions } from '../../../../../utils/utils';

export const SourceList = ({
  globalConfig,
  scrapResults: { data: list, totalItems },
  currentPage,
  onPageChange
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [sourceIcon, setSourceIcon] = useState();

  useEffect(() => {
    if (globalConfig) setSourceIcon(() => {
      if (globalConfig?.values.default_source_image?.contentUrl) return `${process.env.REACT_APP_BASE_URL}${globalConfig.values.default_source_image.contentUrl}`;
      return SCRAP_FALLBACK_IMAGE
    })
  }, [globalConfig]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <List
          size="large"
          dataSource={[...list]}
          pagination={tablePaginationOptions({
            totalItems,
            pageSize: 30,
            currentPage,
            onPageChange,
            messageTranslator
          })}
          locale={{
            emptyText: messageTranslator("sources.results.emtyText", "sources")
          }}
          renderItem={result => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={(
                    <Badge offset={[-5, 50]} count={
                      result.sourceType !== 'website' ? (
                        <Avatar
                          size={20}
                          src={`/images/social-media/${result.sourceType}.png`}
                          className="avatar-source"
                        />
                      ) : null
                    }>
                      <Avatar
                        size={64}
                        src={result.sourceImage || sourceIcon}
                      />
                    </Badge>
                  )}
                  title={(
                    <span className='date'>{moment(result.createdAt).format('DD MMM')}</span> 
                  )}
                  description={(
                    <a
                      href={result.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {result.title || result.content.substring(0, 200)}
                    </a>
                  )}
                />
                <div className='actions-wrapper'>
                  <div className={"status " + result.sentiment} />
                </div>
              </List.Item>
            )
          }}
        />
      </Col>
    </Row>
  );
};
