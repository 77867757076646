import isUrl from "is-url";
import moment from "moment";
import { TablePagination } from "../components/TablePagination";
import { statsSegments } from "./constants";
import { ACTIVE } from "./data/category";

export const tablePaginationOptions = ({
  totalItems,
  pageSize = 30,
  currentPage = 1,
  onPageChange,
  messageTranslator,
}) => ({
  className: "pagination-scrapping",
  hideOnSinglePage: true,
  pageSize,
  current: parseInt(currentPage),
  total: totalItems,
  itemRender: (current, type, originalElement) =>
    TablePagination(current, type, originalElement, messageTranslator),
  onChange: (page) => onPageChange(page),
});

/**
 * ### listIndexer
 *
 * #### What it does:
 * Takes a number of params and returns the number (index) of the element in a given array. the app store loads only N items every pagination.
 *
 * @param {number} index Index of the element in the array.
 * @param {number} itemsPerPage The number of elements per page.
 * @param {number} currentPage The current page.
 * @returns number
 */
export const listIndexer = (index, itemsPerPage, currentPage) =>
  (currentPage - 1) * itemsPerPage + index + 1;

export const checkDuplicatedItemsInArray = (arr) => {
  let duplications = [];
  let obj = {};
  arr.forEach((el) => {
    if (obj[el]) obj[el] += 1;
    else obj[el] = 1;
  });
  for (let key in obj) {
    if (obj[key] > 1) duplications.push(key);
  }
  return duplications;
};

export const isValidUrlRule = (message) => ({
  validator(_, value) {
    if (isUrl(value) || isUrl(`http://${value}`) || !value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(message));
  },
});

export const matchPasswords = (getFieldValue, message) => ({
  validator(_, value) {
    if (!value || getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(message));
  },
});

export const checkReuseSamePassword = (getFieldValue, message) => ({
  validator(_, value) {
    if (!value || getFieldValue("old") !== value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(message));
  },
});

export const makeValidUrl = (url) => {
  if (!url) return null;
  return url.includes("http://") || url.includes("https://")
    ? url
    : `http://${url}`;
};

/**
 *
 * @param {string} pathname
 * @returns
 */
export const extractPageCategory = (pathname) => {
  const fragments = pathname.split("/");
  const lastItem = fragments.slice(-1)[0];
  if (isNaN(lastItem)) return lastItem;
  return ACTIVE;
};

export const extractProfileMenuKey = (pathname) =>
  pathname.split("/").slice(0, 3).join("/");

export const transformPermissions = (permissions) => {
  let newPermissions = {};
  permissions.forEach(({ resource, permission, possessor }) => {
    if (newPermissions[resource])
      newPermissions[resource].push([permission, possessor]);
    else newPermissions[resource] = [[permission, possessor]];
  });
  return newPermissions;
};

/**
 *
 * @param {object} newItem
 * @param {array} array
 * @param {string} compareKey
 * @returns
 */
export const replaceItemInArray = (newItem, array, compareKey) => {
  if (!newItem || !array || !compareKey) return false;
  const itemIndex = array.findIndex(
    (oldItem) => newItem[compareKey] === oldItem[compareKey]
  );
  if (itemIndex > -1) array[itemIndex] = newItem;
  return array;
};

/**
 *
 * @param {number} id The identifier of the element in which will be removed.
 * @param {array} array List of items in which will remove the item.
 * @returns Array
 */
export const removeItemFromArray = (id, array) =>
  array.filter((item) => item.id !== id);

export const alertResultUpdateCategory = (payload) => {
  if (!payload.category) payload.category = "active";
  if (payload.params.bookmarked === false && payload.category === "bookmarked")
    return true;
  if (payload.params.seen && payload.category === "unseen")
    return true;
  return payload.params.status &&
      payload.category !== payload.params.status;

};

export const refreshArrayItems = (newArray, oldArray, compareKey) => {
  newArray.forEach((newItem) => {
    const newItemPosInOldArray = oldArray.findIndex(
      (oldItem) => oldItem[compareKey] === newItem[compareKey]
    );
    if (newItemPosInOldArray > -1) oldArray[newItemPosInOldArray] = newItem;
  });
  return oldArray;
};

export const removeParam = (queryParams, paramName) => {
  if (queryParams.has(paramName)) {
    queryParams.delete(paramName);
  }
  return queryParams;
};

export const addParam = (queryParams, param, key = null) => {
  if (Array.isArray(param) && key) {
    queryParams.delete(key);
    param.forEach((_param) => {
      queryParams.append(key, _param);
    });
  } else {
    queryParams.set(param.name, param.value);
  }
  return queryParams;
};

/**
 *
 * @param {object} history
 * @param {{name: string, value: string}} param
 */
export const updateParam = (queryParams, param) => {
  queryParams.set(param.name, param.value);
  return queryParams;
};

/**
 *
 * @param {object} urlParams
 * @returns {{page: number, search: string}}
 */
export const getPageSearchQueries = (urlParams) => {
  const terms = {};
  terms.page = urlParams.has("page") ? parseInt(urlParams.get("page")) : 1;
  if (urlParams.has("search")) terms.search = urlParams.get("search");
  if (urlParams.has("from")) terms.from = urlParams.get("from");
  if (urlParams.has("to")) terms.to = urlParams.get("to");
  if (urlParams.has("sentiments"))
    terms.sentiments = urlParams.getAll("sentiments");
  return terms;
};

/**
 *
 * @param {object} history
 * @param {number} page
 * @param {function} pageDispatcher
 */
export const pageChangeHandler = (history, page, terms, pageDispatcher) => {
  const queryParams = new URLSearchParams(history.location.search);

  pageDispatcher(page);
  delete terms.page;
  if (page === 1 && !Object.keys(terms).length)
    removeParam(queryParams, "page");
  else updateParam(queryParams, { name: "page", value: page });

  history.replace({
    search: queryParams.toString(),
  });
};

export const searchHandler = (history, terms) => {
  const queryParams = new URLSearchParams(history.location.search);

  if (terms.search || terms.sentiments || terms.from)
    updateParam(queryParams, { value: terms.page, name: "page" });
  else removeParam(queryParams, "page");

  if (terms.search)
    updateParam(queryParams, { value: terms.search, name: "search" });
  else removeParam(queryParams, "search");

  if (terms.from) addParam(queryParams, { name: "from", value: terms.from });
  else removeParam(queryParams, "from");

  if (terms.to) addParam(queryParams, { name: "to", value: terms.to });
  else removeParam(queryParams, "to");

  if (terms.sentiments && terms.sentiments.length)
    addParam(queryParams, terms.sentiments, "sentiments");
  else removeParam(queryParams, "sentiments");

  history.replace({
    search: queryParams.toString(),
  });
};

export const getAlertAdvancedSearchQueries = (urlParams) => {
  let terms = {};
  terms.page = urlParams.has("page") ? parseInt(urlParams.get("page")) : 1;
  if (urlParams.has("search")) terms.search = urlParams.get("search");
  if (urlParams.has("from")) terms.from = urlParams.get("from");
  if (urlParams.has("to")) terms.to = urlParams.get("to");
  if (urlParams.has("sourceName"))
    terms.sourceName = urlParams.getAll("sourceName");
  if (urlParams.has("source")) terms.sources = urlParams.getAll("source");
  if (urlParams.has("language")) terms.languages = urlParams.getAll("language");
  if (urlParams.has("sentiments"))
    terms.sentiments = urlParams.getAll("sentiments");
  return terms;
};

export const alertAdvancedSearchHandler = (terms, history) => {
  const queryParams = new URLSearchParams(history.location.search);

  addParam(queryParams, { name: "page", value: 1 });

  if (terms.search)
    addParam(queryParams, { name: "search", value: terms.search });
  else removeParam(queryParams, "search");

  if (terms.from) addParam(queryParams, { name: "from", value: terms.from });
  else removeParam(queryParams, "from");

  if (terms.to) addParam(queryParams, { name: "to", value: terms.to });
  else removeParam(queryParams, "to");

  if (terms.sourceName?.length)
    addParam(queryParams, terms.sourceName, "sourceName");
  else removeParam(queryParams, "sourceName");

  if (terms.sources?.length) addParam(queryParams, terms.sources, "source");
  else removeParam(queryParams, "source");

  if (terms.languages?.length)
    addParam(queryParams, terms.languages, "language");
  else removeParam(queryParams, "language");

  if (terms.sentiments?.length)
    addParam(queryParams, terms.sentiments, "sentiments");
  else removeParam(queryParams, "sentiments");

  history.replace({
    search: queryParams.toString(),
  });
};

/**
 *
 * @param {array} array: Array of data.
 * @param {string} key: The key contains the translated data.
 * @param {function} translator: The translation function.
 * @param {string} _module: The module in which the trnaslation belong.
 */
export const translateArrayOfObjects = (array, key, translator, _module) =>
  array.map((option) => ({
    ...option,
    [key]: translator(option[key], _module),
  }));

/**
 *
 * @param {string} email The email you want to test its validity.
 * @returns boolean
 */
export const validateEmail = (email) => {
  const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regEx.test(email);
};

/**
 *
 * @param {array} data List of folders to be categorized.
 * @return Object
 */
export const categorizeData = (data) => {
  let allItems = [];
  data.forEach((folder) => {
    allItems = [...allItems, ...folder.folderItems];
  });
  return categorizeNews(allItems);
};

/**
 *
 * @param {array} data News list
 * @returns object
 */
export const categorizeNews = (data, translator) => {
  if (!data) return [];
  let categories = [];
  const uncategorizedName = "alerts.daily.uncategorized.text";
  data.forEach((folderItem) => {
    if (folderItem.parentDuplicated) return;
    const categoryName = folderItem.category
      ? folderItem.category["@id"]
      : uncategorizedName;
    let targetCategory = categories.find((el) => el.id === categoryName);
    if (!!targetCategory) {
      targetCategory.data.push(folderItem);
      // categories = replaceItemInArray(targetCategory, categories, folderItem.category['@id']);
    } else
      categories.push({
        name: folderItem.category
          ? folderItem.category.name
          : translator(uncategorizedName, "alerts"),
        id: categoryName,
        order: folderItem.category ? folderItem.category.order : 99999999999999,
        image: folderItem.category
          ? folderItem.category.image
            ? folderItem.category.image.contentUrl
            : null
          : null,
        // image: folderItem.category.image ? folderItem.category.image.contentUrl : null,
        data: [folderItem],
      });
  });
  return customSorter(categories, "order");
};

/**
 *
 * @param {*} array: The given array you want to sort
 * @param {*} key: The key sort is based on
 * @returns
 */
export const customSorter = (array, key) =>
  array.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));

/**
 *
 * @param {object} values An object of key/values. This function will generate an array of objects.
 * @returns array
 */
export const generateConfigList = (values) => {
  let config = [];
  Object.keys(values).forEach((key) => {
    config.push({
      key,
      value: values[key],
    });
  });
  return config;
};

/**
 *
 * * Functions below belong to the Statistics module
 */

/**
 *
 * @param {object} data
 * @param {function} translator
 * @returns array
 */
export const generateDateNames = (data, unit, translator) => {
  const arr = Object.keys(data);
  if (!arr.length) return [];
  let months = [];
  arr.forEach((key, index) => {
    switch (unit) {
      case statsSegments.day:
        months.push(
          translator(
            `constants.days.${moment()
              .subtract(index, "day")
              .startOf("day")
              .format("dddd")
              .toLowerCase()}`,
            "constants"
          )
        );
        break;

      case statsSegments.week:
        months.push(
          `${translator("constants.week.title", "constants")} ${index + 1}`
        );
        break;

      case statsSegments.month:
        months.push(
          translator(
            `stats.months.${moment()
              .subtract(index, "month")
              .startOf("month")
              .format("MMMM")
              .toLowerCase()}`,
            "stats"
          )
        );
        break;

      default:
        break;
    }
  });

  if (unit === statsSegments.week) {
    return months;
  }

  return months.reverse();
};

export const generateDaysFromRange = ({
  dates,
  messageTranslator,
  unit,
  number,
}) => {
  let units = [];
  Array(number)
    .fill(number)
    .forEach((key, index) => {
      switch (unit) {
        case statsSegments.day:
          units.push(
            messageTranslator(
              `constants.days.${moment(dates[0])
                .add(index, "day")
                .startOf("day")
                .format("dddd")
                .toLowerCase()}`,
              "constants"
            )
          );
          break;

        case statsSegments.week:
          units.push(
            `${messageTranslator("constants.week.title", "constants")} ${
              index + 1
            }`
          );
          break;

        case statsSegments.month:
          units.push(
            messageTranslator(
              `stats.months.${moment(dates[0])
                .add(index, "month")
                .startOf("month")
                .format("MMMM")
                .toLowerCase()}`,
              "stats"
            )
          );
          break;

        default:
          break;
      }
    });
  return units;
};

/**
 *
 * @param {object} data
 * @returns array
 */
export const getPercentage = (data) => {
  if (!data) return [];
  return Object.keys(data).map((key) => data[key].percentage || 0);
};

export const getCount = (data, unit) => {
  if (!data) return [];
  if (unit === statsSegments.week) {
    return Object.keys(data).map((key) => data[key].count);
  }

  return Object.keys(data)
    .map((key) => data[key].count)
    .reverse();
};

export const dataSelectEN = [
  {
    key: "0_MONTHS",
    value: "0_months",
    label: "All",
  },
  {
    key: "1_MONTH",
    value: "1_month",
    label: "1 month",
  },
  {
    key: "2_MONTHS",
    value: "2_months",
    label: "2 months",
  },
  {
    key: "3_MONTHS",
    value: "3_months",
    label: "3 months",
  },
  {
    key: "4_MONTHS",
    value: "4_months",
    label: "4 months",
  },
  {
    key: "5_MONTHS",
    value: "5_months",
    label: "5 months",
  },
  {
    key: "6_MONTHS",
    value: "6_months",
    label: "6 months",
  },
  {
    key: "7_MONTHS",
    value: "7_months",
    label: "7 months",
  },
  {
    key: "8_MONTHS",
    value: "8_months",
    label: "8 months",
  },
  {
    key: "9_MONTHS",
    value: "9_months",
    label: "9 months",
  },
  {
    key: "10_MONTHS",
    value: "10_months",
    label: "10 months",
  },
  {
    key: "11_MONTHS",
    value: "11_months",
    label: "11 months",
  },
  {
    key: "1_YEAR",
    value: "1_year",
    label: "1 year",
  },
  {
    key: "2_YEARS",
    value: "2_years",
    label: "2 years",
  },
];

export const dataSelectAR = [
  {
    key: "0_MONTHS",
    value: "0_months",
    label: "الكل",
  },
  {
    key: "1_MONTH",
    value: "1_month",
    label: "شهر",
  },
  {
    key: "2_MONTHS",
    value: "2_months",
    label: "شهرين",
  },
  {
    key: "3_MONTHS",
    value: "3_months",
    label: "ثلاث اشهر",
  },
  {
    key: "4_MONTHS",
    value: "4_months",
    label: "أربعة أشهر",
  },
  {
    key: "5_MONTHS",
    value: "5_months",
    label: "خمسة أشهر",
  },
  {
    key: "6_MONTHS",
    value: "6_months",
    label: "ستة أشهر",
  },
  {
    key: "7_MONTHS",
    value: "7_months",
    label: "سبعة أشهر",
  },
  {
    key: "8_MONTHS",
    value: "8_months",
    label: "ثمانية أشهر",
  },
  {
    key: "9_MONTHS",
    value: "9_months",
    label: "تسعة أشهر",
  },
  {
    key: "10_MONTHS",
    value: "10_months",
    label: "عشر أشهر",
  },
  {
    key: "11_MONTHS",
    value: "11_months",
    label: "إحدى عشر شهر",
  },
  {
    key: "1_YEAR",
    value: "1_year",
    label: "سنة",
  },
  {
    key: "2_YEARS",
    value: "2_years",
    label: "سنتان",
  },
];
