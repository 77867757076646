/**
 * ? Create user
 */
export const USER_CREATE            = 'USER_CREATE';
export const USER_CREATE_SUCCESS    = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR      = 'USER_CREATE_ERROR';
export const RESET_IS_CREATED       = 'RESET_IS_CREATED';

// Get user
export const USER_GET_BY_ID         = 'USER_GET_BY_ID';
export const USER_GET_BY_ID_SUCCESS = 'USER_GET_BY_ID_SUCCESS';
export const USER_GET_BY_ID_ERROR   = 'USER_GET_BY_ID_ERROR';

// Get Me
export const USER_GET_ME            = 'USER_GET_ME';
export const USER_GET_ME_SUCCESS    = 'USER_GET_ME_SUCCESS';
export const USER_GET_ME_ERROR      = 'USER_GET_ME_ERROR';

// List users
export const USERS_LIST             = 'USERS_LIST';
export const USERS_LIST_SUCCESS     = 'USERS_LIST_SUCCESS';
export const USERS_LIST_ERROR       = 'USERS_LIST_ERROR';

// Update user
export const USER_UPDATE            = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS    = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR      = 'USER_UPDATE_ERROR';
export const USER_RESET_UPDATED     = 'USER_RESET_UPDATED';

// Delete user
export const USER_DELETE            = 'USER_DELETE';
export const USER_DELETE_SUCCESS    = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR      = 'USER_DELETE_ERROR';
export const USER_RESET_IS_DELETED  = 'USER_RESET_IS_DELETED';

// * Change password
export const USER_CHANGE_PASSWORD         = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_ERROR   = 'USER_CHANGE_PASSWORD_ERROR';
export const USER_CHANGE_PASSWORD_RESET   = 'USER_CHANGE_PASSWORD_RESET';
