import { Card, Col, Divider, List, Row, Typography, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import WorldMap from "react-svg-worldmap";
import { ConfigContext } from '../../../../context/config';
import "./countries-map.less";

const colors = ["#2A3560", "#6D9DE4", "#F0953E", "#F3D660", "#44bf84", "#4f2689", "#00a2e8", "#ff7777", "##FEA47F", "#EAB543", "#6D214F", "#9AECDB", "#D6A2E8", "#82589F", "#58B19F", "#FC427B", "#2C3A47", "#227093", "#2c2c54", "#218c74", "#aaa69d", "#706fd3", "#40407a"];

export const CountriesMap = ({ countries, isLoading }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [formattedData, setFormattedData] = useState([]);
  const [formattedMapData, setFormattedMapData] = useState([]);

  useEffect(() => {
    countries && setFormattedData(mapCountriesData(countries.results));
    countries && setFormattedMapData(mapCountriesData1(countries.results));
  }, [countries]);

  const getCountryIndex = (countries, code) => countries.findIndex(elem => elem.country === code.toLowerCase());

  const mapCountriesData = (data) => Object.keys(data).map(key => ({ country: key.toLowerCase(), value: data[key] }));
  const mapCountriesData1 = (data) => Object.keys(data).map(key => ({ country: key.toLowerCase(), value: '(' + data[key] + ')' }));

  const getTotalInteractions = (data) => Object.keys(data).map(key => data[key]).reduce((acc, value) => acc + value, 0);

  const stylingFunction = (values) => {
    return {
      fill: values.countryValue ? colors[getCountryIndex(formattedData, values.countryCode)] : "#E6E9F4",
      stroke: "#FFFFFF",
      strokeWidth: 1,
      strokeOpacity: 1,
      cursor: "pointer",
    };
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        title={(
          <Row justify='space-between'>
            <Col>
              {messageTranslator("stats.countries.card.title", "stats")}
            </Col>
          </Row>
        )}
      >
        <Row>
          <Col flex="290px" className='countries-wrapper'>
            <Card>
              <Typography.Text style={{ fontSize: "38px", lineHeight: "1.2em", color: "#333333" }}>{countries && getTotalInteractions(countries.results)}</Typography.Text><br />
              <Typography.Text type="secondary">{messageTranslator("stats.countries.card.interaction", "stats")}</Typography.Text>
              <Divider />
              <List
                itemLayout="vertical"
                dataSource={formattedData}
                loading={isLoading}
                renderItem={item => !!item.value && (
                  <List.Item
                    key={item.country}
                    extra={item.value}>

                    <List.Item.Meta
                      avatar={<img width={40} src={`/images/flags/${item.country.toLowerCase()}.svg`} alt={item.country} />}
                      title={<Typography.Text>
                        {messageTranslator(`countries.names.${item.country.toUpperCase()}`, "countries")}
                      </Typography.Text>}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
          <Col flex="auto" className='text-right'>
            <WorldMap
              color="red"
              value-suffix="people"
              size="responsive"
              data={formattedMapData}
              tooltipBgColor="#FFFFFF"
              tooltipTextColor="#000000"
              styleFunction={stylingFunction}
              richInteraction
            />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};
