import { CaretDownOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import generatePassword from 'password-generator';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FileUploader } from '../../../../../components/FileUploader';
import { AuthContext } from '../../../../../context/Auth';
import { ConfigContext } from '../../../../../context/config';
import { mergeErrors } from '../../../../../utils/errors-utils';
import { matchPasswords } from '../../../../../utils/utils';

export const UserModal = ({
  roles: { data: listRoles, isLoading: loadingRoles },
  createUser: { isLoading: loadingCreate, errors },
  updateUser: { isLoading: loadingUpdate, errors: updateErrors },
  label,
  isLoading,
  userToUpdate,
  onOk,
  onCancel,
  ...modalProps
}) => {

  const { state } = useContext(AuthContext);

  const { messageTranslator } = useContext(ConfigContext);

  const REQUIRED_FIELD = messageTranslator("commons.required.message", "commons");
  const MINIMUM_LENGTH = messageTranslator("commons.minimumLength.two", "commons");

  const [form] = Form.useForm();

  const uploadRef = useRef();

  const { submit, setFieldsValue, setFields, resetFields, getFieldsValue } = form;

  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const values = mergeErrors(getFieldsValue(), errors, messageTranslator);
    !!setFields && !!values?.length && setFields(values);
  }, [errors, getFieldsValue, setFields, messageTranslator]);

  useEffect(() => {
    const values = mergeErrors(getFieldsValue(), updateErrors, messageTranslator);
    !!setFields && !!values?.length && setFields(values);
  }, [updateErrors, getFieldsValue, setFields, messageTranslator]);

  useEffect(() => {
    if (userToUpdate) setFieldsValue({
      ...userToUpdate,
      role: userToUpdate.role ? userToUpdate.role['@id'] : undefined,
      image: userToUpdate.image ? userToUpdate.image['@id'] : null,
    });
  }, [userToUpdate, setFieldsValue]);

  useEffect(() => {
    if (modalProps.visible && !userToUpdate) {
      resetFields();
      uploadRef?.current?.resetImageUrl();
    }
  }, [modalProps.visible, resetFields]);

  const generateNewPassword = () => {
    const newPassword = generatePassword(8);
    resetFields(['password', 'cofirmPassword']);
    setFieldsValue({
      password: newPassword,
      cofirmPassword: newPassword
    });
  };

  const handleFormFinish = ({ cofirmPassword, ...user }) => {
    if (!user.image) setImageError(true);
    else {
      setImageError(false);
      onOk(user);
    }
  }

  return (
    <Modal
      {...modalProps}
      title={messageTranslator(userToUpdate ? "users.list.label.update" : "users.list.label.add", "users")}
      onOk={onOk}
      onCancel={onCancel}
      getContainer={false}
      forceRender
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={onCancel}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className='btn-wrapper'>
          <Button
            key="submit"
            type="primary"
            loading={loadingCreate || loadingUpdate}
            onClick={submit}
          >
            {messageTranslator(userToUpdate ? "commons.update" : "commons.add", "commons")}
          </Button>
        </span>
      ]}
    >
      <Form
        form={form}
        name="addUser"
        onFinish={handleFormFinish}
      >
        <Form.Item
          name="image"
          hidden
        >
          <Input />
        </Form.Item>
        <Row gutter={40}>
          <Col md={12} xs={24}>
            <Form.Item
              name="userName"
              rules={[
                { required: true, message: REQUIRED_FIELD },
                { min: 2, message: MINIMUM_LENGTH }
              ]}
            >
              <Input
                placeholder={`${messageTranslator("users.list.labels.username", "users")} *`}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="jobTitle"
              rules={[
                { required: true, message: REQUIRED_FIELD },
                { min: 2, message: MINIMUM_LENGTH }
              ]}
            >
              <Input
                placeholder={`${messageTranslator("users.list.labels.jobtitle", "users")} *`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col md={12} xs={24}>
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: REQUIRED_FIELD },
                { min: 2, message: MINIMUM_LENGTH }
              ]}
            >
              <Input
                placeholder={`${messageTranslator("users.list.labels.firstName", "users")} *`}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: REQUIRED_FIELD },
                { min: 2, message: MINIMUM_LENGTH }
              ]}
            >
              <Input
                placeholder={`${messageTranslator("users.list.labels.lastName", "users")} *`}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={40}>
          <Col md={12} xs={24}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: REQUIRED_FIELD },
                { type: 'email', message: messageTranslator("users.list.labels.invalidEmail", "users") }
              ]}
            >
              <Input
                placeholder={`${messageTranslator("users.list.labels.email", "users")} *`}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="role"
              rules={[
                { required: true, message: REQUIRED_FIELD }
              ]}
            >
              <Select
                loading={loadingRoles}
                placeholder={`${messageTranslator("users.list.labels.userRole", "users")} *`}
                suffixIcon={<CaretDownOutlined />}
              >
                {
                  listRoles.map(role => (
                    <Select.Option key={role['@id']} value={role['@id']}>{role.title}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={40}>
          <Col md={12} xs={24}>
            {
              !userToUpdate && (
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: REQUIRED_FIELD },
                    { min: 8, message: messageTranslator("users.list.labels.invalidPassword", "users") }
                  ]}
                >
                  <Input.Password
                    placeholder={`${messageTranslator("users.list.labels.password", "users")} *`}
                    addonBefore={(
                      <Tooltip title={messageTranslator("users.list.labels.generatePassword", "users")}>
                        <SyncOutlined onClick={generateNewPassword} />
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              )
            }
          </Col>
          <Col md={12} xs={24}>
            {
              !userToUpdate && (
                <Form.Item
                  name="cofirmPassword"
                  rules={[
                    { required: true, message: REQUIRED_FIELD },
                    ({ getFieldValue }) => matchPasswords(getFieldValue, messageTranslator("users.list.labels.passwordsNotMatch", "users"))
                  ]}
                >
                  <Input.Password
                    placeholder={`${messageTranslator("users.list.labels.confirmPassword", "users")} *`}
                  />
                </Form.Item>
              )
            }
          </Col>
        </Row>

        <Divider />

        <FileUploader
          ref={uploadRef}
          label={messageTranslator("users.add.image.title", "users")}
          token={state.token}
          imageSrc={userToUpdate?.image?.contentUrl}
          setFieldsValue={setFieldsValue}
          fieldName="image"
        />
        {
          imageError && (
            <Alert
              type="error"
              message={messageTranslator("users.add.image.error", "users")}
              showIcon
            />
          )
        }
      </Form>
    </Modal>
  );
};
