import { Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AuthRightBlock } from '../../../components/AuthRightBlock';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { AuthContext } from '../../../context/Auth';
import { ConfigContext } from '../../../context/config';
import { useQuery } from '../../../hooks/query-params';
import { LoginForm } from './components/LoginForm';

export const Login = ({ route, history }) => {

  const query = useQuery(history);

  const { state: { isLoading, errors }, loginAction } = useContext(AuthContext);
  const { configState, messageTranslator } = useContext(ConfigContext);

  const [hasResetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (query.has("reset-password")) setResetPassword(true)
  }, [query]);

  const handleSubmit = (values) => loginAction(values);

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Row className="full-height">
        <AuthRightBlock />
        <LoginForm
          hasResetPassword={hasResetPassword}
          language={configState.language}
          isLoading={isLoading}
          errors={errors}
          onFormSubmit={handleSubmit}
        />
      </Row>
    </PageSkeleton>
  );
};
