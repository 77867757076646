import { api } from "..";

const URL = "/api/scheduled-reports";

export const createSheduleApi = (schedule) => {
  api.post(URL, schedule);
};

export const updateShceduleApi = (id, ...schedule) =>
  api.put(`${URL}/${id}`, schedule[0]);

export const getSheduleApi = (id) => api.get(`${URL}/${id}`);

export const cancelScheduleApi = (id) => api.delete(`${URL}/${id}`);
