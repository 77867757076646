import { fork } from "redux-saga/effects";
import {
  watchAddFolder,
  watchAdvancedSearch,
  watchAuditorChangeFolderState,
  watchCombineDuplicatedNews,
  watchFilterBySourceName,
  watchGetALertResults,
  watchGetNewALertResults,
  watchGetFolderDaily,
  watchPatchResultCategory,
  watchPublishFolder,
  watchRemoveFromFolder,
  watchReviewFolder,
  watchSearchForResults,
  watchSearchSourceNames,
  watchSetAlertBookmarked,
  watchUpdateFolder,
} from "./alert-results/sagas";
import {
  watchCreateAlert,
  watchDeleteAlert,
  watchFindDuplication,
  watchGetALert,
  watchGetAlertList,
  watchUpdateAlert,
  watchHandleSelectedAlert,
} from "./alerts/sagas";
import {
  watchCreateCategory,
  watchDeleteCategory,
  watchListCategories,
  watchUpdateCategory,
} from "./categories/sagas";
import {
  watchCreateConfig,
  watchGetConfig,
  watchLoadGlobalConfig,
  watchSendEmailTest,
  watchUpdateConfig,
} from "./config/sagas";
import {
  watchCreateEmailGroup,
  watchDeleteEmailGroup,
  watchGetEmailGroup,
  watchGetEmailGroups,
  watchParseContactsFile,
  watchUpdateEmailGroup,
} from "./emailGroups/sagas";
import {
  watchGetNotifications,
  watchMarkNotificationSeen,
} from "./notifications/sagas";
import { watchGetPermissions } from "./permissions/sagas";
import {
  watchCancelSchedule,
  watchCreateReport,
  watchDeleteReport,
  watchExportReport,
  watchGetReport,
  watchGetReports,
  watchUpdateReport,
  watchGetSchedule,
} from "./reports/sagas";
import { watchForgetPassword, watchResetPassword } from "./resetPassword/sagas";
import {
  watchCreateRole,
  watchDeleteRole,
  watchGetRole,
  watchGetRoles,
  watchUpdateRole,
} from "./roles/sagas";
import { watchCreateSchedule, watchUpdateShedudle } from "./schedule/sagas";
import { watchGetScrapResults } from "./scrap-results/sagas";
import {
  watchActivateUpdateSource,
  watchCreateSource,
  watchDeleteSource,
  watchFindDuplicatedSource,
  watchGetListSources,
  watchGetSource,
  watchUpdateSource,
} from "./sources/sagas";
import {
  watchGetCountriesStats,
  watchGetFeedbackStats,
  watchGetHashtagsStats,
  watchGetImportantNewsStats,
  watchGetInfluencersStats,
  watchGetKeywordsStats,
  watchGetMonitoringStats,
} from "./stats/sagas";
import {
  watchChangePassword,
  watchCreateUser,
  watchDeleteUser,
  watchGetMe,
  watchGetUserById,
  watchGetUsers,
  watchUserUpdate,
} from "./users/sagas";

const sagas = [
  // * Users
  fork(watchCreateUser),
  fork(watchGetUsers),
  fork(watchGetUserById),
  fork(watchDeleteUser),
  fork(watchUserUpdate),
  fork(watchGetMe),
  fork(watchChangePassword),

  // * Permissions
  fork(watchGetPermissions),

  // * Roles
  fork(watchCreateRole),
  fork(watchGetRoles),
  fork(watchDeleteRole),
  fork(watchGetRole),
  fork(watchUpdateRole),

  // * Sources
  fork(watchCreateSource),
  fork(watchFindDuplicatedSource),
  fork(watchGetListSources),
  fork(watchGetSource),
  fork(watchUpdateSource),
  fork(watchDeleteSource),
  fork(watchActivateUpdateSource),

  // * Alerts
  fork(watchCreateAlert),
  fork(watchGetALert),
  fork(watchGetALertResults),
  fork(watchGetNewALertResults),
  fork(watchGetAlertList),
  fork(watchFindDuplication),
  fork(watchUpdateAlert),
  fork(watchDeleteAlert),
  fork(watchHandleSelectedAlert),

  // * Notifications
  fork(watchGetALertResults),
  fork(watchSetAlertBookmarked),
  fork(watchSearchSourceNames),
  fork(watchAdvancedSearch),
  fork(watchPatchResultCategory),
  fork(watchSearchForResults),
  fork(watchFilterBySourceName),

  // * Categories
  fork(watchCreateCategory),
  fork(watchListCategories),
  fork(watchUpdateCategory),
  fork(watchDeleteCategory),

  // * Folders
  fork(watchGetFolderDaily),
  fork(watchAddFolder),
  fork(watchUpdateFolder),
  fork(watchCombineDuplicatedNews),
  fork(watchPublishFolder),
  fork(watchReviewFolder),
  fork(watchRemoveFromFolder),
  fork(watchAuditorChangeFolderState),

  // * Scrap reuslts
  fork(watchGetScrapResults),

  // * Config
  fork(watchCreateConfig),
  fork(watchGetConfig),
  fork(watchUpdateConfig),
  fork(watchSendEmailTest),
  fork(watchLoadGlobalConfig),

  // * Email groups
  fork(watchCreateEmailGroup),
  fork(watchGetEmailGroups),
  fork(watchGetEmailGroup),
  fork(watchUpdateEmailGroup),
  fork(watchDeleteEmailGroup),
  fork(watchParseContactsFile),

  // * Reports
  fork(watchCreateReport),
  fork(watchGetReport),
  fork(watchGetReports),
  fork(watchUpdateReport),
  fork(watchDeleteReport),
  fork(watchExportReport),
  fork(watchCancelSchedule),
  fork(watchGetSchedule),
  // * Schedule
  fork(watchCreateSchedule),
  fork(watchUpdateShedudle),

  // * Reset password
  fork(watchForgetPassword),
  fork(watchResetPassword),

  // * Notifications
  fork(watchGetNotifications),
  fork(watchMarkNotificationSeen),

  // * Stats
  fork(watchGetCountriesStats),
  fork(watchGetFeedbackStats),
  fork(watchGetImportantNewsStats),
  fork(watchGetMonitoringStats),
  fork(watchGetHashtagsStats),
  fork(watchGetKeywordsStats),
  fork(watchGetInfluencersStats),
];

export default sagas;
