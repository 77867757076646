import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../store/logout";
import "./FallBackUI.css";

import { ConfigContext } from "../../context/config";
import { AuthContext } from "../../context/Auth";

export default function FallBackUI() {
  const { messageTranslator } = useContext(ConfigContext);
  const { logout } = useContext(AuthContext);

  const dispatch = useDispatch();

  const handleLogout = () => {
    logout();
    dispatch(logoutAction());
    window.location.href = "/login";
  };

  return (
    <div className="FallBack-UI">
      <lottie-player
        src="https://assets6.lottiefiles.com/packages/lf20_kcsr6fcp.json"
        background="transparent"
        speed="1"
        style={{ width: "500px", height: "500px" }}
        loop
        autoplay
      ></lottie-player>
      <h1>{messageTranslator("commons.fallback.message", "commons")}</h1>
      <button type="button" onClick={handleLogout}>
        {messageTranslator("commons.fallback.logout", "commons")}
      </button>
    </div>
  );
}
