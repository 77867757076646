import { DeleteFilled, EditFilled, UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { ConfigContext } from '../../../../../context/config';
import { PAGE_SIZE } from '../../../../../utils/constants';
import { DATE_FORMAT } from '../../../../../utils/moment-utils';
import { CAN_DELETE, CAN_PUT } from '../../../../../utils/permissions-utils';
import { listIndexer, tablePaginationOptions } from '../../../../../utils/utils';
import * as PATHS from './../../../../../routes/paths';

const { Column } = Table;

export const UsersList = ({
  data,
  role,
  currentPage,
  totalItems,
  onPageChange,
  onSelectUser,
  onDeleteUser
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <Table
      dataSource={[...data]}
      pagination={tablePaginationOptions({ totalItems, pageSize: 30, currentPage, onPageChange, messageTranslator })}
      locale={{
        emptyText: messageTranslator("users.list.emptyText", "users")
      }}
      rowKey={rec => rec['@id']}
      scroll={{ x: true }}
    >
      <Column
        key="number"
        title="#"
        render={(user, rec, index) => <>{listIndexer(index, PAGE_SIZE, currentPage)}</>
        }
      />
      <Column
        key="fullname"
        title={messageTranslator("users.list.labels.username", "users")}
        className="text-left"
        render={user => (
          <a href={`${PATHS.UPDATE_USER}/${user.id}`}>
            <Row gutter={16} align='middle'>
              <Col>
                <Avatar
                  icon={!user.image && <UserOutlined />}
                  src={user.image ? `${process.env.REACT_APP_BASE_URL}${user.image.contentUrl}` : null}
                  size={37}
                />
              </Col>
              <Col>
                <Space direction='vertical' size={0}>
                  <Typography.Text strong>{user.firstName} {user.lastName}</Typography.Text>
                  <Typography.Text type="secondary" className="smallTypo">{user.email}</Typography.Text>
                </Space>
              </Col>
            </Row>
          </a>
        )
        }
      />
      <Column
        key="role"
        title={messageTranslator("users.list.labels.role", "users")}
        render={user => <Tag className='large-tag' color={user.role.color}>{user.role.title}</Tag>
        }
      />
      <Column
        key="email"
        title={messageTranslator("users.list.labels.creationDate", "users")}
        render={user => <Typography.Text>{moment(user.createdAt).format(DATE_FORMAT)}</Typography.Text>
        }
      />
      <Column
        key="jobtitle"
        title={messageTranslator("users.list.labels.jobtitle", "users")}
        render={user => <Typography.Text>{user.jobTitle}</Typography.Text>
        }
      />
      <Column
        key="actions"
        title=""
        render={user => (
          <Row gutter={[8, 8]}>
            <PermissionsHandler
              resourceName="User"
              blocPermission={CAN_PUT}
              userRole={role}
            >
              <Col>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("users.list.labels.updateUserData", "users")}
                >
                  <EditFilled onClick={() => onSelectUser(user)} />
                </Tooltip>
              </Col>
            </PermissionsHandler>
            <PermissionsHandler
              resourceName="User"
              blocPermission={CAN_DELETE}
              userRole={role}
            >
              <Col>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("users.list.labels.deleteUser", "users")}
                >
                  <Popconfirm
                    title={`${messageTranslator("users.list.labels.deleteConfirmText", "users")} ${user.firstName} ${user.lastName}`}
                    onConfirm={(e) => onDeleteUser(user.id)}
                    okText={messageTranslator("commons.delete.confirm", "commons")}
                    cancelText={messageTranslator("commons.cancel", "commons")}
                  >
                    <Link to="#">
                      <DeleteFilled />
                    </Link>
                  </Popconfirm>
                </Tooltip>
              </Col>
            </PermissionsHandler>
          </Row>
        )}
      />
    </Table>
  )
};

