import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Dropdown, Menu, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../context/config';
import { NOTIFICATONS } from '../../routes/paths';
import { DATE_FORMAT } from '../../utils/moment-utils';
import './styles.less';

export const Notifications = ({ children, notifications = [], onClick }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const calculateElapsedTime = (time) => {
    const min = moment().diff(time, 'minutes');
    if (!min) return `${1}${messageTranslator("notifications.list.time.minutes", "notifications")}`;
    if (min <= 59) return `${min}${messageTranslator("notifications.list.time.minutes", "notifications")}`;
    const hours = Math.round(min / 60);
    if (hours <= 23) return `${hours}${messageTranslator("notifications.list.time.hours", "notifications")}`;
    const days = Math.round(hours / 24);
    if (days <= 6) return `${days}${messageTranslator("notifications.list.time.days", "notifications")}`;
    return moment(time).format(DATE_FORMAT);
  };

  const renderNotifications = (notfiList, onClick) => (
    <Menu className="notification-area">
      {
        notfiList.length
          ?
          notfiList.slice(0, 3).map(notification => (
            <Menu.Item key={notification['@id']} className={notification.seen ? "" : "unseen"}>
              <HashLink smooth  to={`${NOTIFICATONS}#${notification.id}`} onClick={() => onClick(notification.id)}>
                <Row wrap={false} justify="space-between" align="middle" gutter={10} className="full-width">
                  <Col flex="0 1 48px">
                    <Avatar
                      size={38}
                      src={!!notification.actionDoneByUser?.image?.contentUrl && `${process.env.REACT_APP_BASE_URL}${notification.actionDoneByUser.image.contentUrl}`}
                      icon={!notification.actionDoneByUser && <UserOutlined />}
                    />
                  </Col>
                  <Col flex="1 1 auto" >
                    <Typography.Text>
                      {messageTranslator(notification.notificationType, "notifications")}
                    </Typography.Text>
                  </Col>
                  <Col flex="0 1 40px" className='text-right'>
                    <Typography.Text>
                      {calculateElapsedTime(notification.createdAt)}
                    </Typography.Text>
                  </Col>
                </Row>
              </HashLink>
            </Menu.Item>
          )) : (
            <Menu.Item
              key="no-notifications"
            >
              <Typography.Text type="secondary">{messageTranslator("notifications.list.empty", "notifications")}</Typography.Text>
            </Menu.Item>
          )
      }
      {notfiList.length > 0 && <Menu.Item
            key="all-notifications"
          >
            <Link to="/notifications">
              <Typography.Text type="secondary">{messageTranslator("notifications.list.all", "notifications")}</Typography.Text>
            </Link>
          </Menu.Item>
      }
    </Menu>
  )

  return (
    <Dropdown
      overlay={renderNotifications(notifications, onClick)}
      placement="bottomLeft"
      arrow
      trigger={["click"]}
      overlayClassName="dropdown-notifs"
    >
      {children}
    </Dropdown>
  );
};
