export const countriesTranslations = {
  ar: {
    "countries.names.AD": "أندورا",
    "countries.names.AE": "الإمارات العربية المتحدة",
    "countries.names.AF": "أفغانستان",
    "countries.names.AG": "أنتيغوا وباربودا",
    "countries.names.AL": "ألبانيا",
    "countries.names.AM": "أرمينيا",
    "countries.names.AO": "أنغولا",
    "countries.names.AR": "الأرجنتين",
    "countries.names.AT": "النمسا",
    "countries.names.AU": "أستراليا",
    "countries.names.AZ": "أذربيجان",
    "countries.names.BA": "البوسنة والهرسك",
    "countries.names.BB": "باربادوس",
    "countries.names.BD": "بنغلاديش",
    "countries.names.BE": "بلجيكا",
    "countries.names.BF": "بوركينا فاسو",
    "countries.names.BG": "بلغاريا",
    "countries.names.BH": "البحرين",
    "countries.names.BI": "بوروندي",
    "countries.names.BJ": "بنين",
    "countries.names.BN": "بروناي",
    "countries.names.BO": "بوليفيا",
    "countries.names.BR": "البرازيل",
    "countries.names.BS": "باهاماس",
    "countries.names.BT": "بوتان",
    "countries.names.BW": "بوتسوانا",
    "countries.names.BY": "بيلاروس",
    "countries.names.BZ": "بليز",
    "countries.names.CA": "كندا",
    "countries.names.CD": "جمهورية الكونغو الديمقراطية",
    "countries.names.CF": "جمهورية أفريقيا الوسطى",
    "countries.names.CG": "جمهورية الكونغو",
    "countries.names.CH": "سويسرا",
    "countries.names.CI": "ساحل العاج",
    "countries.names.CL": "تشيلي",
    "countries.names.CM": "الكاميرون",
    "countries.names.CN": "الصين",
    "countries.names.CO": "كولومبيا",
    "countries.names.CR": "كوستاريكا",
    "countries.names.CU": "كوبا",
    "countries.names.CV": "الرأس الأخضر",
    "countries.names.CY": "قبرص",
    "countries.names.CZ": "جمهورية التشيك",
    "countries.names.DE": "ألمانيا",
    "countries.names.DJ": "جيبوتي",
    "countries.names.DK": "الدنمارك",
    "countries.names.DM": "دومينيكا",
    "countries.names.DO": "جمهورية الدومينيكان",
    "countries.names.DZ": "الجزائر",
    "countries.names.EC": "الإكوادور",
    "countries.names.EE": "إستونيا",
    "countries.names.EG": "مصر",
    "countries.names.ER": "إريتريا",
    "countries.names.ES": "إسبانيا",
    "countries.names.ET": "إثيوبيا",
    "countries.names.FI": "فنلندا",
    "countries.names.FJ": "فيجي",
    "countries.names.FM": "ولايات ميكرونيسيا المتحدة",
    "countries.names.FR": "فرنسا",
    "countries.names.GA": "الغابون",
    "countries.names.GB": "المملكة المتحدة",
    "countries.names.GD": "غرينادا",
    "countries.names.GE": "جورجيا",
    "countries.names.GH": "غانا",
    "countries.names.GM": "غامبيا",
    "countries.names.GN": "غينيا",
    "countries.names.GQ": "غينيا الاستوائية",
    "countries.names.GR": "اليونان",
    "countries.names.GT": "غواتيمالا",
    "countries.names.GW": "غينيا بيساو",
    "countries.names.GY": "غيانا",
    "countries.names.HN": "هندوراس",
    "countries.names.HR": "كرواتيا",
    "countries.names.HT": "هايتي",
    "countries.names.HU": "المجر",
    "countries.names.ID": "إندونيسيا",
    "countries.names.IE": "أيرلندا",
    "countries.names.IL": "إسرائيل",
    "countries.names.IN": "الهند",
    "countries.names.IQ": "العراق",
    "countries.names.IR": "إيران",
    "countries.names.IS": "آيسلندا",
    "countries.names.IT": "إيطاليا",
    "countries.names.JM": "جامايكا",
    "countries.names.JO": "الأردن",
    "countries.names.JP": "اليابان",
    "countries.names.KE": "كينيا",
    "countries.names.KG": "قيرغيزستان",
    "countries.names.KH": "كمبوديا",
    "countries.names.KI": "كيريباتي",
    "countries.names.KM": "جزر القمر",
    "countries.names.KN": "سانت كيتس ونيفيس",
    "countries.names.KP": "كوريا الشمالية",
    "countries.names.KR": "كوريا الجنوبية",
    "countries.names.KW": "الكويت",
    "countries.names.KZ": "كازاخستان",
    "countries.names.LA": "لاوس",
    "countries.names.LB": "لبنان",
    "countries.names.LC": "سانت لوسيا",
    "countries.names.LI": "ليختنشتاين",
    "countries.names.LK": "سريلانكا",
    "countries.names.LR": "ليبيريا",
    "countries.names.LS": "ليسوتو",
    "countries.names.LT": "ليتوانيا",
    "countries.names.LU": "لوكسمبورغ",
    "countries.names.LV": "لاتفيا",
    "countries.names.LY": "ليبيا",
    "countries.names.MA": "المغرب",
    "countries.names.MC": "موناكو",
    "countries.names.MD": "مولدوفا",
    "countries.names.ME": "الجبل الأسود",
    "countries.names.MG": "مدغشقر",
    "countries.names.MH": "جزر مارشال",
    "countries.names.MK": "مقدونيا",
    "countries.names.ML": "مالي",
    "countries.names.MM": "ميانمار",
    "countries.names.MN": "منغوليا",
    "countries.names.MR": "موريتانيا",
    "countries.names.MT": "مالطا",
    "countries.names.MU": "موريشيوس",
    "countries.names.MV": "جزر المالديف",
    "countries.names.MW": "مالاوي",
    "countries.names.MX": "المكسيك",
    "countries.names.MY": "ماليزيا",
    "countries.names.MZ": "موزمبيق",
    "countries.names.NA": "ناميبيا",
    "countries.names.NE": "النيجر",
    "countries.names.NG": "نيجيريا",
    "countries.names.NI": "نيكاراغوا",
    "countries.names.NL": "هولندا",
    "countries.names.NO": "النرويج",
    "countries.names.NP": "نيبال",
    "countries.names.NR": "ناورو",
    "countries.names.NZ": "نيوزيلندا",
    "countries.names.OM": "عمان",
    "countries.names.PA": "بنما",
    "countries.names.PE": "بيرو",
    "countries.names.PG": "بابوا غينيا الجديدة",
    "countries.names.PH": "الفلبين",
    "countries.names.PK": "باكستان",
    "countries.names.PL": "بولندا",
    "countries.names.PT": "البرتغال",
    "countries.names.PW": "بالاو",
    "countries.names.PY": "باراغواي",
    "countries.names.QA": "قطر",
    "countries.names.RO": "رومانيا",
    "countries.names.RS": "صربيا",
    "countries.names.RU": "روسيا",
    "countries.names.RW": "رواندا",
    "countries.names.SA": "السعودية",
    "countries.names.SB": "جزر سليمان",
    "countries.names.SC": "سيشل",
    "countries.names.SD": "السودان",
    "countries.names.SE": "السويد",
    "countries.names.SG": "سنغافورة",
    "countries.names.SI": "سلوفينيا",
    "countries.names.SK": "سلوفاكيا",
    "countries.names.SL": "سيراليون",
    "countries.names.SM": "سان مارينو",
    "countries.names.SN": "السنغال",
    "countries.names.SO": "الصومال",
    "countries.names.SR": "سورينام",
    "countries.names.SS": "جنوب السودان",
    "countries.names.ST": "ساو تومي وبرينسيب",
    "countries.names.SV": "السلفادور",
    "countries.names.SY": "سوريا",
    "countries.names.SZ": "إسواتيني",
    "countries.names.TD": "تشاد",
    "countries.names.TG": "توغو",
    "countries.names.TH": "تايلاند",
    "countries.names.TJ": "طاجيكستان",
    "countries.names.TL": "تيمور الشرقية",
    "countries.names.TM": "تركمانستان",
    "countries.names.TN": "تونس",
    "countries.names.TO": "تونغا",
    "countries.names.TR": "تركيا",
    "countries.names.TT": "ترينيداد وتوباغو",
    "countries.names.TV": "توفالو",
    "countries.names.TZ": "تنزانيا",
    "countries.names.UA": "أوكرانيا",
    "countries.names.UG": "أوغندا",
    "countries.names.US": "الولايات المتحدة",
    "countries.names.UY": "الأوروغواي",
    "countries.names.UZ": "أوزبكستان",
    "countries.names.VC": "سانت فينسنت والغرينادين",
    "countries.names.VE": "فنزويلا",
    "countries.names.VN": "فيتنام",
    "countries.names.VU": "فانواتو",
    "countries.names.WS": "ساموا",
    "countries.names.YE": "اليمن",
    "countries.names.ZA": "جنوب أفريقيا",
    "countries.names.ZM": "زامبيا",
    "countries.names.ZW": "زيمبابوي"
  },
  en: {
    "countries.names.AD": "Andorra",
    "countries.names.AE": "United Arab Emirates",
    "countries.names.AF": "Afghanistan",
    "countries.names.AG": "Antigua and Barbuda",
    "countries.names.AI": "Anguilla",
    "countries.names.AL": "Albania",
    "countries.names.AM": "Armenia",
    "countries.names.AO": "Angola",
    "countries.names.AQ": "Antarctica",
    "countries.names.AR": "Argentina",
    "countries.names.AS": "American Samoa",
    "countries.names.AT": "Austria",
    "countries.names.AU": "Australia",
    "countries.names.AW": "Aruba",
    "countries.names.AX": "\u00c5land Islands",
    "countries.names.AZ": "Azerbaijan",
    "countries.names.BA": "Bosnia and Herzegovina",
    "countries.names.BB": "Barbados",
    "countries.names.BD": "Bangladesh",
    "countries.names.BE": "Belgium",
    "countries.names.BF": "Burkina Faso",
    "countries.names.BG": "Bulgaria",
    "countries.names.BH": "Bahrain",
    "countries.names.BI": "Burundi",
    "countries.names.BJ": "Benin",
    "countries.names.BL": "Saint Barthélemy",
    "countries.names.BM": "Bermuda",
    "countries.names.BN": "Brunei Darussalam",
    "countries.names.BO": "Bolivia, Plurinational State of",
    "countries.names.BQ": "Caribbean Netherlands",
    "countries.names.BR": "Brazil",
    "countries.names.BS": "Bahamas",
    "countries.names.BT": "Bhutan",
    "countries.names.BV": "Bouvet Island",
    "countries.names.BW": "Botswana",
    "countries.names.BY": "Belarus",
    "countries.names.BZ": "Belize",
    "countries.names.CA": "Canada",
    "countries.names.CC": "Cocos (Keeling) Islands",
    "countries.names.CD": "Congo, the Democratic Republic of the",
    "countries.names.CF": "Central African Republic",
    "countries.names.CG": "Republic of the Congo",
    "countries.names.CH": "Switzerland",
    "countries.names.CI": "C\u00f4te d'Ivoire",
    "countries.names.CK": "Cook Islands",
    "countries.names.CL": "Chile",
    "countries.names.CM": "Cameroon",
    "countries.names.CN": "China (People's Republic of China)",
    "countries.names.CO": "Colombia",
    "countries.names.CR": "Costa Rica",
    "countries.names.CU": "Cuba",
    "countries.names.CV": "Cape Verde",
    "countries.names.CW": "Cura\u00e7ao",
    "countries.names.CX": "Christmas Island",
    "countries.names.CY": "Cyprus",
    "countries.names.CZ": "Czech Republic",
    "countries.names.DE": "Germany",
    "countries.names.DJ": "Djibouti",
    "countries.names.DK": "Denmark",
    "countries.names.DM": "Dominica",
    "countries.names.DO": "Dominican Republic",
    "countries.names.DZ": "Algeria",
    "countries.names.EC": "Ecuador",
    "countries.names.EE": "Estonia",
    "countries.names.EG": "Egypt",
    "countries.names.EH": "Western Sahara",
    "countries.names.ER": "Eritrea",
    "countries.names.ES": "Spain",
    "countries.names.ET": "Ethiopia",
    "countries.names.EU": "Europe",
    "countries.names.FI": "Finland",
    "countries.names.FJ": "Fiji",
    "countries.names.FK": "Falkland Islands (Malvinas)",
    "countries.names.FM": "Micronesia, Federated States of",
    "countries.names.FO": "Faroe Islands",
    "countries.names.FR": "France",
    "countries.names.GA": "Gabon",
    "GB-Ecountries.names.NG": "England",
    "GB-Ncountries.names.IR": "Northern Ireland",
    "GB-Scountries.names.CT": "Scotland",
    "GB-Wcountries.names.LS": "Wales",
    "countries.names.GB": "United Kingdom",
    "countries.names.GD": "Grenada",
    "countries.names.GE": "Georgia",
    "countries.names.GF": "French Guiana",
    "countries.names.GG": "Guernsey",
    "countries.names.GH": "Ghana",
    "countries.names.GI": "Gibraltar",
    "countries.names.GL": "Greenland",
    "countries.names.GM": "Gambia",
    "countries.names.GN": "Guinea",
    "countries.names.GP": "Guadeloupe",
    "countries.names.GQ": "Equatorial Guinea",
    "countries.names.GR": "Greece",
    "countries.names.GS": "South Georgia and the South Sandwich Islands",
    "countries.names.GT": "Guatemala",
    "countries.names.GU": "Guam",
    "countries.names.GW": "Guinea-Bissau",
    "countries.names.GY": "Guyana",
    "countries.names.HK": "Hong Kong",
    "countries.names.HM": "Heard Island and McDonald Islands",
    "countries.names.HN": "Honduras",
    "countries.names.HR": "Croatia",
    "countries.names.HT": "Haiti",
    "countries.names.HU": "Hungary",
    "countries.names.ID": "Indonesia",
    "countries.names.IE": "Ireland",
    "countries.names.IL": "Israel",
    "countries.names.IM": "Isle of Man",
    "countries.names.IN": "India",
    "countries.names.IO": "British Indian Ocean Territory",
    "countries.names.IQ": "Iraq",
    "countries.names.IR": "Iran, Islamic Republic of",
    "countries.names.IS": "Iceland",
    "countries.names.IT": "Italy",
    "countries.names.JE": "Jersey",
    "countries.names.JM": "Jamaica",
    "countries.names.JO": "Jordan",
    "countries.names.JP": "Japan",
    "countries.names.KE": "Kenya",
    "countries.names.KG": "Kyrgyzstan",
    "countries.names.KH": "Cambodia",
    "countries.names.KI": "Kiribati",
    "countries.names.KM": "Comoros",
    "countries.names.KN": "Saint Kitts and Nevis",
    "countries.names.KP": "Korea, Democratic People's Republic of",
    "countries.names.KR": "Korea, Republic of",
    "countries.names.KW": "Kuwait",
    "countries.names.KY": "Cayman Islands",
    "countries.names.KZ": "Kazakhstan",
    "countries.names.LA": "Laos (Lao People's Democratic Republic)",
    "countries.names.LB": "Lebanon",
    "countries.names.LC": "Saint Lucia",
    "countries.names.LI": "Liechtenstein",
    "countries.names.LK": "Sri Lanka",
    "countries.names.LR": "Liberia",
    "countries.names.LS": "Lesotho",
    "countries.names.LT": "Lithuania",
    "countries.names.LU": "Luxembourg",
    "countries.names.LV": "Latvia",
    "countries.names.LY": "Libya",
    "countries.names.MA": "Morocco",
    "countries.names.MC": "Monaco",
    "countries.names.MD": "Moldova, Republic of",
    "countries.names.ME": "Montenegro",
    "countries.names.MF": "Saint Martin",
    "countries.names.MG": "Madagascar",
    "countries.names.MH": "Marshall Islands",
    "countries.names.MK": "North Macedonia",
    "countries.names.ML": "Mali",
    "countries.names.MM": "Myanmar",
    "countries.names.MN": "Mongolia",
    "countries.names.MO": "Macao",
    "countries.names.MP": "Northern Mariana Islands",
    "countries.names.MQ": "Martinique",
    "countries.names.MR": "Mauritania",
    "countries.names.MS": "Montserrat",
    "countries.names.MT": "Malta",
    "countries.names.MU": "Mauritius",
    "countries.names.MV": "Maldives",
    "countries.names.MW": "Malawi",
    "countries.names.MX": "Mexico",
    "countries.names.MY": "Malaysia",
    "countries.names.MZ": "Mozambique",
    "countries.names.NA": "Namibia",
    "countries.names.NC": "New Caledonia",
    "countries.names.NE": "Niger",
    "countries.names.NF": "Norfolk Island",
    "countries.names.NG": "Nigeria",
    "countries.names.NI": "Nicaragua",
    "countries.names.NL": "Netherlands",
    "countries.names.NO": "Norway",
    "countries.names.NP": "Nepal",
    "countries.names.NR": "Nauru",
    "countries.names.NU": "Niue",
    "countries.names.NZ": "New Zealand",
    "countries.names.OM": "Oman",
    "countries.names.PA": "Panama",
    "countries.names.PE": "Peru",
    "countries.names.PF": "French Polynesia",
    "countries.names.PG": "Papua New Guinea",
    "countries.names.PH": "Philippines",
    "countries.names.PK": "Pakistan",
    "countries.names.PL": "Poland",
    "countries.names.PM": "Saint Pierre and Miquelon",
    "countries.names.PN": "Pitcairn",
    "countries.names.PR": "Puerto Rico",
    "countries.names.PS": "Palestine",
    "countries.names.PT": "Portugal",
    "countries.names.PW": "Palau",
    "countries.names.PY": "Paraguay",
    "countries.names.QA": "Qatar",
    "countries.names.RE": "Réunion",
    "countries.names.RO": "Romania",
    "countries.names.RS": "Serbia",
    "countries.names.RU": "Russian Federation",
    "countries.names.RW": "Rwanda",
    "countries.names.SA": "Saudi Arabia",
    "countries.names.SB": "Solomon Islands",
    "countries.names.SC": "Seychelles",
    "countries.names.SD": "Sudan",
    "countries.names.SE": "Sweden",
    "countries.names.SG": "Singapore",
    "countries.names.SH": "Saint Helena, Ascension and Tristan da Cunha",
    "countries.names.SI": "Slovenia",
    "countries.names.SJ": "Svalbard and Jan Mayen Islands",
    "countries.names.SK": "Slovakia",
    "countries.names.SL": "Sierra Leone",
    "countries.names.SM": "San Marino",
    "countries.names.SN": "Senegal",
    "countries.names.SO": "Somalia",
    "countries.names.SR": "Suriname",
    "countries.names.SS": "South Sudan",
    "countries.names.ST": "Sao Tome and Principe",
    "countries.names.SV": "El Salvador",
    "countries.names.SX": "Sint Maarten (Dutch part)",
    "countries.names.SY": "Syrian Arab Republic",
    "countries.names.SZ": "Swaziland",
    "countries.names.TC": "Turks and Caicos Islands",
    "countries.names.TD": "Chad",
    "countries.names.TF": "French Southern Territories",
    "countries.names.TG": "Togo",
    "countries.names.TH": "Thailand",
    "countries.names.TJ": "Tajikistan",
    "countries.names.TK": "Tokelau",
    "countries.names.TL": "Timor-Leste",
    "countries.names.TM": "Turkmenistan",
    "countries.names.TN": "Tunisia",
    "countries.names.TO": "Tonga",
    "countries.names.TR": "Turkey",
    "countries.names.TT": "Trinidad and Tobago",
    "countries.names.TV": "Tuvalu",
    "countries.names.TW": "Taiwan (Republic of China)",
    "countries.names.TZ": "Tanzania, United Republic of",
    "countries.names.UA": "Ukraine",
    "countries.names.UG": "Uganda",
    "countries.names.UM": "US Minor Outlying Islands",
    "countries.names.US": "United States",
    "countries.names.UY": "Uruguay",
    "countries.names.UZ": "Uzbekistan",
    "countries.names.VA": "Holy See (Vatican City State)",
    "countries.names.VC": "Saint Vincent and the Grenadines",
    "countries.names.VE": "Venezuela, Bolivarian Republic of",
    "countries.names.VG": "Virgin Islands, British",
    "countries.names.VI": "Virgin Islands, U.S.",
    "countries.names.VN": "Vietnam",
    "countries.names.VU": "Vanuatu",
    "countries.names.WF": "Wallis and Futuna Islands",
    "countries.names.WS": "Samoa",
    "countries.names.XK": "Kosovo",
    "countries.names.YE": "Yemen",
    "countries.names.YT": "Mayotte",
    "countries.names.ZA": "South Africa",
    "countries.names.ZM": "Zambia",
    "countries.names.ZW": "Zimbabwe"
  }
};