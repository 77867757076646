import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmailGroupForm } from '../../../components/EmailGroupForm';
import { ImportEmailsModal } from '../../../components/ImportEmailsModal';
import { InboxMenu } from '../../../components/InboxMenu';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { ConfigContext } from '../../../context/config';
import { MAIL_GROUP } from '../../../routes/paths';
import { createEmailGroup, createEmailGroupReset, parseContactsFile, parseContactsFileReset } from '../../../store/emailGroups/actions';

export const NewEmailGroup = ({ route, history }) => {

  const importModalRef = useRef();

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const { emailGroups: {
    create,
    parsedData
  } } = useSelector(state => state);

  const [isImportModalOpen, setImportModalOpen] = useState(false);

  useEffect(() => {
    if (create.isCreated) {
      dispatch(createEmailGroupReset());
      history.push(MAIL_GROUP);
    }
  }, [create, history, dispatch]);

  useEffect(() => {
    if (parsedData.isParsed) {
      dispatch(parseContactsFileReset());
      handleCloseModal();
    }
  }, [parsedData, dispatch]);

  const handleOpenImportModal = () => setImportModalOpen(true);

  const handleCloseModal = () => {
    setImportModalOpen(false);
    importModalRef?.current?.resetModal();
  }

  const handleParseFile = (excelFile) => dispatch(parseContactsFile({ excelFile }));

  const handleSubmitEmailGroup = (group) => dispatch(createEmailGroup(group));

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={create.isLoading || parsedData.isLoading}>
        <Row gutter={[30, 20]} justify="center" wrap="false" className='tabs-wrapper'>
          <Col className='inbox-menu-wrapper'>
            <InboxMenu history={history} />
          </Col>
          <Col className='inbox-settings-wrapper'>
            <EmailGroupForm
              title={messageTranslator("config.email.group.create.title", "config")}
              mode="create"
              create={create}
              parsedData={parsedData}
              cancelButtonText={messageTranslator("commons.cancel", "commons")}
              onImportFile={handleOpenImportModal}
              onSubmitGroup={handleSubmitEmailGroup}
            />
          </Col>
        </Row>

        <ImportEmailsModal
          ref={importModalRef}
          modalProps={{
            title: messageTranslator("config.emailGroup.modal.emails.import.button", "config"),
            visible: isImportModalOpen
          }}
          isLoading={!!parsedData?.isLoading}
          onOk={handleParseFile}
          onCancel={handleCloseModal}
        />
      </Spin>
    </PageSkeleton>
  );
};
