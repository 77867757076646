import { Button, Col, Form, Input, Row } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { matchPasswords } from '../../../../../utils/utils';

export const ResetPasswordForm = ({
  isLoading,
  onSubmitPassword
}) => {

  const [formRef] = Form.useForm();

  const { messageTranslator } = useContext(ConfigContext);

  const handleFinish = (values) => onSubmitPassword(values.password);

  return (
    <Form
      form={formRef}
      onFinish={handleFinish}
    >
      <Form.Item
        name="password"
        rules={[
          { required: true, message: messageTranslator("commons.required.message", "commons") },
          { min: 8, message: messageTranslator("auth.reset.form.password.min.length", "auth") }
        ]}
      >
        <Input.Password placeholder={messageTranslator("auth.reset.form.password.new.placeholder", "auth")} />
      </Form.Item>
      <Form.Item
        name="confirm"
        rules={[
          { required: true, message: messageTranslator("commons.required.message", "commons") },
          ({ getFieldValue }) => matchPasswords(getFieldValue, messageTranslator("auth.reset.form.passwordsNotMatch", "auth"))
        ]}
      >
        <Input.Password placeholder={messageTranslator("auth.reset.form.password.confirm.placeholder", "auth")} />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Button type="primary" loading={isLoading} block htmlType="submit">
            {messageTranslator("commons.save", "commons")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
