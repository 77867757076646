import { CloseCircleFilled } from '@ant-design/icons';
import { Carousel, Modal } from 'antd';
import React, { useEffect, useRef } from 'react';
import { CreateReport } from '../steps/CreateReport';
import { ReportSettings } from '../steps/ReportSettings';
import './styles.less';

export const ReportModal = ({
  create: { isLoading, isCreated },
  selectedReport,
  onSubmitReport,
  ...props
}) => {

  const carouselRef = useRef();
  const ReportFormRef = useRef();

  useEffect(() => {
    if (selectedReport) carouselRef?.current?.goTo(1, true);
    else carouselRef?.current?.goTo(0, true);
  }, [selectedReport]);

  const handleNextStep = () => carouselRef?.current?.next();

  const handleClose = () => {
    props.onCancel();
    ReportFormRef?.current?.resetFields();
    carouselRef?.current?.goTo(0, true);
  };

  return (
    <Modal
      className="fullscreen-modal"
      { ...props }
      onCancel={handleClose}
      closeIcon={<CloseCircleFilled />}
      footer={null}
    >
      <Carousel
        ref={carouselRef}
        effect="fade"
        dots={false}
      >
        
        <CreateReport
          onNext={handleNextStep}
        />

        <ReportSettings
          ref={ReportFormRef}
          selectedReport={selectedReport}
          isLoading={isLoading}
          isCreated={isCreated}
          onSubmitReport={onSubmitReport}
          onCancel={handleClose}
        />

      </Carousel>
    </Modal>
  );
};
