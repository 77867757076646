import {
  NOTIFICATIONS_GET,
  NOTIFICATIONS_GET_ERROR,
  NOTIFICATIONS_GET_SUCCESS,
  NOTIFICATIONS_SET_SEEN,
  NOTIFICATIONS_SET_SEEN_ERROR,
  NOTIFICATIONS_SET_SEEN_SUCCESS,
} from "./types";

export const notificationsInitialState = {
  list: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null,
  },
  patch: {
    isLoading: false,
    errors: null,
  },
};

export const notificationsReducer = (
  state = notificationsInitialState,
  action
) => {
  switch (action.type) {
    // * Get notifs
    case NOTIFICATIONS_GET:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null,
        },
      };

    case NOTIFICATIONS_GET_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.list,
          totalItems: action.payload.totalItems,
        },
      };

    case NOTIFICATIONS_GET_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload,
        },
      };

    // * Mark as seen
    case NOTIFICATIONS_SET_SEEN:
      return {
        ...state,
        patch: {
          ...state.patch,
          isLoading: true,
          errors: null,
        },
      };

    case NOTIFICATIONS_SET_SEEN_SUCCESS:
      return {
        ...state,
        patch: {
          ...state.patch,
          isLoading: false,
        },
      };

    case NOTIFICATIONS_SET_SEEN_ERROR:
      return {
        ...state,
        patch: {
          ...state.patch,
          isLoading: false,
          errors: action.payload,
        },
      };

    default:
      return state;
  }
};
