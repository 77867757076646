// * Create report
export const REPORT_CREATE = "REPORT_CREATE";
export const REPORT_CREATE_SUCCESS = "REPORT_CREATE_SUCCESS";
export const REPORT_CREATE_ERROR = "REPORT_CREATE_ERROR";
export const REPORT_CREATE_RESET = "REPORT_CREATE_RESET";

// * Get report
export const REPORT_GET_ONE = "REPORT_GET_ONE";
export const REPORT_GET_ONE_SUCCESS = "REPORT_GET_ONE_SUCCESS";
export const REPORT_GET_ONE_ERROR = "REPORT_GET_ONE_ERROR";

// * Get reports
export const REPORT_GET_LIST = "REPORT_GET_LIST";
export const REPORT_GET_LIST_SUCCESS = "REPORT_GET_LIST_SUCCESS";
export const REPORT_GET_LIST_ERROR = "REPORT_GET_LIST_ERROR";

// * Update report
export const REPORT_UPDATE = "REPORT_UPDATE";
export const REPORT_UPDATE_SUCCESS = "REPORT_UPDATE_SUCCESS";
export const REPORT_UPDATE_ERROR = "REPORT_UPDATE_ERROR";
export const REPORT_UPDATE_RESET = "REPORT_UPDATE_RESET";

// * Delete report
export const REPORT_DELETE = "REPORT_DELETE";
export const REPORT_DELETE_SUCCESS = "REPORT_DELETE_SUCCESS";
export const REPORT_DELETE_ERROR = "REPORT_DELETE_ERROR";
export const REPORT_DELETE_RESET = "REPORT_DELETE_RESET";

// * Export report
export const REPORT_EXPORT = "REPORT_EXPORT";
export const REPORT_EXPORT_SUCCESS = "REPORT_EXPORT_SUCCESS";
export const REPORT_EXPORT_ERROR = "REPORT_EXPORT_ERROR";
export const REPORT_EXPORT_RESET = "REPORT_EXPORT_RESET";

// * Cancel schedule
export const REPORT_CANCEL_SCHEDULE = "REPORT_CANCEL_SCHEDULE";
export const REPORT_CANCEL_SCHEDULE_SUCCESS = "REPORT_CANCEL_SCHEDULE_SUCCESS";
export const REPORT_CANCEL_SCHEDULE_ERROR = "REPORT_CANCEL_SCHEDULE_ERROR";
export const REPORT_CANCEL_SCHEDULE_RESET = "REPORT_CANCEL_SCHEDULE_RESET ";

//Get schedule
export const REPORT_GET_SCHEDULE = "REPORT_GET_SCHEDULE";
export const REPORT_GET_SCHEDULE_SUCCESS = "REPORT_GET_SCHEDULE_SUCCESS";
export const REPORT_GET_SCHEDULE_ERROR = "REPORT_GET_SCHEDULE_ERROR";
