import { alertsTranslations } from "./alerts-translations";
import { authTranslations } from "./auth-translations";
import { categoriesTranslations } from "./categories-translations";
import { commonsTranslations } from "./commons-translations";
import { configTranslations } from "./config-translations";
import { constantsTranslations } from "./constants-translations";
import { countriesTranslations } from "./countries-translations";
import { errorsTranslations } from "./errors-translation";
import { notificationsTranslations } from "./notifications-translations";
import { permissionsTranslations } from "./permissions-translations";
import { reportsTranslations } from "./reports-translations";
import { rolesTranslations } from "./roles-translations";
import { routesTranslations } from "./routes-translations";
import { sourcesTranslations } from "./sources-translations";
import { statsTranslations } from "./stats-translations";
import { usersTranslations } from "./users-translations";

const getTranslationFiles = () => ({
  alerts: alertsTranslations,
  auth: authTranslations,
  categories: categoriesTranslations,
  commons: commonsTranslations,
  config: configTranslations,
  constants: constantsTranslations,
  countries: countriesTranslations,
  errors: errorsTranslations,
  notifications: notificationsTranslations,
  permissions: permissionsTranslations,
  reports: reportsTranslations,
  roles: rolesTranslations,
  routes: routesTranslations,
  sources: sourcesTranslations,
  stats: statsTranslations,
  users: usersTranslations
});

/**
 * 
 * @param {string} key: The key used to define the translation.
 * @param {string} _module: The group of translations in which the key belongs.
 * @param {string} lang: The current language.
 * @returns string
 */
export const translator = (key, _module, lang) => {
  const translationFile = getTranslationFiles()[_module];
  return translationFile[lang][key] || key;
};
