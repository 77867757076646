export const SOURCE_CREATE                        = 'SOURCE_CREATE';
export const SOURCE_CREATE_SUCCESS                = 'SOURCE_CREATE_SUCCESS';
export const SOURCE_CREATE_ERROR                  = 'SOURCE_CREATE_ERROR';
export const SOURCE_RESET_CREATE                  = 'SOURCE_RESET_CREATE';

export const SOURCE_LIST                          = 'SOURCE_LIST';
export const SOURCE_LIST_SUCCESS                  = 'SOURCE_LIST_SUCCESS';
export const SOURCE_LIST_ERROR                    = 'SOURCE_LIST_ERROR';

export const SOURCE_FIND_NAME_DUPLICATION         = 'SOURCE_FIND_NAME_DUPLICATION';
export const SOURCE_FIND_URL_DUPLICATION          = 'SOURCE_FIND_URL_DUPLICATION';
export const SOURCE_FIND_NAME_DUPLICATION_SUCCESS = 'SOURCE_FIND_NAME_DUPLICATION_SUCCESS';
export const SOURCE_FIND_URL_DUPLICATION_SUCCESS  = 'SOURCE_FIND_URL_DUPLICATION_SUCCESS';
export const SOURCE_FIND_NAME_DUPLICATION_ERROR   = 'SOURCE_FIND_NAME_DUPLICATION_ERROR';
export const SOURCE_FIND_URL_DUPLICATION_ERROR    = 'SOURCE_FIND_URL_DUPLICATION_ERROR';
export const SOURCE_RESET_NAME_DUPLICATION        = 'SOURCE_RESET_NAME_DUPLICATION';
export const SOURCE_RESET_URL_DUPLICATION         = 'SOURCE_RESET_URL_DUPLICATION';

export const SOURCE_GET                           = 'SOURCE_GET';
export const SOURCE_GET_SUCCESS                   = 'SOURCE_GET_SUCCESS';
export const SOURCE_GET_ERROR                     = 'SOURCE_GET_ERROR';

export const SOURCE_UPDATE                        = 'SOURCE_UPDATE';
export const SOURCE_UPDATE_SUCCESS                = 'SOURCE_UPDATE_SUCCESS';
export const SOURCE_UPDATE_ERROR                  = 'SOURCE_UPDATE_ERROR';
export const SOURCE_RESET_UPDATE                  = 'SOURCE_RESET_UPDATE';

export const SOURCE_DELETE                        = 'SOURCE_DELETE';
export const SOURCE_DELETE_SUCCESS                = 'SOURCE_DELETE_SUCCESS';
export const SOURCE_DELETE_ERROR                  = 'SOURCE_DELETE_ERROR';
export const SOURCE_RESET_DELETE                  = 'SOURCE_RESET_DELETE';

export const SOURCE_TOGGLE_ENABLED                 = 'SOURCE_TOGGLE_ENABLED';
export const SOURCE_TOGGLE_ENABLED_SUCCESS         = 'SOURCE_TOGGLE_ENABLED_SUCCESS';
export const SOURCE_TOGGLE_ENABLED_ERROR           = 'SOURCE_TOGGLE_ENABLED_ERROR';
