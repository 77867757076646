import { put, takeLatest } from 'redux-saga/effects';
import { getPermissionsApi } from '../../api/permissions';
import { getPermissionsError, getPermissionsSuccess } from './actions';
import { PERMISSIONS_GET } from './types';

function* getPermissionsSaga() {
  try {
    const res = yield getPermissionsApi();
    yield put(getPermissionsSuccess(res.data['hydra:member']));
  } catch (error) {
    yield put(getPermissionsError(error));
  }
};

export function* watchGetPermissions() {
  yield takeLatest(PERMISSIONS_GET, getPermissionsSaga);
};