import { Avatar, Badge, Card, List, Space, Typography, Spin } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../context/config';
import { SCRAP_FALLBACK_IMAGE } from '../../../../utils/constants';

export const ImportantNews = ({ globalConfig, news, isLoading }) => {
  const [heightCard, setHeightCard] = useState(285);

  useEffect(() => {
    function handleResizeHeightCard() {
      setHeightCard(document.querySelector('.monitoring-stats').offsetHeight);
    }

    window.addEventListener('resize', handleResizeHeightCard);
    handleResizeHeightCard();
    return () => window.removeEventListener('resize', handleResizeHeightCard);
  });
  
  const { messageTranslator } = useContext(ConfigContext);

  const [sourceIcon, setSourceIcon] = useState();

  useEffect(() => {
    if (globalConfig) setSourceIcon(() => {
      if (globalConfig?.values.default_source_image?.contentUrl) return `${process.env.REACT_APP_BASE_URL}${globalConfig.values.default_source_image.contentUrl}`;
      return SCRAP_FALLBACK_IMAGE
    })
  }, [globalConfig]);

  return (
    <Spin spinning={isLoading}>
    <Card 
      title={messageTranslator("stats.important.news.card.title", "stats")}
      className="static-card1"
      style={{height: heightCard+"px"}}
    >
      <List
        itemLayout="horizontal"
        dataSource={news}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={(
                <Badge offset={[-5, 38]} count={
                  item.sourceType !== 'website' ? (
                    <Avatar
                      size={18}
                      src={`/images/social-media/${item.sourceType}.png`}
                      className="avatar-source"
                    />
                  ) : null
                }>
                  <Avatar
                    size={40}
                    src={item.sourceImage || sourceIcon}
                  />
                </Badge>
              )}
              title={(
                <Space direction="horizontal">
                  <Typography.Text type="secondary" className='user'>@{item.sourceName}</Typography.Text>
                  <Typography.Text className='date'>{moment(item.createdAt).format('DD MMM')}</Typography.Text>
                </Space>
              )}
              description={(
                <a href={item.url} target="_blank" rel="noreferrer" className='desc'>
                  {item.title.substring(0, 90)}...
                </a>
              )}
            />
          </List.Item>
        )}
      />
    </Card>
    </Spin>
  )
}
