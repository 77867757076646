export const statsTranslations = {
  ar: {
    "stats.months.january": "يناير",
    "stats.months.february": "فبراير",
    "stats.months.march": "مارس",
    "stats.months.april": "أبريل",
    "stats.months.may": "مايو",
    "stats.months.june": "يونيو",
    "stats.months.july": "يوليو",
    "stats.months.august": "أغسطس",
    "stats.months.september": "سبتمبر",
    "stats.months.october": "أكتوبر",
    "stats.months.november": "نوفمبر",
    "stats.months.december": "ديسمبر",

    "stats.header.title": "لوحة الإحصائيات",
    "stats.header.segmented.month": "شهري",
    "stats.header.segmented.week": "أسبوعي",
    "stats.header.segmented.day": "يومي",
    "stats.header.select.all": "جميع التنبيهات",

    "stats.sentiment.negative": "الأخبار السلبية",
    "stats.sentiment.neutral": "الأخبار المحايدة",
    "stats.sentiment.positive": "الأخبار الإيجابية",
    "stats.sentiment.card.compare.text": "مقارنة بآخر",
    "stats.sentiment.card.compare.month": "شهر",
    "stats.sentiment.card.compare.week": "أسبوع",
    "stats.sentiment.card.compare.day": "يوم",

    "stats.countries.card.title": "أكثر الدول تفاعلاً",
    "stats.countries.card.countries": "جميع الدول",
    "stats.countries.card.interaction": "تفاعل",

    "stats.reactions.card.title": "إحصائية ردود الفعل",
    "stats.reactions.card.positive.text": "الإيجابية",
    "stats.reactions.card.negative.text": "السلبية",

    "stats.important.news.card.title": "أهم الأخبار و المواضيع",
    "stats.monitoring.card.title": "إحصائية الرصد الإخباري",
    "stats.monitoring.card.socialMedia.text": "التواصل الإجتماعي",
    "stats.monitoring.card.newsPaper.text": "الصحف الإخبارية",
    "stats.hastags.card.title": "الهاشتاقات الأكثر تداولاً",

    "stats.tags.card.title": "الكلمات المفتاحية الأكثر تداولاً",

    "stats.influencers.card.title": "المؤثرين"
  },
  en: {
    "stats.months.january": "January",
    "stats.months.february": "February",
    "stats.months.march": "March",
    "stats.months.april": "April",
    "stats.months.may": "May",
    "stats.months.june": "June",
    "stats.months.july": "July",
    "stats.months.august": "August",
    "stats.months.september": "September",
    "stats.months.october": "October",
    "stats.months.november": "November",
    "stats.months.december": "December",

    "stats.header.title": "Stats dashboard",
    "stats.header.segmented.month": "Month",
    "stats.header.segmented.week": "Week",
    "stats.header.segmented.day": "Day",
    "stats.header.select.all": "All alerts",

    "stats.sentiment.negative": "Negative news",
    "stats.sentiment.neutral": "Neutral news",
    "stats.sentiment.positive": "Positive news",
    "stats.sentiment.card.compare.text": "Compared to last",
    "stats.sentiment.card.compare.month": "month",
    "stats.sentiment.card.compare.week": "week",
    "stats.sentiment.card.compare.day": "day",

    "stats.countries.card.title": "Most interactive countries",
    "stats.countries.card.countries": "All countries",
    "stats.countries.card.interaction": "Interactions",

    "stats.reactions.card.title": "Feedback stats",
    "stats.reactions.card.positive.text": "Positive",
    "stats.reactions.card.negative.text": "Negative",

    "stats.important.news.card.title": "Most important news and topics",
    "stats.monitoring.card.title": "News monitoring stats",
    "stats.monitoring.card.socialMedia.text": "Social Media",
    "stats.monitoring.card.newsPaper.text": "Newspapers",
    "stats.hastags.card.title": "Most popular hashtags",

    "stats.tags.card.title": "Most popular keywords",

    "stats.influencers.card.title": "Influencers"
  }
};