export const constantsTranslations = {
  ar: {
    "constants.lang.ar": "العربية",
    "constants.lang.en": "الإنجليزية",

    "constants.sentiments.label": "الانطباع",
    "constants.sentiments.positive": "إيجابي",
    "constants.sentiments.negative": "سلبي",
    "constants.sentiments.neutral": "محايد",

    "constants.days.monday": "الإثنين",
    "constants.days.tuesday": "الثلاثاء",
    "constants.days.wednesday": "الأربعاء",
    "constants.days.thursday": "الخميس",
    "constants.days.friday": "الجمعة",
    "constants.days.saturday": "السبت",
    "constants.days.sunday": "الأحد",

    "constants.week.title": "الأسبوع"
  },
  en: {
    "constants.lang.ar": "Arabic",
    "constants.lang.en": "English",

    "constants.sentiments.label": "Sentiment",
    "constants.sentiments.positive": "Positive",
    "constants.sentiments.negative": "Negative",
    "constants.sentiments.neutral": "Neutral",

    "constants.days.monday": "Monday",
    "constants.days.tuesday": "Tuesday",
    "constants.days.wednesday": "Wednesday",
    "constants.days.thursday": "Thursday",
    "constants.days.friday": "Friday",
    "constants.days.saturday": "Saturday",
    "constants.days.sunday": "Sunday",

    "constants.week.title": "Week"
  }
};