import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigContext } from '../../../../../../context/config';
import { getAlertList } from '../../../../../../store/alerts/actions';
import { ISO_DATE, renderRanges } from '../../../../../../utils/moment-utils';
import "./report.less";

export const ReportSettings = forwardRef(({
  selectedReport,
  isLoading,
  isCreated,
  onSubmitReport,
  onCancel
}, ref) => {

  const [formRef] = Form.useForm();
  const { resetFields, setFieldsValue } = formRef;

  const { messageTranslator } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const { alerts: {
    list: { data: listALerts }
  } } = useSelector(state => state);

  useEffect(() => {
    if (selectedReport) setFieldsValue({
      ...selectedReport,
      date: [moment(selectedReport.startDate), moment(selectedReport.endDate)],
      alert: selectedReport.alert['@id']
    });
  }, [selectedReport, setFieldsValue]);

  useEffect(() => {
    dispatch(getAlertList({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (isCreated) resetFields();
  }, [isCreated, resetFields]);

  const handleFormSubmit = ({ date, ...values }) => onSubmitReport({
    ...values,
    startDate: moment(date[0]).format(ISO_DATE),
    endDate: `${moment(date[1]).format(ISO_DATE)} 23:59:59`
  });

  useImperativeHandle(ref, () => ({
    resetFields
  }),
    [resetFields])

  return (
    <Row align='middle'>
      <Col xs={{ span: 24 }} md={{ span: 9 }} className="img-report-wrapper">
        <div className="img-step-wrapper">
          <img src="/images/report.png" alt="Step Four" />
        </div>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 15 }} className="report-wrapper">
        <div className="content-wrapper">
          <div className="header-report">
            <Typography.Title level={2}>
              {messageTranslator("reports.modal.stepTwo.title", "reports")}
            </Typography.Title>
            <Typography.Text type="secondary">
              {messageTranslator("reports.modal.stepTwo.subTitle", "reports")}
            </Typography.Text>
          </div>
          <Form
            form={formRef}
            layout="vertical"
            onFinish={handleFormSubmit}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={messageTranslator("reports.modal.stepTwo.form.name.label", "reports")}
                  rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, md: 16, lg: 32, xl: 82 }}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="date"
                  label={messageTranslator("reports.modal.stepTwo.form.date.label", "reports")}
                  rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
                >
                  <DatePicker.RangePicker
                    ranges={renderRanges(messageTranslator)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="alert"
                  label={messageTranslator("reports.modal.stepTwo.form.alert.label", "reports")}
                  rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
                >
                  <Select suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />} >
                    {
                      listALerts.map(alert => (
                        <Select.Option key={alert['@id']} value={alert['@id']}>
                          {alert.name}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label={messageTranslator("reports.modal.stepTwo.form.about.label", "reports")}
                  rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
                >
                  <Input.TextArea placeholder={messageTranslator("reports.modal.stepTwo.form.about.placeholder", "reports")} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} align="left">
                <Button onClick={onCancel}>
                  {messageTranslator("commons.cancel", "commons")}
                </Button>
                <span className='btn-wrapper blue'>
                  <Button
                    type="primary"
                    htmlType="submit"
                    name="description"
                  >
                    {messageTranslator("reports.modal.stepTwo.form.create", "reports")}
                  </Button>
                </span>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
});
