import { LANG } from "../../utils/constants";
import Cookies from "universal-cookie";

export const CONFIG_SWITCH_LANGUAGE = "CONFIG_SWITCH_LANGUAGE";

export const CONFIG_SET_ALERT_ID = "CONFIG_SET_ALERT_ID";
export const CONFIG_SET_ADVANCED_SEARCH_TERMS = "CONFIG_ADVANCED_SEARCH_TERMS";
export const CONFIG_RESET_ADVANCED_SEARCH = "CONFIG_RESET_ADVANCED_SEARCH";
export const CONFIG_SET_ALERT_RESULT_PAGE = "CONFIG_SET_ALERT_RESULT_PAGE";
export const CONFIG_SET_SEARCH_ATTEMPS = "CONFIG_SET_SEARCH_ATTEMPS";

export const CONFIG_SET_USERS_PAGE = "CONFIG_SET_USERS_PAGE";

export const CONFIG_ADD_TO_FOLDER = "CONFIG_ADD_TO_FOLDER";

export const CINFIG_SET_ALERT_FRAGMENT = "CINFIG_SET_ALERT_FRAGMENT";

const cookies = new Cookies();

export const dispatchConfigState = (state, actionType, payload = null) => {
  switch (actionType) {
    case CONFIG_SWITCH_LANGUAGE:
      localStorage.setItem(LANG, payload);
      cookies.set(LANG, payload, { path: "/" });
      return {
        ...state,
        language: payload,
      };

    case CONFIG_SET_ALERT_ID:
      return {
        ...state,
        alertId: payload,
      };

    case CONFIG_SET_ADVANCED_SEARCH_TERMS:
      return {
        ...state,
        advancedSearchTerms: payload,
      };

    case CONFIG_RESET_ADVANCED_SEARCH:
      return {
        ...state,
        advancedSearchTerms: payload,
      };

    case CONFIG_SET_ALERT_RESULT_PAGE:
      return {
        ...state,
        alertResultPage: payload,
      };

    case CONFIG_SET_SEARCH_ATTEMPS:
      return {
        ...state,
        searchAttemps: payload,
      };

    case CINFIG_SET_ALERT_FRAGMENT:
      return {
        ...state,
        alertSubPage: payload,
      };

    default:
      return state;
  }
};
