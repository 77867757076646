import { removeItemFromArray, replaceItemInArray } from "../../utils/utils";
import { SOURCE_CREATE, SOURCE_CREATE_ERROR, SOURCE_CREATE_SUCCESS, SOURCE_DELETE, SOURCE_DELETE_ERROR, SOURCE_DELETE_SUCCESS, SOURCE_FIND_NAME_DUPLICATION, SOURCE_FIND_NAME_DUPLICATION_ERROR, SOURCE_FIND_NAME_DUPLICATION_SUCCESS, SOURCE_FIND_URL_DUPLICATION, SOURCE_FIND_URL_DUPLICATION_ERROR, SOURCE_FIND_URL_DUPLICATION_SUCCESS, SOURCE_GET, SOURCE_GET_ERROR, SOURCE_GET_SUCCESS, SOURCE_LIST, SOURCE_LIST_ERROR, SOURCE_LIST_SUCCESS, SOURCE_RESET_CREATE, SOURCE_RESET_DELETE, SOURCE_RESET_NAME_DUPLICATION, SOURCE_RESET_UPDATE, SOURCE_RESET_URL_DUPLICATION, SOURCE_TOGGLE_ENABLED, SOURCE_TOGGLE_ENABLED_ERROR, SOURCE_TOGGLE_ENABLED_SUCCESS, SOURCE_UPDATE, SOURCE_UPDATE_ERROR, SOURCE_UPDATE_SUCCESS } from "./types";

export const sourceInitialState = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null
  },
  source: {
    data: null,
    isLoading: false,
    errors: null
  },
  list: {
    data: [],
    totalItems: 0,
    isLoading: false,
    errors: null
  },
  duplications: {
    name: {
      length: null,
      identifiers: [],
      isLoading: false,
      errors: null
    },
    url: {
      length: null,
      identifiers: [],
      isLoading: false,
      errors: null
    },
  },
  update: {
    isLoading: false,
    isUpdated: false,
    errors: null
  },
  delete: {
    isLoading: false,
    isDeleted: false,
    errors: null
  },
  toggleEnabled: {
    isLoading: false,
    errors: null
  }
};

export const sourceReducer = (state = sourceInitialState, action) => {

  switch (action.type) {
    case SOURCE_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          errors: null
        }
      };

    case SOURCE_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: action.payload.id
        },
        list: {
          ...state.list,
          data: [action.payload, ...state.list.data],
          totalItems: state.list.totalItems + 1
        }
      };

    case SOURCE_CREATE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload
        }
      };

    case SOURCE_RESET_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          isCreated: false,
          errors: null
        }
      };

    case SOURCE_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null
        }
      };

    case SOURCE_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.sources,
          totalItems: action.payload.totalItems
        }
      };

    case SOURCE_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload
        }
      };

    // Find duplication
    case SOURCE_FIND_NAME_DUPLICATION:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          name: {
            ...state.duplications.name,
            isLoading: true,
            errors: null
          }
        }
      };

    case SOURCE_FIND_URL_DUPLICATION:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          url: {
            ...state.duplications.url,
            isLoading: true,
            errors: null
          }
        }
      };

    case SOURCE_FIND_NAME_DUPLICATION_SUCCESS:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          name: {
            ...state.duplications.name,
            length: !!action.payload.data.length,
            identifiers: action.payload.data,
            isLoading: false,
          }
        }
      };

    case SOURCE_FIND_URL_DUPLICATION_SUCCESS:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          url: {
            ...state.duplications.url,
            length: !!action.payload.data.length,
            identifiers: action.payload.data,
            isLoading: false,
          }
        }
      };

    case SOURCE_FIND_NAME_DUPLICATION_ERROR:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          name: {
            ...state.duplications.name,
            isLoading: false,
            errors: action.payload
          }
        }
      };

    case SOURCE_FIND_URL_DUPLICATION_ERROR:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          url: {
            ...state.duplications.url,
            isLoading: false,
            errors: action.payload
          }
        }
      };

    case SOURCE_RESET_NAME_DUPLICATION:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          name: {
            length: null,
            identifiers: [],
            isLoading: false,
            errors: null
          }
        }
      };

    case SOURCE_RESET_URL_DUPLICATION:
      return {
        ...state,
        duplications: {
          ...state.duplications,
          url: {
            length: null,
            identifiers: [],
            isLoading: false,
            errors: null
          }
        }
      };

    case SOURCE_GET:
      return {
        ...state,
        source: {
          ...state.source,
          isLoading: true,
          errors: null
        }
      };

    case SOURCE_GET_SUCCESS:
      return {
        ...state,
        source: {
          ...state.source,
          isLoading: false,
          data: action.payload
        }
      };

    case SOURCE_GET_ERROR:
      return {
        ...state,
        source: {
          ...state.source,
          isLoading: false,
          errors: action.payload
        }
      };

    case SOURCE_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: true,
          errors: null
        }
      };

    case SOURCE_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          isUpdated: true
        },
        list: {
          ...state.list,
          data: replaceItemInArray(action.payload, state.list.data, 'id')
        }
      };

    case SOURCE_UPDATE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          errors: action.payload
        }
      };

    case SOURCE_RESET_UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          isUpdated: false,
          errors: null
        }
      };

    // Delete
    case SOURCE_DELETE:
      return {
        ...state,
        dalete: {
          ...state.delete,
          isLoading: true
        }
      };

    case SOURCE_DELETE_SUCCESS:
      return {
        ...state,
        dalete: {
          ...state.delete,
          isLoading: false,
          isDeleted: true
        },
        list: {
          ...state.list,
          data: removeItemFromArray(action.payload, state.list.data),
          totalItems: state.list.totalItems - 1
        }
      };

    case SOURCE_DELETE_ERROR:
      return {
        ...state,
        dalete: {
          ...state.delete,
          isLoading: false,
          errors: action.payload
        }
      };

    case SOURCE_RESET_DELETE:
      return {
        ...state,
        dalete: {
          ...state.delete,
          isDeleted: false,
          errors: null
        }
      };

    // * Activate update
    case SOURCE_TOGGLE_ENABLED:
      return {
        ...state,
        toggleEnabled: {
          ...state.toggleEnabled,
          isLoading: true,
          errors: null
        }
      };

    case SOURCE_TOGGLE_ENABLED_SUCCESS:
      return {
        ...state,
        toggleEnabled: {
          ...state.toggleEnabled,
          isLoading: false
        },
        list: {
          ...state.list,
          data: replaceItemInArray(action.payload, state.list.data, 'id')
        }
      };

    case SOURCE_TOGGLE_ENABLED_ERROR:
      return {
        ...state,
        toggleEnabled: {
          ...state.toggleEnabled,
          isLoading: false,
          errors: action.payload
        }
      };

    default:
      return state;
  }
};