import { SOURCE_CREATE, SOURCE_CREATE_ERROR, SOURCE_CREATE_SUCCESS, SOURCE_DELETE, SOURCE_DELETE_ERROR, SOURCE_DELETE_SUCCESS, SOURCE_FIND_NAME_DUPLICATION, SOURCE_FIND_NAME_DUPLICATION_ERROR, SOURCE_FIND_NAME_DUPLICATION_SUCCESS, SOURCE_FIND_URL_DUPLICATION, SOURCE_FIND_URL_DUPLICATION_ERROR, SOURCE_FIND_URL_DUPLICATION_SUCCESS, SOURCE_GET, SOURCE_GET_ERROR, SOURCE_GET_SUCCESS, SOURCE_LIST, SOURCE_LIST_ERROR, SOURCE_LIST_SUCCESS, SOURCE_RESET_CREATE, SOURCE_RESET_DELETE, SOURCE_RESET_NAME_DUPLICATION, SOURCE_RESET_UPDATE, SOURCE_RESET_URL_DUPLICATION, SOURCE_TOGGLE_ENABLED, SOURCE_TOGGLE_ENABLED_ERROR, SOURCE_TOGGLE_ENABLED_SUCCESS, SOURCE_UPDATE, SOURCE_UPDATE_ERROR, SOURCE_UPDATE_SUCCESS } from "./types";

// * Create source
export const createSource = (source) => ({
  type: SOURCE_CREATE,
  payload: source
});

export const createSourceSuccess = (source) => ({
  type: SOURCE_CREATE_SUCCESS,
  payload: source
});

export const createSourceError = (error) => ({
  type: SOURCE_CREATE_ERROR,
  payload: error
});

export const resetCreateSource = () => ({
  type: SOURCE_RESET_CREATE
});

// * Get list
export const listSources = (params) => ({
  type: SOURCE_LIST,
  payload: params
});

export const listSourcesSuccess = (sources) => ({
  type: SOURCE_LIST_SUCCESS,
  payload: sources
});

export const listSourcesError = (errors) => ({
  type: SOURCE_LIST_ERROR,
  payload: errors
});

// * Find duplication
export const findDuplicatedSourceName = (params) => ({
  type: SOURCE_FIND_NAME_DUPLICATION,
  payload: params
});

export const findDuplicatedSourceUrl = (params) => ({
  type: SOURCE_FIND_URL_DUPLICATION,
  payload: params
});

export const findDuplicatedSourceUrlSuccess = (data) => ({
  type: SOURCE_FIND_URL_DUPLICATION_SUCCESS,
  payload: data
});

export const findDuplicatedSourceNameSuccess = (data) => ({
  type: SOURCE_FIND_NAME_DUPLICATION_SUCCESS,
  payload: data
});

export const findDuplicatedSourceNameError = (error) => ({
  type: SOURCE_FIND_NAME_DUPLICATION_ERROR,
  payload: error
});

export const findDuplicatedSourceUrlError = (error) => ({
  type: SOURCE_FIND_URL_DUPLICATION_ERROR,
  payload: error
});

export const resetDuplicatedSourceName = () => ({
  type: SOURCE_RESET_NAME_DUPLICATION
});

export const resetDuplicatedSourceUrl = () => ({
  type: SOURCE_RESET_URL_DUPLICATION
});

// * Get source
export const getSource = (id) => ({
  type: SOURCE_GET,
  payload: id
});

export const getSourceSuccess = (source) => ({
  type: SOURCE_GET_SUCCESS,
  payload: source
});

export const getSourceError = (error) => ({
  type: SOURCE_GET_ERROR,
  payload: error
});

// * Update source
export const updateSource = (source) => ({
  type: SOURCE_UPDATE,
  payload: source
});

export const updateSourceSuccess = (source) => ({
  type: SOURCE_UPDATE_SUCCESS,
  payload: source
});

export const updateSourceError = (error) => ({
  type: SOURCE_UPDATE_ERROR,
  payload: error,
});

export const updateSourceReset = () => ({
  type: SOURCE_RESET_UPDATE
});

// * Delete source
export const deleteSource = (id) => ({
  type: SOURCE_DELETE,
  payload: id
});

export const deleteSourceSuccess = (id) => ({
  type: SOURCE_DELETE_SUCCESS,
  payload: id
});

export const deleteSourceError = (error) => ({
  type: SOURCE_DELETE_ERROR,
  payload: error,
});

export const deleteSourceReset = () => ({
  type: SOURCE_RESET_DELETE
});

// * Activate update
export const activateUpdateSource = (params) => ({
  type: SOURCE_TOGGLE_ENABLED,
  payload: params
});

export const activateUpdateSourceSuccess = (newSource) => ({
  type: SOURCE_TOGGLE_ENABLED_SUCCESS,
  payload: newSource
});

export const activateUpdateSourceError = (error) => ({
  type: SOURCE_TOGGLE_ENABLED_ERROR,
  payload: error,
});
