import { api } from "..";

const URL = "/api/configs";

export const createConfigApi = (config) => api.post(URL, config);

export const getConfigApi = (name) => api.get(`${URL}/${name}`);

export const updateConfigApi = ({ name, ...config }) =>
  api.put(`${URL}/${name}`, config);

export const sendTestEMailApi = (email) => api.post("/api/test-mails", email);
