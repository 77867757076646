export const configTranslations = {
  ar: {
    "config.menu.item.label": "إعدادات البريد الإلكتروني",
    "config.menu.mailGroup.label": "المجموعات البريدية",
    "config.menu.globalConfig.label": "الإعدادات العامة",
    "config.menu.scrapingConfig.label": "إعدادات رصد الأخبار",
    "config.menu.twitterApi.label": "إعدادات API تويتر",
    "config.menu.twitterCriteria.label": "إعدادات معايير البحث في تويتر",

    "config.settings.form.sender.label": "بريد المُرسِل",
    "config.settings.form.username.label": "إسم المستخدم",
    "config.settings.form.password.label": "كلمة العبور",

    "config.settings.form.success.message": "تم إنشاء الإعدادات بنجاح",
    "config.settings.form.update.success.message":
      "تم تعديل الإعدادات الخاصة بك بنجاح",
    "config.settings.form.error.message": "لا يمكنك إنشاء إعدادات فارغة",

    "config.email.group.create.title": "انشاء مجموعة بريدية",
    "config.email.group.update.title": "تعديل مجموعة بريدية",

    "config.emailGroup.title": "انشاء مجموعة",
    "config.emailGroup.modal.title": "انشاء مجموعة بريدية",
    "config.emailGroup.modal.name.label": "اسم المجموعة البريدية",
    "config.emailGroup.modal.emails.label": "إضافة عضو للقائمة",
    "config.emailGroup.modal.empty.text": "لا يوجد أعضاء مضافين للمجموعة",
    "config.emailGroup.modal.email.duplicated": "البريد الإلكتروني مكرر !",
    "config.emailGroup.modal.email.invalid": "البريد الإلكتروني غير صالح !",
    "config.emailGroup.modal.email.update.note":
      "إضغط <<Enter>> لتسجيل التعديل",
    "config.emailGroup.modal.email.required.min":
      "قم بإضافة عضو على الأقل للمجموعة البريدية !",
    "config.emailGroup.create.emails.import.button": "استيراد مجموعة أعضاء",

    "config.emailGroup.modal.emails.import.button": "استيراد",
    "config.emailGroup.modal.emails.import.label": "المرفقات",
    "config.emailGroup.modal.emails.import.choose.file": "اختيار ملف",

    "config.emailGroup.delete.confirm.text":
      "هل أنت متأكد أنك تريد حذف المجموعة البريدية من قاعدة البيانات ؟",
    "config.emailGroup.reset.confirm.text":
      "هل أنت متأكد أنك تريد إعادة ضبط الخانات ؟",

    "config.emailGroup.list.workersNumber": "موظف",

    "config.email.test.success": "تم إرسال البريد الإلكتروني التجريبي بنجاح",

    "config.global.logo": "شعار الموقع",
    "config.global.entityLogo": "شعار الجهة",
    "config.global.category.image.default": "الصورة الافتراضية للتصنيفات",
    "config.global.source.image.default": "الصورة الافتراضية للمصادر",
    "config.global.websiteName": "إسم الموقع",
    "config.global.incoming.url": "ربط الوارد",
    "config.global.twitter.url": "رابط حساب الموقع على تويتر",
    "config.global.facebook.url": "رابط حساب الموقع على فيسبوك",
    "config.global.youtube.url": "رابط قناة الموقع على يوتيوب",
    "config.global.instagram.url": "رابط حساب الموقع على إنستجرام",
    "config.global.linkedin.url": "رابط حساب الموقع على لينكد إن",

    "config.scrap.inMinutes": "بالدقائق",
    "config.scrap.inHours": "بالساعات",
    "config.scrap.inDays": "بالأيام",
    "config.scrap.twitter.frequency": "تردد رصد أخبار تويتر",
    "config.scrap.website.frequency": "تردد رصد أخبار الموقع",
    "config.scrap.tweets.keep": "حافظ على التغريدات لـ",
    "config.scrap.website.keep": "الاحتفاظ بأخبار الموقع لـ",

    "config.twitter.apiKeys.label": "مفاتيح ال API",
    "config.twitter.apiSecret.label": "الرمز السري لل API",
    "config.twitter.accessToken.label": "رمز الدخول",
    "config.twitter.accessTokenSecret.label": "الرمز السري لرمز الدخول",

    "config.twitterCriteria.minimumRetweets.label":
      "الحد الأدنى لإعادة التغريد للتغريدة الواحدة",
    "config.twitterCriteria.minimumLikes.label":
      "الحد الأدنى لعدد الاعجابات للتغربدة الواحدة",
    "config.twitterCriteria.minFollowersCount.label":
      "الحد الأدنى لعدد المتابعين في الملف الشخصي",
    "config.twitterCriteria.minStatusCount.label":
      "الحد الأدنى لحالات الملف الشخصي",
    "config.twitterCriteria.accountCreationBefore.label": "تم إنشاء الحساب قبل",
  },
  en: {
    "config.menu.item.label": "Email settings",
    "config.menu.mailGroup.label": "Email groups",
    "config.menu.globalConfig.label": "Global configurations",
    "config.menu.scrapingConfig.label": "Scraping configurations",
    "config.menu.twitterApi.label": "Twitter API configurations",
    "config.menu.twitterCriteria.label":
      "Twitter search criteria configurations",

    "config.settings.form.sender.label": "Sender email",
    "config.settings.form.username.label": "Username",
    "config.settings.form.password.label": "Password",

    "config.settings.form.success.message":
      "Your configs has been successfully created",
    "config.settings.form.update.success.message":
      "Your configs has been successfully updated",
    "config.settings.form.error.message":
      "You can't create empty configurations",

    "config.email.group.create.title": "Create email group",
    "config.email.group.update.title": "Edit email group",

    "config.emailGroup.title": "Create group",
    "config.emailGroup.modal.title": "Create email group",
    "config.emailGroup.modal.name.label": "Email group name",
    "config.emailGroup.modal.emails.label": "Add member to the list",
    "config.emailGroup.modal.empty.text": "No added memebers to the list",
    "config.emailGroup.modal.email.duplicated": "Duplicated email !",
    "config.emailGroup.modal.email.invalid": "The Email is not valid !",
    "config.emailGroup.modal.email.update.note":
      "Press <<Enter>> to save changes",
    "config.emailGroup.modal.email.required.min":
      "Add at least one member to the list !",
    "config.emailGroup.create.emails.import.button": "Import email group",

    "config.emailGroup.modal.emails.import.button": "Import",
    "config.emailGroup.modal.emails.import.label": "Attachments",
    "config.emailGroup.modal.emails.import.choose.file": "Select a file",

    "config.emailGroup.delete.confirm.text":
      "Are you sure you want to delete the mail group from the database ?",
    "config.emailGroup.reset.confirm.text":
      "Are you sure you want to reset the fields ?",

    "config.emailGroup.list.workersNumber": "employees",

    "config.email.test.success": "Test email has been successfully sent",

    "config.global.logo": "Website logo",
    "config.global.entityLogo": "Entity logo",
    "config.global.category.image.default": "Categories default image",
    "config.global.source.image.default": "Sources default image",
    "config.global.websiteName": "Website name",
    "config.global.incoming.url": "Incoming link",
    "config.global.twitter.url": "Twitter account URL",
    "config.global.facebook.url": "Facebook account URL",
    "config.global.youtube.url": "Youtube channel URL",
    "config.global.instagram.url": "Instagram account URL",
    "config.global.linkedin.url": "Linkedin account URL",

    "config.scrap.inMinutes": "in minutes",
    "config.scrap.inHours": "in hours",
    "config.scrap.inDays": "in days",
    "config.scrap.twitter.frequency": "Twtter scrap frequency",
    "config.scrap.website.frequency": "Website scrap frequency",
    "config.scrap.tweets.keep": "Keep tweets for",
    "config.scrap.website.keep": "Keep website news for",

    "config.twitterCriteria.minimumRetweets.label": "Tweet minimum retweets",
    "config.twitterCriteria.minimumLikes.label": "Tweet minimum likes",
    "config.twitterCriteria.minFollowersCount.label":
      "Profile minimum followers count",
    "config.twitterCriteria.minStatusCount.label":
      "Profile minimum statuses count",
    "config.twitterCriteria.accountCreationBefore.label":
      "Account created before",
  },
};
