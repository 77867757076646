export const rolesTranslations = {
  ar: {
    "roles.list.new.text": "إضافة ملف صلاحيات جديد",
    "roles.list.empty.text": "لا يوجد صلاحيات مضافة حالياً",
    "roles.list.label.color": "اللون",
    "roles.list.label.title": "إسم ملف الصلاحيات",
    "roles.list.label.createdAt": "تاريخ الإنشاء",
    "roles.list.tooltip.update": "تعديل ملف الصلاحيات",
    "roles.list.tooltip.delete": "حذف ملف الصلاحيات",
    "roles.list.tooltip.delete.confirm": "تأكيد حذف ملف الصلاحيات",
    "roles.add.message.error": "الرجاء إدخال إسم ملف الصلاحيات !",
    "roles.add.label.title": "إسم ملف الصلاحيات",
    "roles.add.form.title": "إنشاء ملف صلاحيات",
    "roles.update.form.title": "تعديل ملف صلاحيات",
    "roles.update.message.success": "تم تعديل ملف الصلاحيات بنجاح",
    "roles.create.message.success": "تم إضافة ملف الصلاحيات بنجاح",
  },
  en: {
    "roles.list.new.text": "Add a new role",
    "roles.list.empty.text": "There are no roles added at the moment",
    "roles.list.label.color": "Color",
    "roles.list.label.title": "Title",
    "roles.list.label.createdAt": "Created at",
    "roles.list.tooltip.update": "Update role",
    "roles.list.tooltip.delete": "Delete role",
    "roles.add.message.error": "Please enter the role name!",
    "roles.add.label.title": "Role title",
    "roles.add.form.title": "Create role",
    "roles.update.form.title": "Update role",
    "roles.update.message.success": "Role has been successfully updated",
    "roles.create.message.success": "Role has been successfully created",
  },
};
