import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { AuthContext } from '../../context/Auth';
import { ConfigContext } from '../../context/config';
import { FileUploader } from '../FileUploader';

export const ImportEmailsModal = forwardRef(({
  modalProps,
  isLoading,
  onOk,
  onCancel
}, ref) => {

  const [formRef] = Form.useForm();
  const { setFieldsValue, submit, resetFields } = formRef;
  const uploadRef = useRef();

  const { messageTranslator } = useContext(ConfigContext);

  const { state } = useContext(AuthContext);

  const handleSubmitFile = (values) => onOk(values.file);

  useImperativeHandle(ref, () => ({
    resetModal: () => {
      uploadRef?.current?.resetImageUrl();
      resetFields();
    }
  }), [resetFields]);

  return (
    <Modal
      {...modalProps}
      className="import-mails"
      onCancel={onCancel}
      onOk={onOk}
      forceRender
      footer={[
        <span key="cancel" className='btn-wrapper white'>
          <Button
            key="back"
            onClick={onCancel}
          >
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </span>,
        <span key="ok" className='btn-wrapper'>
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            htmlType="submit"
            onClick={submit}
          >
            {messageTranslator("config.emailGroup.modal.emails.import.button", "config")}
          </Button>
        </span>
      ]}
    >
      <Form
        layout="vertical"
        form={formRef}
        onFinish={handleSubmitFile}
      >
        <Form.Item
          name="file"
          hidden
        >
          <Input />
        </Form.Item>
        <Row gutter={25} align="middle">
          <Col flex="auto">
            {/* <Space> */}
              <Form.Item
                name="filename"
                label={messageTranslator("config.emailGroup.modal.emails.import.label", "config")}
              >
                <Input />
              </Form.Item>

              </Col>
          <Col flex="140px">
              <FileUploader
                ref={uploadRef}
                // label={messageTranslator("sources.add.image.title", "sources")}
                token={state.token}
                hideLabel
                setFieldsValue={setFieldsValue}
                fieldName="file"
                accept=".xls,.xlsx,.xml,.csv"
                showFileName="filename"
                file
                customButtonTrigger={(
                  <Button type="primary">
                    {messageTranslator("config.emailGroup.modal.emails.import.choose.file", "config")}
                  </Button>
                )}
              />
            {/* </Space> */}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Text type="secondary">
              .xlsx .csv .xml
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
