import { LockOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../context/config';
import './styles.less';

export const UnauthorizedContent = () => {

  const { messageTranslator } = useContext(ConfigContext);

  return (
    <div className='unauthorized-wrapper'>
      <LockOutlined className="unauthorized" />
      <Typography>{messageTranslator("unauthorizedText", "errors")}</Typography>
    </div>
  );
};
