import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import { AlertContext } from '../../../../../context/Alert';
import { ConfigContext } from '../../../../../context/config';

export const WebsiteStep = ({
  duplicatedWebsite,
  isLoading,
  onNext,
  onCheckWebsiteName,
  onInputChange
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = useForm();

  const { getFieldValue } = form;

  const { addWebsite } = useContext(AlertContext);

  const [isDuplicated, setDuplicated] = useState(false);

  useEffect(() => {
    if (duplicatedWebsite === true) setDuplicated(true);
    else if (duplicatedWebsite === false) {
      addWebsite(getFieldValue('name'));
      onNext();
    }
    else setDuplicated(false);
  }, [duplicatedWebsite, getFieldValue, addWebsite, onNext]);

  const handleNext = (name) => onCheckWebsiteName(name);

  return (
    <Form
      form={form}
      onFinish={({ name }) => handleNext(name)}
    >
      <Row align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <div className="img-step-wrapper">
            <img src="/images/step1.png" alt="Step One" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} className="bg-white">
          <Row className="step-content" align="middle">
            <Col className="full-width">
              <Scrollbar mobileNative={false}>
                <div className="content-wrapper">
                  <Row align='middle'>
                    <Col>
                      <div className="entete">
                        <div className="step-title">
                          {messageTranslator("alerts.add.stepper.stepOne.title", "alerts")}
                        </div>
                        <div className="step-desc">
                          {messageTranslator("alerts.add.stepper.stepOne.label", "alerts")}
                        </div>
                      </div>
                      <Form.Item
                        name="name"
                        rules={[{ required: true, message: messageTranslator("commons.required.message", "commons") }]}
                      >
                        <Input onChange={onInputChange} className="entity-name-input" />
                      </Form.Item>
                      {
                        isDuplicated && (
                          <Alert
                            message={messageTranslator("commons.error", "commons")}
                            description={messageTranslator("alerts.add.duplicated.name", "alerts")}
                            type="error"
                            showIcon
                          />
                        )
                      }
                    </Col>
                  </Row>
                </div>
              </Scrollbar>
            </Col>
          </Row>

          <div className="nav-step-wrapper">
            <Row justify="space-between" className="full-width">
              <Col>
                <Button
                  type="primary"
                  disabled
                >
                  <ArrowLeftOutlined className='transformMiroir' /> {messageTranslator("commons.pagination.prev", "commons")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  loading={isLoading}
                  htmlType='submit'
                >
                  {messageTranslator("commons.pagination.next", "commons")} <ArrowRightOutlined className='transformMiroir' />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
