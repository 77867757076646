import { message, Spin, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertsFilter } from '../../../components/AlertsFilter';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { hasPermission } from '../../../components/PermissionHandler/route-permission-handler';
import { UnauthorizedContent } from '../../../components/UnauthorizedContent';
import { ConfigContext } from '../../../context/config';
import { CINFIG_SET_ALERT_FRAGMENT, CONFIG_SET_ALERT_ID } from '../../../context/config/dispatch-config-state';
import { useQuery } from '../../../hooks/query-params';
import { INBOX } from '../../../routes/paths';
import { addFolder, alertResultsAdvancedSearch, auditorChangeFolderState, auditorChangeFolderStateReset, combineDuplicatedNews, combineDuplicatedNewsReset, filterBySourceType, getFolderDaily, patchAlertResultActions, patchFolder, patchResultCategory, patchResultCategoryReset, publishFolder, publishFolderReset, removeItemFromFolder, searchSourceNames, setFolderToReview, setFolderToReviewReset, getAlertResults, getNewAlertResults, getNewAlertResultsReset } from '../../../store/alert-results/actions';
import { getAlert } from '../../../store/alerts/actions';
import { listCategories } from '../../../store/categories/actions';
import { getUsers } from '../../../store/users/actions';
import { FOLDER_STATES, ROLES } from '../../../utils/constants';
import { ALL, DAILY, FOLDERS_ARCHIVE } from '../../../utils/data/category';
import { ISO_DATE, ISO_DATE_TZ } from '../../../utils/moment-utils';
import { addParam, alertAdvancedSearchHandler, extractPageCategory, getAlertAdvancedSearchQueries, pageChangeHandler, removeParam, replaceItemInArray } from '../../../utils/utils';
import { AuditorFolderBanner } from './components/AuditorFolderBanner';
import { ChangeFolderStateModal } from './components/ChangeFolderStateModal';
import { CreateFolderBanner } from './components/CreateFolderBanner';
import { NotAuthorizedBanner } from './components/NotAuthorizedBanner';
import { AdminBanner } from './components/AdminBanner';
import { DuplicatedNewsModal } from './components/DuplicatedNewsModal';
import { EditorFolderBanner } from './components/EditorFolderBanner';
import { FolderNews } from './components/FolderNews';
import { InformBanner } from './components/InformBanner';
import { ListResults } from './components/ListResults';
import { PublishedModal } from './components/PublishedModal';
import { PublisherFolderArchiveBanner } from './components/PublisherFolderArchiveBanner';
import { PublisherFolderBanner } from './components/PublisherFolderBanner';
import { SendFolderToReviewModal } from './components/SendFolderToReviewModal';
import { UncategorizedNews } from './components/UncategorizedNews';
import { NewAlertsButton } from './components/NewAlerts';
import { TOKEN_NAME } from "../../../utils/token-utils";
import Cookies from "universal-cookie";
import axios from "axios";
import './notifications.less';

const cookies = new Cookies();

export const ViewAlert = ({ route, match: { params: { id, category } }, history }) => {

  const folderNewsRef = useRef();

  const searchParams = useQuery(history);

  const { configState, messageTranslator, configDispatcher } = useContext(ConfigContext);

  const token = localStorage.getItem(TOKEN_NAME) || cookies.get(TOKEN_NAME);

  const dispatch = useDispatch();

  const {
    config: { globalConfig: { data: globalConfig } },
    alertResults: {
      results: { data, totalItems, isLoading: loadingResults },
      patchCategory: { isPatched, isLoading: LoadingPatch },
      sourceNames,
      simpleSearch: { isLoading: simpleSearchLoading },
      folders: {
        folder: { data: folderData, isLoading: folderLoading },
        duplication: { isCreated: duplicationCreated, isLoading: loadingDuplicated },
        update: { isLoading: loadingUpdate },
        publish: { isPublished, isLoading: loadingPublish },
        review: { isSetToReview, isLoading: loadingReview },
        auditorState: { isUpdated: auditorStateUpdated, isLoading: loadingAuditor }
      }
    },
    alerts: { alert: { data: alert } },
    users: {
      me: { data: meData },
      listUsers
    },
    categories: { list: { data: categoryList } }
  } = useSelector(state => state);

  
  const isFolderFallsInToday = !!moment(folderData?.createdAt).isBetween(
    `${moment().tz("Asia/Riyadh").set({ "hour": 0, "minute": 0, "second": 0 }).format(ISO_DATE_TZ)}`,
    `${moment().tz("Asia/Riyadh").set({ "hour": 23, "minute": 59, "second": 59 }).format(ISO_DATE_TZ)}`);

  const [checkedResults, setCheckedResults] = useState([]);
  const [folderCheckedItems, setFolderCheckedItems] = useState([]);
  const [hasUncategorizedResults, setHasUncategorizedResults] = useState(false);
  const [isDuplicationModalOpen, setDuplicationModalOpen] = useState(false);
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [isPublishFolderModalOpen, setPublishFolderModalOpen] = useState(false);
  const [parentToUpdate, setParentToUpdate] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerms, setSearchTerms] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [currentAuditor, setCurrentAuditor] = useState();
  const [currentPublisher, ] = useState();
  const [loadedNews, setLoadedNews] = useState(0);
  const [showNewsTag, setShowNewsTag] = useState(false);


  useEffect(() => {
    const interval = setInterval(() => {
      let newsDate = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
      //dispatch(getNewAlertResults({ id, newsDate }));
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/alert_results?page=1&alert=${id}&status=active&scrapedAt[after]=${newsDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        if(response?.data?.['hydra:totalItems'] > 0) {
          setLoadedNews(response?.data?.['hydra:totalItems']);
          setShowNewsTag(true);
        }
      })
      .catch(error => {});

      setTimeout(() => setShowNewsTag(false) , 5000);
    }, 6000);

    return () => clearInterval(interval);

  }, [id]);

  useEffect(() => {
    if(meData?.role?.title === ROLES.AUDITOR) {
      dispatch(getUsers({
        page: 1,
        role: ROLES.AUDITOR.toUpperCase()
      }));
    }
  }, [currentAuditor]);

  useEffect(() => {
    if(meData?.role?.title === ROLES.PUBLISHER) {
      dispatch(getUsers({
        page: 1,
        role: ROLES.PUBLISHER.toUpperCase()
      }));
    }
  }, [currentPublisher]);

  useEffect(() => {
    configDispatcher(CINFIG_SET_ALERT_FRAGMENT, extractPageCategory(history.location.pathname));
  }, [history, configDispatcher]);

  useEffect(() => {
    if (isPatched) {
      setCheckedResults(prevState => replaceItemInArray(isPatched, prevState, '@id'));
      dispatch(patchResultCategoryReset());
    }
  }, [isPatched, dispatch]);

  useEffect(() => {
    if (duplicationCreated) {
      setDuplicationModalOpen(false);
      dispatch(combineDuplicatedNewsReset());
    }
  }, [duplicationCreated, dispatch]);

  useEffect(() => {
    setCheckedResults(prevState => prevState.map(item => {
      if (!folderData) return item;
      const foundMatch = folderData.folderItems.find(i => i.id === item.id);
      return foundMatch || item;
    }));
  }, [folderData]);

  const getTodaysFolder = useCallback(() => getFolderDaily({
    id,
    after: moment(),
    before: moment(),
    state: category === FOLDERS_ARCHIVE ? FOLDER_STATES.PUBLISHED : null
  }), [category, id]);

  useEffect(() => {
    dispatch(getTodaysFolder());
    dispatch(searchSourceNames({id}));
    if (id) {
      dispatch(getAlert(id));
      dispatch(listCategories(1));
      configDispatcher(CONFIG_SET_ALERT_ID, id);
    }
  }, [dispatch, id, configDispatcher, getTodaysFolder]);


  useEffect(() => {
    if (id) {
      const terms = getAlertAdvancedSearchQueries(searchParams);
      dispatch(alertResultsAdvancedSearch({
        ...terms,
        id,
        status: category
      }));
      setSearchTerms(terms);
      setPage(terms.page);
    }
  }, [id, category, searchParams, dispatch]);

  /**
   * * Advanced Filter
   */
  const handleSourceTypeChange = (sourceType) => {
    const queryParams = new URLSearchParams(history.location.search);
    if (sourceType === ALL) {
      setSearchTerms({ page: 1 });
      dispatch(alertResultsAdvancedSearch({
        page: 1,
        id,
        category
      }));
    }
    else {
      addParam(queryParams, { name: 'source', value: sourceType });
      addParam(queryParams, { name: 'page', value: 1 });
      dispatch(filterBySourceType({
        page: 1,
        id,
        sourceType,
        category
      }));
      history.replace({
        search: queryParams.toString()
      });
    };
  };

  const handleSearchSourceNames = (term) => dispatch(searchSourceNames({term, id}));

  const handleSearchInputSubmit = ({ target: { value } }) => {
    const queryParams = new URLSearchParams(history.location.search);
    setSearchValue(value);
    if (value) {
      addParam(queryParams, { name: 'search', value });
      addParam(queryParams, { name: 'page', value: 1 });
      // dispatch(alertResultsAdvancedSearch({
      //   search: value,
      //   id,
      //   page: 1,
      //   status: category
      // }));
    } else removeParam(queryParams, 'search');

    history.replace({
      search: queryParams.toString()
    });
  };

  const handleSumbitAdvancedSearch = (terms = {}) => {
    alertAdvancedSearchHandler(terms, history);
  }
  /**
   * * Advanced Filter
   */

  const handlePageChange = (page) => pageChangeHandler(history, page, searchTerms, setPage);

  const handlePatchAlertResultActions = (params) => dispatch(patchAlertResultActions({
    ...params,
    alertId: id,
    category
  }));

  const handleSubmitResultCategory = ({ id, category, sentiment }) => {
    if (!category && !sentiment) return;
    const newObj = { id };
    if (category) newObj.category = category;
    if (sentiment) newObj.sentiment = sentiment;

    dispatch(patchResultCategory({
      ...newObj,
      onSuccess: () => message.success(messageTranslator("alerts.list.update.category.success", "alerts"))
    }));
  };

  const setResultSeen = (resId) => dispatch(patchAlertResultActions({
    seen: true,
    id: resId,
    page: configState.alertResultPage,
    alertId: id,
    category
  }));

  const handleAddToFolder = () => {
    const hasUncat = checkedResults.some(item => !item.category);
    if (hasUncat) setHasUncategorizedResults(hasUncat);
    else {
      const listIRI = checkedResults.map(item => item['@id']);
      if (!folderData) {
        dispatch(addFolder({
          alertId: id,
          alert: alert['@id'],
          folderItems: listIRI
        }));
      } else {
        dispatch(patchFolder({
          id: folderData.id,
          alert: alert['@id'],
          alertId: id,
          folderItems: [
            ...listIRI,
            ...folderData.folderItems.map(el => el['@id'])
          ]
        }));
      }
      history.push(`${INBOX}/${id}/${folderData?.moderationState === FOLDER_STATES.PUBLISHED ? FOLDERS_ARCHIVE : DAILY}`);
    }
  };

  const handleCloseModal = () => setHasUncategorizedResults(false);

  const handleSaveDuplications = (data) => {
    if (folderNewsRef) folderNewsRef.current.clearCheckedItems();
    setFolderCheckedItems([]);
    !!data && dispatch(combineDuplicatedNews({
      ...data,
      alertId: id
    }));
  };

  const handleSelectDuplicationParent = (parent) => {
    setParentToUpdate(() => ({
      parentDuplicated: parent,
      childrenDuplicated: parent.childrenDuplicated
    }));
    setDuplicationModalOpen(true);
  };

  const handleOpenReviewModal = () => {
    setReviewModalOpen(true);
    dispatch(getUsers({
      page: 1,
      role: ROLES.AUDITOR.toUpperCase()
    }));
  };

  const handleOpenPublishModal = () => {
    setPublishFolderModalOpen(true);
    let auditor = listUsers?.data?.filter(user => user.id === meData.id)[0];
    setCurrentAuditor(auditor);
    if (!listUsers.data.length) {
      dispatch(getUsers({
        page: 1,
        role: ROLES.EDITOR.toUpperCase()
      }));
    }
  };

  const handleSendFolderToReview = (data) => {
    dispatch(setFolderToReview({
      ...data,
      id: folderData.id,
      alertId: id,
      onSuccess: getTodaysFolder
    }));
  };

  const handleCloseReviewModal = () => {
    setReviewModalOpen(false);
    dispatch(setFolderToReviewReset());
    history.push(`${INBOX}/${id}/${DAILY}`);
  };

  const handleSubmitNote = (note) => dispatch(patchAlertResultActions({
    alertId: id,
    onSuccess: getFolderDaily,
    ...note
  }));

  const handleChangeFolderState = (state) => dispatch(patchFolder({
    ...state,
    id: folderData.id,
    alertId: id
  }));

  const handleAuditorChnageFolderState = (state) => {
    if(state?.moderationState === FOLDER_STATES.PUBLISHED) {
      setPublishFolderModalOpen(false);
      handlePublishFolder(state);
    } else {
      dispatch(auditorChangeFolderState({
        ...state,
        id: folderData.id,
        alertId: id
      }));
    }

    //history.push(`${INBOX}/${id}/${FOLDERS_ARCHIVE}`);
  };

  const handlePublishFolder = (state) => {
    dispatch(publishFolder({
      ...state,
      id: folderData.id,
      alertId: id
    }));

    history.push(`${INBOX}/${id}/${FOLDERS_ARCHIVE}`);
  };

  const handleRemoveNewsFromFolder = (elementsLeft) => dispatch(removeItemFromFolder({
    folderItems: elementsLeft,
    id: folderData.id,
    alertId: id
  }));

  const handleFolderDateChange = (date) => {
    if (date) dispatch(getFolderDaily({
      id,
      after: moment(date).format(ISO_DATE),
      before: moment(date).format(ISO_DATE),
      state: FOLDER_STATES.PUBLISHED
    }));
  };

  if (meData && !hasPermission(meData.role, route.resources)) return <UnauthorizedContent />;

  return (
    <PageSkeleton pageTitle={messageTranslator(route.title, "routes")}>
      <Spin spinning={loadingResults || LoadingPatch || sourceNames.isLoading || simpleSearchLoading || folderLoading || loadingDuplicated || loadingUpdate || loadingPublish || loadingReview || loadingAuditor}>
        {(
          !history.location.pathname.includes(DAILY)
          &&
          !history.location.pathname.includes(FOLDERS_ARCHIVE)
        ) && (
            <AlertsFilter
              sourceNames={sourceNames}
              searchTerms={searchTerms}
              searchValue={searchValue}
              isLoadingSimpleSearch={simpleSearchLoading}
              onSearchInputSubmit={handleSearchInputSubmit}
              onSourceTypeChange={handleSourceTypeChange}
              onSourceNameInputChange={handleSearchSourceNames}
              onSubmitAdvancedSearch={handleSumbitAdvancedSearch}
            />
          )}
        {
          [DAILY, FOLDERS_ARCHIVE].includes(category)
            ?
            <>
              <FolderNews
                ref={folderNewsRef}
                globalConfig={globalConfig}
                news={folderData}
                meData={meData}
                alertId={id}
                pageCategory={category}
                categories={categoryList}
                onSelectDuplicationParent={handleSelectDuplicationParent}
                onFolderItemCheck={setFolderCheckedItems}
                onResultSeen={setResultSeen}
                onSetBookmarked={handlePatchAlertResultActions}
                onSubmitResultCategory={handleSubmitResultCategory}
                onSubmitNote={handleSubmitNote}
                onRemoveNewsFromFolder={handleRemoveNewsFromFolder}
                onDateChange={handleFolderDateChange}
              />

              {/* For [AUDITOR, PUBLISHER] when Folder is not created yet */}

              {/* For [AUDITOR, PUBLISHER] when Folder is not created yet */}

              {/* Editor banner */}
              {
                folderData && meData?.role.title === ROLES.EDITOR && !folderData.editor//folderData && meData && folderData.editor
                &&
                //folderData.editor.id === meData.id // allow any editor to edit the daily news folder
                //&&
                folderData.moderationState === FOLDER_STATES.DRAFT
                && (
                  <EditorFolderBanner
                    counter={folderData.folderItems.length}
                    checkedItems={folderCheckedItems.length}
                    backTo={`${INBOX}/${id}`}
                    openDuplicationModal={setDuplicationModalOpen}
                    openReviewModal={handleOpenReviewModal}
                  />
                ) 
              }
              {/* If Auditor send folder back to specific Editor */}
              {
                folderData && meData && folderData.editor
                &&
                folderData?.editor?.id === meData?.id 
                &&
                folderData.moderationState === FOLDER_STATES.DRAFT
                && (
                  <EditorFolderBanner
                    counter={folderData.folderItems.length}
                    checkedItems={folderCheckedItems.length}
                    backTo={`${INBOX}/${id}`}
                    openDuplicationModal={setDuplicationModalOpen}
                    openReviewModal={handleOpenReviewModal}
                  />
                ) 
              }

              {   
                folderData && meData && folderData.editor
                &&
                folderData?.editor?.id !== meData?.id 
                &&
                folderData.moderationState === FOLDER_STATES.DRAFT
                && (
                  <NotAuthorizedBanner folder={folderData}
                currentUser={meData} 
              />
                )
              }
              {/* Editor banner */}

              {/* Auditor banner */}
              {
                folderData && meData && folderData.auditor
                &&
                folderData?.auditor?.id === meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.NEEDS_REVIEW
                && (
                  <AuditorFolderBanner
                    counter={folderData.folderItems.length}
                    checkedItems={folderCheckedItems.length}
                    backTo={`${INBOX}/${id}`}
                    openDuplicationModal={setDuplicationModalOpen}
                    openSendToPublishModal={handleOpenPublishModal}
                  /> 
                )
              }

              {
                folderData && meData && !folderData.auditor
                &&
                folderData.moderationState === FOLDER_STATES.NEEDS_REVIEW
                && (
                  <AuditorFolderBanner
                    counter={folderData.folderItems.length}
                    checkedItems={folderCheckedItems.length}
                    backTo={`${INBOX}/${id}`}
                    openDuplicationModal={setDuplicationModalOpen}
                    openSendToPublishModal={handleOpenPublishModal}
                  /> 
                )
              }

              {
                folderData && meData && folderData.auditor
                &&
                folderData?.auditor?.id !== meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.NEEDS_REVIEW
                && (
                  <NotAuthorizedBanner folder={folderData}
                currentUser={meData} 
              />
                )
              }
              {/* Auditor banner */}

              {/* Publisher banner */}
              {
                folderData && meData
                &&
                (meData.role.title === ROLES.PUBLISHER) && !folderData.publisher
                &&
                folderData.moderationState === FOLDER_STATES.PUBLISHER_CHECK
                &&
                (
                  <PublisherFolderBanner
                    counter={folderData.folderItems.length}
                    checkedItems={folderCheckedItems.length}
                    backTo={`${INBOX}/${id}`}
                    openDuplicationModal={setDuplicationModalOpen}
                    openReviewModal={handleOpenReviewModal}
                    onPublishFolder={handlePublishFolder}
                  />
                ) 
              }

              {
                folderData && meData
                &&
                (meData.role.title === ROLES.PUBLISHER) && folderData.publisher?.id === meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.PUBLISHER_CHECK
                &&
                (
                  <PublisherFolderBanner
                    counter={folderData.folderItems.length}
                    checkedItems={folderCheckedItems.length}
                    backTo={`${INBOX}/${id}`}
                    openDuplicationModal={setDuplicationModalOpen}
                    openReviewModal={handleOpenReviewModal}
                    onPublishFolder={handlePublishFolder}
                  />
                ) 
              }

              {
                folderData && meData
                &&
                (meData.role.title === ROLES.PUBLISHER) && folderData.publisher?.id !== meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.PUBLISHER_CHECK
                && (
                  <NotAuthorizedBanner folder={folderData}
                currentUser={meData} 
              />
                )
              }

              {/* This will appear only for the publisher in the folders archive page */}
              {
                category === FOLDERS_ARCHIVE
                &&
                folderData && meData
                &&
                (meData.role.title === ROLES.PUBLISHER)
                &&
                folderData.moderationState === FOLDER_STATES.PUBLISHED
                &&
                isFolderFallsInToday
                &&
                (
                  <PublisherFolderArchiveBanner
                    counter={folderData.folderItems.length}
                    checkedItems={folderCheckedItems.length}
                    backTo={`${INBOX}/${id}`}
                    openDuplicationModal={setDuplicationModalOpen}
                    openReviewModal={handleOpenReviewModal}
                    onPublishFolder={handleChangeFolderState}
                  />
                )
              }
              {/* Publisher banner */}

              <InformBanner
                folder={folderData}
                currentUser={meData}
              />
              {/*  */}

              <DuplicatedNewsModal
                visible={isDuplicationModalOpen}
                duplications={folderCheckedItems}
                parentToUpdate={parentToUpdate}
                onCancelGroupDuplications={handleSaveDuplications}
                onModalClose={setDuplicationModalOpen}
                onSaveChanges={handleSaveDuplications}
              />

              <SendFolderToReviewModal
                title={(meData?.role.title === ROLES.PUBLISHER) ? "alerts.view.banner.returnFolder" : "alerts.view.banner.sendToReview"}
                noticePlaceholder={(meData?.role.title === ROLES.PUBLISHER) ? "alerts.view.banner.writeDeclineCause" : "alerts.view.banner.writeRecommendation"}
                visible={isReviewModalOpen}
                alertId={id}
                currentPublisher={currentPublisher}
                listUsers={listUsers}
                isLoading={loadingUpdate}
                isUpdated={isSetToReview}
                onResetUpdate={() => dispatch(setFolderToReviewReset())}
                onModalClose={handleCloseReviewModal}
                onOk={handleSendFolderToReview}
              />

              <ChangeFolderStateModal
                noticePlaceholder={(meData?.role.title === ROLES.PUBLISHER) ? "alerts.view.banner.writeDeclineCause" : "alerts.view.banner.writeRecommendation"}
                visible={isPublishFolderModalOpen}
                isUpdated={auditorStateUpdated}
                alertId={id}
                reportId={folderData && folderData.reportId}
                currentAuditor={currentAuditor}
                isLoading={loadingUpdate}
                meData={meData}
                onModalClose={setPublishFolderModalOpen}
                onUpdateReset={() => dispatch(auditorChangeFolderStateReset())}
                onSaveChanges={handleAuditorChnageFolderState}
              />

              <PublishedModal
                alertId={id}
                history={history}
                reportId={folderData && folderData.reportId}
                status={folderData && folderData.moderationState}
                visible={
                  folderData
                  &&
                  isPublished
                  &&
                  folderData.moderationState === FOLDER_STATES.PUBLISHED
                }
                resetFolderUpdate={publishFolderReset}
              />

              {
                meData &&
                meData?.role?.slug === ROLES.SUPER_ADMIN && <AdminBanner currentUser={meData} />
              }

            </>
            :
            <>
              {/* Add new news tag here */}
              <Row align="center" > 
              {showNewsTag && <NewAlertsButton items={loadedNews} />}
              </Row>
              <ListResults
                folder={folderData}
                data={data}
                config={globalConfig}
                currentPage={page}
                totalItems={totalItems}
                categories={categoryList}
                isCategoryPatched={isPatched}
                currentUser={meData}
                onSyncClick={() => dispatch(alertResultsAdvancedSearch({ id, ...searchTerms }))}
                onResultCheck={setCheckedResults}
                onResultSeen={setResultSeen}
                onPageChange={handlePageChange}
                onSetBookmarked={handlePatchAlertResultActions}
                onSubmitResultCategory={handleSubmitResultCategory}
                hideTag={setShowNewsTag}
              />

              <UncategorizedNews
                visible={hasUncategorizedResults}
                onCancel={handleCloseModal}
              />

              <InformBanner
                folder={folderData}
                currentUser={meData}
              />

              {
                ((!folderData && meData?.role.title === ROLES.EDITOR) || (folderData && meData?.role.title === ROLES.EDITOR && !folderData.editor)) //folderData && meData && folderData.editor
                &&
                //folderData.editor.id === meData.id // allow any editor to edit the daily news folder
                //&&
                (
                  <CreateFolderBanner
                    items={checkedResults.length}
                    folder={folderData}
                    currentUser={meData}
                    onAddFolderClick={handleAddToFolder}
                  />
                )
              }

              {
                folderData && meData && folderData.editor
                &&
                folderData?.editor?.id === meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.DRAFT
                && (
                  <CreateFolderBanner
                    items={checkedResults.length}
                    folder={folderData}
                    currentUser={meData}
                    onAddFolderClick={handleAddToFolder}
                  />
                )
              }

              {
                folderData && meData && folderData.editor
                &&
                folderData?.editor?.id !== meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.DRAFT
                && (
                  <NotAuthorizedBanner folder={folderData}
                    currentUser={meData} 
                  />
                )
              }

              {
                folderData && meData && folderData.auditor
                &&
                folderData?.auditor?.id === meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.NEEDS_REVIEW
                && (
                  <CreateFolderBanner
                items={checkedResults.length}
                folder={folderData}
                currentUser={meData}
                onAddFolderClick={handleAddToFolder}
              />
                )
              }

              {
                folderData && meData && !folderData.auditor
                &&
                folderData.moderationState === FOLDER_STATES.NEEDS_REVIEW
                && (
                  <CreateFolderBanner
                    items={checkedResults.length}
                    folder={folderData}
                    currentUser={meData}
                    onAddFolderClick={handleAddToFolder}
                  />
                )
              }

              {
                folderData && meData && folderData.auditor
                &&
                folderData?.auditor?.id !== meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.NEEDS_REVIEW
                && (
                  <NotAuthorizedBanner folder={folderData}
                currentUser={meData} 
              />
                )
              }

              {
                folderData && meData
                &&
                (meData.role.title === ROLES.PUBLISHER) && folderData.publisher?.id === meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.PUBLISHER_CHECK
                && (
                  <CreateFolderBanner
                items={checkedResults.length}
                folder={folderData}
                currentUser={meData}
                onAddFolderClick={handleAddToFolder}
              />
                )
              }

              {
                folderData && meData
                &&
                (meData.role.title === ROLES.PUBLISHER) && !folderData.publisher
                &&
                folderData.moderationState === FOLDER_STATES.PUBLISHER_CHECK
                && (
                  <CreateFolderBanner
                items={checkedResults.length}
                folder={folderData}
                currentUser={meData}
                onAddFolderClick={handleAddToFolder}
              />
                )
              }

              {
                folderData && meData
                &&
                (meData.role.title === ROLES.PUBLISHER) && folderData.publisher?.id !== meData?.id
                &&
                folderData.moderationState === FOLDER_STATES.PUBLISHER_CHECK
                && (
                  <NotAuthorizedBanner folder={folderData}
                currentUser={meData} 
              />
                )
              }

              {
                meData &&
                meData?.role?.slug === ROLES.SUPER_ADMIN && <AdminBanner currentUser={meData} />
              }
              
            </>
        }
      </Spin>
    </PageSkeleton>
  );
};
