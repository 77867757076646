import { combineReducers } from "redux";
import {
  alertResultsInitialState,
  alertResultsReducer,
} from "./alert-results/reducers";
import { alertInitialState, alertReducer } from "./alerts/reducers";
import {
  categoriesInitialState,
  categoriesReducer,
} from "./categories/reducers";
import { configInitialState, configReducer } from "./config/reducers";
import {
  emailGroupInitialState,
  emailGroupReducer,
} from "./emailGroups/reducers";
import { AUTH_LOGOUT } from "./logout";
import {
  notificationsInitialState,
  notificationsReducer,
} from "./notifications/reducers";
import {
  permissionsInitialState,
  permissionsReducer,
} from "./permissions/reducers";
import { reportsInitialState, reportsReducer } from "./reports/reducers";
import {
  resetPasswordInitialState,
  resetPasswordReducer,
} from "./resetPassword/reducers";
import { rolesInitialState, rolesReducer } from "./roles/reducers";
import { scheduleReducer, schedulesInitialState } from "./schedule/reducers";
import {
  scrapResultsInitialState,
  scrapResultsReducer,
} from "./scrap-results/reducers";
import { sourceInitialState, sourceReducer } from "./sources/reducers";
import { statsInitialState, statsReducer } from "./stats/reducers";
import { userInitialState, userReducer } from "./users/reducers";

const appReducer = combineReducers({
  alertResults: alertResultsReducer,
  alerts: alertReducer,
  categories: categoriesReducer,
  config: configReducer,
  emailGroups: emailGroupReducer,
  notifications: notificationsReducer,
  permissions: permissionsReducer,
  reports: reportsReducer,
  resetPassword: resetPasswordReducer,
  roles: rolesReducer,
  schedules: scheduleReducer,
  scrapResults: scrapResultsReducer,
  sources: sourceReducer,
  stats: statsReducer,
  users: userReducer,
});

const rootReducerInitialState = {
  alerts: alertInitialState,
  alertResults: alertResultsInitialState,
  categories: categoriesInitialState,
  config: configInitialState,
  emailGroups: emailGroupInitialState,
  notifications: notificationsInitialState,
  permissions: permissionsInitialState,
  reports: reportsInitialState,
  resetPassword: resetPasswordInitialState,
  roles: rolesInitialState,
  schedules: schedulesInitialState,
  scrapResults: scrapResultsInitialState,
  sources: sourceInitialState,
  stats: statsInitialState,
  users: userInitialState,
};

const rootReducer = (state = rootReducerInitialState, action) => {
  if (action.type === AUTH_LOGOUT) return rootReducerInitialState;
  return appReducer(state, action);
};

export default rootReducer;
