import { DeleteFilled, EditFilled, FolderOpenFilled } from '@ant-design/icons';
import { Col, message, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PermissionsHandler } from '../../../../../components/PermissionHandler';
import { ConfigContext } from '../../../../../context/config';
import { INBOX } from '../../../../../routes/paths';
import { PAGE_SIZE } from '../../../../../utils/constants';
import { languages } from '../../../../../utils/data/languages';
import { DATE_FORMAT } from '../../../../../utils/moment-utils';
import { CAN_DELETE, CAN_PUT } from '../../../../../utils/permissions-utils';
import { listIndexer, tablePaginationOptions } from '../../../../../utils/utils';

const Column = Table;

export const ListAlerts = ({
  listData,
  role,
  loadingDelete,
  onSelectAlert,
  onPageChange,
  onDeleteALert
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const { errors, data, totalItems } = listData;

  const [page, setPage] = useState(() => 1);

  useEffect(() => {
    !!errors && message.error(errors, 5);
  }, [errors]);

  const handlePageChange = (page) => {
    setPage(page);
    onPageChange(page);
  };

  const getLanguageTranslationKey = (iri) => languages.find(lang => lang.value === iri).label || "";

  return (
    <Table
      rowKey={rec => rec['@id']}
      dataSource={[...data]}
      pagination={tablePaginationOptions({ totalItems, pageSize: 30, onPageChange: handlePageChange, messageTranslator, currentPage: page })}
      locale={{
        emptyText: messageTranslator("alerts.list.emptyText", "alerts")
      }}
      scroll={{ x: true }}
    >
      <Column
        key="index"
        title="#"
        render={(alert, rec, index) => <Typography.Text>{listIndexer(index, PAGE_SIZE, page)}</Typography.Text>}
      />
      <Column
        key="name"
        title={messageTranslator("alerts.list.siteName", "alerts")}
        render={alert => <Link to={`${INBOX}/${alert.id}`}>{alert.name}</Link>}
      />
      <Column
        key="firstName"
        title={messageTranslator("alerts.list.by", "alerts")}
        render={alert => <Typography.Text>{`${alert.owner?.firstName || ""} ${alert.owner?.lastName || ""}`}</Typography.Text>}
      />
      <Column
        key="languages"
        title={messageTranslator("alerts.list.langs", "alerts")}
        render={alert => (
          <Typography.Text>
            <Space size={0}>
              {alert.languages.map(lang => (
                <Tag key={lang.name} color="magenta">{messageTranslator(getLanguageTranslationKey(lang['@id']), "constants")}</Tag>
              ))}
            </Space>
          </Typography.Text>
        )}
      />
      <Column
        key="createdAt"
        title={messageTranslator("alerts.list.createAt", "alerts")}
        render={alert => <Typography.Text>{moment(alert.createdAt).format(DATE_FORMAT)}</Typography.Text>}
      />
      <Column
        key="actions"
        width={100}
        render={alert => (
          <Row gutter={[8, 8]}>
            <Col xs={24} md={8}>
              <Tooltip
                placement="bottom"
                title={messageTranslator("alerts.list.goToFolder", "alerts")}
              >
                <Link to={`alert/${alert.id}/folders`}>
                  <FolderOpenFilled />
                </Link>
              </Tooltip>
            </Col>
            <PermissionsHandler
              resourceName="Alert"
              blocPermission={CAN_PUT}
              userRole={role}
            >
              <Col xs={24} md={8}>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("alerts.list.update", "alerts")}
                >
                  <EditFilled onClick={() => onSelectAlert(alert)} />
                </Tooltip>
              </Col>
            </PermissionsHandler>
            <PermissionsHandler
              resourceName="Alert"
              blocPermission={CAN_DELETE}
              userRole={role}
            >
              <Col xs={24} md={8}>
                <Tooltip
                  placement="bottom"
                  title={messageTranslator("alerts.list.delete", "alerts")}
                >
                  <Popconfirm
                    title={`${messageTranslator("alerts.list.delete.confirm", "alerts")} ${alert.name}`}
                    onConfirm={(e) => onDeleteALert(alert.id)}
                    okText={messageTranslator("commons.delete.confirm", "commons")}
                    cancelText={messageTranslator("commons.cancel", "commons")}
                    okButtonProps={{
                      loading: loadingDelete
                    }}
                  >
                    <DeleteFilled />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </PermissionsHandler>
          </Row>
        )}
      />
    </Table>
  );
};
