import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Typography } from 'antd';
import ReactECharts from 'echarts-for-react';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../context/config';
import { getCount } from '../../../../utils/utils';
import "./sentiment-card.less";

const STEADY = "steady";
const GROWTH = "growth";
const DECAY = "decay";

export const SentimentCard = ({ unit, feedbackUnit, colors, label, lineColor, data }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [changeStatus, setChangeStatus] = useState(null);

  useEffect(() => {
    if (data) setChangeStatus(data["0"]);
  }, [data]);

  const renderChangesStatus = (unit, changes) => {
    if (changes.curve === GROWTH) return [<ArrowUpOutlined style={{ color: colors.growth }} />, colors.growth];
    if (changes.curve === DECAY) return [<ArrowDownOutlined style={{ color: colors.decay }} />, colors.decay];
    if (changes.curve === STEADY) return [<MinusOutlined style={{ color: colors.steady }} />, colors.steady];
  }

  const option = {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '1%',
      right: '1%',
      bottom: '3%',
      containLabel: false
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: [],
        show: false
      }
    ],
    yAxis: [
      {
        type: 'value',
        show: false
      }
    ],
    series: [
      {
        name: '',
        type: 'line',
        stack: '',
        symbol: 'none',
        lineStyle: {
          color: lineColor
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: lineColor },
              { offset: 1, color: "rgba(255, 255, 255)" }
            ],
            global: false
          }
        },
        data: getCount(data, unit)
      }
    ]
  };

  return (
    <Card>
      <Row>
        <Col span={12}>
          <Typography.Text type="secondary">{messageTranslator(label, "stats")}</Typography.Text>
          <Statistic value={changeStatus?.count} className="total-sentiment" />
          {
            changeStatus && changeStatus.percentage !== null ? (
              <div>
                <span className='statistic-sentiment'>
                  <Statistic
                    value={changeStatus.percentage}
                    precision={1}
                    valueStyle={{ fontSize: "12px", color: renderChangesStatus(unit || feedbackUnit, changeStatus)[1] }}
                    prefix={renderChangesStatus(unit || feedbackUnit, changeStatus)[0]}
                    suffix="%"
                  />
                </span>
                <Typography.Text type="secondary" style={{ fontSize: "12px" }}>{messageTranslator("stats.sentiment.card.compare.text", "stats")}</Typography.Text>
                {" "}
                <Typography.Text type="secondary" style={{ fontSize: "12px" }}>{messageTranslator(`stats.sentiment.card.compare.${unit || feedbackUnit}`, "stats")}</Typography.Text>
              </div>
            ) : ''
          }
        </Col>
        <Col span={12}>
          <ReactECharts
            option={option}
            style={{ height: 150 }}
          />
        </Col>
      </Row>
    </Card>
  )
}
