import React from 'react';
import './styles.less';

export const ColorPicker = ({ color, inList, selected, onClick }) => {

  const handleClick = () => { if(!inList) onClick(color); }

  return (
    <div
      className={`cat-color ${inList ? 'in-list': ''}`} style={{
        backgroundColor: selected && selected === color ? `#FFFFFF` : `${color}`,
        borderColor: `${color}`,
      }}
      onClick={handleClick}
    />
  );
};
