import {
  AlertOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  FileTextOutlined,
  KeyOutlined,
  LineChartOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { AnonymLayout } from "../layouts/AnonymLayout";
import { AnonymNews } from "../layouts/AnonymNews";
import { FullLayout } from "../layouts/FullLayout";
import { MainLayout } from "../layouts/MainLayout";
import { NotificationsLayout } from "../layouts/NotificationsLayout";
import { Alerts } from "../pages/alerts/Alerts";
import { DailyFolder } from "../pages/alerts/DailyFolder";
import { NewAlert } from "../pages/alerts/NewAlert";
import { ViewAlert } from "../pages/alerts/ViewAlert";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { Login } from "../pages/auth/Login";
import { ResetPassword } from "../pages/auth/ResetPassword";
import { ListCategories } from "../pages/categories/ListCategories";
import { GlobalConfig } from "../pages/config/GlobalConfig";
import { MailConfig } from "../pages/config/MailConfig";
import { MailGroup } from "../pages/config/MailGroup";
import { NewEmailGroup } from "../pages/config/NewEmailGroup";
import { ScrapingSettings } from "../pages/config/ScrappingSettings";
import { TwitterAPISettings } from "../pages/config/TwitterAPISettings";
import { TwitterCriteriaSettings } from "../pages/config/TwitterCriteriaSettings";
import { UpdateEmailGroup } from "../pages/config/UpdateEmailGroup";
import { Dashboard } from "../pages/Dashboard";
import { ReportsList } from "../pages/reports/ReportsList";
import { ViewReport } from "../pages/reports/ViewReport";
import { ListRoles } from "../pages/roles/ListRoles";
import { ListSources } from "../pages/sources/ListSources";
import { ViewSource } from "../pages/sources/ViewSource";
import { ChangePassword } from "../pages/users/ChangePassword";
import { ListUsers } from "../pages/users/ListUsers";
import { ViewUser } from "../pages/users/ViewUser";
import { AllNotifications } from "../pages/Notifications";
import {
  CAN_CREATE,
  CAN_DELETE,
  CAN_LIST,
  CAN_PUT,
  CAN_VIEW,
} from "../utils/permissions-utils";
import { generateRoutes } from "./generate-routes";
import * as PATHS from "./paths";

export const routes = [
  {
    layout: AnonymLayout,
    routes: [
      {
        key: "login",
        name: "login",
        title: "auth.login.pageTabTitle",
        component: Login,
        path: PATHS.LOGIN,
        isPublic: true,
      },
      {
        key: "forgot-password",
        name: "forgot-password",
        title: "auth.forget.pageTabTitle",
        component: ForgotPassword,
        path: PATHS.FORGOT_PASSWORD,
        isPublic: true,
      },
      {
        key: "reset-password",
        name: "reset-password",
        title: "auth.reset.pageTabTitle",
        component: ResetPassword,
        path: PATHS.RESET_PASSWORD,
        isPublic: true,
      },
    ],
  },
  {
    layout: FullLayout,
    routes: [
      {
        key: "alert",
        name: "alert",
        title: "alerts.create",
        component: NewAlert,
        exact: true,
        hasSiderLink: true,
        icon: null,
        path: PATHS.NEW_ALERT,
        resources: {
          Alert: [CAN_CREATE],
        },
      },
      {
        key: "reports",
        name: "reports",
        title: "routes.reports.pageTabTitle",
        component: ViewReport,
        exact: true,
        path: `${PATHS.REPORTS}/:category/:id`,
        resources: {
          Report: [CAN_LIST],
        },
      },
    ],
  },
  {
    layout: NotificationsLayout,
    routes: [
      {
        key: "notifications",
        name: "notifications",
        title: "routes.alertReuslts.pageTabTitle",
        component: ViewAlert,
        exact: true,
        hasSiderLink: true,
        icon: null,
        path: `${PATHS.INBOX}/:id`,
        resources: {
          AlertItemResult: [CAN_VIEW],
        },
      },
      {
        key: "notifications1",
        name: "notifications",
        title: "routes.alertReuslts.pageTabTitle",
        component: ViewAlert,
        exact: true,
        hasSiderLink: true,
        icon: null,
        path: `${PATHS.INBOX}/:id/:category`,
        resources: {
          AlertItemResult: [CAN_VIEW],
        },
      },
    ],
  },
  {
    layout: MainLayout,
    routes: [
      {
        key: PATHS.ALERTS,
        name: "alert",
        title: "routes.home.pageTabTitle",
        component: Alerts,
        exact: true,
        hasSiderLink: true,
        hasHeaderLink: true,
        icon: <AlertOutlined />,
        path: PATHS.ALERTS,
        resources: {
          Alert: [CAN_LIST],
        },
      },
      {
        key: PATHS.DASHBOARD,
        name: "dashboard",
        title: "routes.dashboard.pageTabTitle",
        component: Dashboard,
        hasHeaderLink: true,
        exact: true,
        icon: <LineChartOutlined />,
        path: PATHS.DASHBOARD,
        resources: {
          Alert: [CAN_LIST],
          AlertItemResult: [CAN_VIEW],
        },
      },
      {
        key: PATHS.INBOX,
        name: "notifications",
        title: "routes.inbox.pageTabTitle",
        component: ViewAlert,
        exact: true,
        hasHeaderLink: true,
        icon: <DownloadOutlined />,
        path: `${PATHS.INBOX}/:id`,
        resources: {
          AlertItemResult: [CAN_VIEW],
        },
      },
      {
        key: PATHS.REPORTS,
        name: "reports",
        title: "routes.reports.pageTabTitle",
        component: ReportsList,
        exact: true,
        hasHeaderLink: true,
        icon: <FileTextOutlined />,
        path: `${PATHS.REPORTS}/:category`,
        resources: {
          FolderReport: [CAN_LIST],
        },
      },
      {
        key: PATHS.SOURCES,
        name: "sources",
        title: "routes.sources.pageTabTitle",
        component: ListSources,
        exact: true,
        hasHeaderLink: true,
        icon: <DatabaseOutlined />,
        path: PATHS.SOURCES,
        resources: {
          Source: [CAN_LIST],
        },
      },
      {
        key: PATHS.SOURCES,
        name: "sources",
        title: "routes.sources.scrap.pageTabTitle",
        component: ViewSource,
        exact: true,
        hasHeaderLink: false,
        icon: null,
        path: `${PATHS.SOURCES}/:id`,
        resources: {
          Source: [CAN_VIEW],
        },
      },
      {
        key: PATHS.CATEGORIES,
        name: "categories",
        title: "routes.categories.pageTabTitle",
        component: ListCategories,
        exact: true,
        hasSiderLink: true,
        hasHeaderLink: true,
        icon: <UnorderedListOutlined />,
        path: PATHS.CATEGORIES,
        resources: {
          Role: [CAN_LIST],
        },
      },
      {
        key: PATHS.USERS,
        name: "users",
        title: "routes.accounts.pageTabTitle",
        component: ListUsers,
        exact: true,
        hasHeaderLink: true,
        icon: <UsergroupAddOutlined />,
        path: PATHS.USERS,
        resources: {
          User: [CAN_LIST],
        },
      },
      {
        key: PATHS.UPDATE_USER,
        name: "users",
        title: null,
        component: ViewUser,
        exact: true,
        icon: null,
        path: `${PATHS.UPDATE_USER}/:id`,
        resources: {
          User: [CAN_VIEW, CAN_PUT, CAN_DELETE],
        },
      },
      {
        key: PATHS.CHANGE_PASSWORD,
        name: "users",
        title: null,
        component: ChangePassword,
        exact: true,
        icon: null,
        path: `${PATHS.CHANGE_PASSWORD}/:id`,
        resources: {
          User: [CAN_VIEW, CAN_PUT],
        },
      },
      {
        key: PATHS.ROLES,
        name: "roles",
        title: "routes.roles.pageTabTitle",
        component: ListRoles,
        exact: true,
        hasSiderLink: true,
        hasHeaderLink: true,
        icon: <KeyOutlined />,
        path: PATHS.ROLES,
        resources: {
          Role: [CAN_LIST],
        },
      },
      {
        key: PATHS.CONFIG,
        name: "config",
        title: "routes.config.pageTabTitle",
        component: MailConfig,
        exact: true,
        hasSiderLink: true,
        hasHeaderLink: true,
        icon: <SettingOutlined />,
        path: PATHS.MAIL_CONFIG,
        resources: {
          Mail: [CAN_LIST],
        },
      },
      {
        key: PATHS.MAIL_GROUP,
        name: "config",
        title: "routes.mailGroup.pageTabTitle",
        component: MailGroup,
        exact: true,
        path: PATHS.MAIL_GROUP,
        resources: {
          Mail: [CAN_LIST],
        },
      },
      {
        key: PATHS.MAIL_GROUP,
        name: "config",
        title: "routes.newMailGroup.pageTabTitle",
        component: NewEmailGroup,
        exact: true,
        path: PATHS.NEW_MAIL_GROUP,
        resources: {
          Mail: [CAN_CREATE],
        },
      },
      {
        key: PATHS.MAIL_GROUP,
        name: "config",
        title: "routes.updateMailGroup.pageTabTitle",
        component: UpdateEmailGroup,
        exact: true,
        path: `${PATHS.UPDATE_MAIL_GROUP}/:id`,
        resources: {
          Mail: [CAN_PUT],
        },
      },
      {
        key: PATHS.GLOBAL_CONFIG,
        name: "config",
        title: "routes.globalConfig.pageTabTitle",
        component: GlobalConfig,
        exact: true,
        path: PATHS.GLOBAL_CONFIG,
        resources: {
          Mail: [CAN_PUT],
        },
      },
      {
        key: PATHS.SCRAPPING_CONFIG,
        name: "config",
        title: "routes.scrapingConfig.pageTabTitle",
        component: ScrapingSettings,
        exact: true,
        path: PATHS.SCRAPPING_CONFIG,
        resources: {
          Mail: [CAN_PUT],
        },
      },
      {
        key: PATHS.TWITTER_API_CONFIG,
        name: "config",
        title: "routes.twitterAPiSettings.pageTabTitle",
        component: TwitterAPISettings,
        exact: true,
        path: PATHS.TWITTER_API_CONFIG,
        resources: {
          Mail: [CAN_PUT],
        },
      },
      {
        key: PATHS.TWITTER_CRITERIA_CONFIG,
        name: "config",
        title: "routes.twitterCriteriaSettings.pageTabTitle",
        component: TwitterCriteriaSettings,
        exact: true,
        path: PATHS.TWITTER_CRITERIA_CONFIG,
        resources: {
          Mail: [CAN_PUT],
        },
      },
      {
        key: "notifications-all",
        name: "notifications",
        title: "routes.allNotifications.pageTabTitle",
        component: AllNotifications,
        exact: true,
        hasSiderLink: true,
        icon: null,
        path: `${PATHS.NOTIFICATONS}`,
        resources: {
          AlertItemResult: [CAN_VIEW],
        },
      },
    ],
  },
  {
    layout: AnonymNews,
    routes: [
      {
        key: "daily-folder",
        name: "daily-folder",
        title: "routes.daily.pageTabTitle",
        component: DailyFolder,
        exact: true,
        path: `/alert/:id/folders`,
        isPublic: true,
      },
    ],
  },
];

export const Routes = generateRoutes(routes);
