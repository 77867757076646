import { put, takeLatest } from 'redux-saga/effects';
import { createRoleApi, deleteRoleApi, getRoleByIdApi, getRolesApi, updateRoleApi } from '../../api/roles';
import { createRoleError, createRoleSuccess, deleteRoleError, deleteRoleSuccess, getRoleError, getRolesSuccess, getRoleSuccess, updateRoleError, updateRoleSuccess } from './actions';
import { ROLES_CREATE, ROLES_DELETE, ROLES_GET_BY_ID, ROLES_LIST, ROLES_UPDATE } from './types';

// Create role
function* createRoleSaga({payload: role}) {
  try {
    const res = yield createRoleApi(role);
    yield put(createRoleSuccess(res.data));
  } catch (error) {
    yield put(createRoleError(error));
  }
};

export function* watchCreateRole() {
  yield takeLatest(ROLES_CREATE, createRoleSaga);
};

// List roles
function* getRolesSaga({payload: params}) {
  try {
    const res = yield getRolesApi(params);
    yield put(getRolesSuccess({
      roles: res.data['hydra:member'],
      totalItems: res.data['hydra:totalItems'],
    }));
  } catch (error) {
    yield put(createRoleError(error));
  }
};

export function* watchGetRoles() {
  yield takeLatest(ROLES_LIST, getRolesSaga);
};

// Delete role
function* deleteRoleSaga({payload: id}) {
  try {
    yield deleteRoleApi(id);
    yield put(deleteRoleSuccess(id));
  } catch (error) {
    yield put(deleteRoleError(error));
  }
};

export function* watchDeleteRole() {
  yield takeLatest(ROLES_DELETE, deleteRoleSaga);
};

// Get role
function* getRoleSaga({payload: id}) {
  try {
    const res = yield getRoleByIdApi(id);
    yield put(getRoleSuccess(res.data));
  } catch (error) {
    yield put(getRoleError(error));
  }
};

export function* watchGetRole() {
  yield takeLatest(ROLES_GET_BY_ID, getRoleSaga);
};

// Update role
function* updateRoleSaga({payload: role}) {
  try {
    const res = yield updateRoleApi(role);
    yield put(updateRoleSuccess(res.data));
  } catch (error) {
    yield put(updateRoleError(error));
  }
};

export function* watchUpdateRole() {
  yield takeLatest(ROLES_UPDATE, updateRoleSaga);
};
