import { api } from '..';
import { formatArrayToUrl } from '../../utils/api-utils';

const URL = '/api/scrap_results';

export const getScrapResultsApi = ({ page = 1, ...params }) => {
  let query = `source=${params.id}&page=${page}`;

  if (params.search) query += `&search=${params.search}`;
  if (params.from) query += `&createdAt[after]=${params.from} 00:00:00`;
  if (params.to) query += `&createdAt[before]=${params.to} 23:59:59`;
  if (params.sentiments) query += formatArrayToUrl(params.sentiments, 'sentiment');

  return api.get(`${URL}?${query}`);
};