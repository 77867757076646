import { Button, Card, Col, Divider, Form, Input, message, Row, Space } from 'antd';
import React, { useContext, useEffect } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { CONFIG_NAMES } from '../../../../../utils/constants';
import { generateConfigList } from '../../../../../utils/utils';

export const TwitterSettingsForm = ({ config, onSubmitConfig }) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  useEffect(() => {
    if (config.data) setFieldsValue({ ...config.data.values });
  }, [config, setFieldsValue]);

  const handleFormSubmit = (values) => {
    const newValues = {};
    if (values.api_keys) newValues.api_keys = values.api_keys;
    if (values.api_secret) newValues.api_secret = values.api_secret;
    if (values.access_token) newValues.access_token = values.access_token;
    if (values.access_token_secret) newValues.access_token_secret = values.access_token_secret;

    if (!Object.keys(newValues).length) {
      message.error(messageTranslator("config.settings.form.error.message", "config"));
      return;
    }

    onSubmitConfig({
      name: CONFIG_NAMES.twitter,
      values: generateConfigList(newValues)
    })
  };

  return (
    <Card
      title={messageTranslator("config.menu.twitterApi.label", "config")}
      bordered={false}
    >
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
      >

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitter.apiKeys.label", "config")}
              name="api_keys"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitter.apiSecret.label", "config")}
              name="api_secret"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitter.accessToken.label", "config")}
              name="access_token"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={messageTranslator("config.twitter.accessTokenSecret.label", "config")}
              name="access_token_secret"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Space>
          <span className='btn-wrapper'>
            <Button type="primary" htmlType="submit">
              {messageTranslator("commons.save", "commons")}
            </Button>
          </span>
          <Button>
            {messageTranslator("commons.cancel", "commons")}
          </Button>
        </Space>
      </Form>
    </Card>
  );
};
