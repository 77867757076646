import { DeleteFilled, DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, FolderFilled, FolderOutlined, StarFilled, StarOutlined, SyncOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Checkbox, Col, Form, List, Row, Space, Tag, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { ACTIVE, ARCHIVED, DELETED, UNDESIRABLE } from '../../../../../utils/data/category';
import { addNewsToFolder, findItemByKey, toggleAddToArray, emptyCheckedNews } from '../../../../../utils/results-utils';
import { tablePaginationOptions } from '../../../../../utils/utils';
import { UpdateResultCategoryFrom } from '../UpdateResultCategoryFrom';

export const ListResults = ({
  folder,
  data,
  currentPage,
  totalItems,
  categories,
  isCategoryPatched,
  config,
  onSyncClick,
  hideTag,
  onResultSeen,
  onPageChange,
  onSetBookmarked,
  onResultCheck,
  onSubmitResultCategory
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState([]);
  const [editableRows, setEditableRows] = useState([]);

  useEffect(() => {
    emptyCheckedNews(checked, setChecked);
    onResultCheck([]);
  }, [data]);

useEffect(() => {
  if (isCategoryPatched) {
    setEditableRows(prev => prev.filter(item => item.id !== isCategoryPatched.id));
  }
}, [isCategoryPatched, editableRows]);

const handlePaginationClick = (currentPage) => {
  setPage(currentPage);
  onPageChange(currentPage);
};

const handleAddResultToFolderArray = (result) => addNewsToFolder(result, onResultCheck);

const test = () => {
  onSyncClick();
  hideTag(false);
};

return (
  <Row gutter={[18, 24]}>
    <Col span={24}>
        <Space className='refresh-wrapper'>
          <Tooltip title={messageTranslator("alerts.results.list.sync.updateNews", "alerts")}>
            <Button
              type="primary"
              shape="circle"
              icon={<SyncOutlined />}
              size={20}
              onClick={test}
            />
          </Tooltip>
        </Space>
      </Col>
    <Col span={24}>
      <List
        size="large"
        dataSource={[...data]}
        pagination={tablePaginationOptions({
          totalItems,
          pageSize: 30,
          currentPage,
          onPageChange: handlePaginationClick,
          messageTranslator
        })}
        locale={{
          emptyText: messageTranslator("alerts.daily.result.empty.text", "alerts")
        }}
        renderItem={result => {
          const isChecked = !!findItemByKey(checked, result, 'id');
          return (
            <List.Item className={isChecked ? "active ant-form-small" : "ant-form-small"}>
              <List.Item.Meta
                avatar={(
                  <a
                    href={result.url}
                    onClick={() => onResultSeen(result.id)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Badge offset={[-5, 50]} count={
                      result.sourceType !== 'website' ? (
                        <Avatar
                          size={20}
                          src={`/images/social-media/${result.sourceType}.png`}
                          className="avatar-source"
                        />
                      ) : null
                    }>
                      <Avatar
                        size={64}
                        src={result.sourceImage || `${process.env.REACT_APP_BASE_URL}${config?.values?.default_source_image?.contentUrl}`}
                      />
                    </Badge>
                  </a>
                )}
                title={(
                  <>
                    <span className='date'>{moment(result.createdAt).format('DD MMM')}</span> <span className='user'>
                      <a
                        href={result.url}
                        onClick={() => onResultSeen(result.id)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {`${result.sourceType === 'twitter' ? `@${result.sourceName}` : result.sourceName}`}
                      </a>
                    </span>
                    {result.category && (<Tag color={result.category.color}>{result.category.name}</Tag>)}
                  </>
                )}
                description={(
                  <a
                    href={result.url}
                    onClick={() => onResultSeen(result.id)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {result.title || result.content.substring(0, 200)}
                  </a>
                )}
              />
              <div className='actions-wrapper'>
                {
                  isChecked && (
                    <Button
                      onClick={() => toggleAddToArray(result, setEditableRows, 'id')}
                      className='edit-btn'
                    >
                      + {messageTranslator(result.category ? "alerts.daily.result.editCategory.btn" : "alerts.daily.result.addCategory.btn", "alerts")}
                    </Button>
                  )
                }
                <div className={"status " + result.sentiment} />

                {
                  !result.folder ? (
                    <Form.Item className="small-check">
                      <Checkbox
                        onChange={(e) => {
                          toggleAddToArray(result, setChecked, 'id', setEditableRows);
                          handleAddResultToFolderArray(result);
                        }}
                        checked={isChecked}
                      />
                    </Form.Item>
                  ) : <Tag color="red">{messageTranslator("alerts.results.already.added", "alerts")} {moment(result?.folder?.createdAt).tz("Asia/Riyadh").format('DD MMM')}</Tag>
                }

                <div className='action-list'>
                  <Tooltip title={messageTranslator(result.bookmarked ? "alerts.daily.result.unbookmark.tooltip" : "alerts.daily.result.bookmark.tooltip", "alerts")}>
                    {
                      result.bookmarked
                        ?
                        <StarFilled onClick={() => onSetBookmarked({
                          id: result.id,
                          bookmarked: !result.bookmarked,
                          page
                        })} />
                        :
                        <StarOutlined onClick={() => onSetBookmarked({
                          id: result.id,
                          bookmarked: !result.bookmarked,
                          page
                        })} />
                    }

                  </Tooltip>
                  <Tooltip
                    title={messageTranslator(result.status === ARCHIVED ? "alerts.daily.result.unarchive.tooltip" : "alerts.daily.result.archive.tooltip", "alerts")}
                  >
                    {
                      result.status === ARCHIVED
                        ?
                        <FolderFilled onClick={() => onSetBookmarked({
                          id: result.id,
                          status: ACTIVE,
                          page
                        })} />
                        :
                        <FolderOutlined onClick={() => onSetBookmarked({
                          id: result.id,
                          status: ARCHIVED,
                          page
                        })} />
                    }
                  </Tooltip>
                  <Tooltip
                    title={messageTranslator(result.status === UNDESIRABLE ? "alerts.daily.result.unUndesirable.tooltip" : "alerts.daily.result.undesirable.tooltip", "alerts")}
                  >
                    {
                      result.status === UNDESIRABLE
                        ?
                        <ExclamationCircleFilled onClick={() => onSetBookmarked({
                          id: result.id,
                          status: ACTIVE,
                          page
                        })} />
                        :
                        <ExclamationCircleOutlined onClick={() => onSetBookmarked({
                          id: result.id,
                          status: UNDESIRABLE,
                          page
                        })} />
                    }
                  </Tooltip>
                  <Tooltip title={messageTranslator(result.status === DELETED ? "alerts.daily.result.restore.tooltip" : "alerts.daily.result.remove.tooltip", "alerts")}>
                    {
                      result.status === DELETED
                        ?
                        <DeleteFilled onClick={() => onSetBookmarked({
                          id: result.id,
                          status: ACTIVE,
                          page
                        })} />
                        :
                        <DeleteOutlined onClick={() => onSetBookmarked({
                          id: result.id,
                          status: DELETED,
                          page
                        })} />
                    }
                  </Tooltip>
                </div>
              </div>
              {
                !!findItemByKey(editableRows, result, 'id') && (
                  <UpdateResultCategoryFrom
                    result={result}
                    categories={categories}
                    stateDispatcher={setEditableRows}
                    onToggleCheckedItems={toggleAddToArray}
                    onSubmitUpdates={onSubmitResultCategory}
                  />
                )
              }
            </List.Item>
          )
        }}
      />
    </Col>
  </Row>
);
};
