import flattenDeep from 'lodash/flattenDeep';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';


export const getFlattenedRoutes = (routes) => {
  if (!routes) return [];
  return flattenDeep(routes.map(({ routes: subRoutes, ...rest }) => [rest, getFlattenedRoutes(subRoutes)]));
};

export const generateRoutes = (mainRoutes) => {
  const Routes = ({ authorized }) => {

    const layouts = mainRoutes.map(({ layout: Layout, routes }, index) => {
      const subRoutes = getFlattenedRoutes(routes);

      return (
        <Route key={index} path={subRoutes.filter(route => route.path).map(route => route.path)} exact={subRoutes.some(route => route.exact)}>
          <Layout>
            <Switch>
              {subRoutes.map(route => {
                return (
                  route.path &&
                  route.component && (
                    <ProtectedRoute
                      exact={!!route.exact}
                      path={route.path}
                      key={`${index}-${route.path}`}
                      isAuthorized={authorized}
                      component={route.component}
                      isPublic={!!route.isPublic}
                      title={ route.title }
                      withProps={{
                        route: route,
                      }}
                    />
                  )
                );
              })}
            </Switch>
          </Layout>
        </Route>
      );
    });
    return <Switch>{layouts}</Switch>;
  };

  return Routes;
};