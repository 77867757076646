import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { INBOX, PUBLIC_FOLDER } from '../../../routes/paths';
import { DATE_FORMAT } from '../../../utils/moment-utils';
import { Avatar, Row, Col, Typography, List } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ConfigContext } from '../../../context/config';
import { tablePaginationOptions } from '../../../utils/utils';
import "./notifications.less";

export const NotificationsList = ({ notifications = [], onClick, currentPage,
    totalItems, onPageChange }) => {

    const { messageTranslator } = useContext(ConfigContext);
  
    const [, setPage] = useState(1);

    const calculateElapsedTime = (time) => {
        const min = moment().diff(time, 'minutes');
        if (!min) return `${1}${messageTranslator("notifications.list.time.minutes", "notifications")}`;
        if (min <= 59) return `${min}${messageTranslator("notifications.list.time.minutes", "notifications")}`;
        const hours = Math.round(min / 60);
        if (hours <= 23) return `${hours}${messageTranslator("notifications.list.time.hours", "notifications")}`;
        const days = Math.round(hours / 24);
        if (days <= 6) return `${days}${messageTranslator("notifications.list.time.days", "notifications")}`;
        return moment(time).format(DATE_FORMAT);
      };

      const handlePaginationClick = (currentPage) => {
        setPage(currentPage);
        onPageChange(currentPage);
      };

      return (
        <Row gutter={[18, 24]}>
          <Col span={24}>
            <List 
              size="large"
              dataSource={[...notifications]}
              pagination={tablePaginationOptions({
                totalItems,
                pageSize: 30,
                currentPage,
                onPageChange: handlePaginationClick,
                messageTranslator
              })}
              locale={{
                emptyText: messageTranslator("notifications.list.empty", "notifications")
              }}
              renderItem={result => {
                return (
                  <List.Item key={result['@id']} className={result.seen ? "" : "unseen"} id={`${result.id}`}>
                    <Link className="notification-item" to={`${result.notificationType === 'FOLDER_PUBLISHED' ? `${PUBLIC_FOLDER}/${result.parentId}/folders` : result.notificationType === 'FOLDER_UNPUBLISHED' ? `${INBOX}/${result.parentId}/daily` : `${INBOX}/${result.parentId}/daily`}`} onClick={() => onClick(result.id)}>
                      <Row wrap={false} justify="space-between" align="middle" gutter={10}>
                        <Col flex="0 1 48px">
                          <Avatar
                            size={38}
                            src={!!result.actionDoneByUser?.image?.contentUrl && `${process.env.REACT_APP_BASE_URL}${result.actionDoneByUser.image.contentUrl}`}
                            icon={!result.actionDoneByUser && <UserOutlined />}
                          />
                        </Col>
                        <Col flex="1 1 auto" style={{ display: "flex", flexDirection: "column" }}>
                          <Typography.Text>
                            {messageTranslator(result.notificationType, "notifications")}
                          </Typography.Text>
                          <Typography.Text className='notification-note' style={{ whiteSpace: "pre-line" }}>
                            {result?.notificationText}
                          </Typography.Text>
                        </Col>
                        <Col flex="0 1 80px" className='text-right'>
                          <Typography.Text>
                            {calculateElapsedTime(result.createdAt)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Link>
                  </List.Item>
                );
              }}
            />
          </Col>
        </Row>
      );
};
