import { Card, Col, Row, Typography, Tag } from 'antd';
import React, { useContext } from 'react';
import { ConfigContext } from '../../../../../context/config';
import { FOLDER_STATES, ROLES } from '../../../../../utils/constants';
// import './folder-banner.less';

export const NotAuthorizedBanner = ({
  folder,
  currentUser,
}) => {

  const { messageTranslator } = useContext(ConfigContext);

  const renderNotAuthorizedBanner = (folder, meData) => {
    return !!(
      meData
      &&
      folder &&
      (folder.editor || folder.auditor || folder.publisher )
      &&
      (folder &&
        ((meData.role.title === ROLES.EDITOR && folder.moderationState === FOLDER_STATES.DRAFT && meData?.id !== folder.editor?.id)
          ||
          (meData.role.title === ROLES.AUDITOR && folder.moderationState === FOLDER_STATES.NEEDS_REVIEW && meData?.id !== folder.auditor?.id)
          ||
          (meData.role.title === ROLES.PUBLISHER && folder.moderationState === FOLDER_STATES.PUBLISHER_CHECK && meData?.id !== folder.publisher?.id))
      )
    )
  };

  if (renderNotAuthorizedBanner(folder, currentUser))
    return (
      <Card
        className='folder-banner'
        size='small'
      >
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
            <Row justify="space-between" align='middle'>
              <Col flex="auto" align="middle" xs={24}>
                {currentUser.role.title === ROLES.EDITOR && <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.editor.notAuthorized", "alerts")} <Tag color="#00e096">{folder?.editor?.firstName} {folder?.editor?.lastName}</Tag></Typography.Title>}
                {currentUser.role.title === ROLES.AUDITOR && <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.auditor.notAuthorized", "alerts")} <Tag color="#00e096">{folder?.auditor?.firstName} {folder?.auditor?.lastName}</Tag></Typography.Title>}
                {currentUser.role.title === ROLES.PUBLISHER && <Typography.Title level={5}>{messageTranslator("alerts.daily.banner.publisher.notAuthorized", "alerts")} <Tag color="#00e096">{folder?.publisher?.firstName} {folder?.publisher?.lastName}</Tag></Typography.Title>}
                
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  return null;
};
