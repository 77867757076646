import { api } from '..';

const URL = "/api/alert_results/stats";

/**
 * 
 * @param {{
 * action: "comparison" | "count",
 * subject: "sentiment" | "source-type" | "hashtags" | "tags" | "countries" | "influencers",
 * type: "unit" | "period",
 * unit: string,
 * number: number,
 * limit: number,
 * after: Date,
 * before: Date,
 * alert: string
 * }} object 
 * @returns Promise<AxiosResponse<any, any>>
 */
export const getStatsApi = ({ action, subject, type, unit, number, limit, after, before, alert }) => {
  let query = `${URL}?`;
  if (type === "unit") {
    query += `action=${action}&subject=${subject}&type=${type}&unit=${unit}&number=${number}`;
  } else {
    query += `action=${action}&subject=${subject}&type=${type}&after=${after}&before=${before}`;
  }
  if (action === "count") query += `&limit=${limit}`;
  if (alert && alert !== "all") query += `&alert=${alert}`
  return api.get(query);
}

export const getImportantNewsStatsApi = ({ type, unit, after, before, alert }) => {
  let query = `/api/alert_results/most-important-news-and-topics?`;
  if (type === "unit") {
    query += `type=${type}&unit=${unit}`;
  } else {
    query += `type=${type}&after=${after}&before=${before}`;
  }
  if (alert && alert !== "all") query += `&alert=${alert}`
  return api.get(query);
}

